import React from 'react' 
import {Container,Row,Col,Image,Button} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';

import img1 from '../../assets/images/repuestos/1.JPG'
import img2 from '../../assets/images/repuestos/2.JPG'
import img3 from '../../assets/images/repuestos/3.JPG'
import img4 from '../../assets/images/repuestos/4.JPG'
import img5 from '../../assets/images/repuestos/5.JPG'
import img6 from '../../assets/images/repuestos/6.JPG'

export default class PostSale extends React.Component{

    render(){
        return(
            
            
            <React.Fragment>
                <Container fluid style={{backgroundColor:'#0033a1',marginTop:0, minHeight:500, paddingTop:40, }}>
                    <Container style={{paddingTop:60,paddingBottom:40}}>
                        <Row >
                            <Col md={12}>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h1 className="text-center sectionTitleWhite">Jamás volverás a estar solo</h1>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <p className="text-center sectionSubTitleWhite">
                                        Nuestros servicio de POST VENTA está dirigido a todos nuestros clientes y aliados
                                        como canal de conexión Comercio-Banco (B2B) y de atención preventiva y correctiva
                                    </p>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container >
                    <Container style={{paddingTop:0}}>
                     <Row >
                        <Col md={8} className='wraper-image' style={{height:380}}>
                            <div className='box-image'
                                style={{
                                                backgroundImage: "url('https://cdn.pixabay.com/photo/2017/08/01/08/29/people-2563491_960_720.jpg')",
                                                opacity:.3,
                                            }}/>
                            <div className='text-center content'>
                                    <h1 > Plataforma tecnológica de administración de tickets. </h1>
                                    <p>Obtenga información de sus cierres de lote digitales en su email, con el detalle de cada venta en formato de excel para sus conciliaciones.</p>
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                            </div>
                            
                        </Col>
                        <Col md={4} className='wraper-image' style={{height:380}}>
                            <div   className='box-image'
                                style={{
                                        backgroundImage: "url('https://cdn.pixabay.com/photo/2015/01/15/12/46/model-600225_960_720.jpg')",
                                        opacity:.3,
                                        }}/>
                            <div className='text-center content'>
                                <h1 > Servicio técnico y mantenimiento  </h1>
                                <p> Contamos con los mejores especialistas técnicos para la reparación de sus dispositivos, y empleamos repuestos originales ofrecidos por el fabricante.</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                            </div>
                        </Col>
                        <Col md={4} className='wraper-image' style={{height:380}}> 
                            <div className='box-image'
                                    style={{
                                            backgroundImage: "url('https://cdn.pixabay.com/photo/2016/01/19/15/05/doctor-1149149_960_720.jpg')",
                                            opacity:.3,
                                            }}/>
                                <div className='text-center content'>
                                    <h1 > Asistencia telefónica  </h1>
                                    <p> Cuente con el Centro de Atención al Usuario que le ofrece toda la asistencia técnica y funcional que necesita sobre nuestros equipos.</p>
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                                </div>                    
                        </Col>
                        <Col md={3} className='wraper-image' style={{height:380}}>
                            <div className='box-image'
                                style={{    
                                            backgroundImage: "url('https://cdn.pixabay.com/photo/2015/01/08/18/29/entrepreneur-593358_960_720.jpg')",
                                            opacity:.3,
                                        }}/>
                            <div className='text-center content'>
                                    <h1 > Atención Exclusiva </h1>
                                    <p>Tu negocio será atendido de forma personalizada.</p>
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                            </div>
                        </Col>
                        <Col md={2} className='wraper-image' style={{height:380}}>
                            <div   className='box-image'
                                style={{
                                        backgroundImage: "url('https://cdn.pixabay.com/photo/2015/01/15/12/46/model-600225_960_720.jpg')",
                                        opacity:.3,
                                        }}/>
                            <div className='text-center content'>
                                <h1 > Garantía </h1>
                                <p>Garantía de 1 año. Todos los POS poseen un años de garantía, por fábrica.</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                            </div>
                        </Col>
                        <Col md={3} className='wraper-image' style={{height:380}}> 
                            <div className='box-image'
                                    style={{
                                            backgroundImage: "url('https://cdn.pixabay.com/photo/2016/03/23/04/01/beautiful-1274056_960_720.jpg')",
                                            opacity:.3,
                                            }}/>
                                <div className='text-center content'>
                                    <h1 > Homologación Bancaria </h1>
                                    <p>Realizamos todo el proceso de conexión y configuración del POS con su banco. Cada equipo es entregado operativo.</p>
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                                <title>arrow1</title>
                                            <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                        </svg>
                                </div>                    
                        </Col>
                       
                </Row>
                    </Container>
                </Container>
                <Container fluid  style={{backgroundColor:'#FFF',}}>
                    <Container >
                        <Row >
                            <Col md={12}>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <h1 className="text-center sectionTitle">¿Necesitas repuestos?</h1>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <p className="text-center sectionSubTitle">
                                    Si tu Punto de Venta tiene alguna avería en cualquiera de las piezas internas o externas, puedes buscar con nosotros los respuestos que necesites 100% originales y con la calidad que mereces.

                                    </p>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                    <div style={{ 
                                                background:`url(${img1})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                    <div style={{ 
                                                background:`url(${img2})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                <div style={{ 
                                                background:`url(${img3})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                    <div style={{ 
                                                background:`url(${img4})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                    <div style={{ 
                                                background:`url(${img5})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                            <Col xs={6} md={2}>
                                <div className='text-center'>
                                    <div style={{ 
                                                background:`url(${img6})`,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:'#b3b3b3', 
                                                width:100,
                                                height:100,
                                                borderRadius:'50%',
                                                margin: '20px auto',
                                                boxShadow:'3px 3px 10px #d8d8d8',
                                                
                                                }}/>
                                    {/* <p style={{margin:'0 auto'}}>Título</p> */}
                                </div>
                            </Col>
                        </Row>
                 
                        </Container>
                    </Container>
               
            </React.Fragment>
     
     )
    }


}