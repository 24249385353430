import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import Logo3 from "../../assets/images/logo.svg"
import Loading from '../Loading/Loading'

export default class Header extends React.Component{
    constructor(props){
        super(props)
        this.state={
                    loading:true,
                    width:window.innerWidth,
                    particleSize:2,
                    particleGap:2,
                    }
      
    }
 
    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);

        var i=0;
        var logo=document.getElementById('particles-canvas')
        var canvas = setInterval(getCanvas.bind(this), 500);//ejecuta la funcion getcanvas cada segundo

        function getCanvas() {
          if (i == 20 || (logo!==null&&logo!==undefined&&logo!=='')) {
               
                this.setState({loading:false})
                clearInterval(canvas);//detiene el llamado del serInterval
          } 
          else {
            i++;
            logo=document.getElementById('particles-canvas')
          }
        }
       
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render(){
            return(
                <React.Fragment>
                {(this.state.loading)?<Loading/>:null}
                    <Container fluid style={{
                                               
                                                background:(this.props.backgroundImage!=null&&this.props.backgroundImage!=undefined&&this.props.backgroundImage!='')?this.props.backgroundImage:null,
                                                backgroundPosition:'center',
                                                backgroundSize:'cover',
                                                backgroundColor:"#001740", 
                                                color:'#FFF',
                                                }}>
                
                        <Row style={{
                                        backgroundColor:(this.props.filterColor!=null&&this.props.filterColor!=undefined&&this.props.filterColor!='')?this.props.filterColor:null,
                                        paddingBottom: '50px', 
                                        minHeight: '550px',
                                    }}>
                        {/* {
                            (this.props.backgroundImage!=null&&this.props.backgroundImage!=undefined&&this.props.backgroundImage!='')?
                            <div style={{
                                position:'absolute',
                                height:(768<window.innerWidth)?window.innerHeight-window.innerHeight*0.19:window.innerHeight-window.innerHeight*0.3,
                                width:'100%',
                                top:0,
                                left:0,
                                backgroundColor:(this.props.filterColor!=null&&this.props.filterColor!=undefined&&this.props.filterColor!='')?this.props.filterColor:'rgba(0, 23, 64, 0.9)',
                                }}/>
                            :
                            null
                        }
                         */}
                            <Col md={12} className='text-center' >
        
        
                            <img 
                                id='logo' 
                                className="next-particle"
                                src={Logo3} 
                                alt="logo"
                                width= '550px'
                                data-gravity="0.07"
                                data-noise="10"
                                //data-renderer='webgl'
                                data-particle-gap={this.state.particleGap}
                                data-particle-size={this.state.particleSize}
                                data-mouse-force="20"
                                data-width={window.innerWidth-95}
                                data-height={(768<window.innerWidth)?window.innerHeight-window.innerHeight*0.3:window.innerHeight-window.innerHeight*0.5}
                                data-min-width={(768<window.innerWidth)?500:this.state.width}
                                data-min-height={(768<window.innerWidth)?500:this.state.width}
        
                                />
                            <p className='header-text'>
                            Mantenimiento y Reparación de equipos PAX certificados de acuerdo a los estándares más altos de calidad y seguridad en productos de Medios de Pago</p>
                          
                           
                            </Col>
                        </Row>
                     
                    </Container>
                </React.Fragment>
                )
        }

}