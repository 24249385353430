import React from "react"
import {Animated} from "react-animated-css";
import {
    Button, 
   } from 'react-bootstrap';
   import {appServer} from '../../connection'

export default class MenuMobile extends React.Component{

    constructor(props){
        super(props)
        this.state={
            height:window.innerHeight,
            width:window.innerWidth,
            openBody:false
        }
    }
    toggle(){
        this.setState({openBody:!this.state.openBody})
        var element= document.getElementsByClassName('iconHamburger')
        element[0].classList.toggle("active")

        var elem= document.querySelector('body')
            elem.classList.toggle("overflowHide")
         

    }
    goTo(data){
        this.toggle()
        // var element=document.getElementById(data)
        // element.scrollIntoView({behavior:'smooth'})
        // console.log(element)
    
    }
    closeSession(){
        localStorage.setItem("tokenPOS",'')
        localStorage.setItem("userPOS",'')
        localStorage.setItem("rolPOS",'')
        window.location.reload()

    }
    componentDidMount(){
        var token=localStorage.getItem("tokenPOS")
        var user=localStorage.getItem("userPOS")
            user=(user!==''&&user!==null&&user!==undefined)?JSON.parse(user):''
        this.setState({
                        token:token,
                        user:user,
                    })
    }
  
    render(){
        return(
            <React.Fragment>
                <div className="iconHamburger" onClick={()=>this.toggle()}>  
                    <div className="hamburger">

                    </div>
                </div>
                {
                    (this.state.openBody)?
                    <div id="bodyMenu">
                    <div>
    
                    <Animated animationIn={"slideInLeft"}animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            <a  href="/"onClick={()=>{this.goTo('servicios')}}>
                                        <h4>INICIO</h4>
                            </a>
                    </Animated>
                    <Animated animationIn={"slideInRight"} animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            <a  href="/nosotros"onClick={()=>{this.goTo('aboutUs')}}>
                                        <h4>NOSOTROS</h4>
                            </a>
                    </Animated>
                    <Animated animationIn={"slideInLeft"} animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            <a  href="/productos"onClick={()=>{this.goTo('products')}}>
                                        <h4>PRODUCTOS</h4>
                            </a>
                    </Animated>
                    <Animated animationIn={"slideInRight"} animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            <a href="/requisitos" onClick={()=>{this.goTo('products2')}}>
                                        <h4>REQUISITOS</h4>
                            </a>
                    </Animated>
                    <Animated animationIn={"slideInLeft"} animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            {/* <a href="/certificaciones" onClick={()=>{this.goTo('products3')}}>
                                        <h4>CERTIFICACIONES</h4>
                            </a> */}
                            <a href="/contacto" onClick={()=>{this.goTo('products2')}}>
                                        <h4>CONTACTO</h4>
                            </a>
                    </Animated>
                    <Animated animationIn={"slideInRight"} animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
    
                            {/* <a href="/contacto" onClick={()=>{this.goTo('products2')}}>
                                        <h4>CONTACTO</h4>
                            </a> */}
                             <Button variant="primary"  href={appServer} style={{padding:5,width:'11rem'}} onClick={()=>{this.goTo('servicios')}}>
                             SOLICITUD ONLINE
                        </Button>
                    </Animated>
                    {
                        (this.state.token!==''&&this.state.token!==null&&this.state.token!==undefined)?
                        <Animated animationIn={"slideInRight"}animationInDuration={1000} animationOut="fadeOut" isVisible={true}>
                             <a href="#" onClick={()=>this.closeSession()}>
                                        <h4 style={{fontSize:19}}>CERRAR SESIÓN</h4>
                            </a>
                        </Animated>
                        
                        :null
                    }
                    
                    </div>
                    </div>
                    :null    
                }	      
            </React.Fragment>
        )
    }
} 