import React from "react"
import { Container, Row, Col,Button, Image} from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import img1 from '../../../assets/images/home/services/01.svg'
import img2 from '../../../assets/images/home/services/02.svg'
import img3 from '../../../assets/images/home/services/03.svg'
import img4 from '../../../assets/images/home/services/04.svg'
import img5 from '../../../assets/images/home/services/05.svg'
import img6 from '../../../assets/images/home/services/06.svg'
import NextDiv from '../../../assets/images/next-div.svg'

export default class Services extends React.Component{
   
    render(){
        return(
        <React.Fragment>
            <Container >
                <Row>
                  <Col xs={12}>
                      <h1 className="text-center sectionTitle" >Tecnología inalámbrica de pagos</h1>
                    <p className="text-center sectionSubTitle">
                    POSITIVO te ofrece una gama de productos de alta tecnología digital que te permitirá llegar donde quieras.</p>
                       
                  </Col>
                </Row>
                <Row >

                        <Col md={4} style={{margin: "20px 0 20px 0",}} >
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className='text-center'>
                                    <img  style={{
                                            width:120,
                                            height:120,
                                            }}
                                            src={img1}
                                            />
                                    <p className='services-text'>Amplia aceptación de
                                        diversas Franquicias y tarjetas
                                        Contactless</p>
                                </div>
                                
                            </ScrollAnimation>
                        </Col>

                        <Col md={4} style={{margin: "20px 0 20px 0",}} >
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className='text-center'>
                                <img  style={{
                                            width:120,
                                            height:120,
                                            }}
                                            src={img2}
                                            />
                                    <p className='services-text'>Control de ventas diarias a
                                        través de plataforma digital</p>
                                </div>
                            </ScrollAnimation>
                        </Col>

                        <Col md={4} style={{margin: "20px 0 20px 0",}} >
                            <ScrollAnimation animateIn="fadeInUp">
                            <div className='text-center'>
                                <img  style={{
                                            width:120,
                                            height:120,
                                            }}
                                            src={img3}
                                            />
                                    <p className='services-text'>Tecnología inalámbrica de
                                        pagos con pantallas táctil
                                        </p>
                                </div>
                            </ScrollAnimation>
                        </Col>
                </Row>
                 <Row >

                    <Col md={4} style={{margin: "20px 0 20px 0",}}>
                            <ScrollAnimation animateIn="fadeInUp">
                            <div className='text-center'>
                                <img  style={{
                                            width:120,
                                            height:120,
                                            }}
                                            src={img4}
                                            />
                                    <p className='services-text'>Registro de transacciones
                                        almacenados en la nube con
                                        acceso seguro</p>
                                </div>
                            </ScrollAnimation>
                        </Col>

                        <Col md={4} style={{margin: "20px 0 20px 0",}}>
                            <ScrollAnimation animateIn="fadeInUp">
                            <div className='text-center'>
                                    <img  style={{
                                            width:120,
                                            height:120,
                                            }}
                                            src={img5}
                                            />
                                    <p className='services-text'>Desmaterializamos el papel
                                        impreso / Registros
                                        digitalizados en plataforma</p>
                                </div>
                            </ScrollAnimation>
                        </Col>

                        <Col md={4} style={{margin: "20px 0 20px 0",}}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className='text-center'>
                                    <img  style={{
                                                width:120,
                                                height:120,
                                                }}
                                                src={img6}
                                                />
                                    <p className='services-text'>Alta velocidad e interacción
                                        End to End en la transacción</p>
                                </div>
                            </ScrollAnimation>
                        </Col>
                </Row>
                <Row className="justify-content-center">
                    <ScrollAnimation animateIn="fadeInUp">
                        <div style={{cursor:'pointer'}} onClick={()=>{
                            document.querySelector('#productos').scrollIntoView({
                                behavior: 'smooth'
                            });
                        }} >
                        <img 
                            src={NextDiv}
                            />
                        </div>
                    </ScrollAnimation>
                </Row>
                {/* <Row >
                    <Col md={12} className='d-flex justify-content-center'>
                        <ScrollAnimation animateIn="fadeInUp">
                            <Button  variant="outline-secondary"  href="#home" >
                                Más servicios
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                    <title>arrow1</title>
                                    <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                </svg>
                            </Button>
                        </ScrollAnimation>
                    </Col>
                </Row> */}
            </Container>
         </React.Fragment>
        )
    }

}