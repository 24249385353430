import React from 'react'
import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Form,
    Modal,
    } from 'react-bootstrap'


import  {domainServer }  from '../../connection'
import Modalregistrer from '../../Components/ModalSession/Modalregistrer'

import Lottie from "react-lottie-wrapper";

import * as animationData from "./confetti-falling.json"



export default class Confirm extends React.Component{

    constructor(props){
        super(props)
        this.state={
            show:false,
            errors:[],
            email:'',//email para login
            password:'',//password para login
            loadingLogin:false,//password para login

            show:false,
            showCreate:false,
        }
    }

  
    render(){
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          };
        return(
            <React.Fragment>
                <div className="container-fluid" style={{backgroundColor:'#F5F5F5'}}>
                <div className="container" style={{
                                                    minHeight:'100vh',
                                                    }}>
                    <div style={{
                                position:'absolute',
                                width:400,
                                backgroundColor:'#FFFFFF',
                                padding:20,
                                top:'50%',
                                left:'50%',
                                transform:'translate(-50%,-50%)',
                                }} >

                        <div className="row">
                            <div className="col-md-12">
                                    <h2 style={{fontSize:'19px',textAlign:'center'}}>Gracias por confirmar tu correo</h2>
                            </div>
                        </div>
                        <div className="row" >
                                <div className="col-md-12 text-center">
                                <Lottie 
                                        options={defaultOptions}
                                        height={300}
                                        width={300}
                                        style={{margin: '0 auto',}}
                                        />
                                </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-12  text-center">

                                <Button variant="primary" 
                                        style={{width:'100%'}}
                                        href='/pre-registro'
                                        >
                                        Iniciar sesión
                                </Button>
                               
                            </div>
                        </div>
                    </div>

                </div>
                </div>
                <Modalregistrer
                    show={this.state.showCreate}
                    close={(e)=>{this.setState({showCreate:e})}}
                />
            </React.Fragment>
        )}




}