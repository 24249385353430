import React from 'react'
import img2 from '../../assets/images/certifications/image2.svg'
import img3 from '../../assets/images/certifications/image3.svg'
import img4 from '../../assets/images/certifications/image4.svg'
import img5 from '../../assets/images/certifications/image5.svg'
import img6 from '../../assets/images/certifications/image6.svg'
import img7 from '../../assets/images/certifications/image7.svg'
import img8 from '../../assets/images/certifications/image8.svg'
import img9 from '../../assets/images/certifications/image9.svg'
import img10 from '../../assets/images/certifications/image10.svg'
import img11 from '../../assets/images/certifications/image11.svg'
import {Container,Row,Col,Image,Button} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';



export default class Certifications extends React.Component{

    render(){
        return(
            <React.Fragment>
                <Container fluid  style={{height:536, backgroundColor:'#001740', color:'#FFF',}}>
                    <Container>
                        <Row>
                                <Col md={6} >
                                        <h1 >Certificaciones</h1>
                                        <p style={{marginTop:40}}>Nuestros POS están debidamente certificados por todos los entes regulatorios a fin
                                            de proteger la información de forma efectiva y con los más altos estándares de
                                            seguridad y calidad en nuestros equipos.
                                        </p>
                                </Col>
                            </Row>
                    </Container>
                </Container>
                <Container fluid style={{backgroundColor:'#FFF',marginTop:0, paddingBottom:80,paddingTop:65, }}>

                <Container style={{
                                    position:'relative',
                                    top:'-144px',
                                    marginTop:0,
                                    padding:0,}}>
                    
                    <ScrollAnimation animateIn="fadeIn">
                        <Row style={{
                            display:'flex',
                            justifyContent:'center',
                        }}>
                        <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                    className='mx-auto d-block'
                                    fluid
                                    src={img2}
                                    alt='product'
                                />
                                </div> 
                            </Col>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                    fluid
                                    src={img3}
                                    alt='product'
                                />
                                </div> 
                            </Col>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                    fluid
                                    src={img4}
                                    alt='product'
                                />
                                </div> 
                            </Col>
                           
                        </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                        <Row style={{
                                        display:'flex',
                                        justifyContent:'center',
                                        paddingTop:50
                                        }}>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                        fluid
                                        src={img10}
                                        alt='product'
                                  
                                  />
                                  </div> 
                            </Col>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block py-2'
                                        fluid
                                        src={img6}
                                        alt='product'
                                  
                                  />
                                  </div> 
                            </Col>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                    fluid
                                    src={img7}
                                    alt='product'
                                />
                                </div> 
                            </Col>
                           
                        </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                        <Row style={{
                                        display:'flex',
                                        justifyContent:'center',
                                        paddingTop:50
                                        }}>
                        <Col md={3} className='certifications-item '>
                            <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                    fluid
                                    src={img8}
                                    alt='product'
                                />
                                </div> 
                            </Col>
                          
                            <Col md={3} className='certifications-item '>
                            <div className='bank-animation'>
                                <Image 
                                        className='mx-auto d-block'
                                        fluid
                                        src={img5}
                                        alt='product'
                                  
                                  />
                                  </div> 
                            </Col>
                            <Col md={3} className='certifications-item '>
                                <div className='bank-animation'>

                                <Image 
                                        className='mx-auto d-block'
                                        fluid
                                        src={img11}
                                        alt='product'
                                  
                                  />
                                  </div> 
                            </Col>
                        </Row>
                        
                    </ScrollAnimation>
                    
                </Container>
                </Container>
            </React.Fragment>
        )
    }



}