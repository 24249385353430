import React from "react"
import { Container, Row, Col,Button,Image, } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
export default class ProductLeft extends React.Component{
  
    render(){
        return(
        <React.Fragment>
            <Container style={{paddingBottom:40,paddingTop:0}}>
                <Row style={{ color:'#FFF',textAlign:'right'}}>
                  <Col sm={6}>
                    <ScrollAnimation animateIn="fadeInLeft">
                        <Image 
                            src={this.props.img}
                            alt='product'
                            height={(window.innerWidth<=768)?'400':'600'}
                        /> 
                    </ScrollAnimation>
                  </Col>
                  <Col sm={6}>
                    <ScrollAnimation animateIn="fadeInRight">
                        <div
                            style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                            }}
                        >

                            <h1 className="sectionTitle" >{this.props.title}</h1>
                            <p className="subtitle-product">
                                {this.props.subtitle}
                            </p>
                            <span style={{ 
                                backgroundColor: this.props.codeColor,
                                               padding:'6px 23px',
                                               borderRadius: '20px',
                                               fontSize:'16px',
                                        }}>
                                {this.props.code}
                            </span>
                            
                            <p style={{marginTop:20, maxWidth:'400px',fontSize:'17px'}}>
                                {this.props.text}
                            </p>
                            <Button className='button-product'  href={this.props.href}  >
                                    {this.props.textButton}
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                        <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </Button>
                        </div>
                    </ScrollAnimation>
                  </Col>
                </Row>
                
            </Container>
         </React.Fragment>
        )
    }

}