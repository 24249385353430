import React from 'react'
import {Container,Row,Col,Button} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';


export default class GetPos extends React.Component{

render(){
    return(
        <React.Fragment>
            <Container fluid style={{color:'#FFF',marginTop:100,paddingBottom:40, paddingTop:0}}>
            <Container >
              
                <Row >
                    <Col md={12} >
                        <h1 className='text-center sectionTitle'>¿Quieres saber como obtener un pos?</h1>
                        <p className='text-center sectionSubTitle'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                        <div className='text-center'>
                            <Button variant="outline-secondary" href='/requisitos'>
                                    Ver más 
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                        <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            </Container>

        </React.Fragment>
    )
}


} 