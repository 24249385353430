import React from "react";
import img2 from "../../../assets/images/bank/BNC.png";
import img3 from "../../../assets/images/bank/bank2.png";
import img4 from "../../../assets/images/bank/bank3.png";
import img5 from "../../../assets/images/bank/bank4.png";
import img6 from "../../../assets/images/bank/bank5.png";
import img7 from "../../../assets/images/bank/bank6.png";
import img8 from "../../../assets/images/bank/MiBanco.png";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";

export default class Banks extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container
          fluid
          style={{
            backgroundColor: "#FFF",
            marginTop: 0,
            paddingBottom: 0,
            paddingTop: 0,
            justifyContent: "center",
          }}
        >
          <Container style={{ backgroundColor: "#FFF", marginTop: 0, }}>
            <Row>
              <Col md={12}>
                <ScrollAnimation animateIn="fadeIn">
                  <h1 className="text-center sectionTitle">Bancos Aliados</h1>
                  {/* <p className="text-center sectionSubTitle" >
                                Conoce nuestros bancos aliados con los que podrás abrir tu cuenta bancaria y gestionar tu código de afiliación.</p> */}
                </ScrollAnimation>
              </Col>
            </Row>
            <ScrollAnimation animateIn="fadeIn">
              <Row>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img2}
                      alt="product"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img3}
                      alt="product"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img4}
                      alt="product"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img5}
                      alt="product"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img7}
                      alt="product"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img6}
                      alt="product"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="bank-animation">
                    <Image
                      className="mx-auto d-block "
                      fluid
                      src={img8}
                      alt="product"
                    />
                  </div>
                </Col>
              </Row>
            </ScrollAnimation>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}
