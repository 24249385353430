import React from 'react'
import {Container,Row,Col,Image,Button} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';

import img1 from '../../../assets/images/testimonio/arturos.png'
import img2 from '../../../assets/images/testimonio/braseros.png'
import img3 from '../../../assets/images/testimonio/maxidonas.png'
import img4 from '../../../assets/images/testimonio/ny&co.png'
import img5 from '../../../assets/images/testimonio/urrutia.png'
import img6 from '../../../assets/images/testimonio/memphis.png'

export default class Testimony extends React.Component{

    render(){
        return(
            <React.Fragment>
                <Container fluid style={{backgroundColor:'#16181c',color:'#FFF',marginTop:0, paddingBottom:0, paddingTop:70}}>
                <Row>
                    <Col md={12}>
                            <ScrollAnimation animateIn="fadeIn">
                                <h1 className="text-center sectionTitleWhite">Testimonios</h1>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn">
                                <p className="text-center sectionSubTitle">Esto es lo que algunos clientes dicen acerca de nosotros y nuestros servicios y estamos muy orgullosos de compartirlos contigo.
                                </p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                <Row>
                    <Col md={4} className='wraper-image'>
                        <div className='box-image'
                            style={{
                                            backgroundImage: `url(${img1})`,
                                        }}/>
                        <div className='text-center content'>
                                <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                    Pollos Arturo´s
                                    <p style={{color:'#FFF',fontSize:'1.1rem'}}> A nivel nacional </p>    
                                </h1>
                                
                                <p>“En cuanto a su servicio técnico, han sido bastante rápidos, nos sentimos respaldados y confiados de trabajar con Positivo Plus. Excelente servicio.“</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                        </div>
                        
                    </Col>
                    <Col md={4} className='wraper-image'>
                        <div   className='box-image'
                            style={{
                                        backgroundImage: `url(${img2})`,
                                    }}/>
                        <div className='text-center content'>
                            <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                    Braseros Grill
                                    <p style={{color:'#FFF',fontSize:'1.1rem'}}>Barquisimeto </p>    
                            </h1>
                            <p>“El 90% de nuestras ventas son con tarjetas, el tener un punto de venta con Positivo Plus es una de las mejores decisiones que hemos tomado.”</p>
                            <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                        </div>
                    </Col>
                    <Col md={4} 
                         className='wraper-image'> 
                        <div className='box-image'
                                style={{
                                            backgroundImage: `url(${img3})`,
                                        }}/>
                            <div className='text-center content'>
                                <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                    Maxidonas
                                        <p style={{color:'#FFF',fontSize:'1.1rem'}}>Caracas </p>    
                                </h1>
                                <p>“Decidimos comprar Puntos de Positivo porque nos lo recomendaron. Atención muy buena, tienen buenos esquemas de comunicación con los clientes.“</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </div>                    
                    </Col>
                    <Col md={4} className='wraper-image'>
                        <div className='box-image'
                            style={{
                                    backgroundImage: `url(${img4})`,
                                        }}/>
                        <div className='text-center content'>
                                <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                    Tiendas Ny & Co
                                    <p style={{color:'#FFF',fontSize:'1.1rem'}}> Caracas </p>    
                                </h1>
                                <p>“Los conocimos en un operativo de POS, nuestro banco aliado nos lo recomendó, desde entonces estamos muy complacidos en el servicio que nos presta.“</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                        </div>
                    </Col>
                    <Col md={4} className='wraper-image'>
                        <div   className='box-image'
                            style={{
                                    backgroundImage: `url(${img5})`,
                                    }}/>
                        <div className='text-center content'>
                                <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                        Casa Urrutia
                                        <p style={{color:'#FFF',fontSize:'1.1rem'}}> Caracas </p>    
                                </h1>
                            <p>“Al principio no supimos nada del proceso, la vendedora fue muy amable y nos guio amablemente en todo el proceso de compra de nuestros equipos“</p>
                            <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                        </div>
                    </Col>
                    <Col md={4} 
                         className='wraper-image'> 
                        <div className='box-image'
                                style={{
                                        backgroundImage: `url(${img6})`,
                                        }}/>
                            <div className='text-center content'>
                                <h1 className='sectionTitleWhite' style={{lineHeight: '2.5rem'}}> 
                                        Memphis
                                        <p style={{color:'#FFF',fontSize:'1.1rem'}}> Maiquetía </p>    
                                </h1>
                                <p>“El proceso de compra y entrega fue rápido. Lo que más nos gusta es la atención que prestan una vez requerimos de la atención de un técnico.“</p>
                                <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </div>                    
                    </Col>
                </Row>

                {/* <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <Button variant='outline-primary'>
                                    Ver más
                                        <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                            <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </Button>
                        </div>
                    </Col>
                </Row> */}

               </Container>
            </React.Fragment>
        )
    }



}