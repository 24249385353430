import React from 'react'

class  Block extends React.Component{

    render(){
        return( 
        <div 
            style={{
                /* Vector */
                position: (this.props.position!==''&&this.props.position!==null&&this.props.position!==undefined)?this.props.position:'',
                width: 132,
                height: 79,
                margin:'0 auto',
                top: this.props.top,
                left: this.props.left,
                background: '#001740',
                border: '2px solid #d2beb0',
                boxSizing: "border-box",
                borderRadius: 3,
                color:'#FFF',
                display:(this.props.show)?'block':'none',
                opacity:(this.props.show)?1:0,
                transition:'all .7s ease'
                
            }}
       > 
        <p
            style={{
                    color: '#FF6600',
                    fontSize:17,
                    marginBottom:7,
                }}
            >
            {this.props.title}
        </p>
        <p
            style={{
                    color: '#FFF',
                }}
            >
            {this.props.text}
        </p>
       </div>
       )
    }
  
}

class  Marker extends React.Component{

    constructor(props){
        super(props)
        this.state={
            blockShow:false
        }
    }
    render(){
        return( 
            <React.Fragment>
                 {(window.innerWidth<=768)?
                    <Block
                            position='absolute'
                            top={'-15%'}
                            left={`36%`}
                            title={this.props.title}
                            text={this.props.text}
                            show={this.state.blockShow}
                        />:null}
               <div
                    style={{
                        /* Vector */
                        position: 'absolute',
                        height:100,
                        width:132,
                        top: this.props.top+'%',
                        left:this.props.left+'%',
                        paddingTop:(this.state.blockShow&&(window.innerWidth>768))?0:79,
                        zIndex:(!this.props.zIndex)?0:this.props.zIndex
                    }}>
                    
                    {(window.innerWidth>768)?
                    <Block
                            top={`0.17%`}
                            left={`${this.props.left-6}%`}
                            title={this.props.title}
                            text={this.props.text}
                            show={this.state.blockShow}
                        />:null}

                        <div style={{
                            /* Vector */
                            // position: 're',
                            width:(window.innerWidth>768)?18:12,
                            height:(window.innerWidth>768)?18:12,
                            margin:'0 auto',
                            // top: this.props.top+'%',
                            // left:this.props.left+'%',
                            background: '#FF6600',//FF6600
                            border: '2px solid #CCCCCC',
                            borderRadius:50,
                        }}
                        onClick={()=>{ 
                                        if(window.innerWidth>768){
                                            this.setState({blockShow:!this.state.blockShow})
                                        }
                                    }}
                        />
               </div>
                
            </React.Fragment>

        )
    }
  
}

export default class Map extends React.Component{

    render(){
        return(
            <React.Fragment>
                <svg  width={(window.innerWidth>=768)?"730":'300'} height="554" viewBox="0 0 750 554" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M154.033 18.7577C155.624 18.7577 156.908 17.4659 156.908 15.8827C156.908 14.2917 155.616 13.0078 154.033 13.0078C152.442 13.0078 151.158 14.2996 151.158 15.8827C151.158 17.4659 152.442 18.7577 154.033 18.7577Z" fill="#C2C2C2"/>
<path d="M165.659 18.7577C167.25 18.7577 168.534 17.4659 168.534 15.8827C168.534 14.2917 167.242 13.0078 165.659 13.0078C164.068 13.0078 162.784 14.2996 162.784 15.8827C162.784 17.4659 164.075 18.7577 165.659 18.7577Z" fill="#C2C2C2"/>
<path d="M177.292 18.7577C178.883 18.7577 180.167 17.4659 180.167 15.8827C180.167 14.2917 178.875 13.0078 177.292 13.0078C175.701 13.0078 174.417 14.2996 174.417 15.8827C174.409 17.4659 175.701 18.7577 177.292 18.7577Z" fill="#C2C2C2"/>
<path d="M188.918 18.7577C190.509 18.7577 191.793 17.4659 191.793 15.8827C191.793 14.2917 190.501 13.0078 188.918 13.0078C187.327 13.0078 186.043 14.2996 186.043 15.8827C186.035 17.4659 187.327 18.7577 188.918 18.7577Z" fill="#C2C2C2"/>
<path d="M84.2707 30.3834C85.8617 30.3834 87.1456 29.0916 87.1456 27.5085C87.1456 25.9253 85.8538 24.6335 84.2707 24.6335C82.6796 24.6335 81.3958 25.9253 81.3958 27.5085C81.3958 29.0916 82.6796 30.3834 84.2707 30.3834Z" fill="#C2C2C2"/>
<path d="M95.8964 30.3834C97.4875 30.3834 98.7713 29.0916 98.7713 27.5085C98.7713 25.9253 97.4796 24.6335 95.8964 24.6335C94.3054 24.6335 93.0215 25.9253 93.0215 27.5085C93.0215 29.0916 94.3054 30.3834 95.8964 30.3834Z" fill="#C2C2C2"/>
<path d="M107.522 30.3834C109.113 30.3834 110.397 29.0916 110.397 27.5085C110.397 25.9253 109.105 24.6335 107.522 24.6335C105.939 24.6335 104.647 25.9253 104.647 27.5085C104.647 29.0916 105.939 30.3834 107.522 30.3834Z" fill="#C2C2C2"/>
<path d="M165.659 30.3834C167.25 30.3834 168.534 29.0916 168.534 27.5085C168.534 25.9253 167.242 24.6335 165.659 24.6335C164.068 24.6335 162.784 25.9253 162.784 27.5085C162.784 29.0916 164.075 30.3834 165.659 30.3834Z" fill="#C2C2C2"/>
<path d="M177.292 30.3834C178.883 30.3834 180.167 29.0916 180.167 27.5085C180.167 25.9253 178.875 24.6335 177.292 24.6335C175.701 24.6335 174.417 25.9253 174.417 27.5085C174.417 29.0916 175.701 30.3834 177.292 30.3834Z" fill="#C2C2C2"/>
<path d="M72.645 42.0091C74.236 42.0091 75.5199 40.7174 75.5199 39.1342C75.5199 37.5431 74.2282 36.2593 72.645 36.2593C71.0539 36.2593 69.7701 37.551 69.7701 39.1342C69.7622 40.7253 71.0539 42.0091 72.645 42.0091Z" fill="#C2C2C2"/>
<path d="M84.2707 42.0091C85.8617 42.0091 87.1456 40.7174 87.1456 39.1342C87.1456 37.5431 85.8538 36.2593 84.2707 36.2593C82.6796 36.2593 81.3958 37.551 81.3958 39.1342C81.3958 40.7253 82.6796 42.0091 84.2707 42.0091Z" fill="#C2C2C2"/>
<path d="M95.8964 42.0091C97.4875 42.0091 98.7713 40.7174 98.7713 39.1342C98.7713 37.5431 97.4796 36.2593 95.8964 36.2593C94.3054 36.2593 93.0215 37.551 93.0215 39.1342C93.0215 40.7253 94.3054 42.0091 95.8964 42.0091Z" fill="#C2C2C2"/>
<path d="M107.522 42.0091C109.113 42.0091 110.397 40.7174 110.397 39.1342C110.397 37.5431 109.105 36.2593 107.522 36.2593C105.939 36.2593 104.647 37.551 104.647 39.1342C104.647 40.7253 105.939 42.0091 107.522 42.0091Z" fill="#C2C2C2"/>
<path d="M165.659 42.0091C167.25 42.0091 168.534 40.7174 168.534 39.1342C168.534 37.5431 167.242 36.2593 165.659 36.2593C164.068 36.2593 162.784 37.551 162.784 39.1342C162.784 40.7253 164.075 42.0091 165.659 42.0091Z" fill="#C2C2C2"/>
<path d="M177.292 42.0091C178.883 42.0091 180.167 40.7174 180.167 39.1342C180.167 37.5431 178.875 36.2593 177.292 36.2593C175.701 36.2593 174.417 37.551 174.417 39.1342C174.409 40.7253 175.701 42.0091 177.292 42.0091Z" fill="#C2C2C2"/>
<path d="M188.918 42.0091C190.509 42.0091 191.793 40.7174 191.793 39.1342C191.793 37.5431 190.501 36.2593 188.918 36.2593C187.327 36.2593 186.043 37.551 186.043 39.1342C186.035 40.7253 187.327 42.0091 188.918 42.0091Z" fill="#C2C2C2"/>
<path d="M61.0114 53.6346C62.6024 53.6346 63.8863 52.3429 63.8863 50.7597C63.8863 49.1765 62.5946 47.8848 61.0114 47.8848C59.4203 47.8848 58.1365 49.1765 58.1365 50.7597C58.1365 52.3429 59.4282 53.6346 61.0114 53.6346Z" fill="#C2C2C2"/>
<path d="M72.6449 53.6346C74.2327 53.6346 75.5199 52.3475 75.5199 50.7597C75.5199 49.1719 74.2327 47.8848 72.6449 47.8848C71.0572 47.8848 69.77 49.1719 69.77 50.7597C69.77 52.3475 71.0572 53.6346 72.6449 53.6346Z" fill="#C2C2C2"/>
<path d="M154.033 53.6346C155.624 53.6346 156.908 52.3429 156.908 50.7597C156.908 49.1765 155.616 47.8848 154.033 47.8848C152.442 47.8848 151.158 49.1765 151.158 50.7597C151.158 52.3429 152.442 53.6346 154.033 53.6346Z" fill="#C2C2C2"/>
<path d="M165.659 53.6346C167.246 53.6346 168.534 52.3475 168.534 50.7597C168.534 49.1719 167.246 47.8848 165.659 47.8848C164.071 47.8848 162.784 49.1719 162.784 50.7597C162.784 52.3475 164.071 53.6346 165.659 53.6346Z" fill="#C2C2C2"/>
<path d="M177.292 53.6346C178.88 53.6346 180.167 52.3475 180.167 50.7597C180.167 49.1719 178.88 47.8848 177.292 47.8848C175.704 47.8848 174.417 49.1719 174.417 50.7597C174.417 52.3475 175.704 53.6346 177.292 53.6346Z" fill="#C2C2C2"/>
<path d="M188.918 53.6346C190.509 53.6346 191.793 52.3429 191.793 50.7597C191.793 49.1765 190.501 47.8848 188.918 47.8848C187.327 47.8848 186.043 49.1765 186.043 50.7597C186.043 52.3429 187.327 53.6346 188.918 53.6346Z" fill="#C2C2C2"/>
<path d="M200.543 53.6346C202.134 53.6346 203.418 52.3429 203.418 50.7597C203.418 49.1765 202.127 47.8848 200.543 47.8848C198.952 47.8848 197.668 49.1765 197.668 50.7597C197.668 52.3429 198.952 53.6346 200.543 53.6346Z" fill="#C2C2C2"/>
<path d="M212.169 53.6346C213.76 53.6346 215.044 52.3429 215.044 50.7597C215.044 49.1765 213.753 47.8848 212.169 47.8848C210.578 47.8848 209.294 49.1765 209.294 50.7597C209.294 52.3429 210.578 53.6346 212.169 53.6346Z" fill="#C2C2C2"/>
<path d="M223.795 53.6346C225.383 53.6346 226.67 52.3475 226.67 50.7597C226.67 49.1719 225.383 47.8848 223.795 47.8848C222.207 47.8848 220.92 49.1719 220.92 50.7597C220.92 52.3475 222.207 53.6346 223.795 53.6346Z" fill="#C2C2C2"/>
<path d="M235.429 53.6346C237.016 53.6346 238.304 52.3475 238.304 50.7597C238.304 49.1719 237.016 47.8848 235.429 47.8848C233.841 47.8848 232.554 49.1719 232.554 50.7597C232.554 52.3475 233.841 53.6346 235.429 53.6346Z" fill="#C2C2C2"/>
<path d="M247.054 53.6346C248.645 53.6346 249.929 52.3429 249.929 50.7597C249.929 49.1765 248.637 47.8848 247.054 47.8848C245.463 47.8848 244.179 49.1765 244.179 50.7597C244.179 52.3429 245.463 53.6346 247.054 53.6346Z" fill="#C2C2C2"/>
<path d="M258.68 53.6346C260.271 53.6346 261.555 52.3429 261.555 50.7597C261.555 49.1765 260.263 47.8848 258.68 47.8848C257.089 47.8848 255.805 49.1765 255.805 50.7597C255.805 52.3429 257.089 53.6346 258.68 53.6346Z" fill="#C2C2C2"/>
<path d="M49.3857 65.2684C50.9767 65.2684 52.2606 63.9767 52.2606 62.3935C52.2606 60.8024 50.9688 59.5186 49.3857 59.5186C47.8025 59.5186 46.5107 60.8103 46.5107 62.3935C46.5107 63.9767 47.7946 65.2684 49.3857 65.2684Z" fill="#C2C2C2"/>
<path d="M61.0114 65.2684C62.6024 65.2684 63.8863 63.9767 63.8863 62.3935C63.8863 60.8024 62.5946 59.5186 61.0114 59.5186C59.4203 59.5186 58.1365 60.8103 58.1365 62.3935C58.1365 63.9767 59.4282 65.2684 61.0114 65.2684Z" fill="#C2C2C2"/>
<path d="M72.6449 65.2606C74.2327 65.2606 75.5199 63.9734 75.5199 62.3857C75.5199 60.7979 74.2327 59.5107 72.6449 59.5107C71.0572 59.5107 69.77 60.7979 69.77 62.3857C69.77 63.9734 71.0572 65.2606 72.6449 65.2606Z" fill="#C2C2C2"/>
<path d="M84.2707 65.2684C85.8617 65.2684 87.1456 63.9767 87.1456 62.3935C87.1456 60.8024 85.8538 59.5186 84.2707 59.5186C82.6796 59.5186 81.3958 60.8103 81.3958 62.3935C81.3958 63.9767 82.6796 65.2684 84.2707 65.2684Z" fill="#C2C2C2"/>
<path d="M107.522 65.2684C109.113 65.2684 110.397 63.9767 110.397 62.3935C110.397 60.8024 109.105 59.5186 107.522 59.5186C105.939 59.5186 104.647 60.8103 104.647 62.3935C104.647 63.9767 105.939 65.2684 107.522 65.2684Z" fill="#C2C2C2"/>
<path d="M119.156 65.2684C120.747 65.2684 122.031 63.9767 122.031 62.3935C122.031 60.8024 120.739 59.5186 119.156 59.5186C117.565 59.5186 116.281 60.8103 116.281 62.3935C116.273 63.9767 117.565 65.2684 119.156 65.2684Z" fill="#C2C2C2"/>
<path d="M130.781 65.2684C132.372 65.2684 133.656 63.9767 133.656 62.3935C133.656 60.8024 132.365 59.5186 130.781 59.5186C129.19 59.5186 127.907 60.8103 127.907 62.3935C127.899 63.9767 129.19 65.2684 130.781 65.2684Z" fill="#C2C2C2"/>
<path d="M142.407 65.2606C143.995 65.2606 145.282 63.9734 145.282 62.3857C145.282 60.7979 143.995 59.5107 142.407 59.5107C140.819 59.5107 139.532 60.7979 139.532 62.3857C139.532 63.9734 140.819 65.2606 142.407 65.2606Z" fill="#C2C2C2"/>
<path d="M154.033 65.2684C155.624 65.2684 156.908 63.9767 156.908 62.3935C156.908 60.8024 155.616 59.5186 154.033 59.5186C152.442 59.5186 151.158 60.8103 151.158 62.3935C151.158 63.9767 152.442 65.2684 154.033 65.2684Z" fill="#C2C2C2"/>
<path d="M165.659 65.2606C167.246 65.2606 168.534 63.9734 168.534 62.3857C168.534 60.7979 167.246 59.5107 165.659 59.5107C164.071 59.5107 162.784 60.7979 162.784 62.3857C162.784 63.9734 164.071 65.2606 165.659 65.2606Z" fill="#C2C2C2"/>
<path d="M177.292 65.2606C178.88 65.2606 180.167 63.9734 180.167 62.3857C180.167 60.7979 178.88 59.5107 177.292 59.5107C175.704 59.5107 174.417 60.7979 174.417 62.3857C174.417 63.9734 175.704 65.2606 177.292 65.2606Z" fill="#C2C2C2"/>
<path d="M188.918 65.2684C190.509 65.2684 191.793 63.9767 191.793 62.3935C191.793 60.8024 190.501 59.5186 188.918 59.5186C187.327 59.5186 186.043 60.8103 186.043 62.3935C186.035 63.9767 187.327 65.2684 188.918 65.2684Z" fill="#C2C2C2"/>
<path d="M200.543 65.2684C202.134 65.2684 203.418 63.9767 203.418 62.3935C203.418 60.8024 202.127 59.5186 200.543 59.5186C198.952 59.5186 197.668 60.8103 197.668 62.3935C197.668 63.9767 198.952 65.2684 200.543 65.2684Z" fill="#C2C2C2"/>
<path d="M212.169 65.2684C213.76 65.2684 215.044 63.9767 215.044 62.3935C215.044 60.8024 213.753 59.5186 212.169 59.5186C210.578 59.5186 209.294 60.8103 209.294 62.3935C209.294 63.9767 210.578 65.2684 212.169 65.2684Z" fill="#C2C2C2"/>
<path d="M223.795 65.2606C225.383 65.2606 226.67 63.9734 226.67 62.3857C226.67 60.7979 225.383 59.5107 223.795 59.5107C222.207 59.5107 220.92 60.7979 220.92 62.3857C220.92 63.9734 222.207 65.2606 223.795 65.2606Z" fill="#C2C2C2"/>
<path d="M235.429 65.2606C237.016 65.2606 238.304 63.9734 238.304 62.3857C238.304 60.7979 237.016 59.5107 235.429 59.5107C233.841 59.5107 232.554 60.7979 232.554 62.3857C232.554 63.9734 233.841 65.2606 235.429 65.2606Z" fill="#C2C2C2"/>
<path d="M247.054 65.2684C248.645 65.2684 249.929 63.9767 249.929 62.3935C249.929 60.8024 248.637 59.5186 247.054 59.5186C245.463 59.5186 244.179 60.8103 244.179 62.3935C244.179 63.9767 245.463 65.2684 247.054 65.2684Z" fill="#C2C2C2"/>
<path d="M258.68 65.2684C260.271 65.2684 261.555 63.9767 261.555 62.3935C261.555 60.8024 260.263 59.5186 258.68 59.5186C257.089 59.5186 255.805 60.8103 255.805 62.3935C255.805 63.9767 257.089 65.2684 258.68 65.2684Z" fill="#C2C2C2"/>
<path d="M467.856 65.2684C469.447 65.2684 470.739 63.9767 470.739 62.3935C470.739 60.8024 469.447 59.5186 467.856 59.5186C466.265 59.5186 464.981 60.8103 464.981 62.3935C464.981 63.9767 466.273 65.2684 467.856 65.2684Z" fill="#C2C2C2"/>
<path d="M479.482 65.2684C481.073 65.2684 482.364 63.9767 482.364 62.3935C482.364 60.8024 481.073 59.5186 479.482 59.5186C477.891 59.5186 476.607 60.8103 476.607 62.3935C476.607 63.9767 477.898 65.2684 479.482 65.2684Z" fill="#C2C2C2"/>
<path d="M491.115 65.2684C492.706 65.2684 493.998 63.9767 493.998 62.3935C493.998 60.8024 492.706 59.5186 491.115 59.5186C489.524 59.5186 488.24 60.8103 488.24 62.3935C488.232 63.9767 489.524 65.2684 491.115 65.2684Z" fill="#C2C2C2"/>
<path d="M502.741 65.2606C504.329 65.2606 505.616 63.9734 505.616 62.3857C505.616 60.7979 504.329 59.5107 502.741 59.5107C501.153 59.5107 499.866 60.7979 499.866 62.3857C499.866 63.9734 501.153 65.2606 502.741 65.2606Z" fill="#C2C2C2"/>
<path d="M514.248 65.2684C515.84 65.2684 517.131 63.9767 517.131 62.3935C517.131 60.8024 515.84 59.5186 514.248 59.5186C512.657 59.5186 511.374 60.8103 511.374 62.3935C511.374 63.9767 512.665 65.2684 514.248 65.2684Z" fill="#C2C2C2"/>
<path d="M525.882 65.2606C527.47 65.2606 528.757 63.9734 528.757 62.3857C528.757 60.7979 527.47 59.5107 525.882 59.5107C524.294 59.5107 523.007 60.7979 523.007 62.3857C523.007 63.9734 524.294 65.2606 525.882 65.2606Z" fill="#C2C2C2"/>
<path d="M536.893 65.2606C538.481 65.2606 539.768 63.9734 539.768 62.3857C539.768 60.7979 538.481 59.5107 536.893 59.5107C535.306 59.5107 534.019 60.7979 534.019 62.3857C534.019 63.9734 535.306 65.2606 536.893 65.2606Z" fill="#C2C2C2"/>
<path d="M549.016 65.2606C550.603 65.2606 551.891 63.9734 551.891 62.3857C551.891 60.7979 550.603 59.5107 549.016 59.5107C547.428 59.5107 546.141 60.7979 546.141 62.3857C546.141 63.9734 547.428 65.2606 549.016 65.2606Z" fill="#C2C2C2"/>
<path d="M560.035 65.2606C561.622 65.2606 562.909 63.9734 562.909 62.3857C562.909 60.7979 561.622 59.5107 560.035 59.5107C558.447 59.5107 557.16 60.7979 557.16 62.3857C557.16 63.9734 558.447 65.2606 560.035 65.2606Z" fill="#C2C2C2"/>
<path d="M37.7599 76.8939C39.351 76.8939 40.6349 75.6021 40.6349 74.019C40.6349 72.4279 39.3431 71.144 37.7599 71.144C36.1768 71.144 34.885 72.4358 34.885 74.019C34.885 75.6021 36.1689 76.8939 37.7599 76.8939Z" fill="#C2C2C2"/>
<path d="M49.3857 76.8939C50.9767 76.8939 52.2606 75.6021 52.2606 74.019C52.2606 72.4279 50.9688 71.144 49.3857 71.144C47.8025 71.144 46.5107 72.4358 46.5107 74.019C46.5107 75.6021 47.7946 76.8939 49.3857 76.8939Z" fill="#C2C2C2"/>
<path d="M61.0114 76.8939C62.6024 76.8939 63.8863 75.6021 63.8863 74.019C63.8863 72.4279 62.5946 71.144 61.0114 71.144C59.4203 71.144 58.1365 72.4358 58.1365 74.019C58.1365 75.6021 59.4282 76.8939 61.0114 76.8939Z" fill="#C2C2C2"/>
<path d="M72.645 76.8939C74.236 76.8939 75.5199 75.6021 75.5199 74.019C75.5199 72.4279 74.2282 71.144 72.645 71.144C71.0539 71.144 69.7701 72.4358 69.7701 74.019C69.7622 75.6021 71.0539 76.8939 72.645 76.8939Z" fill="#C2C2C2"/>
<path d="M84.2707 76.8939C85.8617 76.8939 87.1456 75.6021 87.1456 74.019C87.1456 72.4279 85.8538 71.144 84.2707 71.144C82.6796 71.144 81.3958 72.4358 81.3958 74.019C81.3958 75.6021 82.6796 76.8939 84.2707 76.8939Z" fill="#C2C2C2"/>
<path d="M107.522 76.8939C109.113 76.8939 110.397 75.6021 110.397 74.019C110.397 72.4279 109.105 71.144 107.522 71.144C105.939 71.144 104.647 72.4358 104.647 74.019C104.647 75.6021 105.939 76.8939 107.522 76.8939Z" fill="#C2C2C2"/>
<path d="M119.156 76.8939C120.747 76.8939 122.031 75.6021 122.031 74.019C122.031 72.4279 120.739 71.144 119.156 71.144C117.565 71.144 116.281 72.4358 116.281 74.019C116.273 75.6021 117.565 76.8939 119.156 76.8939Z" fill="#C2C2C2"/>
<path d="M130.781 76.8939C132.372 76.8939 133.656 75.6021 133.656 74.019C133.656 72.4279 132.365 71.144 130.781 71.144C129.19 71.144 127.907 72.4358 127.907 74.019C127.899 75.6021 129.19 76.8939 130.781 76.8939Z" fill="#C2C2C2"/>
<path d="M142.407 76.8939C143.998 76.8939 145.282 75.6021 145.282 74.019C145.282 72.4279 143.99 71.144 142.407 71.144C140.816 71.144 139.532 72.4358 139.532 74.019C139.532 75.6021 140.816 76.8939 142.407 76.8939Z" fill="#C2C2C2"/>
<path d="M154.033 76.8939C155.624 76.8939 156.908 75.6021 156.908 74.019C156.908 72.4279 155.616 71.144 154.033 71.144C152.442 71.144 151.158 72.4358 151.158 74.019C151.158 75.6021 152.442 76.8939 154.033 76.8939Z" fill="#C2C2C2"/>
<path d="M165.659 76.8939C167.25 76.8939 168.534 75.6021 168.534 74.019C168.534 72.4279 167.242 71.144 165.659 71.144C164.068 71.144 162.784 72.4358 162.784 74.019C162.784 75.6021 164.075 76.8939 165.659 76.8939Z" fill="#C2C2C2"/>
<path d="M177.292 76.8939C178.883 76.8939 180.167 75.6021 180.167 74.019C180.167 72.4279 178.875 71.144 177.292 71.144C175.701 71.144 174.417 72.4358 174.417 74.019C174.409 75.6021 175.701 76.8939 177.292 76.8939Z" fill="#C2C2C2"/>
<path d="M188.918 76.8939C190.509 76.8939 191.793 75.6021 191.793 74.019C191.793 72.4279 190.501 71.144 188.918 71.144C187.327 71.144 186.043 72.4358 186.043 74.019C186.035 75.6021 187.327 76.8939 188.918 76.8939Z" fill="#C2C2C2"/>
<path d="M200.543 76.8939C202.134 76.8939 203.418 75.6021 203.418 74.019C203.418 72.4279 202.127 71.144 200.543 71.144C198.952 71.144 197.668 72.4358 197.668 74.019C197.668 75.6021 198.952 76.8939 200.543 76.8939Z" fill="#C2C2C2"/>
<path d="M212.169 76.8939C213.76 76.8939 215.044 75.6021 215.044 74.019C215.044 72.4279 213.753 71.144 212.169 71.144C210.578 71.144 209.294 72.4358 209.294 74.019C209.294 75.6021 210.578 76.8939 212.169 76.8939Z" fill="#C2C2C2"/>
<path d="M223.795 76.8939C225.386 76.8939 226.67 75.6021 226.67 74.019C226.67 72.4279 225.378 71.144 223.795 71.144C222.204 71.144 220.92 72.4358 220.92 74.019C220.92 75.6021 222.212 76.8939 223.795 76.8939Z" fill="#C2C2C2"/>
<path d="M235.428 76.8939C237.019 76.8939 238.303 75.6021 238.303 74.019C238.303 72.4279 237.012 71.144 235.428 71.144C233.837 71.144 232.554 72.4358 232.554 74.019C232.546 75.6021 233.837 76.8939 235.428 76.8939Z" fill="#C2C2C2"/>
<path d="M247.054 76.8939C248.645 76.8939 249.929 75.6021 249.929 74.019C249.929 72.4279 248.637 71.144 247.054 71.144C245.463 71.144 244.179 72.4358 244.179 74.019C244.179 75.6021 245.463 76.8939 247.054 76.8939Z" fill="#C2C2C2"/>
<path d="M258.68 76.8939C260.271 76.8939 261.555 75.6021 261.555 74.019C261.555 72.4279 260.263 71.144 258.68 71.144C257.089 71.144 255.805 72.4358 255.805 74.019C255.805 75.6021 257.089 76.8939 258.68 76.8939Z" fill="#C2C2C2"/>
<path d="M270.306 76.8939C271.897 76.8939 273.181 75.6021 273.181 74.019C273.181 72.4279 271.889 71.144 270.306 71.144C268.715 71.144 267.431 72.4358 267.431 74.019C267.431 75.6021 268.722 76.8939 270.306 76.8939Z" fill="#C2C2C2"/>
<path d="M467.974 76.8939C469.565 76.8939 470.857 75.6021 470.857 74.019C470.857 72.4279 469.565 71.144 467.974 71.144C466.383 71.144 465.099 72.4358 465.099 74.019C465.099 75.6021 466.383 76.8939 467.974 76.8939Z" fill="#C2C2C2"/>
<path d="M479.6 76.8939C481.191 76.8939 482.475 75.6021 482.475 74.019C482.475 72.4279 481.191 71.144 479.6 71.144C478.009 71.144 476.725 72.4358 476.725 74.019C476.725 75.6021 478.017 76.8939 479.6 76.8939Z" fill="#C2C2C2"/>
<path d="M490.737 76.8939C492.328 76.8939 493.612 75.6021 493.612 74.019C493.612 72.4279 492.32 71.144 490.737 71.144C489.146 71.144 487.862 72.4358 487.862 74.019C487.854 75.6021 489.146 76.8939 490.737 76.8939Z" fill="#C2C2C2"/>
<path d="M502.363 76.8939C503.954 76.8939 505.238 75.6021 505.238 74.019C505.238 72.4279 503.954 71.144 502.363 71.144C500.78 71.144 499.488 72.4358 499.488 74.019C499.488 75.6021 500.78 76.8939 502.363 76.8939Z" fill="#C2C2C2"/>
<path d="M513.989 76.8939C515.58 76.8939 516.864 75.6021 516.864 74.019C516.864 72.4279 515.58 71.144 513.989 71.144C512.398 71.144 511.114 72.4358 511.114 74.019C511.114 75.6021 512.406 76.8939 513.989 76.8939Z" fill="#C2C2C2"/>
<path d="M525.614 76.8939C527.205 76.8939 528.489 75.6021 528.489 74.019C528.489 72.4279 527.205 71.144 525.614 71.144C524.023 71.144 522.74 72.4358 522.74 74.019C522.74 75.6021 524.031 76.8939 525.614 76.8939Z" fill="#C2C2C2"/>
<path d="M537.248 76.8939C538.839 76.8939 540.123 75.6021 540.123 74.019C540.123 72.4279 538.839 71.144 537.248 71.144C535.657 71.144 534.373 72.4358 534.373 74.019C534.373 75.6021 535.657 76.8939 537.248 76.8939Z" fill="#C2C2C2"/>
<path d="M548.874 76.8939C550.465 76.8939 551.749 75.6021 551.749 74.019C551.749 72.4279 550.465 71.144 548.874 71.144C547.283 71.144 545.999 72.4358 545.999 74.019C545.999 75.6021 547.283 76.8939 548.874 76.8939Z" fill="#C2C2C2"/>
<path d="M560.499 76.8939C562.09 76.8939 563.374 75.6021 563.374 74.019C563.374 72.4279 562.09 71.144 560.499 71.144C558.908 71.144 557.625 72.4358 557.625 74.019C557.625 75.6021 558.916 76.8939 560.499 76.8939Z" fill="#C2C2C2"/>
<path d="M572.621 76.8939C574.212 76.8939 575.496 75.6021 575.496 74.019C575.496 72.4279 574.212 71.144 572.621 71.144C571.03 71.144 569.746 72.4358 569.746 74.019C569.746 75.6021 571.03 76.8939 572.621 76.8939Z" fill="#C2C2C2"/>
<path d="M26.1342 88.5199C27.7253 88.5199 29.0092 87.2281 29.0092 85.6449C29.0092 84.0539 27.7174 82.77 26.1342 82.77C24.5432 82.77 23.2593 84.0618 23.2593 85.6449C23.2514 87.2281 24.5432 88.5199 26.1342 88.5199Z" fill="#C2C2C2"/>
<path d="M37.7599 88.5199C39.351 88.5199 40.6349 87.2281 40.6349 85.6449C40.6349 84.0539 39.3431 82.77 37.7599 82.77C36.1768 82.77 34.885 84.0618 34.885 85.6449C34.885 87.2281 36.1689 88.5199 37.7599 88.5199Z" fill="#C2C2C2"/>
<path d="M49.3857 88.5199C50.9767 88.5199 52.2606 87.2281 52.2606 85.6449C52.2606 84.0539 50.9688 82.77 49.3857 82.77C47.8025 82.77 46.5107 84.0618 46.5107 85.6449C46.5107 87.2281 47.7946 88.5199 49.3857 88.5199Z" fill="#C2C2C2"/>
<path d="M61.0114 88.5199C62.6024 88.5199 63.8863 87.2281 63.8863 85.6449C63.8863 84.0539 62.5946 82.77 61.0114 82.77C59.4203 82.77 58.1365 84.0618 58.1365 85.6449C58.1365 87.2281 59.4282 88.5199 61.0114 88.5199Z" fill="#C2C2C2"/>
<path d="M72.645 88.5199C74.236 88.5199 75.5199 87.2281 75.5199 85.6449C75.5199 84.0539 74.2282 82.77 72.645 82.77C71.0539 82.77 69.7701 84.0618 69.7701 85.6449C69.7622 87.2281 71.0539 88.5199 72.645 88.5199Z" fill="#C2C2C2"/>
<path d="M84.2707 88.5199C85.8617 88.5199 87.1456 87.2281 87.1456 85.6449C87.1456 84.0539 85.8538 82.77 84.2707 82.77C82.6796 82.77 81.3958 84.0618 81.3958 85.6449C81.3958 87.2281 82.6796 88.5199 84.2707 88.5199Z" fill="#C2C2C2"/>
<path d="M107.522 88.5199C109.113 88.5199 110.397 87.2281 110.397 85.6449C110.397 84.0539 109.105 82.77 107.522 82.77C105.939 82.77 104.647 84.0618 104.647 85.6449C104.647 87.2281 105.939 88.5199 107.522 88.5199Z" fill="#C2C2C2"/>
<path d="M119.156 88.5199C120.747 88.5199 122.031 87.2281 122.031 85.6449C122.031 84.0539 120.739 82.77 119.156 82.77C117.565 82.77 116.281 84.0618 116.281 85.6449C116.273 87.2281 117.565 88.5199 119.156 88.5199Z" fill="#C2C2C2"/>
<path d="M130.781 88.5199C132.372 88.5199 133.656 87.2281 133.656 85.6449C133.656 84.0539 132.365 82.77 130.781 82.77C129.19 82.77 127.907 84.0618 127.907 85.6449C127.899 87.2281 129.19 88.5199 130.781 88.5199Z" fill="#C2C2C2"/>
<path d="M142.407 88.5199C143.998 88.5199 145.282 87.2281 145.282 85.6449C145.282 84.0539 143.99 82.77 142.407 82.77C140.816 82.77 139.532 84.0618 139.532 85.6449C139.532 87.2281 140.816 88.5199 142.407 88.5199Z" fill="#C2C2C2"/>
<path d="M154.033 88.5199C155.624 88.5199 156.908 87.2281 156.908 85.6449C156.908 84.0539 155.616 82.77 154.033 82.77C152.442 82.77 151.158 84.0618 151.158 85.6449C151.158 87.2281 152.442 88.5199 154.033 88.5199Z" fill="#C2C2C2"/>
<path d="M165.659 88.5199C167.25 88.5199 168.534 87.2281 168.534 85.6449C168.534 84.0539 167.242 82.77 165.659 82.77C164.068 82.77 162.784 84.0618 162.784 85.6449C162.784 87.2281 164.075 88.5199 165.659 88.5199Z" fill="#C2C2C2"/>
<path d="M177.292 88.5199C178.883 88.5199 180.167 87.2281 180.167 85.6449C180.167 84.0539 178.875 82.77 177.292 82.77C175.701 82.77 174.417 84.0618 174.417 85.6449C174.409 87.2281 175.701 88.5199 177.292 88.5199Z" fill="#C2C2C2"/>
<path d="M188.918 88.5199C190.509 88.5199 191.793 87.2281 191.793 85.6449C191.793 84.0539 190.501 82.77 188.918 82.77C187.327 82.77 186.043 84.0618 186.043 85.6449C186.035 87.2281 187.327 88.5199 188.918 88.5199Z" fill="#C2C2C2"/>
<path d="M200.543 88.5199C202.134 88.5199 203.418 87.2281 203.418 85.6449C203.418 84.0539 202.127 82.77 200.543 82.77C198.952 82.77 197.668 84.0618 197.668 85.6449C197.668 87.2281 198.952 88.5199 200.543 88.5199Z" fill="#C2C2C2"/>
<path d="M212.169 88.5199C213.76 88.5199 215.044 87.2281 215.044 85.6449C215.044 84.0539 213.753 82.77 212.169 82.77C210.578 82.77 209.294 84.0618 209.294 85.6449C209.294 87.2281 210.578 88.5199 212.169 88.5199Z" fill="#C2C2C2"/>
<path d="M223.795 88.5199C225.386 88.5199 226.67 87.2281 226.67 85.6449C226.67 84.0539 225.378 82.77 223.795 82.77C222.204 82.77 220.92 84.0618 220.92 85.6449C220.92 87.2281 222.212 88.5199 223.795 88.5199Z" fill="#C2C2C2"/>
<path d="M235.428 88.5199C237.019 88.5199 238.303 87.2281 238.303 85.6449C238.303 84.0539 237.012 82.77 235.428 82.77C233.837 82.77 232.554 84.0618 232.554 85.6449C232.546 87.2281 233.837 88.5199 235.428 88.5199Z" fill="#C2C2C2"/>
<path d="M247.054 88.5199C248.645 88.5199 249.929 87.2281 249.929 85.6449C249.929 84.0539 248.637 82.77 247.054 82.77C245.463 82.77 244.179 84.0618 244.179 85.6449C244.179 87.2281 245.463 88.5199 247.054 88.5199Z" fill="#C2C2C2"/>
<path d="M258.68 88.5199C260.271 88.5199 261.555 87.2281 261.555 85.6449C261.555 84.0539 260.263 82.77 258.68 82.77C257.089 82.77 255.805 84.0618 255.805 85.6449C255.805 87.2281 257.089 88.5199 258.68 88.5199Z" fill="#C2C2C2"/>
<path d="M467.974 88.5199C469.565 88.5199 470.857 87.2281 470.857 85.6449C470.857 84.0539 469.565 82.77 467.974 82.77C466.383 82.77 465.099 84.0618 465.099 85.6449C465.099 87.2281 466.383 88.5199 467.974 88.5199Z" fill="#C2C2C2"/>
<path d="M479.6 88.5199C481.191 88.5199 482.475 87.2281 482.475 85.6449C482.475 84.0539 481.191 82.77 479.6 82.77C478.009 82.77 476.725 84.0618 476.725 85.6449C476.725 87.2281 478.017 88.5199 479.6 88.5199Z" fill="#C2C2C2"/>
<path d="M491.225 88.5199C492.817 88.5199 494.1 87.2281 494.1 85.6449C494.1 84.0539 492.817 82.77 491.225 82.77C489.634 82.77 488.351 84.0618 488.351 85.6449C488.351 87.2281 489.642 88.5199 491.225 88.5199Z" fill="#C2C2C2"/>
<path d="M502.859 88.5199C504.45 88.5199 505.734 87.2281 505.734 85.6449C505.734 84.0539 504.442 82.77 502.859 82.77C501.268 82.77 499.984 84.0618 499.984 85.6449C499.977 87.2281 501.268 88.5199 502.859 88.5199Z" fill="#C2C2C2"/>
<path d="M514.485 88.5199C516.076 88.5199 517.36 87.2281 517.36 85.6449C517.36 84.0539 516.076 82.77 514.485 82.77C512.894 82.77 511.61 84.0618 511.61 85.6449C511.61 87.2281 512.894 88.5199 514.485 88.5199Z" fill="#C2C2C2"/>
<path d="M526.111 88.5199C527.702 88.5199 528.985 87.2281 528.985 85.6449C528.985 84.0539 527.702 82.77 526.111 82.77C524.519 82.77 523.236 84.0618 523.236 85.6449C523.236 87.2281 524.519 88.5199 526.111 88.5199Z" fill="#C2C2C2"/>
<path d="M537.736 88.5199C539.327 88.5199 540.611 87.2281 540.611 85.6449C540.611 84.0539 539.327 82.77 537.736 82.77C536.145 82.77 534.861 84.0618 534.861 85.6449C534.861 87.2281 536.153 88.5199 537.736 88.5199Z" fill="#C2C2C2"/>
<path d="M549.362 88.5199C550.953 88.5199 552.237 87.2281 552.237 85.6449C552.237 84.0539 550.953 82.77 549.362 82.77C547.771 82.77 546.487 84.0618 546.487 85.6449C546.487 87.2281 547.779 88.5199 549.362 88.5199Z" fill="#C2C2C2"/>
<path d="M560.996 88.5199C562.587 88.5199 563.871 87.2281 563.871 85.6449C563.871 84.0539 562.587 82.77 560.996 82.77C559.405 82.77 558.121 84.0618 558.121 85.6449C558.113 87.2281 559.405 88.5199 560.996 88.5199Z" fill="#C2C2C2"/>
<path d="M2.87492 100.145C4.46597 100.145 5.74984 98.8536 5.74984 97.2704C5.74984 95.6794 4.4581 94.3955 2.87492 94.3955C1.28387 94.3955 0 95.6873 0 97.2704C0 98.8615 1.29175 100.145 2.87492 100.145Z" fill="#C2C2C2"/>
<path d="M14.5085 100.145C16.0996 100.145 17.3834 98.8536 17.3834 97.2704C17.3834 95.6794 16.0917 94.3955 14.5085 94.3955C12.9253 94.3955 11.6336 95.6873 11.6336 97.2704C11.6257 98.8615 12.9174 100.145 14.5085 100.145Z" fill="#C2C2C2"/>
<path d="M26.1342 100.145C27.7253 100.145 29.0092 98.8536 29.0092 97.2704C29.0092 95.6794 27.7174 94.3955 26.1342 94.3955C24.5432 94.3955 23.2593 95.6873 23.2593 97.2704C23.2514 98.8615 24.5432 100.145 26.1342 100.145Z" fill="#C2C2C2"/>
<path d="M37.7599 100.145C39.351 100.145 40.6349 98.8536 40.6349 97.2704C40.6349 95.6794 39.3431 94.3955 37.7599 94.3955C36.1768 94.3955 34.885 95.6873 34.885 97.2704C34.885 98.8615 36.1689 100.145 37.7599 100.145Z" fill="#C2C2C2"/>
<path d="M49.3857 100.145C50.9767 100.145 52.2606 98.8536 52.2606 97.2704C52.2606 95.6794 50.9688 94.3955 49.3857 94.3955C47.8025 94.3955 46.5107 95.6873 46.5107 97.2704C46.5107 98.8615 47.7946 100.145 49.3857 100.145Z" fill="#C2C2C2"/>
<path d="M61.0114 100.145C62.6024 100.145 63.8863 98.8536 63.8863 97.2704C63.8863 95.6794 62.5946 94.3955 61.0114 94.3955C59.4203 94.3955 58.1365 95.6873 58.1365 97.2704C58.1365 98.8615 59.4282 100.145 61.0114 100.145Z" fill="#C2C2C2"/>
<path d="M72.645 100.145C74.236 100.145 75.5199 98.8536 75.5199 97.2704C75.5199 95.6794 74.2282 94.3955 72.645 94.3955C71.0539 94.3955 69.7701 95.6873 69.7701 97.2704C69.7622 98.8615 71.0539 100.145 72.645 100.145Z" fill="#C2C2C2"/>
<path d="M84.2707 100.145C85.8617 100.145 87.1456 98.8536 87.1456 97.2704C87.1456 95.6794 85.8538 94.3955 84.2707 94.3955C82.6796 94.3955 81.3958 95.6873 81.3958 97.2704C81.3958 98.8615 82.6796 100.145 84.2707 100.145Z" fill="#C2C2C2"/>
<path d="M107.522 100.145C109.113 100.145 110.397 98.8536 110.397 97.2704C110.397 95.6794 109.105 94.3955 107.522 94.3955C105.939 94.3955 104.647 95.6873 104.647 97.2704C104.647 98.8615 105.939 100.145 107.522 100.145Z" fill="#C2C2C2"/>
<path d="M119.156 100.145C120.747 100.145 122.031 98.8536 122.031 97.2704C122.031 95.6794 120.739 94.3955 119.156 94.3955C117.565 94.3955 116.281 95.6873 116.281 97.2704C116.273 98.8615 117.565 100.145 119.156 100.145Z" fill="#C2C2C2"/>
<path d="M130.781 100.145C132.372 100.145 133.656 98.8536 133.656 97.2704C133.656 95.6794 132.365 94.3955 130.781 94.3955C129.19 94.3955 127.907 95.6873 127.907 97.2704C127.899 98.8615 129.19 100.145 130.781 100.145Z" fill="#C2C2C2"/>
<path d="M142.407 100.145C143.998 100.145 145.282 98.8536 145.282 97.2704C145.282 95.6794 143.99 94.3955 142.407 94.3955C140.816 94.3955 139.532 95.6873 139.532 97.2704C139.532 98.8615 140.816 100.145 142.407 100.145Z" fill="#C2C2C2"/>
<path d="M154.033 100.145C155.624 100.145 156.908 98.8536 156.908 97.2704C156.908 95.6794 155.616 94.3955 154.033 94.3955C152.442 94.3955 151.158 95.6873 151.158 97.2704C151.158 98.8615 152.442 100.145 154.033 100.145Z" fill="#C2C2C2"/>
<path d="M165.659 100.145C167.25 100.145 168.534 98.8536 168.534 97.2704C168.534 95.6794 167.242 94.3955 165.659 94.3955C164.068 94.3955 162.784 95.6873 162.784 97.2704C162.784 98.8615 164.075 100.145 165.659 100.145Z" fill="#C2C2C2"/>
<path d="M177.292 100.145C178.883 100.145 180.167 98.8536 180.167 97.2704C180.167 95.6794 178.875 94.3955 177.292 94.3955C175.701 94.3955 174.417 95.6873 174.417 97.2704C174.409 98.8615 175.701 100.145 177.292 100.145Z" fill="#C2C2C2"/>
<path d="M188.918 100.145C190.509 100.145 191.793 98.8536 191.793 97.2704C191.793 95.6794 190.501 94.3955 188.918 94.3955C187.327 94.3955 186.043 95.6873 186.043 97.2704C186.035 98.8615 187.327 100.145 188.918 100.145Z" fill="#C2C2C2"/>
<path d="M200.543 100.145C202.134 100.145 203.418 98.8536 203.418 97.2704C203.418 95.6794 202.127 94.3955 200.543 94.3955C198.952 94.3955 197.668 95.6873 197.668 97.2704C197.668 98.8615 198.952 100.145 200.543 100.145Z" fill="#C2C2C2"/>
<path d="M212.169 100.145C213.76 100.145 215.044 98.8536 215.044 97.2704C215.044 95.6794 213.753 94.3955 212.169 94.3955C210.578 94.3955 209.294 95.6873 209.294 97.2704C209.294 98.8615 210.578 100.145 212.169 100.145Z" fill="#C2C2C2"/>
<path d="M223.795 100.145C225.386 100.145 226.67 98.8536 226.67 97.2704C226.67 95.6794 225.378 94.3955 223.795 94.3955C222.204 94.3955 220.92 95.6873 220.92 97.2704C220.92 98.8615 222.212 100.145 223.795 100.145Z" fill="#C2C2C2"/>
<path d="M235.428 100.145C237.019 100.145 238.303 98.8536 238.303 97.2704C238.303 95.6794 237.012 94.3955 235.428 94.3955C233.837 94.3955 232.554 95.6873 232.554 97.2704C232.546 98.8615 233.837 100.145 235.428 100.145Z" fill="#C2C2C2"/>
<path d="M247.054 100.145C248.645 100.145 249.929 98.8536 249.929 97.2704C249.929 95.6794 248.637 94.3955 247.054 94.3955C245.463 94.3955 244.179 95.6873 244.179 97.2704C244.179 98.8615 245.463 100.145 247.054 100.145Z" fill="#C2C2C2"/>
<path d="M258.68 100.145C260.271 100.145 261.555 98.8536 261.555 97.2704C261.555 95.6794 260.263 94.3955 258.68 94.3955C257.089 94.3955 255.805 95.6873 255.805 97.2704C255.805 98.8615 257.089 100.145 258.68 100.145Z" fill="#C2C2C2"/>
<path d="M270.306 100.145C271.897 100.145 273.181 98.8536 273.181 97.2704C273.181 95.6794 271.889 94.3955 270.306 94.3955C268.715 94.3955 267.431 95.6873 267.431 97.2704C267.431 98.8615 268.722 100.145 270.306 100.145Z" fill="#C2C2C2"/>
<path d="M281.939 100.145C283.53 100.145 284.814 98.8536 284.814 97.2704C284.814 95.6794 283.522 94.3955 281.939 94.3955C280.348 94.3955 279.064 95.6873 279.064 97.2704C279.056 98.8615 280.348 100.145 281.939 100.145Z" fill="#C2C2C2"/>
<path d="M293.565 100.145C295.156 100.145 296.44 98.8536 296.44 97.2704C296.44 95.6794 295.148 94.3955 293.565 94.3955C291.982 94.3955 290.69 95.6873 290.69 97.2704C290.682 98.8615 291.974 100.145 293.565 100.145Z" fill="#C2C2C2"/>
<path d="M467.974 100.145C469.565 100.145 470.857 98.8536 470.857 97.2704C470.857 95.6794 469.565 94.3955 467.974 94.3955C466.383 94.3955 465.099 95.6873 465.099 97.2704C465.099 98.8615 466.383 100.145 467.974 100.145Z" fill="#C2C2C2"/>
<path d="M479.6 100.145C481.191 100.145 482.475 98.8536 482.475 97.2704C482.475 95.6794 481.191 94.3955 479.6 94.3955C478.009 94.3955 476.725 95.6873 476.725 97.2704C476.725 98.8615 478.017 100.145 479.6 100.145Z" fill="#C2C2C2"/>
<path d="M491.225 100.145C492.817 100.145 494.1 98.8536 494.1 97.2704C494.1 95.6794 492.817 94.3955 491.225 94.3955C489.634 94.3955 488.351 95.6873 488.351 97.2704C488.351 98.8615 489.642 100.145 491.225 100.145Z" fill="#C2C2C2"/>
<path d="M502.859 100.145C504.45 100.145 505.734 98.8536 505.734 97.2704C505.734 95.6794 504.442 94.3955 502.859 94.3955C501.268 94.3955 499.984 95.6873 499.984 97.2704C499.977 98.8615 501.268 100.145 502.859 100.145Z" fill="#C2C2C2"/>
<path d="M514.485 100.145C516.076 100.145 517.36 98.8536 517.36 97.2704C517.36 95.6794 516.076 94.3955 514.485 94.3955C512.894 94.3955 511.61 95.6873 511.61 97.2704C511.61 98.8615 512.894 100.145 514.485 100.145Z" fill="#C2C2C2"/>
<path d="M526.111 100.145C527.702 100.145 528.985 98.8536 528.985 97.2704C528.985 95.6794 527.702 94.3955 526.111 94.3955C524.519 94.3955 523.236 95.6873 523.236 97.2704C523.236 98.8615 524.519 100.145 526.111 100.145Z" fill="#C2C2C2"/>
<path d="M537.736 100.145C539.327 100.145 540.611 98.8536 540.611 97.2704C540.611 95.6794 539.327 94.3955 537.736 94.3955C536.145 94.3955 534.861 95.6873 534.861 97.2704C534.861 98.8615 536.153 100.145 537.736 100.145Z" fill="#C2C2C2"/>
<path d="M549.362 100.145C550.953 100.145 552.237 98.8536 552.237 97.2704C552.237 95.6794 550.953 94.3955 549.362 94.3955C547.771 94.3955 546.487 95.6873 546.487 97.2704C546.487 98.8615 547.779 100.145 549.362 100.145Z" fill="#C2C2C2"/>
<path d="M2.87492 111.771C4.46597 111.771 5.74984 110.48 5.74984 108.896C5.74984 107.305 4.4581 106.021 2.87492 106.021C1.28387 106.021 0 107.313 0 108.896C0 110.487 1.29175 111.771 2.87492 111.771Z" fill="#C2C2C2"/>
<path d="M14.5085 111.771C16.0996 111.771 17.3834 110.48 17.3834 108.896C17.3834 107.305 16.0917 106.021 14.5085 106.021C12.9253 106.021 11.6336 107.313 11.6336 108.896C11.6257 110.487 12.9174 111.771 14.5085 111.771Z" fill="#C2C2C2"/>
<path d="M26.1342 111.771C27.7253 111.771 29.0092 110.48 29.0092 108.896C29.0092 107.305 27.7174 106.021 26.1342 106.021C24.5432 106.021 23.2593 107.313 23.2593 108.896C23.2514 110.487 24.5432 111.771 26.1342 111.771Z" fill="#C2C2C2"/>
<path d="M37.7599 111.771C39.351 111.771 40.6349 110.48 40.6349 108.896C40.6349 107.305 39.3431 106.021 37.7599 106.021C36.1768 106.021 34.885 107.313 34.885 108.896C34.885 110.487 36.1689 111.771 37.7599 111.771Z" fill="#C2C2C2"/>
<path d="M49.3857 111.771C50.9767 111.771 52.2606 110.48 52.2606 108.896C52.2606 107.305 50.9688 106.021 49.3857 106.021C47.8025 106.021 46.5107 107.313 46.5107 108.896C46.5107 110.487 47.7946 111.771 49.3857 111.771Z" fill="#C2C2C2"/>
<path d="M61.0114 111.771C62.6024 111.771 63.8863 110.48 63.8863 108.896C63.8863 107.305 62.5946 106.021 61.0114 106.021C59.4203 106.021 58.1365 107.313 58.1365 108.896C58.1365 110.487 59.4282 111.771 61.0114 111.771Z" fill="#C2C2C2"/>
<path d="M72.645 111.771C74.236 111.771 75.5199 110.48 75.5199 108.896C75.5199 107.305 74.2282 106.021 72.645 106.021C71.0539 106.021 69.7701 107.313 69.7701 108.896C69.7622 110.487 71.0539 111.771 72.645 111.771Z" fill="#C2C2C2"/>
<path d="M107.522 111.771C109.113 111.771 110.397 110.48 110.397 108.896C110.397 107.305 109.105 106.021 107.522 106.021C105.939 106.021 104.647 107.313 104.647 108.896C104.647 110.487 105.939 111.771 107.522 111.771Z" fill="#C2C2C2"/>
<path d="M119.156 111.771C120.747 111.771 122.031 110.48 122.031 108.896C122.031 107.305 120.739 106.021 119.156 106.021C117.565 106.021 116.281 107.313 116.281 108.896C116.273 110.487 117.565 111.771 119.156 111.771Z" fill="#C2C2C2"/>
<path d="M130.781 111.771C132.372 111.771 133.656 110.48 133.656 108.896C133.656 107.305 132.365 106.021 130.781 106.021C129.19 106.021 127.907 107.313 127.907 108.896C127.899 110.487 129.19 111.771 130.781 111.771Z" fill="#C2C2C2"/>
<path d="M142.407 111.771C143.998 111.771 145.282 110.48 145.282 108.896C145.282 107.305 143.99 106.021 142.407 106.021C140.816 106.021 139.532 107.313 139.532 108.896C139.532 110.487 140.816 111.771 142.407 111.771Z" fill="#C2C2C2"/>
<path d="M154.033 111.771C155.624 111.771 156.908 110.48 156.908 108.896C156.908 107.305 155.616 106.021 154.033 106.021C152.442 106.021 151.158 107.313 151.158 108.896C151.158 110.487 152.442 111.771 154.033 111.771Z" fill="#C2C2C2"/>
<path d="M165.659 111.771C167.25 111.771 168.534 110.48 168.534 108.896C168.534 107.305 167.242 106.021 165.659 106.021C164.068 106.021 162.784 107.313 162.784 108.896C162.784 110.487 164.075 111.771 165.659 111.771Z" fill="#C2C2C2"/>
<path d="M177.292 111.771C178.883 111.771 180.167 110.48 180.167 108.896C180.167 107.305 178.875 106.021 177.292 106.021C175.701 106.021 174.417 107.313 174.417 108.896C174.409 110.487 175.701 111.771 177.292 111.771Z" fill="#C2C2C2"/>
<path d="M188.918 111.771C190.509 111.771 191.793 110.48 191.793 108.896C191.793 107.305 190.501 106.021 188.918 106.021C187.327 106.021 186.043 107.313 186.043 108.896C186.035 110.487 187.327 111.771 188.918 111.771Z" fill="#C2C2C2"/>
<path d="M200.543 111.771C202.134 111.771 203.418 110.48 203.418 108.896C203.418 107.305 202.127 106.021 200.543 106.021C198.952 106.021 197.668 107.313 197.668 108.896C197.668 110.487 198.952 111.771 200.543 111.771Z" fill="#C2C2C2"/>
<path d="M212.169 111.771C213.76 111.771 215.044 110.48 215.044 108.896C215.044 107.305 213.753 106.021 212.169 106.021C210.578 106.021 209.294 107.313 209.294 108.896C209.294 110.487 210.578 111.771 212.169 111.771Z" fill="#C2C2C2"/>
<path d="M223.795 111.771C225.386 111.771 226.67 110.48 226.67 108.896C226.67 107.305 225.378 106.021 223.795 106.021C222.204 106.021 220.92 107.313 220.92 108.896C220.92 110.487 222.212 111.771 223.795 111.771Z" fill="#C2C2C2"/>
<path d="M235.428 111.771C237.019 111.771 238.303 110.48 238.303 108.896C238.303 107.305 237.012 106.021 235.428 106.021C233.837 106.021 232.554 107.313 232.554 108.896C232.546 110.487 233.837 111.771 235.428 111.771Z" fill="#C2C2C2"/>
<path d="M247.054 111.771C248.645 111.771 249.929 110.48 249.929 108.896C249.929 107.305 248.637 106.021 247.054 106.021C245.463 106.021 244.179 107.313 244.179 108.896C244.179 110.487 245.463 111.771 247.054 111.771Z" fill="#C2C2C2"/>
<path d="M258.68 111.771C260.271 111.771 261.555 110.48 261.555 108.896C261.555 107.305 260.263 106.021 258.68 106.021C257.089 106.021 255.805 107.313 255.805 108.896C255.805 110.487 257.089 111.771 258.68 111.771Z" fill="#C2C2C2"/>
<path d="M270.306 111.771C271.897 111.771 273.181 110.48 273.181 108.896C273.181 107.305 271.889 106.021 270.306 106.021C268.715 106.021 267.431 107.313 267.431 108.896C267.431 110.487 268.722 111.771 270.306 111.771Z" fill="#C2C2C2"/>
<path d="M281.939 111.771C283.53 111.771 284.814 110.48 284.814 108.896C284.814 107.305 283.522 106.021 281.939 106.021C280.348 106.021 279.064 107.313 279.064 108.896C279.056 110.487 280.348 111.771 281.939 111.771Z" fill="#C2C2C2"/>
<path d="M293.565 111.771C295.156 111.771 296.44 110.48 296.44 108.896C296.44 107.305 295.148 106.021 293.565 106.021C291.982 106.021 290.69 107.313 290.69 108.896C290.682 110.487 291.974 111.771 293.565 111.771Z" fill="#C2C2C2"/>
<path d="M467.974 111.771C469.565 111.771 470.857 110.48 470.857 108.896C470.857 107.305 469.565 106.021 467.974 106.021C466.383 106.021 465.099 107.313 465.099 108.896C465.099 110.487 466.383 111.771 467.974 111.771Z" fill="#C2C2C2"/>
<path d="M479.6 111.771C481.191 111.771 482.475 110.48 482.475 108.896C482.475 107.305 481.191 106.021 479.6 106.021C478.009 106.021 476.725 107.313 476.725 108.896C476.725 110.487 478.017 111.771 479.6 111.771Z" fill="#C2C2C2"/>
<path d="M491.225 111.771C492.817 111.771 494.1 110.48 494.1 108.896C494.1 107.305 492.817 106.021 491.225 106.021C489.634 106.021 488.351 107.313 488.351 108.896C488.351 110.487 489.642 111.771 491.225 111.771Z" fill="#C2C2C2"/>
<path d="M502.859 111.771C504.45 111.771 505.734 110.48 505.734 108.896C505.734 107.305 504.442 106.021 502.859 106.021C501.268 106.021 499.984 107.313 499.984 108.896C499.977 110.487 501.268 111.771 502.859 111.771Z" fill="#C2C2C2"/>
<path d="M514.485 111.771C516.076 111.771 517.36 110.48 517.36 108.896C517.36 107.305 516.076 106.021 514.485 106.021C512.894 106.021 511.61 107.313 511.61 108.896C511.61 110.487 512.894 111.771 514.485 111.771Z" fill="#C2C2C2"/>
<path d="M526.111 111.771C527.702 111.771 528.985 110.48 528.985 108.896C528.985 107.305 527.702 106.021 526.111 106.021C524.519 106.021 523.236 107.313 523.236 108.896C523.236 110.487 524.519 111.771 526.111 111.771Z" fill="#C2C2C2"/>
<path d="M537.736 111.771C539.327 111.771 540.611 110.48 540.611 108.896C540.611 107.305 539.327 106.021 537.736 106.021C536.145 106.021 534.861 107.313 534.861 108.896C534.861 110.487 536.153 111.771 537.736 111.771Z" fill="#C2C2C2"/>
<path d="M549.362 111.771C550.953 111.771 552.237 110.48 552.237 108.896C552.237 107.305 550.953 106.021 549.362 106.021C547.771 106.021 546.487 107.313 546.487 108.896C546.487 110.487 547.779 111.771 549.362 111.771Z" fill="#C2C2C2"/>
<path d="M2.87492 123.405C4.46597 123.405 5.74984 122.113 5.74984 120.53C5.74984 118.947 4.4581 117.655 2.87492 117.655C1.28387 117.655 0 118.947 0 120.53C0 122.113 1.29175 123.405 2.87492 123.405Z" fill="#C2C2C2"/>
<path d="M14.5085 123.405C16.0995 123.405 17.3834 122.113 17.3834 120.53C17.3834 118.947 16.0916 117.655 14.5085 117.655C12.9253 117.655 11.6335 118.947 11.6335 120.53C11.6335 122.113 12.9174 123.405 14.5085 123.405Z" fill="#C2C2C2"/>
<path d="M26.1342 123.405C27.7252 123.405 29.0091 122.113 29.0091 120.53C29.0091 118.947 27.7174 117.655 26.1342 117.655C24.5431 117.655 23.2593 118.947 23.2593 120.53C23.2593 122.113 24.5431 123.405 26.1342 123.405Z" fill="#C2C2C2"/>
<path d="M37.7599 123.405C39.351 123.405 40.6349 122.113 40.6349 120.53C40.6349 118.947 39.3431 117.655 37.7599 117.655C36.1768 117.655 34.885 118.947 34.885 120.53C34.885 122.113 36.1689 123.405 37.7599 123.405Z" fill="#C2C2C2"/>
<path d="M49.3857 123.405C50.9767 123.405 52.2606 122.113 52.2606 120.53C52.2606 118.947 50.9688 117.655 49.3857 117.655C47.8025 117.655 46.5107 118.947 46.5107 120.53C46.5107 122.113 47.7946 123.405 49.3857 123.405Z" fill="#C2C2C2"/>
<path d="M61.0114 123.405C62.6024 123.405 63.8863 122.113 63.8863 120.53C63.8863 118.947 62.5946 117.655 61.0114 117.655C59.4203 117.655 58.1365 118.947 58.1365 120.53C58.1365 122.113 59.4282 123.405 61.0114 123.405Z" fill="#C2C2C2"/>
<path d="M72.6449 123.405C74.236 123.405 75.5199 122.113 75.5199 120.53C75.5199 118.947 74.2281 117.655 72.6449 117.655C71.0539 117.655 69.77 118.947 69.77 120.53C69.77 122.113 71.0539 123.405 72.6449 123.405Z" fill="#C2C2C2"/>
<path d="M119.156 123.405C120.747 123.405 122.031 122.113 122.031 120.53C122.031 118.947 120.739 117.655 119.156 117.655C117.565 117.655 116.281 118.947 116.281 120.53C116.281 122.113 117.565 123.405 119.156 123.405Z" fill="#C2C2C2"/>
<path d="M130.781 123.405C132.372 123.405 133.656 122.113 133.656 120.53C133.656 118.947 132.365 117.655 130.781 117.655C129.19 117.655 127.906 118.947 127.906 120.53C127.906 122.113 129.19 123.405 130.781 123.405Z" fill="#C2C2C2"/>
<path d="M142.407 123.405C143.998 123.405 145.282 122.113 145.282 120.53C145.282 118.947 143.99 117.655 142.407 117.655C140.816 117.655 139.532 118.947 139.532 120.53C139.532 122.113 140.816 123.405 142.407 123.405Z" fill="#C2C2C2"/>
<path d="M154.033 123.405C155.624 123.405 156.908 122.113 156.908 120.53C156.908 118.947 155.616 117.655 154.033 117.655C152.442 117.655 151.158 118.947 151.158 120.53C151.158 122.113 152.442 123.405 154.033 123.405Z" fill="#C2C2C2"/>
<path d="M165.659 123.405C167.25 123.405 168.534 122.113 168.534 120.53C168.534 118.947 167.242 117.655 165.659 117.655C164.068 117.655 162.784 118.947 162.784 120.53C162.784 122.113 164.075 123.405 165.659 123.405Z" fill="#C2C2C2"/>
<path d="M177.292 123.405C178.883 123.405 180.167 122.113 180.167 120.53C180.167 118.947 178.875 117.655 177.292 117.655C175.701 117.655 174.417 118.947 174.417 120.53C174.417 122.113 175.701 123.405 177.292 123.405Z" fill="#C2C2C2"/>
<path d="M188.918 123.405C190.509 123.405 191.793 122.113 191.793 120.53C191.793 118.947 190.501 117.655 188.918 117.655C187.327 117.655 186.043 118.947 186.043 120.53C186.043 122.113 187.327 123.405 188.918 123.405Z" fill="#C2C2C2"/>
<path d="M200.543 123.405C202.134 123.405 203.418 122.113 203.418 120.53C203.418 118.947 202.127 117.655 200.543 117.655C198.952 117.655 197.668 118.947 197.668 120.53C197.668 122.113 198.952 123.405 200.543 123.405Z" fill="#C2C2C2"/>
<path d="M212.169 123.405C213.76 123.405 215.044 122.113 215.044 120.53C215.044 118.947 213.753 117.655 212.169 117.655C210.578 117.655 209.294 118.947 209.294 120.53C209.294 122.113 210.578 123.405 212.169 123.405Z" fill="#C2C2C2"/>
<path d="M223.795 123.405C225.386 123.405 226.67 122.113 226.67 120.53C226.67 118.947 225.378 117.655 223.795 117.655C222.204 117.655 220.92 118.947 220.92 120.53C220.92 122.113 222.212 123.405 223.795 123.405Z" fill="#C2C2C2"/>
<path d="M235.429 123.405C237.02 123.405 238.304 122.113 238.304 120.53C238.304 118.947 237.012 117.655 235.429 117.655C233.838 117.655 232.554 118.947 232.554 120.53C232.554 122.113 233.838 123.405 235.429 123.405Z" fill="#C2C2C2"/>
<path d="M247.054 123.405C248.645 123.405 249.929 122.113 249.929 120.53C249.929 118.947 248.637 117.655 247.054 117.655C245.463 117.655 244.179 118.947 244.179 120.53C244.179 122.113 245.463 123.405 247.054 123.405Z" fill="#C2C2C2"/>
<path d="M258.68 123.405C260.271 123.405 261.555 122.113 261.555 120.53C261.555 118.947 260.263 117.655 258.68 117.655C257.089 117.655 255.805 118.947 255.805 120.53C255.805 122.113 257.089 123.405 258.68 123.405Z" fill="#C2C2C2"/>
<path d="M270.306 123.405C271.897 123.405 273.181 122.113 273.181 120.53C273.181 118.947 271.889 117.655 270.306 117.655C268.715 117.655 267.431 118.947 267.431 120.53C267.431 122.113 268.722 123.405 270.306 123.405Z" fill="#C2C2C2"/>
<path d="M281.939 123.405C283.53 123.405 284.814 122.113 284.814 120.53C284.814 118.947 283.522 117.655 281.939 117.655C280.348 117.655 279.064 118.947 279.064 120.53C279.064 122.113 280.348 123.405 281.939 123.405Z" fill="#C2C2C2"/>
<path d="M293.565 123.405C295.156 123.405 296.44 122.113 296.44 120.53C296.44 118.947 295.148 117.655 293.565 117.655C291.982 117.655 290.69 118.947 290.69 120.53C290.69 122.113 291.974 123.405 293.565 123.405Z" fill="#C2C2C2"/>
<path d="M467.974 123.405C469.565 123.405 470.857 122.113 470.857 120.53C470.857 118.947 469.565 117.655 467.974 117.655C466.383 117.655 465.099 118.947 465.099 120.53C465.099 122.113 466.383 123.405 467.974 123.405Z" fill="#C2C2C2"/>
<path d="M479.6 123.405C481.191 123.405 482.475 122.113 482.475 120.53C482.475 118.947 481.191 117.655 479.6 117.655C478.009 117.655 476.725 118.947 476.725 120.53C476.725 122.113 478.017 123.405 479.6 123.405Z" fill="#C2C2C2"/>
<path d="M491.225 123.405C492.817 123.405 494.1 122.113 494.1 120.53C494.1 118.947 492.817 117.655 491.225 117.655C489.634 117.655 488.351 118.947 488.351 120.53C488.351 122.113 489.642 123.405 491.225 123.405Z" fill="#C2C2C2"/>
<path d="M502.859 123.405C504.45 123.405 505.734 122.113 505.734 120.53C505.734 118.947 504.442 117.655 502.859 117.655C501.268 117.655 499.984 118.947 499.984 120.53C499.984 122.113 501.268 123.405 502.859 123.405Z" fill="#C2C2C2"/>
<path d="M514.485 123.405C516.076 123.405 517.36 122.113 517.36 120.53C517.36 118.947 516.076 117.655 514.485 117.655C512.894 117.655 511.61 118.947 511.61 120.53C511.61 122.113 512.894 123.405 514.485 123.405Z" fill="#C2C2C2"/>
<path d="M526.111 123.405C527.702 123.405 528.985 122.113 528.985 120.53C528.985 118.947 527.702 117.655 526.111 117.655C524.519 117.655 523.236 118.947 523.236 120.53C523.236 122.113 524.519 123.405 526.111 123.405Z" fill="#C2C2C2"/>
<path d="M537.736 123.405C539.327 123.405 540.611 122.113 540.611 120.53C540.611 118.947 539.327 117.655 537.736 117.655C536.145 117.655 534.861 118.947 534.861 120.53C534.861 122.113 536.153 123.405 537.736 123.405Z" fill="#C2C2C2"/>
<path d="M549.362 123.405C550.953 123.405 552.237 122.113 552.237 120.53C552.237 118.947 550.953 117.655 549.362 117.655C547.771 117.655 546.487 118.947 546.487 120.53C546.487 122.113 547.779 123.405 549.362 123.405Z" fill="#C2C2C2"/>
<path d="M560.996 123.405C562.587 123.405 563.87 122.113 563.87 120.53C563.87 118.947 562.587 117.655 560.996 117.655C559.404 117.655 558.121 118.947 558.121 120.53C558.121 122.113 559.404 123.405 560.996 123.405Z" fill="#C2C2C2"/>
<path d="M572.621 123.405C574.212 123.405 575.496 122.113 575.496 120.53C575.496 118.947 574.212 117.655 572.621 117.655C571.03 117.655 569.746 118.947 569.746 120.53C569.746 122.113 571.03 123.405 572.621 123.405Z" fill="#C2C2C2"/>
<path d="M2.87492 135.031C4.46597 135.031 5.74984 133.739 5.74984 132.156C5.74984 130.573 4.4581 129.281 2.87492 129.281C1.28387 129.281 0 130.573 0 132.156C0 133.739 1.29175 135.031 2.87492 135.031Z" fill="#C2C2C2"/>
<path d="M14.5085 135.031C16.0995 135.031 17.3834 133.739 17.3834 132.156C17.3834 130.573 16.0916 129.281 14.5085 129.281C12.9253 129.281 11.6335 130.573 11.6335 132.156C11.6335 133.739 12.9174 135.031 14.5085 135.031Z" fill="#C2C2C2"/>
<path d="M26.1342 135.031C27.7252 135.031 29.0091 133.739 29.0091 132.156C29.0091 130.573 27.7174 129.281 26.1342 129.281C24.5431 129.281 23.2593 130.573 23.2593 132.156C23.2593 133.739 24.5431 135.031 26.1342 135.031Z" fill="#C2C2C2"/>
<path d="M37.7599 135.031C39.351 135.031 40.6349 133.739 40.6349 132.156C40.6349 130.573 39.3431 129.281 37.7599 129.281C36.1768 129.281 34.885 130.573 34.885 132.156C34.885 133.739 36.1689 135.031 37.7599 135.031Z" fill="#C2C2C2"/>
<path d="M49.3857 135.031C50.9767 135.031 52.2606 133.739 52.2606 132.156C52.2606 130.573 50.9688 129.281 49.3857 129.281C47.8025 129.281 46.5107 130.573 46.5107 132.156C46.5107 133.739 47.7946 135.031 49.3857 135.031Z" fill="#C2C2C2"/>
<path d="M61.0114 135.031C62.6024 135.031 63.8863 133.739 63.8863 132.156C63.8863 130.573 62.5946 129.281 61.0114 129.281C59.4203 129.281 58.1365 130.573 58.1365 132.156C58.1365 133.739 59.4282 135.031 61.0114 135.031Z" fill="#C2C2C2"/>
<path d="M119.156 135.031C120.747 135.031 122.031 133.739 122.031 132.156C122.031 130.573 120.739 129.281 119.156 129.281C117.565 129.281 116.281 130.573 116.281 132.156C116.281 133.739 117.565 135.031 119.156 135.031Z" fill="#C2C2C2"/>
<path d="M130.781 135.031C132.372 135.031 133.656 133.739 133.656 132.156C133.656 130.573 132.365 129.281 130.781 129.281C129.19 129.281 127.906 130.573 127.906 132.156C127.906 133.739 129.19 135.031 130.781 135.031Z" fill="#C2C2C2"/>
<path d="M142.407 135.031C143.995 135.031 145.282 133.743 145.282 132.156C145.282 130.568 143.995 129.281 142.407 129.281C140.819 129.281 139.532 130.568 139.532 132.156C139.532 133.743 140.819 135.031 142.407 135.031Z" fill="#C2C2C2"/>
<path d="M154.033 135.031C155.624 135.031 156.908 133.739 156.908 132.156C156.908 130.573 155.616 129.281 154.033 129.281C152.442 129.281 151.158 130.573 151.158 132.156C151.158 133.739 152.442 135.031 154.033 135.031Z" fill="#C2C2C2"/>
<path d="M165.659 135.031C167.246 135.031 168.534 133.743 168.534 132.156C168.534 130.568 167.246 129.281 165.659 129.281C164.071 129.281 162.784 130.568 162.784 132.156C162.784 133.743 164.071 135.031 165.659 135.031Z" fill="#C2C2C2"/>
<path d="M177.292 135.031C178.88 135.031 180.167 133.743 180.167 132.156C180.167 130.568 178.88 129.281 177.292 129.281C175.704 129.281 174.417 130.568 174.417 132.156C174.417 133.743 175.704 135.031 177.292 135.031Z" fill="#C2C2C2"/>
<path d="M188.918 135.031C190.509 135.031 191.793 133.739 191.793 132.156C191.793 130.573 190.501 129.281 188.918 129.281C187.327 129.281 186.043 130.573 186.043 132.156C186.043 133.739 187.327 135.031 188.918 135.031Z" fill="#C2C2C2"/>
<path d="M200.543 135.031C202.134 135.031 203.418 133.739 203.418 132.156C203.418 130.573 202.127 129.281 200.543 129.281C198.952 129.281 197.668 130.573 197.668 132.156C197.668 133.739 198.952 135.031 200.543 135.031Z" fill="#C2C2C2"/>
<path d="M212.169 135.031C213.76 135.031 215.044 133.739 215.044 132.156C215.044 130.573 213.753 129.281 212.169 129.281C210.578 129.281 209.294 130.573 209.294 132.156C209.294 133.739 210.578 135.031 212.169 135.031Z" fill="#C2C2C2"/>
<path d="M223.795 135.031C225.383 135.031 226.67 133.743 226.67 132.156C226.67 130.568 225.383 129.281 223.795 129.281C222.207 129.281 220.92 130.568 220.92 132.156C220.92 133.743 222.207 135.031 223.795 135.031Z" fill="#C2C2C2"/>
<path d="M235.429 135.031C237.016 135.031 238.304 133.743 238.304 132.156C238.304 130.568 237.016 129.281 235.429 129.281C233.841 129.281 232.554 130.568 232.554 132.156C232.554 133.743 233.841 135.031 235.429 135.031Z" fill="#C2C2C2"/>
<path d="M247.054 135.031C248.645 135.031 249.929 133.739 249.929 132.156C249.929 130.573 248.637 129.281 247.054 129.281C245.463 129.281 244.179 130.573 244.179 132.156C244.179 133.739 245.463 135.031 247.054 135.031Z" fill="#C2C2C2"/>
<path d="M258.68 135.031C260.271 135.031 261.555 133.739 261.555 132.156C261.555 130.573 260.263 129.281 258.68 129.281C257.089 129.281 255.805 130.573 255.805 132.156C255.805 133.739 257.089 135.031 258.68 135.031Z" fill="#C2C2C2"/>
<path d="M270.306 135.031C271.897 135.031 273.181 133.739 273.181 132.156C273.181 130.573 271.889 129.281 270.306 129.281C268.715 129.281 267.431 130.573 267.431 132.156C267.431 133.739 268.722 135.031 270.306 135.031Z" fill="#C2C2C2"/>
<path d="M281.939 135.031C283.53 135.031 284.814 133.739 284.814 132.156C284.814 130.573 283.522 129.281 281.939 129.281C280.348 129.281 279.064 130.573 279.064 132.156C279.064 133.739 280.348 135.031 281.939 135.031Z" fill="#C2C2C2"/>
<path d="M293.565 135.031C295.156 135.031 296.44 133.739 296.44 132.156C296.44 130.573 295.148 129.281 293.565 129.281C291.982 129.281 290.69 130.573 290.69 132.156C290.69 133.739 291.974 135.031 293.565 135.031Z" fill="#C2C2C2"/>
<path d="M467.974 135.031C469.565 135.031 470.857 133.739 470.857 132.156C470.857 130.573 469.565 129.281 467.974 129.281C466.383 129.281 465.099 130.573 465.099 132.156C465.099 133.739 466.383 135.031 467.974 135.031Z" fill="#C2C2C2"/>
<path d="M479.6 135.031C481.188 135.031 482.475 133.743 482.475 132.156C482.475 130.568 481.188 129.281 479.6 129.281C478.012 129.281 476.725 130.568 476.725 132.156C476.725 133.743 478.012 135.031 479.6 135.031Z" fill="#C2C2C2"/>
<path d="M494.108 132.161C494.112 130.573 492.829 129.282 491.241 129.278C489.653 129.273 488.362 130.557 488.358 132.144C488.353 133.732 489.637 135.023 491.225 135.027C492.813 135.032 494.103 133.748 494.108 132.161Z" fill="#C2C2C2"/>
<path d="M502.859 135.031C504.45 135.031 505.734 133.739 505.734 132.156C505.734 130.573 504.442 129.281 502.859 129.281C501.268 129.281 499.984 130.573 499.984 132.156C499.984 133.739 501.268 135.031 502.859 135.031Z" fill="#C2C2C2"/>
<path d="M514.485 135.031C516.073 135.031 517.36 133.743 517.36 132.156C517.36 130.568 516.073 129.281 514.485 129.281C512.897 129.281 511.61 130.568 511.61 132.156C511.61 133.743 512.897 135.031 514.485 135.031Z" fill="#C2C2C2"/>
<path d="M526.111 135.031C527.702 135.031 528.985 133.739 528.985 132.156C528.985 130.573 527.702 129.281 526.111 129.281C524.519 129.281 523.236 130.573 523.236 132.156C523.236 133.739 524.519 135.031 526.111 135.031Z" fill="#C2C2C2"/>
<path d="M537.736 135.031C539.327 135.031 540.611 133.739 540.611 132.156C540.611 130.573 539.327 129.281 537.736 129.281C536.145 129.281 534.861 130.573 534.861 132.156C534.861 133.739 536.153 135.031 537.736 135.031Z" fill="#C2C2C2"/>
<path d="M549.362 135.031C550.953 135.031 552.237 133.739 552.237 132.156C552.237 130.573 550.953 129.281 549.362 129.281C547.771 129.281 546.487 130.573 546.487 132.156C546.487 133.739 547.779 135.031 549.362 135.031Z" fill="#C2C2C2"/>
<path d="M560.996 135.031C562.587 135.031 563.87 133.739 563.87 132.156C563.87 130.573 562.587 129.281 560.996 129.281C559.404 129.281 558.121 130.573 558.121 132.156C558.121 133.739 559.404 135.031 560.996 135.031Z" fill="#C2C2C2"/>
<path d="M572.621 135.031C574.212 135.031 575.496 133.739 575.496 132.156C575.496 130.573 574.212 129.281 572.621 129.281C571.03 129.281 569.746 130.573 569.746 132.156C569.746 133.739 571.03 135.031 572.621 135.031Z" fill="#C2C2C2"/>
<path d="M584.247 135.031C585.838 135.031 587.122 133.739 587.122 132.156C587.122 130.573 585.83 129.281 584.247 129.281C582.656 129.281 581.372 130.573 581.372 132.156C581.372 133.739 582.664 135.031 584.247 135.031Z" fill="#C2C2C2"/>
<path d="M595.873 135.031C597.464 135.031 598.748 133.739 598.748 132.156C598.748 130.573 597.456 129.281 595.873 129.281C594.282 129.281 592.998 130.573 592.998 132.156C592.998 133.739 594.282 135.031 595.873 135.031Z" fill="#C2C2C2"/>
<path d="M607.506 135.031C609.097 135.031 610.381 133.739 610.381 132.156C610.381 130.573 609.089 129.281 607.506 129.281C605.915 129.281 604.631 130.573 604.631 132.156C604.631 133.739 605.915 135.031 607.506 135.031Z" fill="#C2C2C2"/>
<path d="M2.87492 146.656C4.46597 146.656 5.74984 145.364 5.74984 143.781C5.74984 142.19 4.4581 140.906 2.87492 140.906C1.28387 140.906 0 142.198 0 143.781C0 145.372 1.29175 146.656 2.87492 146.656Z" fill="#C2C2C2"/>
<path d="M14.5085 146.656C16.0996 146.656 17.3834 145.364 17.3834 143.781C17.3834 142.19 16.0917 140.906 14.5085 140.906C12.9253 140.906 11.6336 142.198 11.6336 143.781C11.6257 145.372 12.9174 146.656 14.5085 146.656Z" fill="#C2C2C2"/>
<path d="M26.1342 146.656C27.7253 146.656 29.0092 145.364 29.0092 143.781C29.0092 142.19 27.7174 140.906 26.1342 140.906C24.5432 140.906 23.2593 142.198 23.2593 143.781C23.2514 145.372 24.5432 146.656 26.1342 146.656Z" fill="#C2C2C2"/>
<path d="M37.7599 146.656C39.351 146.656 40.6349 145.364 40.6349 143.781C40.6349 142.19 39.3431 140.906 37.7599 140.906C36.1768 140.906 34.885 142.198 34.885 143.781C34.885 145.372 36.1689 146.656 37.7599 146.656Z" fill="#C2C2C2"/>
<path d="M49.3857 146.656C50.9767 146.656 52.2606 145.364 52.2606 143.781C52.2606 142.19 50.9688 140.906 49.3857 140.906C47.8025 140.906 46.5107 142.198 46.5107 143.781C46.5107 145.372 47.7946 146.656 49.3857 146.656Z" fill="#C2C2C2"/>
<path d="M61.0114 146.656C62.6024 146.656 63.8863 145.364 63.8863 143.781C63.8863 142.19 62.5946 140.906 61.0114 140.906C59.4203 140.906 58.1365 142.198 58.1365 143.781C58.1365 145.372 59.4282 146.656 61.0114 146.656Z" fill="#C2C2C2"/>
<path d="M72.645 146.656C74.236 146.656 75.5199 145.364 75.5199 143.781C75.5199 142.19 74.2282 140.906 72.645 140.906C71.0539 140.906 69.7701 142.198 69.7701 143.781C69.7622 145.372 71.0539 146.656 72.645 146.656Z" fill="#C2C2C2"/>
<path d="M107.522 146.656C109.113 146.656 110.397 145.364 110.397 143.781C110.397 142.19 109.105 140.906 107.522 140.906C105.939 140.906 104.647 142.198 104.647 143.781C104.647 145.372 105.939 146.656 107.522 146.656Z" fill="#C2C2C2"/>
<path d="M119.156 146.656C120.747 146.656 122.031 145.364 122.031 143.781C122.031 142.19 120.739 140.906 119.156 140.906C117.565 140.906 116.281 142.198 116.281 143.781C116.273 145.372 117.565 146.656 119.156 146.656Z" fill="#C2C2C2"/>
<path d="M130.781 146.656C132.372 146.656 133.656 145.364 133.656 143.781C133.656 142.19 132.365 140.906 130.781 140.906C129.19 140.906 127.907 142.198 127.907 143.781C127.899 145.372 129.19 146.656 130.781 146.656Z" fill="#C2C2C2"/>
<path d="M142.407 146.656C143.998 146.656 145.282 145.364 145.282 143.781C145.282 142.19 143.99 140.906 142.407 140.906C140.816 140.906 139.532 142.198 139.532 143.781C139.532 145.372 140.816 146.656 142.407 146.656Z" fill="#C2C2C2"/>
<path d="M154.033 146.656C155.624 146.656 156.908 145.364 156.908 143.781C156.908 142.19 155.616 140.906 154.033 140.906C152.442 140.906 151.158 142.198 151.158 143.781C151.158 145.372 152.442 146.656 154.033 146.656Z" fill="#C2C2C2"/>
<path d="M165.659 146.656C167.25 146.656 168.534 145.364 168.534 143.781C168.534 142.19 167.242 140.906 165.659 140.906C164.068 140.906 162.784 142.198 162.784 143.781C162.784 145.372 164.075 146.656 165.659 146.656Z" fill="#C2C2C2"/>
<path d="M177.292 146.656C178.883 146.656 180.167 145.364 180.167 143.781C180.167 142.19 178.875 140.906 177.292 140.906C175.701 140.906 174.417 142.198 174.417 143.781C174.409 145.372 175.701 146.656 177.292 146.656Z" fill="#C2C2C2"/>
<path d="M188.918 146.656C190.509 146.656 191.793 145.364 191.793 143.781C191.793 142.19 190.501 140.906 188.918 140.906C187.327 140.906 186.043 142.198 186.043 143.781C186.035 145.372 187.327 146.656 188.918 146.656Z" fill="#C2C2C2"/>
<path d="M200.543 146.656C202.134 146.656 203.418 145.364 203.418 143.781C203.418 142.19 202.127 140.906 200.543 140.906C198.952 140.906 197.668 142.198 197.668 143.781C197.668 145.372 198.952 146.656 200.543 146.656Z" fill="#C2C2C2"/>
<path d="M212.169 146.656C213.76 146.656 215.044 145.364 215.044 143.781C215.044 142.19 213.753 140.906 212.169 140.906C210.578 140.906 209.294 142.198 209.294 143.781C209.294 145.372 210.578 146.656 212.169 146.656Z" fill="#C2C2C2"/>
<path d="M223.795 146.656C225.386 146.656 226.67 145.364 226.67 143.781C226.67 142.19 225.378 140.906 223.795 140.906C222.204 140.906 220.92 142.198 220.92 143.781C220.92 145.372 222.212 146.656 223.795 146.656Z" fill="#C2C2C2"/>
<path d="M235.428 146.656C237.019 146.656 238.303 145.364 238.303 143.781C238.303 142.19 237.012 140.906 235.428 140.906C233.837 140.906 232.554 142.198 232.554 143.781C232.546 145.372 233.837 146.656 235.428 146.656Z" fill="#C2C2C2"/>
<path d="M247.054 146.656C248.645 146.656 249.929 145.364 249.929 143.781C249.929 142.19 248.637 140.906 247.054 140.906C245.463 140.906 244.179 142.198 244.179 143.781C244.179 145.372 245.463 146.656 247.054 146.656Z" fill="#C2C2C2"/>
<path d="M258.68 146.656C260.271 146.656 261.555 145.364 261.555 143.781C261.555 142.19 260.263 140.906 258.68 140.906C257.089 140.906 255.805 142.198 255.805 143.781C255.805 145.372 257.089 146.656 258.68 146.656Z" fill="#C2C2C2"/>
<path d="M270.306 146.656C271.897 146.656 273.181 145.364 273.181 143.781C273.181 142.19 271.889 140.906 270.306 140.906C268.715 140.906 267.431 142.198 267.431 143.781C267.431 145.372 268.722 146.656 270.306 146.656Z" fill="#C2C2C2"/>
<path d="M281.939 146.656C283.53 146.656 284.814 145.364 284.814 143.781C284.814 142.19 283.522 140.906 281.939 140.906C280.348 140.906 279.064 142.198 279.064 143.781C279.056 145.372 280.348 146.656 281.939 146.656Z" fill="#C2C2C2"/>
<path d="M293.565 146.656C295.156 146.656 296.44 145.364 296.44 143.781C296.44 142.19 295.148 140.906 293.565 140.906C291.982 140.906 290.69 142.198 290.69 143.781C290.682 145.372 291.974 146.656 293.565 146.656Z" fill="#C2C2C2"/>
<path d="M467.974 146.656C469.565 146.656 470.857 145.364 470.857 143.781C470.857 142.19 469.565 140.906 467.974 140.906C466.383 140.906 465.099 142.198 465.099 143.781C465.099 145.372 466.383 146.656 467.974 146.656Z" fill="#C2C2C2"/>
<path d="M479.6 146.656C481.191 146.656 482.475 145.364 482.475 143.781C482.475 142.19 481.191 140.906 479.6 140.906C478.009 140.906 476.725 142.198 476.725 143.781C476.725 145.372 478.017 146.656 479.6 146.656Z" fill="#C2C2C2"/>
<path d="M491.225 146.656C492.817 146.656 494.1 145.364 494.1 143.781C494.1 142.19 492.817 140.906 491.225 140.906C489.634 140.906 488.351 142.198 488.351 143.781C488.351 145.372 489.642 146.656 491.225 146.656Z" fill="#C2C2C2"/>
<path d="M502.859 146.656C504.45 146.656 505.734 145.364 505.734 143.781C505.734 142.19 504.442 140.906 502.859 140.906C501.268 140.906 499.984 142.198 499.984 143.781C499.977 145.372 501.268 146.656 502.859 146.656Z" fill="#C2C2C2"/>
<path d="M514.485 146.656C516.076 146.656 517.36 145.364 517.36 143.781C517.36 142.19 516.076 140.906 514.485 140.906C512.894 140.906 511.61 142.198 511.61 143.781C511.61 145.372 512.894 146.656 514.485 146.656Z" fill="#C2C2C2"/>
<path d="M526.111 146.656C527.702 146.656 528.985 145.364 528.985 143.781C528.985 142.19 527.702 140.906 526.111 140.906C524.519 140.906 523.236 142.198 523.236 143.781C523.236 145.372 524.519 146.656 526.111 146.656Z" fill="#C2C2C2"/>
<path d="M537.736 146.656C539.327 146.656 540.611 145.364 540.611 143.781C540.611 142.19 539.327 140.906 537.736 140.906C536.145 140.906 534.861 142.198 534.861 143.781C534.861 145.372 536.153 146.656 537.736 146.656Z" fill="#C2C2C2"/>
<path d="M549.362 146.656C550.953 146.656 552.237 145.364 552.237 143.781C552.237 142.19 550.953 140.906 549.362 140.906C547.771 140.906 546.487 142.198 546.487 143.781C546.487 145.372 547.779 146.656 549.362 146.656Z" fill="#C2C2C2"/>
<path d="M560.996 146.656C562.587 146.656 563.871 145.364 563.871 143.781C563.871 142.19 562.587 140.906 560.996 140.906C559.405 140.906 558.121 142.198 558.121 143.781C558.113 145.372 559.405 146.656 560.996 146.656Z" fill="#C2C2C2"/>
<path d="M572.621 146.656C574.212 146.656 575.496 145.364 575.496 143.781C575.496 142.19 574.212 140.906 572.621 140.906C571.03 140.906 569.746 142.198 569.746 143.781C569.746 145.372 571.03 146.656 572.621 146.656Z" fill="#C2C2C2"/>
<path d="M584.247 146.656C585.838 146.656 587.122 145.364 587.122 143.781C587.122 142.19 585.83 140.906 584.247 140.906C582.656 140.906 581.372 142.198 581.372 143.781C581.372 145.372 582.664 146.656 584.247 146.656Z" fill="#C2C2C2"/>
<path d="M595.873 146.656C597.464 146.656 598.748 145.364 598.748 143.781C598.748 142.19 597.456 140.906 595.873 140.906C594.282 140.906 592.998 142.198 592.998 143.781C592.998 145.372 594.282 146.656 595.873 146.656Z" fill="#C2C2C2"/>
<path d="M607.506 146.656C609.097 146.656 610.381 145.364 610.381 143.781C610.381 142.19 609.09 140.906 607.506 140.906C605.915 140.906 604.631 142.198 604.631 143.781C604.624 145.372 605.915 146.656 607.506 146.656Z" fill="#C2C2C2"/>
<path d="M2.87492 158.282C4.46597 158.282 5.74984 156.99 5.74984 155.407C5.74984 153.824 4.4581 152.532 2.87492 152.532C1.28387 152.532 0 153.824 0 155.407C0 156.99 1.29175 158.282 2.87492 158.282Z" fill="#C2C2C2"/>
<path d="M14.5085 158.282C16.0995 158.282 17.3834 156.99 17.3834 155.407C17.3834 153.824 16.0916 152.532 14.5085 152.532C12.9253 152.532 11.6335 153.824 11.6335 155.407C11.6335 156.99 12.9174 158.282 14.5085 158.282Z" fill="#C2C2C2"/>
<path d="M26.1342 158.282C27.7252 158.282 29.0091 156.99 29.0091 155.407C29.0091 153.824 27.7174 152.532 26.1342 152.532C24.5431 152.532 23.2593 153.824 23.2593 155.407C23.2593 156.99 24.5431 158.282 26.1342 158.282Z" fill="#C2C2C2"/>
<path d="M37.7599 158.282C39.351 158.282 40.6349 156.99 40.6349 155.407C40.6349 153.824 39.3431 152.532 37.7599 152.532C36.1768 152.532 34.885 153.824 34.885 155.407C34.885 156.99 36.1689 158.282 37.7599 158.282Z" fill="#C2C2C2"/>
<path d="M49.3857 158.282C50.9767 158.282 52.2606 156.99 52.2606 155.407C52.2606 153.824 50.9688 152.532 49.3857 152.532C47.8025 152.532 46.5107 153.824 46.5107 155.407C46.5107 156.99 47.7946 158.282 49.3857 158.282Z" fill="#C2C2C2"/>
<path d="M61.0114 158.282C62.6024 158.282 63.8863 156.99 63.8863 155.407C63.8863 153.824 62.5946 152.532 61.0114 152.532C59.4203 152.532 58.1365 153.824 58.1365 155.407C58.1365 156.99 59.4282 158.282 61.0114 158.282Z" fill="#C2C2C2"/>
<path d="M72.6449 158.282C74.2327 158.282 75.5199 156.995 75.5199 155.407C75.5199 153.819 74.2327 152.532 72.6449 152.532C71.0572 152.532 69.77 153.819 69.77 155.407C69.77 156.995 71.0572 158.282 72.6449 158.282Z" fill="#C2C2C2"/>
<path d="M84.2707 158.282C85.8617 158.282 87.1456 156.99 87.1456 155.407C87.1456 153.824 85.8538 152.532 84.2707 152.532C82.6796 152.532 81.3958 153.824 81.3958 155.407C81.3958 156.99 82.6796 158.282 84.2707 158.282Z" fill="#C2C2C2"/>
<path d="M95.8964 158.282C97.4875 158.282 98.7713 156.99 98.7713 155.407C98.7713 153.824 97.4796 152.532 95.8964 152.532C94.3054 152.532 93.0215 153.824 93.0215 155.407C93.0215 156.99 94.3054 158.282 95.8964 158.282Z" fill="#C2C2C2"/>
<path d="M107.522 158.282C109.113 158.282 110.397 156.99 110.397 155.407C110.397 153.824 109.105 152.532 107.522 152.532C105.939 152.532 104.647 153.824 104.647 155.407C104.647 156.99 105.939 158.282 107.522 158.282Z" fill="#C2C2C2"/>
<path d="M119.156 158.282C120.747 158.282 122.031 156.99 122.031 155.407C122.031 153.824 120.739 152.532 119.156 152.532C117.565 152.532 116.281 153.824 116.281 155.407C116.281 156.99 117.565 158.282 119.156 158.282Z" fill="#C2C2C2"/>
<path d="M130.781 158.282C132.372 158.282 133.656 156.99 133.656 155.407C133.656 153.824 132.365 152.532 130.781 152.532C129.19 152.532 127.906 153.824 127.906 155.407C127.906 156.99 129.19 158.282 130.781 158.282Z" fill="#C2C2C2"/>
<path d="M142.407 158.282C143.995 158.282 145.282 156.995 145.282 155.407C145.282 153.819 143.995 152.532 142.407 152.532C140.819 152.532 139.532 153.819 139.532 155.407C139.532 156.995 140.819 158.282 142.407 158.282Z" fill="#C2C2C2"/>
<path d="M154.033 158.282C155.624 158.282 156.908 156.99 156.908 155.407C156.908 153.824 155.616 152.532 154.033 152.532C152.442 152.532 151.158 153.824 151.158 155.407C151.158 156.99 152.442 158.282 154.033 158.282Z" fill="#C2C2C2"/>
<path d="M165.659 158.282C167.246 158.282 168.534 156.995 168.534 155.407C168.534 153.819 167.246 152.532 165.659 152.532C164.071 152.532 162.784 153.819 162.784 155.407C162.784 156.995 164.071 158.282 165.659 158.282Z" fill="#C2C2C2"/>
<path d="M177.292 158.282C178.88 158.282 180.167 156.995 180.167 155.407C180.167 153.819 178.88 152.532 177.292 152.532C175.704 152.532 174.417 153.819 174.417 155.407C174.417 156.995 175.704 158.282 177.292 158.282Z" fill="#C2C2C2"/>
<path d="M188.918 158.282C190.509 158.282 191.793 156.99 191.793 155.407C191.793 153.824 190.501 152.532 188.918 152.532C187.327 152.532 186.043 153.824 186.043 155.407C186.043 156.99 187.327 158.282 188.918 158.282Z" fill="#C2C2C2"/>
<path d="M200.543 158.282C202.134 158.282 203.418 156.99 203.418 155.407C203.418 153.824 202.127 152.532 200.543 152.532C198.952 152.532 197.668 153.824 197.668 155.407C197.668 156.99 198.952 158.282 200.543 158.282Z" fill="#C2C2C2"/>
<path d="M212.169 158.282C213.76 158.282 215.044 156.99 215.044 155.407C215.044 153.824 213.753 152.532 212.169 152.532C210.578 152.532 209.294 153.824 209.294 155.407C209.294 156.99 210.578 158.282 212.169 158.282Z" fill="#C2C2C2"/>
<path d="M223.795 158.282C225.383 158.282 226.67 156.995 226.67 155.407C226.67 153.819 225.383 152.532 223.795 152.532C222.207 152.532 220.92 153.819 220.92 155.407C220.92 156.995 222.207 158.282 223.795 158.282Z" fill="#C2C2C2"/>
<path d="M235.429 158.282C237.016 158.282 238.304 156.995 238.304 155.407C238.304 153.819 237.016 152.532 235.429 152.532C233.841 152.532 232.554 153.819 232.554 155.407C232.554 156.995 233.841 158.282 235.429 158.282Z" fill="#C2C2C2"/>
<path d="M247.054 158.282C248.645 158.282 249.929 156.99 249.929 155.407C249.929 153.824 248.637 152.532 247.054 152.532C245.463 152.532 244.179 153.824 244.179 155.407C244.179 156.99 245.463 158.282 247.054 158.282Z" fill="#C2C2C2"/>
<path d="M258.68 158.282C260.271 158.282 261.555 156.99 261.555 155.407C261.555 153.824 260.263 152.532 258.68 152.532C257.089 152.532 255.805 153.824 255.805 155.407C255.805 156.99 257.089 158.282 258.68 158.282Z" fill="#C2C2C2"/>
<path d="M270.306 158.282C271.897 158.282 273.181 156.99 273.181 155.407C273.181 153.824 271.889 152.532 270.306 152.532C268.715 152.532 267.431 153.824 267.431 155.407C267.431 156.99 268.722 158.282 270.306 158.282Z" fill="#C2C2C2"/>
<path d="M281.939 158.282C283.53 158.282 284.814 156.99 284.814 155.407C284.814 153.824 283.522 152.532 281.939 152.532C280.348 152.532 279.064 153.824 279.064 155.407C279.064 156.99 280.348 158.282 281.939 158.282Z" fill="#C2C2C2"/>
<path d="M293.565 158.282C295.156 158.282 296.44 156.99 296.44 155.407C296.44 153.824 295.148 152.532 293.565 152.532C291.982 152.532 290.69 153.824 290.69 155.407C290.69 156.99 291.974 158.282 293.565 158.282Z" fill="#C2C2C2"/>
<path d="M467.974 158.282C469.565 158.282 470.857 156.99 470.857 155.407C470.857 153.824 469.565 152.532 467.974 152.532C466.383 152.532 465.099 153.824 465.099 155.407C465.099 156.99 466.383 158.282 467.974 158.282Z" fill="#C2C2C2"/>
<path d="M479.6 158.282C481.188 158.282 482.475 156.995 482.475 155.407C482.475 153.819 481.188 152.532 479.6 152.532C478.012 152.532 476.725 153.819 476.725 155.407C476.725 156.995 478.012 158.282 479.6 158.282Z" fill="#C2C2C2"/>
<path d="M494.104 155.416C494.109 153.828 492.825 152.537 491.237 152.533C489.649 152.528 488.359 153.812 488.354 155.4C488.35 156.987 489.633 158.278 491.221 158.282C492.809 158.287 494.1 157.003 494.104 155.416Z" fill="#C2C2C2"/>
<path d="M502.859 158.282C504.45 158.282 505.734 156.99 505.734 155.407C505.734 153.824 504.442 152.532 502.859 152.532C501.268 152.532 499.984 153.824 499.984 155.407C499.984 156.99 501.268 158.282 502.859 158.282Z" fill="#C2C2C2"/>
<path d="M514.485 158.282C516.073 158.282 517.36 156.995 517.36 155.407C517.36 153.819 516.073 152.532 514.485 152.532C512.897 152.532 511.61 153.819 511.61 155.407C511.61 156.995 512.897 158.282 514.485 158.282Z" fill="#C2C2C2"/>
<path d="M526.111 158.282C527.702 158.282 528.985 156.99 528.985 155.407C528.985 153.824 527.702 152.532 526.111 152.532C524.519 152.532 523.236 153.824 523.236 155.407C523.236 156.99 524.519 158.282 526.111 158.282Z" fill="#C2C2C2"/>
<path d="M537.736 158.282C539.327 158.282 540.611 156.99 540.611 155.407C540.611 153.824 539.327 152.532 537.736 152.532C536.145 152.532 534.861 153.824 534.861 155.407C534.861 156.99 536.153 158.282 537.736 158.282Z" fill="#C2C2C2"/>
<path d="M549.362 158.282C550.953 158.282 552.237 156.99 552.237 155.407C552.237 153.824 550.953 152.532 549.362 152.532C547.771 152.532 546.487 153.824 546.487 155.407C546.487 156.99 547.779 158.282 549.362 158.282Z" fill="#C2C2C2"/>
<path d="M560.996 158.282C562.587 158.282 563.87 156.99 563.87 155.407C563.87 153.824 562.587 152.532 560.996 152.532C559.404 152.532 558.121 153.824 558.121 155.407C558.121 156.99 559.404 158.282 560.996 158.282Z" fill="#C2C2C2"/>
<path d="M572.621 158.282C574.212 158.282 575.496 156.99 575.496 155.407C575.496 153.824 574.212 152.532 572.621 152.532C571.03 152.532 569.746 153.824 569.746 155.407C569.746 156.99 571.03 158.282 572.621 158.282Z" fill="#C2C2C2"/>
<path d="M584.247 158.282C585.838 158.282 587.122 156.99 587.122 155.407C587.122 153.824 585.83 152.532 584.247 152.532C582.656 152.532 581.372 153.824 581.372 155.407C581.372 156.99 582.664 158.282 584.247 158.282Z" fill="#C2C2C2"/>
<path d="M595.873 158.282C597.464 158.282 598.748 156.99 598.748 155.407C598.748 153.824 597.456 152.532 595.873 152.532C594.282 152.532 592.998 153.824 592.998 155.407C592.998 156.99 594.282 158.282 595.873 158.282Z" fill="#C2C2C2"/>
<path d="M607.506 158.282C609.097 158.282 610.381 156.99 610.381 155.407C610.381 153.824 609.089 152.532 607.506 152.532C605.915 152.532 604.631 153.824 604.631 155.407C604.631 156.99 605.915 158.282 607.506 158.282Z" fill="#C2C2C2"/>
<path d="M619.132 158.282C620.72 158.282 622.007 156.995 622.007 155.407C622.007 153.819 620.72 152.532 619.132 152.532C617.544 152.532 616.257 153.819 616.257 155.407C616.257 156.995 617.544 158.282 619.132 158.282Z" fill="#C2C2C2"/>
<path d="M26.1342 169.908C27.7252 169.908 29.0091 168.616 29.0091 167.033C29.0091 165.449 27.7174 164.158 26.1342 164.158C24.5431 164.158 23.2593 165.449 23.2593 167.033C23.2593 168.616 24.5431 169.908 26.1342 169.908Z" fill="#C2C2C2"/>
<path d="M37.7599 169.908C39.351 169.908 40.6349 168.616 40.6349 167.033C40.6349 165.449 39.3431 164.158 37.7599 164.158C36.1768 164.158 34.885 165.449 34.885 167.033C34.885 168.616 36.1689 169.908 37.7599 169.908Z" fill="#C2C2C2"/>
<path d="M49.3857 169.908C50.9767 169.908 52.2606 168.616 52.2606 167.033C52.2606 165.449 50.9688 164.158 49.3857 164.158C47.8025 164.158 46.5107 165.449 46.5107 167.033C46.5107 168.616 47.7946 169.908 49.3857 169.908Z" fill="#C2C2C2"/>
<path d="M61.0114 169.908C62.6024 169.908 63.8863 168.616 63.8863 167.033C63.8863 165.449 62.5946 164.158 61.0114 164.158C59.4203 164.158 58.1365 165.449 58.1365 167.033C58.1365 168.616 59.4282 169.908 61.0114 169.908Z" fill="#C2C2C2"/>
<path d="M72.6449 169.908C74.236 169.908 75.5199 168.616 75.5199 167.033C75.5199 165.449 74.2281 164.158 72.6449 164.158C71.0539 164.158 69.77 165.449 69.77 167.033C69.77 168.616 71.0539 169.908 72.6449 169.908Z" fill="#C2C2C2"/>
<path d="M84.2707 169.908C85.8617 169.908 87.1456 168.616 87.1456 167.033C87.1456 165.449 85.8538 164.158 84.2707 164.158C82.6796 164.158 81.3958 165.449 81.3958 167.033C81.3958 168.616 82.6796 169.908 84.2707 169.908Z" fill="#C2C2C2"/>
<path d="M95.8964 169.908C97.4875 169.908 98.7713 168.616 98.7713 167.033C98.7713 165.449 97.4796 164.158 95.8964 164.158C94.3054 164.158 93.0215 165.449 93.0215 167.033C93.0215 168.616 94.3054 169.908 95.8964 169.908Z" fill="#C2C2C2"/>
<path d="M107.522 169.908C109.113 169.908 110.397 168.616 110.397 167.033C110.397 165.449 109.105 164.158 107.522 164.158C105.939 164.158 104.647 165.449 104.647 167.033C104.647 168.616 105.939 169.908 107.522 169.908Z" fill="#C2C2C2"/>
<path d="M119.156 169.908C120.747 169.908 122.031 168.616 122.031 167.033C122.031 165.449 120.739 164.158 119.156 164.158C117.565 164.158 116.281 165.449 116.281 167.033C116.281 168.616 117.565 169.908 119.156 169.908Z" fill="#C2C2C2"/>
<path d="M130.781 169.908C132.372 169.908 133.656 168.616 133.656 167.033C133.656 165.449 132.365 164.158 130.781 164.158C129.19 164.158 127.906 165.449 127.906 167.033C127.906 168.616 129.19 169.908 130.781 169.908Z" fill="#C2C2C2"/>
<path d="M142.407 169.908C143.998 169.908 145.282 168.616 145.282 167.033C145.282 165.449 143.99 164.158 142.407 164.158C140.816 164.158 139.532 165.449 139.532 167.033C139.532 168.616 140.816 169.908 142.407 169.908Z" fill="#C2C2C2"/>
<path d="M154.033 169.908C155.624 169.908 156.908 168.616 156.908 167.033C156.908 165.449 155.616 164.158 154.033 164.158C152.442 164.158 151.158 165.449 151.158 167.033C151.158 168.616 152.442 169.908 154.033 169.908Z" fill="#C2C2C2"/>
<path d="M165.659 169.908C167.25 169.908 168.534 168.616 168.534 167.033C168.534 165.449 167.242 164.158 165.659 164.158C164.068 164.158 162.784 165.449 162.784 167.033C162.784 168.616 164.075 169.908 165.659 169.908Z" fill="#C2C2C2"/>
<path d="M177.292 169.908C178.883 169.908 180.167 168.616 180.167 167.033C180.167 165.449 178.875 164.158 177.292 164.158C175.701 164.158 174.417 165.449 174.417 167.033C174.417 168.616 175.701 169.908 177.292 169.908Z" fill="#C2C2C2"/>
<path d="M188.918 169.908C190.509 169.908 191.793 168.616 191.793 167.033C191.793 165.449 190.501 164.158 188.918 164.158C187.327 164.158 186.043 165.449 186.043 167.033C186.043 168.616 187.327 169.908 188.918 169.908Z" fill="#C2C2C2"/>
<path d="M200.543 169.908C202.134 169.908 203.418 168.616 203.418 167.033C203.418 165.449 202.127 164.158 200.543 164.158C198.952 164.158 197.668 165.449 197.668 167.033C197.668 168.616 198.952 169.908 200.543 169.908Z" fill="#C2C2C2"/>
<path d="M212.169 169.908C213.76 169.908 215.044 168.616 215.044 167.033C215.044 165.449 213.753 164.158 212.169 164.158C210.578 164.158 209.294 165.449 209.294 167.033C209.294 168.616 210.578 169.908 212.169 169.908Z" fill="#C2C2C2"/>
<path d="M223.795 169.908C225.386 169.908 226.67 168.616 226.67 167.033C226.67 165.449 225.378 164.158 223.795 164.158C222.204 164.158 220.92 165.449 220.92 167.033C220.92 168.616 222.212 169.908 223.795 169.908Z" fill="#C2C2C2"/>
<path d="M235.429 169.908C237.02 169.908 238.304 168.616 238.304 167.033C238.304 165.449 237.012 164.158 235.429 164.158C233.838 164.158 232.554 165.449 232.554 167.033C232.554 168.616 233.838 169.908 235.429 169.908Z" fill="#C2C2C2"/>
<path d="M247.054 169.908C248.645 169.908 249.929 168.616 249.929 167.033C249.929 165.449 248.637 164.158 247.054 164.158C245.463 164.158 244.179 165.449 244.179 167.033C244.179 168.616 245.463 169.908 247.054 169.908Z" fill="#C2C2C2"/>
<path d="M258.68 169.908C260.271 169.908 261.555 168.616 261.555 167.033C261.555 165.449 260.263 164.158 258.68 164.158C257.089 164.158 255.805 165.449 255.805 167.033C255.805 168.616 257.089 169.908 258.68 169.908Z" fill="#C2C2C2"/>
<path d="M270.306 169.908C271.897 169.908 273.181 168.616 273.181 167.033C273.181 165.449 271.889 164.158 270.306 164.158C268.715 164.158 267.431 165.449 267.431 167.033C267.431 168.616 268.722 169.908 270.306 169.908Z" fill="#C2C2C2"/>
<path d="M281.939 169.908C283.53 169.908 284.814 168.616 284.814 167.033C284.814 165.449 283.522 164.158 281.939 164.158C280.348 164.158 279.064 165.449 279.064 167.033C279.064 168.616 280.348 169.908 281.939 169.908Z" fill="#C2C2C2"/>
<path d="M293.565 169.908C295.156 169.908 296.44 168.616 296.44 167.033C296.44 165.449 295.148 164.158 293.565 164.158C291.982 164.158 290.69 165.449 290.69 167.033C290.69 168.616 291.974 169.908 293.565 169.908Z" fill="#C2C2C2"/>
<path d="M467.974 169.908C469.565 169.908 470.857 168.616 470.857 167.033C470.857 165.449 469.565 164.158 467.974 164.158C466.383 164.158 465.099 165.449 465.099 167.033C465.099 168.616 466.383 169.908 467.974 169.908Z" fill="#C2C2C2"/>
<path d="M479.6 169.908C481.191 169.908 482.475 168.616 482.475 167.033C482.475 165.449 481.191 164.158 479.6 164.158C478.009 164.158 476.725 165.449 476.725 167.033C476.725 168.616 478.017 169.908 479.6 169.908Z" fill="#C2C2C2"/>
<path d="M491.225 169.908C492.817 169.908 494.1 168.616 494.1 167.033C494.1 165.449 492.817 164.158 491.225 164.158C489.634 164.158 488.351 165.449 488.351 167.033C488.351 168.616 489.642 169.908 491.225 169.908Z" fill="#C2C2C2"/>
<path d="M502.859 169.908C504.45 169.908 505.734 168.616 505.734 167.033C505.734 165.449 504.442 164.158 502.859 164.158C501.268 164.158 499.984 165.449 499.984 167.033C499.984 168.616 501.268 169.908 502.859 169.908Z" fill="#C2C2C2"/>
<path d="M514.485 169.908C516.076 169.908 517.36 168.616 517.36 167.033C517.36 165.449 516.076 164.158 514.485 164.158C512.894 164.158 511.61 165.449 511.61 167.033C511.61 168.616 512.894 169.908 514.485 169.908Z" fill="#C2C2C2"/>
<path d="M526.111 169.908C527.702 169.908 528.985 168.616 528.985 167.033C528.985 165.449 527.702 164.158 526.111 164.158C524.519 164.158 523.236 165.449 523.236 167.033C523.236 168.616 524.519 169.908 526.111 169.908Z" fill="#C2C2C2"/>
<path d="M537.736 169.908C539.327 169.908 540.611 168.616 540.611 167.033C540.611 165.449 539.327 164.158 537.736 164.158C536.145 164.158 534.861 165.449 534.861 167.033C534.861 168.616 536.153 169.908 537.736 169.908Z" fill="#C2C2C2"/>
<path d="M549.362 169.908C550.953 169.908 552.237 168.616 552.237 167.033C552.237 165.449 550.953 164.158 549.362 164.158C547.771 164.158 546.487 165.449 546.487 167.033C546.487 168.616 547.779 169.908 549.362 169.908Z" fill="#C2C2C2"/>
<path d="M560.996 169.908C562.587 169.908 563.87 168.616 563.87 167.033C563.87 165.449 562.587 164.158 560.996 164.158C559.404 164.158 558.121 165.449 558.121 167.033C558.121 168.616 559.404 169.908 560.996 169.908Z" fill="#C2C2C2"/>
<path d="M572.621 169.908C574.212 169.908 575.496 168.616 575.496 167.033C575.496 165.449 574.212 164.158 572.621 164.158C571.03 164.158 569.746 165.449 569.746 167.033C569.746 168.616 571.03 169.908 572.621 169.908Z" fill="#C2C2C2"/>
<path d="M584.247 169.908C585.838 169.908 587.122 168.616 587.122 167.033C587.122 165.449 585.83 164.158 584.247 164.158C582.656 164.158 581.372 165.449 581.372 167.033C581.372 168.616 582.664 169.908 584.247 169.908Z" fill="#C2C2C2"/>
<path d="M595.873 169.908C597.464 169.908 598.748 168.616 598.748 167.033C598.748 165.449 597.456 164.158 595.873 164.158C594.282 164.158 592.998 165.449 592.998 167.033C592.998 168.616 594.282 169.908 595.873 169.908Z" fill="#C2C2C2"/>
<path d="M607.506 169.908C609.097 169.908 610.381 168.616 610.381 167.033C610.381 165.449 609.089 164.158 607.506 164.158C605.915 164.158 604.631 165.449 604.631 167.033C604.631 168.616 605.915 169.908 607.506 169.908Z" fill="#C2C2C2"/>
<path d="M37.7599 181.541C39.351 181.541 40.6349 180.25 40.6349 178.666C40.6349 177.075 39.3431 175.792 37.7599 175.792C36.1768 175.792 34.885 177.083 34.885 178.666C34.885 180.25 36.1689 181.541 37.7599 181.541Z" fill="#C2C2C2"/>
<path d="M49.3857 181.541C50.9767 181.541 52.2606 180.25 52.2606 178.666C52.2606 177.075 50.9688 175.792 49.3857 175.792C47.8025 175.792 46.5107 177.083 46.5107 178.666C46.5107 180.25 47.7946 181.541 49.3857 181.541Z" fill="#C2C2C2"/>
<path d="M61.0114 181.541C62.6024 181.541 63.8863 180.25 63.8863 178.666C63.8863 177.075 62.5946 175.792 61.0114 175.792C59.4203 175.792 58.1365 177.083 58.1365 178.666C58.1365 180.25 59.4282 181.541 61.0114 181.541Z" fill="#C2C2C2"/>
<path d="M72.645 181.541C74.236 181.541 75.5199 180.25 75.5199 178.666C75.5199 177.075 74.2282 175.792 72.645 175.792C71.0539 175.792 69.7701 177.083 69.7701 178.666C69.7622 180.25 71.0539 181.541 72.645 181.541Z" fill="#C2C2C2"/>
<path d="M84.2707 181.541C85.8617 181.541 87.1456 180.25 87.1456 178.666C87.1456 177.075 85.8538 175.792 84.2707 175.792C82.6796 175.792 81.3958 177.083 81.3958 178.666C81.3958 180.25 82.6796 181.541 84.2707 181.541Z" fill="#C2C2C2"/>
<path d="M95.8964 181.541C97.4875 181.541 98.7713 180.25 98.7713 178.666C98.7713 177.075 97.4796 175.792 95.8964 175.792C94.3054 175.792 93.0215 177.083 93.0215 178.666C93.0215 180.25 94.3054 181.541 95.8964 181.541Z" fill="#C2C2C2"/>
<path d="M107.522 181.541C109.113 181.541 110.397 180.25 110.397 178.666C110.397 177.075 109.105 175.792 107.522 175.792C105.939 175.792 104.647 177.083 104.647 178.666C104.647 180.25 105.939 181.541 107.522 181.541Z" fill="#C2C2C2"/>
<path d="M119.156 181.541C120.747 181.541 122.031 180.25 122.031 178.666C122.031 177.075 120.739 175.792 119.156 175.792C117.565 175.792 116.281 177.083 116.281 178.666C116.273 180.25 117.565 181.541 119.156 181.541Z" fill="#C2C2C2"/>
<path d="M130.781 181.541C132.372 181.541 133.656 180.25 133.656 178.666C133.656 177.075 132.365 175.792 130.781 175.792C129.19 175.792 127.907 177.083 127.907 178.666C127.899 180.25 129.19 181.541 130.781 181.541Z" fill="#C2C2C2"/>
<path d="M142.407 181.541C143.998 181.541 145.282 180.25 145.282 178.666C145.282 177.075 143.99 175.792 142.407 175.792C140.816 175.792 139.532 177.083 139.532 178.666C139.532 180.25 140.816 181.541 142.407 181.541Z" fill="#C2C2C2"/>
<path d="M154.033 181.541C155.624 181.541 156.908 180.25 156.908 178.666C156.908 177.075 155.616 175.792 154.033 175.792C152.442 175.792 151.158 177.083 151.158 178.666C151.158 180.25 152.442 181.541 154.033 181.541Z" fill="#C2C2C2"/>
<path d="M165.659 181.541C167.25 181.541 168.534 180.25 168.534 178.666C168.534 177.075 167.242 175.792 165.659 175.792C164.068 175.792 162.784 177.083 162.784 178.666C162.784 180.25 164.075 181.541 165.659 181.541Z" fill="#C2C2C2"/>
<path d="M177.292 181.541C178.883 181.541 180.167 180.25 180.167 178.666C180.167 177.075 178.875 175.792 177.292 175.792C175.701 175.792 174.417 177.083 174.417 178.666C174.409 180.25 175.701 181.541 177.292 181.541Z" fill="#C2C2C2"/>
<path d="M188.918 181.541C190.509 181.541 191.793 180.25 191.793 178.666C191.793 177.075 190.501 175.792 188.918 175.792C187.327 175.792 186.043 177.083 186.043 178.666C186.035 180.25 187.327 181.541 188.918 181.541Z" fill="#C2C2C2"/>
<path d="M200.543 181.541C202.134 181.541 203.418 180.25 203.418 178.666C203.418 177.075 202.127 175.792 200.543 175.792C198.952 175.792 197.668 177.083 197.668 178.666C197.668 180.25 198.952 181.541 200.543 181.541Z" fill="#C2C2C2"/>
<path d="M212.169 181.541C213.76 181.541 215.044 180.25 215.044 178.666C215.044 177.075 213.753 175.792 212.169 175.792C210.578 175.792 209.294 177.083 209.294 178.666C209.294 180.25 210.578 181.541 212.169 181.541Z" fill="#C2C2C2"/>
<path d="M223.795 181.541C225.386 181.541 226.67 180.25 226.67 178.666C226.67 177.075 225.378 175.792 223.795 175.792C222.204 175.792 220.92 177.083 220.92 178.666C220.92 180.25 222.212 181.541 223.795 181.541Z" fill="#C2C2C2"/>
<path d="M235.428 181.541C237.019 181.541 238.303 180.25 238.303 178.666C238.303 177.075 237.012 175.792 235.428 175.792C233.837 175.792 232.554 177.083 232.554 178.666C232.546 180.25 233.837 181.541 235.428 181.541Z" fill="#C2C2C2"/>
<path d="M247.054 181.541C248.645 181.541 249.929 180.25 249.929 178.666C249.929 177.075 248.637 175.792 247.054 175.792C245.463 175.792 244.179 177.083 244.179 178.666C244.179 180.25 245.463 181.541 247.054 181.541Z" fill="#C2C2C2"/>
<path d="M258.68 181.541C260.271 181.541 261.555 180.25 261.555 178.666C261.555 177.075 260.263 175.792 258.68 175.792C257.089 175.792 255.805 177.083 255.805 178.666C255.805 180.25 257.089 181.541 258.68 181.541Z" fill="#C2C2C2"/>
<path d="M270.306 181.541C271.897 181.541 273.181 180.25 273.181 178.666C273.181 177.075 271.889 175.792 270.306 175.792C268.715 175.792 267.431 177.083 267.431 178.666C267.431 180.25 268.722 181.541 270.306 181.541Z" fill="#C2C2C2"/>
<path d="M281.939 181.541C283.53 181.541 284.814 180.25 284.814 178.666C284.814 177.075 283.522 175.792 281.939 175.792C280.348 175.792 279.064 177.083 279.064 178.666C279.056 180.25 280.348 181.541 281.939 181.541Z" fill="#C2C2C2"/>
<path d="M293.565 181.541C295.156 181.541 296.44 180.25 296.44 178.666C296.44 177.075 295.148 175.792 293.565 175.792C291.982 175.792 290.69 177.083 290.69 178.666C290.682 180.25 291.974 181.541 293.565 181.541Z" fill="#C2C2C2"/>
<path d="M467.974 181.541C469.565 181.541 470.857 180.25 470.857 178.666C470.857 177.075 469.565 175.792 467.974 175.792C466.383 175.792 465.099 177.083 465.099 178.666C465.099 180.25 466.383 181.541 467.974 181.541Z" fill="#C2C2C2"/>
<path d="M479.6 181.541C481.191 181.541 482.475 180.25 482.475 178.666C482.475 177.075 481.191 175.792 479.6 175.792C478.009 175.792 476.725 177.083 476.725 178.666C476.725 180.25 478.017 181.541 479.6 181.541Z" fill="#C2C2C2"/>
<path d="M491.225 181.541C492.817 181.541 494.1 180.25 494.1 178.666C494.1 177.075 492.817 175.792 491.225 175.792C489.634 175.792 488.351 177.083 488.351 178.666C488.351 180.25 489.642 181.541 491.225 181.541Z" fill="#C2C2C2"/>
<path d="M502.859 181.541C504.45 181.541 505.734 180.25 505.734 178.666C505.734 177.075 504.442 175.792 502.859 175.792C501.268 175.792 499.984 177.083 499.984 178.666C499.977 180.25 501.268 181.541 502.859 181.541Z" fill="#C2C2C2"/>
<path d="M514.485 181.541C516.076 181.541 517.36 180.25 517.36 178.666C517.36 177.075 516.076 175.792 514.485 175.792C512.894 175.792 511.61 177.083 511.61 178.666C511.61 180.25 512.894 181.541 514.485 181.541Z" fill="#C2C2C2"/>
<path d="M526.111 181.541C527.702 181.541 528.985 180.25 528.985 178.666C528.985 177.075 527.702 175.792 526.111 175.792C524.519 175.792 523.236 177.083 523.236 178.666C523.236 180.25 524.519 181.541 526.111 181.541Z" fill="#C2C2C2"/>
<path d="M537.736 181.541C539.327 181.541 540.611 180.25 540.611 178.666C540.611 177.075 539.327 175.792 537.736 175.792C536.145 175.792 534.861 177.083 534.861 178.666C534.861 180.25 536.153 181.541 537.736 181.541Z" fill="#C2C2C2"/>
<path d="M549.362 181.541C550.953 181.541 552.237 180.25 552.237 178.666C552.237 177.075 550.953 175.792 549.362 175.792C547.771 175.792 546.487 177.083 546.487 178.666C546.487 180.25 547.779 181.541 549.362 181.541Z" fill="#C2C2C2"/>
<path d="M560.996 181.541C562.587 181.541 563.871 180.25 563.871 178.666C563.871 177.075 562.587 175.792 560.996 175.792C559.405 175.792 558.121 177.083 558.121 178.666C558.113 180.25 559.405 181.541 560.996 181.541Z" fill="#C2C2C2"/>
<path d="M572.621 181.541C574.212 181.541 575.496 180.25 575.496 178.666C575.496 177.075 574.212 175.792 572.621 175.792C571.03 175.792 569.746 177.083 569.746 178.666C569.746 180.25 571.03 181.541 572.621 181.541Z" fill="#C2C2C2"/>
<path d="M584.247 181.541C585.838 181.541 587.122 180.25 587.122 178.666C587.122 177.075 585.83 175.792 584.247 175.792C582.656 175.792 581.372 177.083 581.372 178.666C581.372 180.25 582.664 181.541 584.247 181.541Z" fill="#C2C2C2"/>
<path d="M595.873 181.541C597.464 181.541 598.748 180.25 598.748 178.666C598.748 177.075 597.456 175.792 595.873 175.792C594.282 175.792 592.998 177.083 592.998 178.666C592.998 180.25 594.282 181.541 595.873 181.541Z" fill="#C2C2C2"/>
<path d="M607.506 181.541C609.097 181.541 610.381 180.25 610.381 178.666C610.381 177.075 609.09 175.792 607.506 175.792C605.915 175.792 604.631 177.083 604.631 178.666C604.624 180.25 605.915 181.541 607.506 181.541Z" fill="#C2C2C2"/>
<path d="M619.132 181.541C620.723 181.541 622.007 180.25 622.007 178.666C622.007 177.075 620.715 175.792 619.132 175.792C617.541 175.792 616.257 177.083 616.257 178.666C616.257 180.25 617.541 181.541 619.132 181.541Z" fill="#C2C2C2"/>
<path d="M630.758 181.541C632.349 181.541 633.633 180.25 633.633 178.666C633.633 177.075 632.341 175.792 630.758 175.792C629.167 175.792 627.883 177.083 627.883 178.666C627.883 180.25 629.167 181.541 630.758 181.541Z" fill="#C2C2C2"/>
<path d="M642.383 181.541C643.974 181.541 645.258 180.25 645.258 178.666C645.258 177.075 643.967 175.792 642.383 175.792C640.792 175.792 639.509 177.083 639.509 178.666C639.509 180.25 640.8 181.541 642.383 181.541Z" fill="#C2C2C2"/>
<path d="M654.017 181.541C655.608 181.541 656.892 180.25 656.892 178.666C656.892 177.075 655.6 175.792 654.017 175.792C652.426 175.792 651.142 177.083 651.142 178.666C651.134 180.25 652.426 181.541 654.017 181.541Z" fill="#C2C2C2"/>
<path d="M665.643 181.541C667.234 181.541 668.518 180.25 668.518 178.666C668.518 177.075 667.226 175.792 665.643 175.792C664.052 175.792 662.768 177.083 662.768 178.666C662.76 180.25 664.052 181.541 665.643 181.541Z" fill="#C2C2C2"/>
<path d="M37.7599 193.167C39.351 193.167 40.6349 191.875 40.6349 190.292C40.6349 188.701 39.3431 187.417 37.7599 187.417C36.1768 187.417 34.885 188.709 34.885 190.292C34.885 191.875 36.1689 193.167 37.7599 193.167Z" fill="#C2C2C2"/>
<path d="M49.3857 193.167C50.9767 193.167 52.2606 191.875 52.2606 190.292C52.2606 188.701 50.9688 187.417 49.3857 187.417C47.8025 187.417 46.5107 188.709 46.5107 190.292C46.5107 191.875 47.7946 193.167 49.3857 193.167Z" fill="#C2C2C2"/>
<path d="M61.0114 193.167C62.6024 193.167 63.8863 191.875 63.8863 190.292C63.8863 188.701 62.5946 187.417 61.0114 187.417C59.4203 187.417 58.1365 188.709 58.1365 190.292C58.1365 191.875 59.4282 193.167 61.0114 193.167Z" fill="#C2C2C2"/>
<path d="M72.645 193.167C74.236 193.167 75.5199 191.875 75.5199 190.292C75.5199 188.701 74.2282 187.417 72.645 187.417C71.0539 187.417 69.7701 188.709 69.7701 190.292C69.7622 191.875 71.0539 193.167 72.645 193.167Z" fill="#C2C2C2"/>
<path d="M84.2707 193.167C85.8617 193.167 87.1456 191.875 87.1456 190.292C87.1456 188.701 85.8538 187.417 84.2707 187.417C82.6796 187.417 81.3958 188.709 81.3958 190.292C81.3958 191.875 82.6796 193.167 84.2707 193.167Z" fill="#C2C2C2"/>
<path d="M95.8964 193.167C97.4875 193.167 98.7713 191.875 98.7713 190.292C98.7713 188.701 97.4796 187.417 95.8964 187.417C94.3054 187.417 93.0215 188.709 93.0215 190.292C93.0215 191.875 94.3054 193.167 95.8964 193.167Z" fill="#C2C2C2"/>
<path d="M107.522 193.167C109.113 193.167 110.397 191.875 110.397 190.292C110.397 188.701 109.105 187.417 107.522 187.417C105.939 187.417 104.647 188.709 104.647 190.292C104.647 191.875 105.939 193.167 107.522 193.167Z" fill="#C2C2C2"/>
<path d="M119.156 193.167C120.747 193.167 122.031 191.875 122.031 190.292C122.031 188.701 120.739 187.417 119.156 187.417C117.565 187.417 116.281 188.709 116.281 190.292C116.273 191.875 117.565 193.167 119.156 193.167Z" fill="#C2C2C2"/>
<path d="M130.781 193.167C132.372 193.167 133.656 191.875 133.656 190.292C133.656 188.701 132.365 187.417 130.781 187.417C129.19 187.417 127.907 188.709 127.907 190.292C127.899 191.875 129.19 193.167 130.781 193.167Z" fill="#C2C2C2"/>
<path d="M142.407 193.167C143.998 193.167 145.282 191.875 145.282 190.292C145.282 188.701 143.99 187.417 142.407 187.417C140.816 187.417 139.532 188.709 139.532 190.292C139.532 191.875 140.816 193.167 142.407 193.167Z" fill="#C2C2C2"/>
<path d="M154.033 193.167C155.624 193.167 156.908 191.875 156.908 190.292C156.908 188.701 155.616 187.417 154.033 187.417C152.442 187.417 151.158 188.709 151.158 190.292C151.158 191.875 152.442 193.167 154.033 193.167Z" fill="#C2C2C2"/>
<path d="M165.659 193.167C167.25 193.167 168.534 191.875 168.534 190.292C168.534 188.701 167.242 187.417 165.659 187.417C164.068 187.417 162.784 188.709 162.784 190.292C162.784 191.875 164.075 193.167 165.659 193.167Z" fill="#C2C2C2"/>
<path d="M177.292 193.167C178.883 193.167 180.167 191.875 180.167 190.292C180.167 188.701 178.875 187.417 177.292 187.417C175.701 187.417 174.417 188.709 174.417 190.292C174.409 191.875 175.701 193.167 177.292 193.167Z" fill="#C2C2C2"/>
<path d="M188.918 193.167C190.509 193.167 191.793 191.875 191.793 190.292C191.793 188.701 190.501 187.417 188.918 187.417C187.327 187.417 186.043 188.709 186.043 190.292C186.035 191.875 187.327 193.167 188.918 193.167Z" fill="#C2C2C2"/>
<path d="M200.543 193.167C202.134 193.167 203.418 191.875 203.418 190.292C203.418 188.701 202.127 187.417 200.543 187.417C198.952 187.417 197.668 188.709 197.668 190.292C197.668 191.875 198.952 193.167 200.543 193.167Z" fill="#C2C2C2"/>
<path d="M212.169 193.167C213.76 193.167 215.044 191.875 215.044 190.292C215.044 188.701 213.753 187.417 212.169 187.417C210.578 187.417 209.294 188.709 209.294 190.292C209.294 191.875 210.578 193.167 212.169 193.167Z" fill="#C2C2C2"/>
<path d="M223.795 193.167C225.386 193.167 226.67 191.875 226.67 190.292C226.67 188.701 225.378 187.417 223.795 187.417C222.204 187.417 220.92 188.709 220.92 190.292C220.92 191.875 222.212 193.167 223.795 193.167Z" fill="#C2C2C2"/>
<path d="M235.428 193.167C237.019 193.167 238.303 191.875 238.303 190.292C238.303 188.701 237.012 187.417 235.428 187.417C233.837 187.417 232.554 188.709 232.554 190.292C232.546 191.875 233.837 193.167 235.428 193.167Z" fill="#C2C2C2"/>
<path d="M247.054 193.167C248.645 193.167 249.929 191.875 249.929 190.292C249.929 188.701 248.637 187.417 247.054 187.417C245.463 187.417 244.179 188.709 244.179 190.292C244.179 191.875 245.463 193.167 247.054 193.167Z" fill="#C2C2C2"/>
<path d="M258.68 193.167C260.271 193.167 261.555 191.875 261.555 190.292C261.555 188.701 260.263 187.417 258.68 187.417C257.089 187.417 255.805 188.709 255.805 190.292C255.805 191.875 257.089 193.167 258.68 193.167Z" fill="#C2C2C2"/>
<path d="M270.306 193.167C271.897 193.167 273.181 191.875 273.181 190.292C273.181 188.701 271.889 187.417 270.306 187.417C268.715 187.417 267.431 188.709 267.431 190.292C267.431 191.875 268.722 193.167 270.306 193.167Z" fill="#C2C2C2"/>
<path d="M281.939 193.167C283.53 193.167 284.814 191.875 284.814 190.292C284.814 188.701 283.522 187.417 281.939 187.417C280.348 187.417 279.064 188.709 279.064 190.292C279.056 191.875 280.348 193.167 281.939 193.167Z" fill="#C2C2C2"/>
<path d="M293.565 193.167C295.156 193.167 296.44 191.875 296.44 190.292C296.44 188.701 295.148 187.417 293.565 187.417C291.982 187.417 290.69 188.709 290.69 190.292C290.682 191.875 291.974 193.167 293.565 193.167Z" fill="#C2C2C2"/>
<path d="M467.974 193.167C469.565 193.167 470.857 191.875 470.857 190.292C470.857 188.701 469.565 187.417 467.974 187.417C466.383 187.417 465.099 188.709 465.099 190.292C465.099 191.875 466.383 193.167 467.974 193.167Z" fill="#C2C2C2"/>
<path d="M479.6 193.167C481.191 193.167 482.475 191.875 482.475 190.292C482.475 188.701 481.191 187.417 479.6 187.417C478.009 187.417 476.725 188.709 476.725 190.292C476.725 191.875 478.017 193.167 479.6 193.167Z" fill="#C2C2C2"/>
<path d="M491.225 193.167C492.817 193.167 494.1 191.875 494.1 190.292C494.1 188.701 492.817 187.417 491.225 187.417C489.634 187.417 488.351 188.709 488.351 190.292C488.351 191.875 489.642 193.167 491.225 193.167Z" fill="#C2C2C2"/>
<path d="M502.859 193.167C504.45 193.167 505.734 191.875 505.734 190.292C505.734 188.701 504.442 187.417 502.859 187.417C501.268 187.417 499.984 188.709 499.984 190.292C499.977 191.875 501.268 193.167 502.859 193.167Z" fill="#C2C2C2"/>
<path d="M514.485 193.167C516.076 193.167 517.36 191.875 517.36 190.292C517.36 188.701 516.076 187.417 514.485 187.417C512.894 187.417 511.61 188.709 511.61 190.292C511.61 191.875 512.894 193.167 514.485 193.167Z" fill="#C2C2C2"/>
<path d="M526.111 193.167C527.702 193.167 528.985 191.875 528.985 190.292C528.985 188.701 527.702 187.417 526.111 187.417C524.519 187.417 523.236 188.709 523.236 190.292C523.236 191.875 524.519 193.167 526.111 193.167Z" fill="#C2C2C2"/>
<path d="M537.736 193.167C539.327 193.167 540.611 191.875 540.611 190.292C540.611 188.701 539.327 187.417 537.736 187.417C536.145 187.417 534.861 188.709 534.861 190.292C534.861 191.875 536.153 193.167 537.736 193.167Z" fill="#C2C2C2"/>
<path d="M549.362 193.167C550.953 193.167 552.237 191.875 552.237 190.292C552.237 188.701 550.953 187.417 549.362 187.417C547.771 187.417 546.487 188.709 546.487 190.292C546.487 191.875 547.779 193.167 549.362 193.167Z" fill="#C2C2C2"/>
<path d="M560.996 193.167C562.587 193.167 563.871 191.875 563.871 190.292C563.871 188.701 562.587 187.417 560.996 187.417C559.405 187.417 558.121 188.709 558.121 190.292C558.113 191.875 559.405 193.167 560.996 193.167Z" fill="#C2C2C2"/>
<path d="M572.621 193.167C574.212 193.167 575.496 191.875 575.496 190.292C575.496 188.701 574.212 187.417 572.621 187.417C571.03 187.417 569.746 188.709 569.746 190.292C569.746 191.875 571.03 193.167 572.621 193.167Z" fill="#C2C2C2"/>
<path d="M584.247 193.167C585.838 193.167 587.122 191.875 587.122 190.292C587.122 188.701 585.83 187.417 584.247 187.417C582.656 187.417 581.372 188.709 581.372 190.292C581.372 191.875 582.664 193.167 584.247 193.167Z" fill="#C2C2C2"/>
<path d="M595.873 193.167C597.464 193.167 598.748 191.875 598.748 190.292C598.748 188.701 597.456 187.417 595.873 187.417C594.282 187.417 592.998 188.709 592.998 190.292C592.998 191.875 594.282 193.167 595.873 193.167Z" fill="#C2C2C2"/>
<path d="M607.506 193.167C609.097 193.167 610.381 191.875 610.381 190.292C610.381 188.701 609.09 187.417 607.506 187.417C605.915 187.417 604.631 188.709 604.631 190.292C604.624 191.875 605.915 193.167 607.506 193.167Z" fill="#C2C2C2"/>
<path d="M619.132 193.167C620.723 193.167 622.007 191.875 622.007 190.292C622.007 188.701 620.715 187.417 619.132 187.417C617.541 187.417 616.257 188.709 616.257 190.292C616.257 191.875 617.541 193.167 619.132 193.167Z" fill="#C2C2C2"/>
<path d="M630.758 193.167C632.349 193.167 633.633 191.875 633.633 190.292C633.633 188.701 632.341 187.417 630.758 187.417C629.167 187.417 627.883 188.709 627.883 190.292C627.883 191.875 629.167 193.167 630.758 193.167Z" fill="#C2C2C2"/>
<path d="M642.383 193.167C643.974 193.167 645.258 191.875 645.258 190.292C645.258 188.701 643.967 187.417 642.383 187.417C640.792 187.417 639.509 188.709 639.509 190.292C639.509 191.875 640.8 193.167 642.383 193.167Z" fill="#C2C2C2"/>
<path d="M654.017 193.167C655.608 193.167 656.892 191.875 656.892 190.292C656.892 188.701 655.6 187.417 654.017 187.417C652.426 187.417 651.142 188.709 651.142 190.292C651.134 191.875 652.426 193.167 654.017 193.167Z" fill="#C2C2C2"/>
<path d="M665.643 193.167C667.234 193.167 668.518 191.875 668.518 190.292C668.518 188.701 667.226 187.417 665.643 187.417C664.052 187.417 662.768 188.709 662.768 190.292C662.76 191.875 664.052 193.167 665.643 193.167Z" fill="#C2C2C2"/>
<path d="M49.3857 204.793C50.9767 204.793 52.2606 203.501 52.2606 201.918C52.2606 200.327 50.9688 199.043 49.3857 199.043C47.8025 199.043 46.5107 200.335 46.5107 201.918C46.5107 203.509 47.7946 204.793 49.3857 204.793Z" fill="#C2C2C2"/>
<path d="M61.0114 204.793C62.6024 204.793 63.8863 203.501 63.8863 201.918C63.8863 200.327 62.5946 199.043 61.0114 199.043C59.4203 199.043 58.1365 200.335 58.1365 201.918C58.1365 203.509 59.4282 204.793 61.0114 204.793Z" fill="#C2C2C2"/>
<path d="M72.645 204.793C74.236 204.793 75.5199 203.501 75.5199 201.918C75.5199 200.327 74.2282 199.043 72.645 199.043C71.0539 199.043 69.7701 200.335 69.7701 201.918C69.7622 203.509 71.0539 204.793 72.645 204.793Z" fill="#C2C2C2"/>
<path d="M84.2707 204.793C85.8617 204.793 87.1456 203.501 87.1456 201.918C87.1456 200.327 85.8538 199.043 84.2707 199.043C82.6796 199.043 81.3958 200.335 81.3958 201.918C81.3958 203.509 82.6796 204.793 84.2707 204.793Z" fill="#C2C2C2"/>
<path d="M95.8964 204.793C97.4875 204.793 98.7713 203.501 98.7713 201.918C98.7713 200.327 97.4796 199.043 95.8964 199.043C94.3054 199.043 93.0215 200.335 93.0215 201.918C93.0215 203.509 94.3054 204.793 95.8964 204.793Z" fill="#C2C2C2"/>
<path d="M107.522 204.793C109.113 204.793 110.397 203.501 110.397 201.918C110.397 200.327 109.105 199.043 107.522 199.043C105.939 199.043 104.647 200.335 104.647 201.918C104.647 203.509 105.939 204.793 107.522 204.793Z" fill="#C2C2C2"/>
<path d="M119.156 204.793C120.747 204.793 122.031 203.501 122.031 201.918C122.031 200.327 120.739 199.043 119.156 199.043C117.565 199.043 116.281 200.335 116.281 201.918C116.273 203.509 117.565 204.793 119.156 204.793Z" fill="#C2C2C2"/>
<path d="M130.781 204.793C132.372 204.793 133.656 203.501 133.656 201.918C133.656 200.327 132.365 199.043 130.781 199.043C129.19 199.043 127.907 200.335 127.907 201.918C127.899 203.509 129.19 204.793 130.781 204.793Z" fill="#C2C2C2"/>
<path d="M142.407 204.793C143.998 204.793 145.282 203.501 145.282 201.918C145.282 200.327 143.99 199.043 142.407 199.043C140.816 199.043 139.532 200.335 139.532 201.918C139.532 203.509 140.816 204.793 142.407 204.793Z" fill="#C2C2C2"/>
<path d="M154.033 204.793C155.624 204.793 156.908 203.501 156.908 201.918C156.908 200.327 155.616 199.043 154.033 199.043C152.442 199.043 151.158 200.335 151.158 201.918C151.158 203.509 152.442 204.793 154.033 204.793Z" fill="#C2C2C2"/>
<path d="M165.659 204.793C167.25 204.793 168.534 203.501 168.534 201.918C168.534 200.327 167.242 199.043 165.659 199.043C164.068 199.043 162.784 200.335 162.784 201.918C162.784 203.509 164.075 204.793 165.659 204.793Z" fill="#C2C2C2"/>
<path d="M177.292 204.793C178.883 204.793 180.167 203.501 180.167 201.918C180.167 200.327 178.875 199.043 177.292 199.043C175.701 199.043 174.417 200.335 174.417 201.918C174.409 203.509 175.701 204.793 177.292 204.793Z" fill="#C2C2C2"/>
<path d="M188.918 204.793C190.509 204.793 191.793 203.501 191.793 201.918C191.793 200.327 190.501 199.043 188.918 199.043C187.327 199.043 186.043 200.335 186.043 201.918C186.035 203.509 187.327 204.793 188.918 204.793Z" fill="#C2C2C2"/>
<path d="M200.543 204.793C202.134 204.793 203.418 203.501 203.418 201.918C203.418 200.327 202.127 199.043 200.543 199.043C198.952 199.043 197.668 200.335 197.668 201.918C197.668 203.509 198.952 204.793 200.543 204.793Z" fill="#C2C2C2"/>
<path d="M212.169 204.793C213.76 204.793 215.044 203.501 215.044 201.918C215.044 200.327 213.753 199.043 212.169 199.043C210.578 199.043 209.294 200.335 209.294 201.918C209.294 203.509 210.578 204.793 212.169 204.793Z" fill="#C2C2C2"/>
<path d="M223.795 204.793C225.386 204.793 226.67 203.501 226.67 201.918C226.67 200.327 225.378 199.043 223.795 199.043C222.204 199.043 220.92 200.335 220.92 201.918C220.92 203.509 222.212 204.793 223.795 204.793Z" fill="#C2C2C2"/>
<path d="M235.428 204.793C237.019 204.793 238.303 203.501 238.303 201.918C238.303 200.327 237.012 199.043 235.428 199.043C233.837 199.043 232.554 200.335 232.554 201.918C232.546 203.509 233.837 204.793 235.428 204.793Z" fill="#C2C2C2"/>
<path d="M247.054 204.793C248.645 204.793 249.929 203.501 249.929 201.918C249.929 200.327 248.637 199.043 247.054 199.043C245.463 199.043 244.179 200.335 244.179 201.918C244.179 203.509 245.463 204.793 247.054 204.793Z" fill="#C2C2C2"/>
<path d="M258.68 204.793C260.271 204.793 261.555 203.501 261.555 201.918C261.555 200.327 260.263 199.043 258.68 199.043C257.089 199.043 255.805 200.335 255.805 201.918C255.805 203.509 257.089 204.793 258.68 204.793Z" fill="#C2C2C2"/>
<path d="M270.306 204.793C271.897 204.793 273.181 203.501 273.181 201.918C273.181 200.327 271.889 199.043 270.306 199.043C268.715 199.043 267.431 200.335 267.431 201.918C267.431 203.509 268.722 204.793 270.306 204.793Z" fill="#C2C2C2"/>
<path d="M281.939 204.793C283.53 204.793 284.814 203.501 284.814 201.918C284.814 200.327 283.522 199.043 281.939 199.043C280.348 199.043 279.064 200.335 279.064 201.918C279.056 203.509 280.348 204.793 281.939 204.793Z" fill="#C2C2C2"/>
<path d="M293.565 204.793C295.156 204.793 296.44 203.501 296.44 201.918C296.44 200.327 295.148 199.043 293.565 199.043C291.982 199.043 290.69 200.335 290.69 201.918C290.682 203.509 291.974 204.793 293.565 204.793Z" fill="#C2C2C2"/>
<path d="M467.974 204.793C469.565 204.793 470.857 203.501 470.857 201.918C470.857 200.327 469.565 199.043 467.974 199.043C466.383 199.043 465.099 200.335 465.099 201.918C465.099 203.509 466.383 204.793 467.974 204.793Z" fill="#C2C2C2"/>
<path d="M479.6 204.793C481.191 204.793 482.475 203.501 482.475 201.918C482.475 200.327 481.191 199.043 479.6 199.043C478.009 199.043 476.725 200.335 476.725 201.918C476.725 203.509 478.017 204.793 479.6 204.793Z" fill="#C2C2C2"/>
<path d="M491.225 204.793C492.817 204.793 494.1 203.501 494.1 201.918C494.1 200.327 492.817 199.043 491.225 199.043C489.634 199.043 488.351 200.335 488.351 201.918C488.351 203.509 489.642 204.793 491.225 204.793Z" fill="#C2C2C2"/>
<path d="M502.859 204.793C504.45 204.793 505.734 203.501 505.734 201.918C505.734 200.327 504.442 199.043 502.859 199.043C501.268 199.043 499.984 200.335 499.984 201.918C499.977 203.509 501.268 204.793 502.859 204.793Z" fill="#C2C2C2"/>
<path d="M514.485 204.793C516.076 204.793 517.36 203.501 517.36 201.918C517.36 200.327 516.076 199.043 514.485 199.043C512.894 199.043 511.61 200.335 511.61 201.918C511.61 203.509 512.894 204.793 514.485 204.793Z" fill="#C2C2C2"/>
<path d="M526.111 204.793C527.702 204.793 528.985 203.501 528.985 201.918C528.985 200.327 527.702 199.043 526.111 199.043C524.519 199.043 523.236 200.335 523.236 201.918C523.236 203.509 524.519 204.793 526.111 204.793Z" fill="#C2C2C2"/>
<path d="M537.736 204.793C539.327 204.793 540.611 203.501 540.611 201.918C540.611 200.327 539.327 199.043 537.736 199.043C536.145 199.043 534.861 200.335 534.861 201.918C534.861 203.509 536.153 204.793 537.736 204.793Z" fill="#C2C2C2"/>
<path d="M549.362 204.793C550.953 204.793 552.237 203.501 552.237 201.918C552.237 200.327 550.953 199.043 549.362 199.043C547.771 199.043 546.487 200.335 546.487 201.918C546.487 203.509 547.779 204.793 549.362 204.793Z" fill="#C2C2C2"/>
<path d="M560.996 204.793C562.587 204.793 563.871 203.501 563.871 201.918C563.871 200.327 562.587 199.043 560.996 199.043C559.405 199.043 558.121 200.335 558.121 201.918C558.113 203.509 559.405 204.793 560.996 204.793Z" fill="#C2C2C2"/>
<path d="M572.621 204.793C574.212 204.793 575.496 203.501 575.496 201.918C575.496 200.327 574.212 199.043 572.621 199.043C571.03 199.043 569.746 200.335 569.746 201.918C569.746 203.509 571.03 204.793 572.621 204.793Z" fill="#C2C2C2"/>
<path d="M584.247 204.793C585.838 204.793 587.122 203.501 587.122 201.918C587.122 200.327 585.83 199.043 584.247 199.043C582.656 199.043 581.372 200.335 581.372 201.918C581.372 203.509 582.664 204.793 584.247 204.793Z" fill="#C2C2C2"/>
<path d="M595.873 204.793C597.464 204.793 598.748 203.501 598.748 201.918C598.748 200.327 597.456 199.043 595.873 199.043C594.282 199.043 592.998 200.335 592.998 201.918C592.998 203.509 594.282 204.793 595.873 204.793Z" fill="#C2C2C2"/>
<path d="M607.506 204.793C609.097 204.793 610.381 203.501 610.381 201.918C610.381 200.327 609.09 199.043 607.506 199.043C605.915 199.043 604.631 200.335 604.631 201.918C604.624 203.509 605.915 204.793 607.506 204.793Z" fill="#C2C2C2"/>
<path d="M619.132 204.793C620.723 204.793 622.007 203.501 622.007 201.918C622.007 200.327 620.715 199.043 619.132 199.043C617.541 199.043 616.257 200.335 616.257 201.918C616.257 203.509 617.541 204.793 619.132 204.793Z" fill="#C2C2C2"/>
<path d="M630.758 204.793C632.349 204.793 633.633 203.501 633.633 201.918C633.633 200.327 632.341 199.043 630.758 199.043C629.167 199.043 627.883 200.335 627.883 201.918C627.883 203.509 629.167 204.793 630.758 204.793Z" fill="#C2C2C2"/>
<path d="M642.383 204.793C643.974 204.793 645.258 203.501 645.258 201.918C645.258 200.327 643.967 199.043 642.383 199.043C640.792 199.043 639.509 200.335 639.509 201.918C639.509 203.509 640.8 204.793 642.383 204.793Z" fill="#C2C2C2"/>
<path d="M654.017 204.793C655.608 204.793 656.892 203.501 656.892 201.918C656.892 200.327 655.6 199.043 654.017 199.043C652.426 199.043 651.142 200.335 651.142 201.918C651.134 203.509 652.426 204.793 654.017 204.793Z" fill="#C2C2C2"/>
<path d="M49.3857 216.418C50.9767 216.418 52.2606 215.127 52.2606 213.543C52.2606 211.96 50.9688 210.668 49.3857 210.668C47.8025 210.668 46.5107 211.96 46.5107 213.543C46.5107 215.127 47.7946 216.418 49.3857 216.418Z" fill="#C2C2C2"/>
<path d="M61.0114 216.418C62.6024 216.418 63.8863 215.127 63.8863 213.543C63.8863 211.96 62.5946 210.668 61.0114 210.668C59.4203 210.668 58.1365 211.96 58.1365 213.543C58.1365 215.127 59.4282 216.418 61.0114 216.418Z" fill="#C2C2C2"/>
<path d="M72.6449 216.418C74.236 216.418 75.5199 215.127 75.5199 213.543C75.5199 211.96 74.2281 210.668 72.6449 210.668C71.0539 210.668 69.77 211.96 69.77 213.543C69.77 215.127 71.0539 216.418 72.6449 216.418Z" fill="#C2C2C2"/>
<path d="M84.2707 216.418C85.8617 216.418 87.1456 215.127 87.1456 213.543C87.1456 211.96 85.8538 210.668 84.2707 210.668C82.6796 210.668 81.3958 211.96 81.3958 213.543C81.3958 215.127 82.6796 216.418 84.2707 216.418Z" fill="#C2C2C2"/>
<path d="M95.8964 216.418C97.4875 216.418 98.7713 215.127 98.7713 213.543C98.7713 211.96 97.4796 210.668 95.8964 210.668C94.3054 210.668 93.0215 211.96 93.0215 213.543C93.0215 215.127 94.3054 216.418 95.8964 216.418Z" fill="#C2C2C2"/>
<path d="M107.522 216.418C109.113 216.418 110.397 215.127 110.397 213.543C110.397 211.96 109.105 210.668 107.522 210.668C105.939 210.668 104.647 211.96 104.647 213.543C104.647 215.127 105.939 216.418 107.522 216.418Z" fill="#C2C2C2"/>
<path d="M119.156 216.418C120.747 216.418 122.031 215.127 122.031 213.543C122.031 211.96 120.739 210.668 119.156 210.668C117.565 210.668 116.281 211.96 116.281 213.543C116.281 215.127 117.565 216.418 119.156 216.418Z" fill="#C2C2C2"/>
<path d="M130.781 216.418C132.372 216.418 133.656 215.127 133.656 213.543C133.656 211.96 132.365 210.668 130.781 210.668C129.19 210.668 127.906 211.96 127.906 213.543C127.906 215.127 129.19 216.418 130.781 216.418Z" fill="#C2C2C2"/>
<path d="M142.407 216.418C143.998 216.418 145.282 215.127 145.282 213.543C145.282 211.96 143.99 210.668 142.407 210.668C140.816 210.668 139.532 211.96 139.532 213.543C139.532 215.127 140.816 216.418 142.407 216.418Z" fill="#C2C2C2"/>
<path d="M154.033 216.418C155.624 216.418 156.908 215.127 156.908 213.543C156.908 211.96 155.616 210.668 154.033 210.668C152.442 210.668 151.158 211.96 151.158 213.543C151.158 215.127 152.442 216.418 154.033 216.418Z" fill="#C2C2C2"/>
<path d="M165.659 216.418C167.25 216.418 168.534 215.127 168.534 213.543C168.534 211.96 167.242 210.668 165.659 210.668C164.068 210.668 162.784 211.96 162.784 213.543C162.784 215.127 164.075 216.418 165.659 216.418Z" fill="#C2C2C2"/>
<path d="M177.292 216.418C178.883 216.418 180.167 215.127 180.167 213.543C180.167 211.96 178.875 210.668 177.292 210.668C175.701 210.668 174.417 211.96 174.417 213.543C174.417 215.127 175.701 216.418 177.292 216.418Z" fill="#C2C2C2"/>
<path d="M188.918 216.418C190.509 216.418 191.793 215.127 191.793 213.543C191.793 211.96 190.501 210.668 188.918 210.668C187.327 210.668 186.043 211.96 186.043 213.543C186.043 215.127 187.327 216.418 188.918 216.418Z" fill="#C2C2C2"/>
<path d="M200.543 216.418C202.134 216.418 203.418 215.127 203.418 213.543C203.418 211.96 202.127 210.668 200.543 210.668C198.952 210.668 197.668 211.96 197.668 213.543C197.668 215.127 198.952 216.418 200.543 216.418Z" fill="#C2C2C2"/>
<path d="M212.169 216.418C213.76 216.418 215.044 215.127 215.044 213.543C215.044 211.96 213.753 210.668 212.169 210.668C210.578 210.668 209.294 211.96 209.294 213.543C209.294 215.127 210.578 216.418 212.169 216.418Z" fill="#C2C2C2"/>
<path d="M223.795 216.418C225.386 216.418 226.67 215.127 226.67 213.543C226.67 211.96 225.378 210.668 223.795 210.668C222.204 210.668 220.92 211.96 220.92 213.543C220.92 215.127 222.212 216.418 223.795 216.418Z" fill="#C2C2C2"/>
<path d="M235.429 216.418C237.02 216.418 238.304 215.127 238.304 213.543C238.304 211.96 237.012 210.668 235.429 210.668C233.838 210.668 232.554 211.96 232.554 213.543C232.554 215.127 233.838 216.418 235.429 216.418Z" fill="#C2C2C2"/>
<path d="M247.054 216.418C248.645 216.418 249.929 215.127 249.929 213.543C249.929 211.96 248.637 210.668 247.054 210.668C245.463 210.668 244.179 211.96 244.179 213.543C244.179 215.127 245.463 216.418 247.054 216.418Z" fill="#C2C2C2"/>
<path d="M258.68 216.418C260.271 216.418 261.555 215.127 261.555 213.543C261.555 211.96 260.263 210.668 258.68 210.668C257.089 210.668 255.805 211.96 255.805 213.543C255.805 215.127 257.089 216.418 258.68 216.418Z" fill="#C2C2C2"/>
<path d="M270.306 216.418C271.897 216.418 273.181 215.127 273.181 213.543C273.181 211.96 271.889 210.668 270.306 210.668C268.715 210.668 267.431 211.96 267.431 213.543C267.431 215.127 268.722 216.418 270.306 216.418Z" fill="#C2C2C2"/>
<path d="M281.939 216.418C283.53 216.418 284.814 215.127 284.814 213.543C284.814 211.96 283.522 210.668 281.939 210.668C280.348 210.668 279.064 211.96 279.064 213.543C279.064 215.127 280.348 216.418 281.939 216.418Z" fill="#C2C2C2"/>
<path d="M293.565 216.418C295.156 216.418 296.44 215.127 296.44 213.543C296.44 211.96 295.148 210.668 293.565 210.668C291.982 210.668 290.69 211.96 290.69 213.543C290.69 215.127 291.974 216.418 293.565 216.418Z" fill="#C2C2C2"/>
<path d="M467.974 216.418C469.565 216.418 470.857 215.127 470.857 213.543C470.857 211.96 469.565 210.668 467.974 210.668C466.383 210.668 465.099 211.96 465.099 213.543C465.099 215.127 466.383 216.418 467.974 216.418Z" fill="#C2C2C2"/>
<path d="M479.6 216.418C481.191 216.418 482.475 215.127 482.475 213.543C482.475 211.96 481.191 210.668 479.6 210.668C478.009 210.668 476.725 211.96 476.725 213.543C476.725 215.127 478.017 216.418 479.6 216.418Z" fill="#C2C2C2"/>
<path d="M491.225 216.418C492.817 216.418 494.1 215.127 494.1 213.543C494.1 211.96 492.817 210.668 491.225 210.668C489.634 210.668 488.351 211.96 488.351 213.543C488.351 215.127 489.642 216.418 491.225 216.418Z" fill="#C2C2C2"/>
<path d="M502.859 216.418C504.45 216.418 505.734 215.127 505.734 213.543C505.734 211.96 504.442 210.668 502.859 210.668C501.268 210.668 499.984 211.96 499.984 213.543C499.984 215.127 501.268 216.418 502.859 216.418Z" fill="#C2C2C2"/>
<path d="M514.485 216.418C516.076 216.418 517.36 215.127 517.36 213.543C517.36 211.96 516.076 210.668 514.485 210.668C512.894 210.668 511.61 211.96 511.61 213.543C511.61 215.127 512.894 216.418 514.485 216.418Z" fill="#C2C2C2"/>
<path d="M526.111 216.418C527.702 216.418 528.985 215.127 528.985 213.543C528.985 211.96 527.702 210.668 526.111 210.668C524.519 210.668 523.236 211.96 523.236 213.543C523.236 215.127 524.519 216.418 526.111 216.418Z" fill="#C2C2C2"/>
<path d="M537.736 216.418C539.327 216.418 540.611 215.127 540.611 213.543C540.611 211.96 539.327 210.668 537.736 210.668C536.145 210.668 534.861 211.96 534.861 213.543C534.861 215.127 536.153 216.418 537.736 216.418Z" fill="#C2C2C2"/>
<path d="M549.362 216.418C550.953 216.418 552.237 215.127 552.237 213.543C552.237 211.96 550.953 210.668 549.362 210.668C547.771 210.668 546.487 211.96 546.487 213.543C546.487 215.127 547.779 216.418 549.362 216.418Z" fill="#C2C2C2"/>
<path d="M560.996 216.418C562.587 216.418 563.87 215.127 563.87 213.543C563.87 211.96 562.587 210.668 560.996 210.668C559.404 210.668 558.121 211.96 558.121 213.543C558.121 215.127 559.404 216.418 560.996 216.418Z" fill="#C2C2C2"/>
<path d="M572.621 216.418C574.212 216.418 575.496 215.127 575.496 213.543C575.496 211.96 574.212 210.668 572.621 210.668C571.03 210.668 569.746 211.96 569.746 213.543C569.746 215.127 571.03 216.418 572.621 216.418Z" fill="#C2C2C2"/>
<path d="M584.247 216.418C585.838 216.418 587.122 215.127 587.122 213.543C587.122 211.96 585.83 210.668 584.247 210.668C582.656 210.668 581.372 211.96 581.372 213.543C581.372 215.127 582.664 216.418 584.247 216.418Z" fill="#C2C2C2"/>
<path d="M595.873 216.418C597.464 216.418 598.748 215.127 598.748 213.543C598.748 211.96 597.456 210.668 595.873 210.668C594.282 210.668 592.998 211.96 592.998 213.543C592.998 215.127 594.282 216.418 595.873 216.418Z" fill="#C2C2C2"/>
<path d="M607.506 216.418C609.097 216.418 610.381 215.127 610.381 213.543C610.381 211.96 609.089 210.668 607.506 210.668C605.915 210.668 604.631 211.96 604.631 213.543C604.631 215.127 605.915 216.418 607.506 216.418Z" fill="#C2C2C2"/>
<path d="M619.132 216.418C620.723 216.418 622.007 215.127 622.007 213.543C622.007 211.96 620.715 210.668 619.132 210.668C617.541 210.668 616.257 211.96 616.257 213.543C616.257 215.127 617.541 216.418 619.132 216.418Z" fill="#C2C2C2"/>
<path d="M630.758 216.418C632.349 216.418 633.633 215.127 633.633 213.543C633.633 211.96 632.341 210.668 630.758 210.668C629.167 210.668 627.883 211.96 627.883 213.543C627.883 215.127 629.167 216.418 630.758 216.418Z" fill="#C2C2C2"/>
<path d="M642.383 216.418C643.974 216.418 645.258 215.127 645.258 213.543C645.258 211.96 643.967 210.668 642.383 210.668C640.792 210.668 639.509 211.96 639.509 213.543C639.509 215.127 640.8 216.418 642.383 216.418Z" fill="#C2C2C2"/>
<path d="M49.3857 228.052C50.9767 228.052 52.2606 226.76 52.2606 225.177C52.2606 223.586 50.9688 222.302 49.3857 222.302C47.8025 222.302 46.5107 223.594 46.5107 225.177C46.5107 226.76 47.7946 228.052 49.3857 228.052Z" fill="#C2C2C2"/>
<path d="M61.0114 228.052C62.6024 228.052 63.8863 226.76 63.8863 225.177C63.8863 223.586 62.5946 222.302 61.0114 222.302C59.4203 222.302 58.1365 223.594 58.1365 225.177C58.1365 226.76 59.4282 228.052 61.0114 228.052Z" fill="#C2C2C2"/>
<path d="M72.6449 228.044C74.2327 228.044 75.5199 226.757 75.5199 225.169C75.5199 223.581 74.2327 222.294 72.6449 222.294C71.0572 222.294 69.77 223.581 69.77 225.169C69.77 226.757 71.0572 228.044 72.6449 228.044Z" fill="#C2C2C2"/>
<path d="M84.2707 228.052C85.8617 228.052 87.1456 226.76 87.1456 225.177C87.1456 223.586 85.8538 222.302 84.2707 222.302C82.6796 222.302 81.3958 223.594 81.3958 225.177C81.3958 226.76 82.6796 228.052 84.2707 228.052Z" fill="#C2C2C2"/>
<path d="M95.8964 228.052C97.4875 228.052 98.7713 226.76 98.7713 225.177C98.7713 223.586 97.4796 222.302 95.8964 222.302C94.3054 222.302 93.0215 223.594 93.0215 225.177C93.0215 226.76 94.3054 228.052 95.8964 228.052Z" fill="#C2C2C2"/>
<path d="M107.522 228.052C109.113 228.052 110.397 226.76 110.397 225.177C110.397 223.586 109.105 222.302 107.522 222.302C105.939 222.302 104.647 223.594 104.647 225.177C104.647 226.76 105.939 228.052 107.522 228.052Z" fill="#C2C2C2"/>
<path d="M119.156 228.052C120.747 228.052 122.031 226.76 122.031 225.177C122.031 223.586 120.739 222.302 119.156 222.302C117.565 222.302 116.281 223.594 116.281 225.177C116.273 226.76 117.565 228.052 119.156 228.052Z" fill="#C2C2C2"/>
<path d="M130.781 228.052C132.372 228.052 133.656 226.76 133.656 225.177C133.656 223.586 132.365 222.302 130.781 222.302C129.19 222.302 127.907 223.594 127.907 225.177C127.899 226.76 129.19 228.052 130.781 228.052Z" fill="#C2C2C2"/>
<path d="M142.407 228.044C143.995 228.044 145.282 226.757 145.282 225.169C145.282 223.581 143.995 222.294 142.407 222.294C140.819 222.294 139.532 223.581 139.532 225.169C139.532 226.757 140.819 228.044 142.407 228.044Z" fill="#C2C2C2"/>
<path d="M154.033 228.052C155.624 228.052 156.908 226.76 156.908 225.177C156.908 223.586 155.616 222.302 154.033 222.302C152.442 222.302 151.158 223.594 151.158 225.177C151.158 226.76 152.442 228.052 154.033 228.052Z" fill="#C2C2C2"/>
<path d="M165.659 228.044C167.246 228.044 168.534 226.757 168.534 225.169C168.534 223.581 167.246 222.294 165.659 222.294C164.071 222.294 162.784 223.581 162.784 225.169C162.784 226.757 164.071 228.044 165.659 228.044Z" fill="#C2C2C2"/>
<path d="M177.292 228.044C178.88 228.044 180.167 226.757 180.167 225.169C180.167 223.581 178.88 222.294 177.292 222.294C175.704 222.294 174.417 223.581 174.417 225.169C174.417 226.757 175.704 228.044 177.292 228.044Z" fill="#C2C2C2"/>
<path d="M188.918 228.052C190.509 228.052 191.793 226.76 191.793 225.177C191.793 223.586 190.501 222.302 188.918 222.302C187.327 222.302 186.043 223.594 186.043 225.177C186.035 226.76 187.327 228.052 188.918 228.052Z" fill="#C2C2C2"/>
<path d="M200.543 228.052C202.134 228.052 203.418 226.76 203.418 225.177C203.418 223.586 202.127 222.302 200.543 222.302C198.952 222.302 197.668 223.594 197.668 225.177C197.668 226.76 198.952 228.052 200.543 228.052Z" fill="#C2C2C2"/>
<path d="M212.169 228.052C213.76 228.052 215.044 226.76 215.044 225.177C215.044 223.586 213.753 222.302 212.169 222.302C210.578 222.302 209.294 223.594 209.294 225.177C209.294 226.76 210.578 228.052 212.169 228.052Z" fill="#C2C2C2"/>
<path d="M223.795 228.044C225.383 228.044 226.67 226.757 226.67 225.169C226.67 223.581 225.383 222.294 223.795 222.294C222.207 222.294 220.92 223.581 220.92 225.169C220.92 226.757 222.207 228.044 223.795 228.044Z" fill="#C2C2C2"/>
<path d="M235.429 228.044C237.016 228.044 238.304 226.757 238.304 225.169C238.304 223.581 237.016 222.294 235.429 222.294C233.841 222.294 232.554 223.581 232.554 225.169C232.554 226.757 233.841 228.044 235.429 228.044Z" fill="#C2C2C2"/>
<path d="M247.054 228.052C248.645 228.052 249.929 226.76 249.929 225.177C249.929 223.586 248.637 222.302 247.054 222.302C245.463 222.302 244.179 223.594 244.179 225.177C244.179 226.76 245.463 228.052 247.054 228.052Z" fill="#C2C2C2"/>
<path d="M258.68 228.052C260.271 228.052 261.555 226.76 261.555 225.177C261.555 223.586 260.263 222.302 258.68 222.302C257.089 222.302 255.805 223.594 255.805 225.177C255.805 226.76 257.089 228.052 258.68 228.052Z" fill="#C2C2C2"/>
<path d="M270.306 228.052C271.897 228.052 273.181 226.76 273.181 225.177C273.181 223.586 271.889 222.302 270.306 222.302C268.715 222.302 267.431 223.594 267.431 225.177C267.431 226.76 268.722 228.052 270.306 228.052Z" fill="#C2C2C2"/>
<path d="M281.939 228.052C283.53 228.052 284.814 226.76 284.814 225.177C284.814 223.586 283.522 222.302 281.939 222.302C280.348 222.302 279.064 223.594 279.064 225.177C279.056 226.76 280.348 228.052 281.939 228.052Z" fill="#C2C2C2"/>
<path d="M293.565 228.052C295.156 228.052 296.44 226.76 296.44 225.177C296.44 223.586 295.148 222.302 293.565 222.302C291.982 222.302 290.69 223.594 290.69 225.177C290.682 226.76 291.974 228.052 293.565 228.052Z" fill="#C2C2C2"/>
<path d="M467.974 228.052C469.565 228.052 470.857 226.76 470.857 225.177C470.857 223.586 469.565 222.302 467.974 222.302C466.383 222.302 465.099 223.594 465.099 225.177C465.099 226.76 466.383 228.052 467.974 228.052Z" fill="#C2C2C2"/>
<path d="M479.6 228.044C481.188 228.044 482.475 226.757 482.475 225.169C482.475 223.581 481.188 222.294 479.6 222.294C478.012 222.294 476.725 223.581 476.725 225.169C476.725 226.757 478.012 228.044 479.6 228.044Z" fill="#C2C2C2"/>
<path d="M494.102 225.18C494.107 223.593 492.823 222.302 491.235 222.297C489.648 222.293 488.357 223.576 488.352 225.164C488.348 226.752 489.631 228.043 491.219 228.047C492.807 228.052 494.098 226.768 494.102 225.18Z" fill="#C2C2C2"/>
<path d="M502.859 228.052C504.45 228.052 505.734 226.76 505.734 225.177C505.734 223.586 504.442 222.302 502.859 222.302C501.268 222.302 499.984 223.594 499.984 225.177C499.977 226.76 501.268 228.052 502.859 228.052Z" fill="#C2C2C2"/>
<path d="M514.485 228.044C516.073 228.044 517.36 226.757 517.36 225.169C517.36 223.581 516.073 222.294 514.485 222.294C512.897 222.294 511.61 223.581 511.61 225.169C511.61 226.757 512.897 228.044 514.485 228.044Z" fill="#C2C2C2"/>
<path d="M526.111 228.052C527.702 228.052 528.985 226.76 528.985 225.177C528.985 223.586 527.702 222.302 526.111 222.302C524.519 222.302 523.236 223.594 523.236 225.177C523.236 226.76 524.519 228.052 526.111 228.052Z" fill="#C2C2C2"/>
<path d="M537.736 228.052C539.327 228.052 540.611 226.76 540.611 225.177C540.611 223.586 539.327 222.302 537.736 222.302C536.145 222.302 534.861 223.594 534.861 225.177C534.861 226.76 536.153 228.052 537.736 228.052Z" fill="#C2C2C2"/>
<path d="M549.362 228.052C550.953 228.052 552.237 226.76 552.237 225.177C552.237 223.586 550.953 222.302 549.362 222.302C547.771 222.302 546.487 223.594 546.487 225.177C546.487 226.76 547.779 228.052 549.362 228.052Z" fill="#C2C2C2"/>
<path d="M560.996 228.052C562.587 228.052 563.871 226.76 563.871 225.177C563.871 223.586 562.587 222.302 560.996 222.302C559.405 222.302 558.121 223.594 558.121 225.177C558.113 226.76 559.405 228.052 560.996 228.052Z" fill="#C2C2C2"/>
<path d="M572.621 228.052C574.212 228.052 575.496 226.76 575.496 225.177C575.496 223.586 574.212 222.302 572.621 222.302C571.03 222.302 569.746 223.594 569.746 225.177C569.746 226.76 571.03 228.052 572.621 228.052Z" fill="#C2C2C2"/>
<path d="M584.247 228.052C585.838 228.052 587.122 226.76 587.122 225.177C587.122 223.586 585.83 222.302 584.247 222.302C582.656 222.302 581.372 223.594 581.372 225.177C581.372 226.76 582.664 228.052 584.247 228.052Z" fill="#C2C2C2"/>
<path d="M595.873 228.052C597.464 228.052 598.748 226.76 598.748 225.177C598.748 223.586 597.456 222.302 595.873 222.302C594.282 222.302 592.998 223.594 592.998 225.177C592.998 226.76 594.282 228.052 595.873 228.052Z" fill="#C2C2C2"/>
<path d="M607.506 228.052C609.097 228.052 610.381 226.76 610.381 225.177C610.381 223.586 609.09 222.302 607.506 222.302C605.915 222.302 604.631 223.594 604.631 225.177C604.624 226.76 605.915 228.052 607.506 228.052Z" fill="#C2C2C2"/>
<path d="M619.132 228.044C620.72 228.044 622.007 226.757 622.007 225.169C622.007 223.581 620.72 222.294 619.132 222.294C617.544 222.294 616.257 223.581 616.257 225.169C616.257 226.757 617.544 228.044 619.132 228.044Z" fill="#C2C2C2"/>
<path d="M630.758 228.052C632.349 228.052 633.633 226.76 633.633 225.177C633.633 223.586 632.341 222.302 630.758 222.302C629.167 222.302 627.883 223.594 627.883 225.177C627.883 226.76 629.167 228.052 630.758 228.052Z" fill="#C2C2C2"/>
<path d="M49.3857 239.678C50.9767 239.678 52.2606 238.386 52.2606 236.803C52.2606 235.212 50.9688 233.928 49.3857 233.928C47.8025 233.928 46.5107 235.219 46.5107 236.803C46.5107 238.386 47.7946 239.678 49.3857 239.678Z" fill="#C2C2C2"/>
<path d="M61.0114 239.678C62.6024 239.678 63.8863 238.386 63.8863 236.803C63.8863 235.212 62.5946 233.928 61.0114 233.928C59.4203 233.928 58.1365 235.219 58.1365 236.803C58.1365 238.386 59.4282 239.678 61.0114 239.678Z" fill="#C2C2C2"/>
<path d="M72.645 239.678C74.236 239.678 75.5199 238.386 75.5199 236.803C75.5199 235.212 74.2282 233.928 72.645 233.928C71.0539 233.928 69.7701 235.219 69.7701 236.803C69.7622 238.386 71.0539 239.678 72.645 239.678Z" fill="#C2C2C2"/>
<path d="M84.2707 239.678C85.8617 239.678 87.1456 238.386 87.1456 236.803C87.1456 235.212 85.8538 233.928 84.2707 233.928C82.6796 233.928 81.3958 235.219 81.3958 236.803C81.3958 238.386 82.6796 239.678 84.2707 239.678Z" fill="#C2C2C2"/>
<path d="M95.8964 239.678C97.4875 239.678 98.7713 238.386 98.7713 236.803C98.7713 235.212 97.4796 233.928 95.8964 233.928C94.3054 233.928 93.0215 235.219 93.0215 236.803C93.0215 238.386 94.3054 239.678 95.8964 239.678Z" fill="#C2C2C2"/>
<path d="M107.522 239.678C109.113 239.678 110.397 238.386 110.397 236.803C110.397 235.212 109.105 233.928 107.522 233.928C105.939 233.928 104.647 235.219 104.647 236.803C104.647 238.386 105.939 239.678 107.522 239.678Z" fill="#C2C2C2"/>
<path d="M119.156 239.678C120.747 239.678 122.031 238.386 122.031 236.803C122.031 235.212 120.739 233.928 119.156 233.928C117.565 233.928 116.281 235.219 116.281 236.803C116.273 238.386 117.565 239.678 119.156 239.678Z" fill="#C2C2C2"/>
<path d="M130.781 239.678C132.372 239.678 133.656 238.386 133.656 236.803C133.656 235.212 132.365 233.928 130.781 233.928C129.19 233.928 127.907 235.219 127.907 236.803C127.899 238.386 129.19 239.678 130.781 239.678Z" fill="#C2C2C2"/>
<path d="M142.407 239.678C143.998 239.678 145.282 238.386 145.282 236.803C145.282 235.212 143.99 233.928 142.407 233.928C140.816 233.928 139.532 235.219 139.532 236.803C139.532 238.386 140.816 239.678 142.407 239.678Z" fill="#C2C2C2"/>
<path d="M154.033 239.678C155.624 239.678 156.908 238.386 156.908 236.803C156.908 235.212 155.616 233.928 154.033 233.928C152.442 233.928 151.158 235.219 151.158 236.803C151.158 238.386 152.442 239.678 154.033 239.678Z" fill="#C2C2C2"/>
<path d="M165.659 239.678C167.25 239.678 168.534 238.386 168.534 236.803C168.534 235.212 167.242 233.928 165.659 233.928C164.068 233.928 162.784 235.219 162.784 236.803C162.784 238.386 164.075 239.678 165.659 239.678Z" fill="#C2C2C2"/>
<path d="M177.292 239.678C178.883 239.678 180.167 238.386 180.167 236.803C180.167 235.212 178.875 233.928 177.292 233.928C175.701 233.928 174.417 235.219 174.417 236.803C174.409 238.386 175.701 239.678 177.292 239.678Z" fill="#C2C2C2"/>
<path d="M188.918 239.678C190.509 239.678 191.793 238.386 191.793 236.803C191.793 235.212 190.501 233.928 188.918 233.928C187.327 233.928 186.043 235.219 186.043 236.803C186.035 238.386 187.327 239.678 188.918 239.678Z" fill="#C2C2C2"/>
<path d="M200.543 239.678C202.134 239.678 203.418 238.386 203.418 236.803C203.418 235.212 202.127 233.928 200.543 233.928C198.952 233.928 197.668 235.219 197.668 236.803C197.668 238.386 198.952 239.678 200.543 239.678Z" fill="#C2C2C2"/>
<path d="M212.169 239.678C213.76 239.678 215.044 238.386 215.044 236.803C215.044 235.212 213.753 233.928 212.169 233.928C210.578 233.928 209.294 235.219 209.294 236.803C209.294 238.386 210.578 239.678 212.169 239.678Z" fill="#C2C2C2"/>
<path d="M223.795 239.678C225.386 239.678 226.67 238.386 226.67 236.803C226.67 235.212 225.378 233.928 223.795 233.928C222.204 233.928 220.92 235.219 220.92 236.803C220.92 238.386 222.212 239.678 223.795 239.678Z" fill="#C2C2C2"/>
<path d="M235.428 239.678C237.019 239.678 238.303 238.386 238.303 236.803C238.303 235.212 237.012 233.928 235.428 233.928C233.837 233.928 232.554 235.219 232.554 236.803C232.546 238.386 233.837 239.678 235.428 239.678Z" fill="#C2C2C2"/>
<path d="M247.054 239.678C248.645 239.678 249.929 238.386 249.929 236.803C249.929 235.212 248.637 233.928 247.054 233.928C245.463 233.928 244.179 235.219 244.179 236.803C244.179 238.386 245.463 239.678 247.054 239.678Z" fill="#C2C2C2"/>
<path d="M258.68 239.678C260.271 239.678 261.555 238.386 261.555 236.803C261.555 235.212 260.263 233.928 258.68 233.928C257.089 233.928 255.805 235.219 255.805 236.803C255.805 238.386 257.089 239.678 258.68 239.678Z" fill="#C2C2C2"/>
<path d="M270.306 239.678C271.897 239.678 273.181 238.386 273.181 236.803C273.181 235.212 271.889 233.928 270.306 233.928C268.715 233.928 267.431 235.219 267.431 236.803C267.431 238.386 268.722 239.678 270.306 239.678Z" fill="#C2C2C2"/>
<path d="M281.939 239.678C283.53 239.678 284.814 238.386 284.814 236.803C284.814 235.212 283.522 233.928 281.939 233.928C280.348 233.928 279.064 235.219 279.064 236.803C279.056 238.386 280.348 239.678 281.939 239.678Z" fill="#C2C2C2"/>
<path d="M293.565 239.678C295.156 239.678 296.44 238.386 296.44 236.803C296.44 235.212 295.148 233.928 293.565 233.928C291.982 233.928 290.69 235.219 290.69 236.803C290.682 238.386 291.974 239.678 293.565 239.678Z" fill="#C2C2C2"/>
<path d="M467.974 239.678C469.565 239.678 470.857 238.386 470.857 236.803C470.857 235.212 469.565 233.928 467.974 233.928C466.383 233.928 465.099 235.219 465.099 236.803C465.099 238.386 466.383 239.678 467.974 239.678Z" fill="#C2C2C2"/>
<path d="M479.6 239.678C481.191 239.678 482.475 238.386 482.475 236.803C482.475 235.212 481.191 233.928 479.6 233.928C478.009 233.928 476.725 235.219 476.725 236.803C476.725 238.386 478.017 239.678 479.6 239.678Z" fill="#C2C2C2"/>
<path d="M491.225 239.678C492.817 239.678 494.1 238.386 494.1 236.803C494.1 235.212 492.817 233.928 491.225 233.928C489.634 233.928 488.351 235.219 488.351 236.803C488.351 238.386 489.642 239.678 491.225 239.678Z" fill="#C2C2C2"/>
<path d="M502.859 239.678C504.45 239.678 505.734 238.386 505.734 236.803C505.734 235.212 504.442 233.928 502.859 233.928C501.268 233.928 499.984 235.219 499.984 236.803C499.977 238.386 501.268 239.678 502.859 239.678Z" fill="#C2C2C2"/>
<path d="M514.485 239.678C516.076 239.678 517.36 238.386 517.36 236.803C517.36 235.212 516.076 233.928 514.485 233.928C512.894 233.928 511.61 235.219 511.61 236.803C511.61 238.386 512.894 239.678 514.485 239.678Z" fill="#C2C2C2"/>
<path d="M526.111 239.678C527.702 239.678 528.985 238.386 528.985 236.803C528.985 235.212 527.702 233.928 526.111 233.928C524.519 233.928 523.236 235.219 523.236 236.803C523.236 238.386 524.519 239.678 526.111 239.678Z" fill="#C2C2C2"/>
<path d="M537.736 239.678C539.327 239.678 540.611 238.386 540.611 236.803C540.611 235.212 539.327 233.928 537.736 233.928C536.145 233.928 534.861 235.219 534.861 236.803C534.861 238.386 536.153 239.678 537.736 239.678Z" fill="#C2C2C2"/>
<path d="M549.362 239.678C550.953 239.678 552.237 238.386 552.237 236.803C552.237 235.212 550.953 233.928 549.362 233.928C547.771 233.928 546.487 235.219 546.487 236.803C546.487 238.386 547.779 239.678 549.362 239.678Z" fill="#C2C2C2"/>
<path d="M560.996 239.678C562.587 239.678 563.871 238.386 563.871 236.803C563.871 235.212 562.587 233.928 560.996 233.928C559.405 233.928 558.121 235.219 558.121 236.803C558.113 238.386 559.405 239.678 560.996 239.678Z" fill="#C2C2C2"/>
<path d="M572.621 239.678C574.212 239.678 575.496 238.386 575.496 236.803C575.496 235.212 574.212 233.928 572.621 233.928C571.03 233.928 569.746 235.219 569.746 236.803C569.746 238.386 571.03 239.678 572.621 239.678Z" fill="#C2C2C2"/>
<path d="M584.247 239.678C585.838 239.678 587.122 238.386 587.122 236.803C587.122 235.212 585.83 233.928 584.247 233.928C582.656 233.928 581.372 235.219 581.372 236.803C581.372 238.386 582.664 239.678 584.247 239.678Z" fill="#C2C2C2"/>
<path d="M595.873 239.678C597.464 239.678 598.748 238.386 598.748 236.803C598.748 235.212 597.456 233.928 595.873 233.928C594.282 233.928 592.998 235.219 592.998 236.803C592.998 238.386 594.282 239.678 595.873 239.678Z" fill="#C2C2C2"/>
<path d="M607.506 239.678C609.097 239.678 610.381 238.386 610.381 236.803C610.381 235.212 609.09 233.928 607.506 233.928C605.915 233.928 604.631 235.219 604.631 236.803C604.624 238.386 605.915 239.678 607.506 239.678Z" fill="#C2C2C2"/>
<path d="M619.132 239.678C620.723 239.678 622.007 238.386 622.007 236.803C622.007 235.212 620.715 233.928 619.132 233.928C617.541 233.928 616.257 235.219 616.257 236.803C616.257 238.386 617.541 239.678 619.132 239.678Z" fill="#C2C2C2"/>
<path d="M630.758 239.678C632.349 239.678 633.633 238.386 633.633 236.803C633.633 235.212 632.341 233.928 630.758 233.928C629.167 233.928 627.883 235.219 627.883 236.803C627.883 238.386 629.167 239.678 630.758 239.678Z" fill="#C2C2C2"/>
<path d="M642.383 239.678C643.974 239.678 645.258 238.386 645.258 236.803C645.258 235.212 643.967 233.928 642.383 233.928C640.792 233.928 639.509 235.219 639.509 236.803C639.509 238.386 640.8 239.678 642.383 239.678Z" fill="#C2C2C2"/>
<path d="M61.0114 251.304C62.6024 251.304 63.8863 250.012 63.8863 248.429C63.8863 246.838 62.5946 245.554 61.0114 245.554C59.4203 245.554 58.1365 246.845 58.1365 248.429C58.1365 250.012 59.4282 251.304 61.0114 251.304Z" fill="#C2C2C2"/>
<path d="M72.645 251.304C74.236 251.304 75.5199 250.012 75.5199 248.429C75.5199 246.838 74.2282 245.554 72.645 245.554C71.0539 245.554 69.7701 246.845 69.7701 248.429C69.7622 250.012 71.0539 251.304 72.645 251.304Z" fill="#C2C2C2"/>
<path d="M84.2707 251.304C85.8617 251.304 87.1456 250.012 87.1456 248.429C87.1456 246.838 85.8538 245.554 84.2707 245.554C82.6796 245.554 81.3958 246.845 81.3958 248.429C81.3958 250.012 82.6796 251.304 84.2707 251.304Z" fill="#C2C2C2"/>
<path d="M95.8964 251.304C97.4875 251.304 98.7713 250.012 98.7713 248.429C98.7713 246.838 97.4796 245.554 95.8964 245.554C94.3054 245.554 93.0215 246.845 93.0215 248.429C93.0215 250.012 94.3054 251.304 95.8964 251.304Z" fill="#C2C2C2"/>
<path d="M107.522 251.304C109.113 251.304 110.397 250.012 110.397 248.429C110.397 246.838 109.105 245.554 107.522 245.554C105.939 245.554 104.647 246.845 104.647 248.429C104.647 250.012 105.939 251.304 107.522 251.304Z" fill="#C2C2C2"/>
<path d="M119.156 251.304C120.747 251.304 122.031 250.012 122.031 248.429C122.031 246.838 120.739 245.554 119.156 245.554C117.565 245.554 116.281 246.845 116.281 248.429C116.273 250.012 117.565 251.304 119.156 251.304Z" fill="#C2C2C2"/>
<path d="M130.781 251.304C132.372 251.304 133.656 250.012 133.656 248.429C133.656 246.838 132.365 245.554 130.781 245.554C129.19 245.554 127.907 246.845 127.907 248.429C127.899 250.012 129.19 251.304 130.781 251.304Z" fill="#C2C2C2"/>
<path d="M142.407 251.304C143.998 251.304 145.282 250.012 145.282 248.429C145.282 246.838 143.99 245.554 142.407 245.554C140.816 245.554 139.532 246.845 139.532 248.429C139.532 250.012 140.816 251.304 142.407 251.304Z" fill="#C2C2C2"/>
<path d="M154.033 251.304C155.624 251.304 156.908 250.012 156.908 248.429C156.908 246.838 155.616 245.554 154.033 245.554C152.442 245.554 151.158 246.845 151.158 248.429C151.158 250.012 152.442 251.304 154.033 251.304Z" fill="#C2C2C2"/>
<path d="M165.659 251.304C167.25 251.304 168.534 250.012 168.534 248.429C168.534 246.838 167.242 245.554 165.659 245.554C164.068 245.554 162.784 246.845 162.784 248.429C162.784 250.012 164.075 251.304 165.659 251.304Z" fill="#C2C2C2"/>
<path d="M177.292 251.304C178.883 251.304 180.167 250.012 180.167 248.429C180.167 246.838 178.875 245.554 177.292 245.554C175.701 245.554 174.417 246.845 174.417 248.429C174.409 250.012 175.701 251.304 177.292 251.304Z" fill="#C2C2C2"/>
<path d="M188.918 251.304C190.509 251.304 191.793 250.012 191.793 248.429C191.793 246.838 190.501 245.554 188.918 245.554C187.327 245.554 186.043 246.845 186.043 248.429C186.035 250.012 187.327 251.304 188.918 251.304Z" fill="#C2C2C2"/>
<path d="M200.543 251.304C202.134 251.304 203.418 250.012 203.418 248.429C203.418 246.838 202.127 245.554 200.543 245.554C198.952 245.554 197.668 246.845 197.668 248.429C197.668 250.012 198.952 251.304 200.543 251.304Z" fill="#C2C2C2"/>
<path d="M212.169 251.304C213.76 251.304 215.044 250.012 215.044 248.429C215.044 246.838 213.753 245.554 212.169 245.554C210.578 245.554 209.294 246.845 209.294 248.429C209.294 250.012 210.578 251.304 212.169 251.304Z" fill="#C2C2C2"/>
<path d="M223.795 251.304C225.386 251.304 226.67 250.012 226.67 248.429C226.67 246.838 225.378 245.554 223.795 245.554C222.204 245.554 220.92 246.845 220.92 248.429C220.92 250.012 222.212 251.304 223.795 251.304Z" fill="#C2C2C2"/>
<path d="M235.428 251.304C237.019 251.304 238.303 250.012 238.303 248.429C238.303 246.838 237.012 245.554 235.428 245.554C233.837 245.554 232.554 246.845 232.554 248.429C232.546 250.012 233.837 251.304 235.428 251.304Z" fill="#C2C2C2"/>
<path d="M247.054 251.304C248.645 251.304 249.929 250.012 249.929 248.429C249.929 246.838 248.637 245.554 247.054 245.554C245.463 245.554 244.179 246.845 244.179 248.429C244.179 250.012 245.463 251.304 247.054 251.304Z" fill="#C2C2C2"/>
<path d="M258.68 251.304C260.271 251.304 261.555 250.012 261.555 248.429C261.555 246.838 260.263 245.554 258.68 245.554C257.089 245.554 255.805 246.845 255.805 248.429C255.805 250.012 257.089 251.304 258.68 251.304Z" fill="#C2C2C2"/>
<path d="M270.306 251.304C271.897 251.304 273.181 250.012 273.181 248.429C273.181 246.838 271.889 245.554 270.306 245.554C268.715 245.554 267.431 246.845 267.431 248.429C267.431 250.012 268.722 251.304 270.306 251.304Z" fill="#C2C2C2"/>
<path d="M281.939 251.304C283.53 251.304 284.814 250.012 284.814 248.429C284.814 246.838 283.522 245.554 281.939 245.554C280.348 245.554 279.064 246.845 279.064 248.429C279.056 250.012 280.348 251.304 281.939 251.304Z" fill="#C2C2C2"/>
<path d="M293.565 251.304C295.156 251.304 296.44 250.012 296.44 248.429C296.44 246.838 295.148 245.554 293.565 245.554C291.982 245.554 290.69 246.845 290.69 248.429C290.682 250.012 291.974 251.304 293.565 251.304Z" fill="#C2C2C2"/>
<path d="M467.974 251.304C469.565 251.304 470.857 250.012 470.857 248.429C470.857 246.838 469.565 245.554 467.974 245.554C466.383 245.554 465.099 246.845 465.099 248.429C465.099 250.012 466.383 251.304 467.974 251.304Z" fill="#C2C2C2"/>
<path d="M479.6 251.304C481.191 251.304 482.475 250.012 482.475 248.429C482.475 246.838 481.191 245.554 479.6 245.554C478.009 245.554 476.725 246.845 476.725 248.429C476.725 250.012 478.017 251.304 479.6 251.304Z" fill="#C2C2C2"/>
<path d="M491.225 251.304C492.817 251.304 494.1 250.012 494.1 248.429C494.1 246.838 492.817 245.554 491.225 245.554C489.634 245.554 488.351 246.845 488.351 248.429C488.351 250.012 489.642 251.304 491.225 251.304Z" fill="#C2C2C2"/>
<path d="M502.859 251.304C504.45 251.304 505.734 250.012 505.734 248.429C505.734 246.838 504.442 245.554 502.859 245.554C501.268 245.554 499.984 246.845 499.984 248.429C499.977 250.012 501.268 251.304 502.859 251.304Z" fill="#C2C2C2"/>
<path d="M514.485 251.304C516.076 251.304 517.36 250.012 517.36 248.429C517.36 246.838 516.076 245.554 514.485 245.554C512.894 245.554 511.61 246.845 511.61 248.429C511.61 250.012 512.894 251.304 514.485 251.304Z" fill="#C2C2C2"/>
<path d="M526.111 251.304C527.702 251.304 528.985 250.012 528.985 248.429C528.985 246.838 527.702 245.554 526.111 245.554C524.519 245.554 523.236 246.845 523.236 248.429C523.236 250.012 524.519 251.304 526.111 251.304Z" fill="#C2C2C2"/>
<path d="M537.736 251.304C539.327 251.304 540.611 250.012 540.611 248.429C540.611 246.838 539.327 245.554 537.736 245.554C536.145 245.554 534.861 246.845 534.861 248.429C534.861 250.012 536.153 251.304 537.736 251.304Z" fill="#C2C2C2"/>
<path d="M549.362 251.304C550.953 251.304 552.237 250.012 552.237 248.429C552.237 246.838 550.953 245.554 549.362 245.554C547.771 245.554 546.487 246.845 546.487 248.429C546.487 250.012 547.779 251.304 549.362 251.304Z" fill="#C2C2C2"/>
<path d="M560.996 251.304C562.587 251.304 563.871 250.012 563.871 248.429C563.871 246.838 562.587 245.554 560.996 245.554C559.405 245.554 558.121 246.845 558.121 248.429C558.113 250.012 559.405 251.304 560.996 251.304Z" fill="#C2C2C2"/>
<path d="M572.621 251.304C574.212 251.304 575.496 250.012 575.496 248.429C575.496 246.838 574.212 245.554 572.621 245.554C571.03 245.554 569.746 246.845 569.746 248.429C569.746 250.012 571.03 251.304 572.621 251.304Z" fill="#C2C2C2"/>
<path d="M584.247 251.304C585.838 251.304 587.122 250.012 587.122 248.429C587.122 246.838 585.83 245.554 584.247 245.554C582.656 245.554 581.372 246.845 581.372 248.429C581.372 250.012 582.664 251.304 584.247 251.304Z" fill="#C2C2C2"/>
<path d="M595.873 251.304C597.464 251.304 598.748 250.012 598.748 248.429C598.748 246.838 597.456 245.554 595.873 245.554C594.282 245.554 592.998 246.845 592.998 248.429C592.998 250.012 594.282 251.304 595.873 251.304Z" fill="#C2C2C2"/>
<path d="M607.506 251.304C609.097 251.304 610.381 250.012 610.381 248.429C610.381 246.838 609.09 245.554 607.506 245.554C605.915 245.554 604.631 246.845 604.631 248.429C604.624 250.012 605.915 251.304 607.506 251.304Z" fill="#C2C2C2"/>
<path d="M619.132 251.304C620.723 251.304 622.007 250.012 622.007 248.429C622.007 246.838 620.715 245.554 619.132 245.554C617.541 245.554 616.257 246.845 616.257 248.429C616.257 250.012 617.541 251.304 619.132 251.304Z" fill="#C2C2C2"/>
<path d="M630.758 251.304C632.349 251.304 633.633 250.012 633.633 248.429C633.633 246.838 632.341 245.554 630.758 245.554C629.167 245.554 627.883 246.845 627.883 248.429C627.883 250.012 629.167 251.304 630.758 251.304Z" fill="#C2C2C2"/>
<path d="M642.383 251.304C643.974 251.304 645.258 250.012 645.258 248.429C645.258 246.838 643.967 245.554 642.383 245.554C640.792 245.554 639.509 246.845 639.509 248.429C639.509 250.012 640.8 251.304 642.383 251.304Z" fill="#C2C2C2"/>
<path d="M654.017 251.304C655.608 251.304 656.892 250.012 656.892 248.429C656.892 246.838 655.6 245.554 654.017 245.554C652.426 245.554 651.142 246.845 651.142 248.429C651.134 250.012 652.426 251.304 654.017 251.304Z" fill="#C2C2C2"/>
<path d="M165.659 262.929C167.25 262.929 168.534 261.637 168.534 260.054C168.534 258.463 167.242 257.179 165.659 257.179C164.068 257.179 162.784 258.471 162.784 260.054C162.784 261.645 164.075 262.929 165.659 262.929Z" fill="#C2C2C2"/>
<path d="M177.292 262.929C178.883 262.929 180.167 261.637 180.167 260.054C180.167 258.463 178.875 257.179 177.292 257.179C175.701 257.179 174.417 258.471 174.417 260.054C174.409 261.645 175.701 262.929 177.292 262.929Z" fill="#C2C2C2"/>
<path d="M188.918 262.929C190.509 262.929 191.793 261.637 191.793 260.054C191.793 258.463 190.501 257.179 188.918 257.179C187.327 257.179 186.043 258.471 186.043 260.054C186.035 261.645 187.327 262.929 188.918 262.929Z" fill="#C2C2C2"/>
<path d="M200.543 262.929C202.134 262.929 203.418 261.637 203.418 260.054C203.418 258.463 202.127 257.179 200.543 257.179C198.952 257.179 197.668 258.471 197.668 260.054C197.668 261.645 198.952 262.929 200.543 262.929Z" fill="#C2C2C2"/>
<path d="M212.169 262.929C213.76 262.929 215.044 261.637 215.044 260.054C215.044 258.463 213.753 257.179 212.169 257.179C210.578 257.179 209.294 258.471 209.294 260.054C209.294 261.645 210.578 262.929 212.169 262.929Z" fill="#C2C2C2"/>
<path d="M223.795 262.929C225.386 262.929 226.67 261.637 226.67 260.054C226.67 258.463 225.378 257.179 223.795 257.179C222.204 257.179 220.92 258.471 220.92 260.054C220.92 261.645 222.212 262.929 223.795 262.929Z" fill="#C2C2C2"/>
<path d="M235.428 262.929C237.019 262.929 238.303 261.637 238.303 260.054C238.303 258.463 237.012 257.179 235.428 257.179C233.837 257.179 232.554 258.471 232.554 260.054C232.546 261.645 233.837 262.929 235.428 262.929Z" fill="#C2C2C2"/>
<path d="M247.054 262.929C248.645 262.929 249.929 261.637 249.929 260.054C249.929 258.463 248.637 257.179 247.054 257.179C245.463 257.179 244.179 258.471 244.179 260.054C244.179 261.645 245.463 262.929 247.054 262.929Z" fill="#C2C2C2"/>
<path d="M258.68 262.929C260.271 262.929 261.555 261.637 261.555 260.054C261.555 258.463 260.263 257.179 258.68 257.179C257.089 257.179 255.805 258.471 255.805 260.054C255.805 261.645 257.089 262.929 258.68 262.929Z" fill="#C2C2C2"/>
<path d="M270.306 262.929C271.897 262.929 273.181 261.637 273.181 260.054C273.181 258.463 271.889 257.179 270.306 257.179C268.715 257.179 267.431 258.471 267.431 260.054C267.431 261.645 268.722 262.929 270.306 262.929Z" fill="#C2C2C2"/>
<path d="M281.939 262.929C283.53 262.929 284.814 261.637 284.814 260.054C284.814 258.463 283.522 257.179 281.939 257.179C280.348 257.179 279.064 258.471 279.064 260.054C279.056 261.645 280.348 262.929 281.939 262.929Z" fill="#C2C2C2"/>
<path d="M293.565 262.929C295.156 262.929 296.44 261.637 296.44 260.054C296.44 258.463 295.148 257.179 293.565 257.179C291.982 257.179 290.69 258.471 290.69 260.054C290.682 261.645 291.974 262.929 293.565 262.929Z" fill="#C2C2C2"/>
<path d="M467.974 262.929C469.565 262.929 470.857 261.637 470.857 260.054C470.857 258.463 469.565 257.179 467.974 257.179C466.383 257.179 465.099 258.471 465.099 260.054C465.099 261.645 466.383 262.929 467.974 262.929Z" fill="#C2C2C2"/>
<path d="M479.6 262.929C481.191 262.929 482.475 261.637 482.475 260.054C482.475 258.463 481.191 257.179 479.6 257.179C478.009 257.179 476.725 258.471 476.725 260.054C476.725 261.645 478.017 262.929 479.6 262.929Z" fill="#C2C2C2"/>
<path d="M491.225 262.929C492.817 262.929 494.1 261.637 494.1 260.054C494.1 258.463 492.817 257.179 491.225 257.179C489.634 257.179 488.351 258.471 488.351 260.054C488.351 261.645 489.642 262.929 491.225 262.929Z" fill="#C2C2C2"/>
<path d="M502.859 262.929C504.45 262.929 505.734 261.637 505.734 260.054C505.734 258.463 504.442 257.179 502.859 257.179C501.268 257.179 499.984 258.471 499.984 260.054C499.977 261.645 501.268 262.929 502.859 262.929Z" fill="#C2C2C2"/>
<path d="M514.485 262.929C516.076 262.929 517.36 261.637 517.36 260.054C517.36 258.463 516.076 257.179 514.485 257.179C512.894 257.179 511.61 258.471 511.61 260.054C511.61 261.645 512.894 262.929 514.485 262.929Z" fill="#C2C2C2"/>
<path d="M526.111 262.929C527.702 262.929 528.985 261.637 528.985 260.054C528.985 258.463 527.702 257.179 526.111 257.179C524.519 257.179 523.236 258.471 523.236 260.054C523.236 261.645 524.519 262.929 526.111 262.929Z" fill="#C2C2C2"/>
<path d="M537.736 262.929C539.327 262.929 540.611 261.637 540.611 260.054C540.611 258.463 539.327 257.179 537.736 257.179C536.145 257.179 534.861 258.471 534.861 260.054C534.861 261.645 536.153 262.929 537.736 262.929Z" fill="#C2C2C2"/>
<path d="M549.362 262.929C550.953 262.929 552.237 261.637 552.237 260.054C552.237 258.463 550.953 257.179 549.362 257.179C547.771 257.179 546.487 258.471 546.487 260.054C546.487 261.645 547.779 262.929 549.362 262.929Z" fill="#C2C2C2"/>
<path d="M560.996 262.929C562.587 262.929 563.871 261.637 563.871 260.054C563.871 258.463 562.587 257.179 560.996 257.179C559.405 257.179 558.121 258.471 558.121 260.054C558.113 261.645 559.405 262.929 560.996 262.929Z" fill="#C2C2C2"/>
<path d="M572.621 262.929C574.212 262.929 575.496 261.637 575.496 260.054C575.496 258.463 574.212 257.179 572.621 257.179C571.03 257.179 569.746 258.471 569.746 260.054C569.746 261.645 571.03 262.929 572.621 262.929Z" fill="#C2C2C2"/>
<path d="M584.247 262.929C585.838 262.929 587.122 261.637 587.122 260.054C587.122 258.463 585.83 257.179 584.247 257.179C582.656 257.179 581.372 258.471 581.372 260.054C581.372 261.645 582.664 262.929 584.247 262.929Z" fill="#C2C2C2"/>
<path d="M595.873 262.929C597.464 262.929 598.748 261.637 598.748 260.054C598.748 258.463 597.456 257.179 595.873 257.179C594.282 257.179 592.998 258.471 592.998 260.054C592.998 261.645 594.282 262.929 595.873 262.929Z" fill="#C2C2C2"/>
<path d="M607.506 262.929C609.097 262.929 610.381 261.637 610.381 260.054C610.381 258.463 609.09 257.179 607.506 257.179C605.915 257.179 604.631 258.471 604.631 260.054C604.624 261.645 605.915 262.929 607.506 262.929Z" fill="#C2C2C2"/>
<path d="M619.132 262.929C620.723 262.929 622.007 261.637 622.007 260.054C622.007 258.463 620.715 257.179 619.132 257.179C617.541 257.179 616.257 258.471 616.257 260.054C616.257 261.645 617.541 262.929 619.132 262.929Z" fill="#C2C2C2"/>
<path d="M630.758 262.929C632.349 262.929 633.633 261.637 633.633 260.054C633.633 258.463 632.341 257.179 630.758 257.179C629.167 257.179 627.883 258.471 627.883 260.054C627.883 261.645 629.167 262.929 630.758 262.929Z" fill="#C2C2C2"/>
<path d="M642.383 262.929C643.974 262.929 645.258 261.637 645.258 260.054C645.258 258.463 643.967 257.179 642.383 257.179C640.792 257.179 639.509 258.471 639.509 260.054C639.509 261.645 640.8 262.929 642.383 262.929Z" fill="#C2C2C2"/>
<path d="M654.017 262.929C655.608 262.929 656.892 261.637 656.892 260.054C656.892 258.463 655.6 257.179 654.017 257.179C652.426 257.179 651.142 258.471 651.142 260.054C651.134 261.645 652.426 262.929 654.017 262.929Z" fill="#C2C2C2"/>
<path d="M177.292 274.555C178.883 274.555 180.167 273.263 180.167 271.68C180.167 270.089 178.875 268.805 177.292 268.805C175.701 268.805 174.417 270.096 174.417 271.68C174.409 273.271 175.701 274.555 177.292 274.555Z" fill="#C2C2C2"/>
<path d="M188.918 274.555C190.509 274.555 191.793 273.263 191.793 271.68C191.793 270.089 190.501 268.805 188.918 268.805C187.327 268.805 186.043 270.096 186.043 271.68C186.035 273.271 187.327 274.555 188.918 274.555Z" fill="#C2C2C2"/>
<path d="M200.543 274.555C202.134 274.555 203.418 273.263 203.418 271.68C203.418 270.089 202.127 268.805 200.543 268.805C198.952 268.805 197.668 270.096 197.668 271.68C197.668 273.271 198.952 274.555 200.543 274.555Z" fill="#C2C2C2"/>
<path d="M212.169 274.555C213.76 274.555 215.044 273.263 215.044 271.68C215.044 270.089 213.753 268.805 212.169 268.805C210.578 268.805 209.294 270.096 209.294 271.68C209.294 273.271 210.578 274.555 212.169 274.555Z" fill="#C2C2C2"/>
<path d="M223.795 274.555C225.386 274.555 226.67 273.263 226.67 271.68C226.67 270.089 225.378 268.805 223.795 268.805C222.204 268.805 220.92 270.096 220.92 271.68C220.92 273.271 222.212 274.555 223.795 274.555Z" fill="#C2C2C2"/>
<path d="M235.428 274.555C237.019 274.555 238.303 273.263 238.303 271.68C238.303 270.089 237.012 268.805 235.428 268.805C233.837 268.805 232.554 270.096 232.554 271.68C232.546 273.271 233.837 274.555 235.428 274.555Z" fill="#C2C2C2"/>
<path d="M247.054 274.555C248.645 274.555 249.929 273.263 249.929 271.68C249.929 270.089 248.637 268.805 247.054 268.805C245.463 268.805 244.179 270.096 244.179 271.68C244.179 273.271 245.463 274.555 247.054 274.555Z" fill="#C2C2C2"/>
<path d="M258.68 274.555C260.271 274.555 261.555 273.263 261.555 271.68C261.555 270.089 260.263 268.805 258.68 268.805C257.089 268.805 255.805 270.096 255.805 271.68C255.805 273.271 257.089 274.555 258.68 274.555Z" fill="#C2C2C2"/>
<path d="M270.306 274.555C271.897 274.555 273.181 273.263 273.181 271.68C273.181 270.089 271.889 268.805 270.306 268.805C268.715 268.805 267.431 270.096 267.431 271.68C267.431 273.271 268.722 274.555 270.306 274.555Z" fill="#C2C2C2"/>
<path d="M281.939 274.555C283.53 274.555 284.814 273.263 284.814 271.68C284.814 270.089 283.522 268.805 281.939 268.805C280.348 268.805 279.064 270.096 279.064 271.68C279.056 273.271 280.348 274.555 281.939 274.555Z" fill="#C2C2C2"/>
<path d="M293.565 274.555C295.156 274.555 296.44 273.263 296.44 271.68C296.44 270.089 295.148 268.805 293.565 268.805C291.982 268.805 290.69 270.096 290.69 271.68C290.682 273.271 291.974 274.555 293.565 274.555Z" fill="#C2C2C2"/>
<path d="M467.974 274.555C469.565 274.555 470.857 273.263 470.857 271.68C470.857 270.089 469.565 268.805 467.974 268.805C466.383 268.805 465.099 270.096 465.099 271.68C465.099 273.271 466.383 274.555 467.974 274.555Z" fill="#C2C2C2"/>
<path d="M479.6 274.555C481.191 274.555 482.475 273.263 482.475 271.68C482.475 270.089 481.191 268.805 479.6 268.805C478.009 268.805 476.725 270.096 476.725 271.68C476.725 273.271 478.017 274.555 479.6 274.555Z" fill="#C2C2C2"/>
<path d="M491.225 274.555C492.817 274.555 494.1 273.263 494.1 271.68C494.1 270.089 492.817 268.805 491.225 268.805C489.634 268.805 488.351 270.096 488.351 271.68C488.351 273.271 489.642 274.555 491.225 274.555Z" fill="#C2C2C2"/>
<path d="M502.859 274.555C504.45 274.555 505.734 273.263 505.734 271.68C505.734 270.089 504.442 268.805 502.859 268.805C501.268 268.805 499.984 270.096 499.984 271.68C499.977 273.271 501.268 274.555 502.859 274.555Z" fill="#C2C2C2"/>
<path d="M514.485 274.555C516.076 274.555 517.36 273.263 517.36 271.68C517.36 270.089 516.076 268.805 514.485 268.805C512.894 268.805 511.61 270.096 511.61 271.68C511.61 273.271 512.894 274.555 514.485 274.555Z" fill="#C2C2C2"/>
<path d="M526.111 274.555C527.702 274.555 528.985 273.263 528.985 271.68C528.985 270.089 527.702 268.805 526.111 268.805C524.519 268.805 523.236 270.096 523.236 271.68C523.236 273.271 524.519 274.555 526.111 274.555Z" fill="#C2C2C2"/>
<path d="M537.736 274.555C539.327 274.555 540.611 273.263 540.611 271.68C540.611 270.089 539.327 268.805 537.736 268.805C536.145 268.805 534.861 270.096 534.861 271.68C534.861 273.271 536.153 274.555 537.736 274.555Z" fill="#C2C2C2"/>
<path d="M549.362 274.555C550.953 274.555 552.237 273.263 552.237 271.68C552.237 270.089 550.953 268.805 549.362 268.805C547.771 268.805 546.487 270.096 546.487 271.68C546.487 273.271 547.779 274.555 549.362 274.555Z" fill="#C2C2C2"/>
<path d="M560.996 274.555C562.587 274.555 563.871 273.263 563.871 271.68C563.871 270.089 562.587 268.805 560.996 268.805C559.405 268.805 558.121 270.096 558.121 271.68C558.113 273.271 559.405 274.555 560.996 274.555Z" fill="#C2C2C2"/>
<path d="M572.621 274.555C574.212 274.555 575.496 273.263 575.496 271.68C575.496 270.089 574.212 268.805 572.621 268.805C571.03 268.805 569.746 270.096 569.746 271.68C569.746 273.271 571.03 274.555 572.621 274.555Z" fill="#C2C2C2"/>
<path d="M584.247 274.555C585.838 274.555 587.122 273.263 587.122 271.68C587.122 270.089 585.83 268.805 584.247 268.805C582.656 268.805 581.372 270.096 581.372 271.68C581.372 273.271 582.664 274.555 584.247 274.555Z" fill="#C2C2C2"/>
<path d="M595.873 274.555C597.464 274.555 598.748 273.263 598.748 271.68C598.748 270.089 597.456 268.805 595.873 268.805C594.282 268.805 592.998 270.096 592.998 271.68C592.998 273.271 594.282 274.555 595.873 274.555Z" fill="#C2C2C2"/>
<path d="M607.506 274.555C609.097 274.555 610.381 273.263 610.381 271.68C610.381 270.089 609.09 268.805 607.506 268.805C605.915 268.805 604.631 270.096 604.631 271.68C604.624 273.271 605.915 274.555 607.506 274.555Z" fill="#C2C2C2"/>
<path d="M200.543 286.188C202.134 286.188 203.418 284.897 203.418 283.313C203.418 281.722 202.127 280.438 200.543 280.438C198.952 280.438 197.668 281.73 197.668 283.313C197.668 284.897 198.952 286.188 200.543 286.188Z" fill="#C2C2C2"/>
<path d="M212.169 286.188C213.76 286.188 215.044 284.897 215.044 283.313C215.044 281.722 213.753 280.438 212.169 280.438C210.578 280.438 209.294 281.73 209.294 283.313C209.294 284.897 210.578 286.188 212.169 286.188Z" fill="#C2C2C2"/>
<path d="M223.795 286.188C225.386 286.188 226.67 284.897 226.67 283.313C226.67 281.722 225.378 280.438 223.795 280.438C222.204 280.438 220.92 281.73 220.92 283.313C220.92 284.897 222.212 286.188 223.795 286.188Z" fill="#C2C2C2"/>
<path d="M235.428 286.188C237.019 286.188 238.303 284.897 238.303 283.313C238.303 281.722 237.012 280.438 235.428 280.438C233.837 280.438 232.554 281.73 232.554 283.313C232.546 284.897 233.837 286.188 235.428 286.188Z" fill="#C2C2C2"/>
<path d="M247.054 286.188C248.645 286.188 249.929 284.897 249.929 283.313C249.929 281.722 248.637 280.438 247.054 280.438C245.463 280.438 244.179 281.73 244.179 283.313C244.179 284.897 245.463 286.188 247.054 286.188Z" fill="#C2C2C2"/>
<path d="M258.68 286.188C260.271 286.188 261.555 284.897 261.555 283.313C261.555 281.722 260.263 280.438 258.68 280.438C257.089 280.438 255.805 281.73 255.805 283.313C255.805 284.897 257.089 286.188 258.68 286.188Z" fill="#C2C2C2"/>
<path d="M270.306 286.188C271.897 286.188 273.181 284.897 273.181 283.313C273.181 281.722 271.889 280.438 270.306 280.438C268.715 280.438 267.431 281.73 267.431 283.313C267.431 284.897 268.722 286.188 270.306 286.188Z" fill="#C2C2C2"/>
<path d="M281.939 286.188C283.53 286.188 284.814 284.897 284.814 283.313C284.814 281.722 283.522 280.438 281.939 280.438C280.348 280.438 279.064 281.73 279.064 283.313C279.056 284.897 280.348 286.188 281.939 286.188Z" fill="#C2C2C2"/>
<path d="M293.565 286.188C295.156 286.188 296.44 284.897 296.44 283.313C296.44 281.722 295.148 280.438 293.565 280.438C291.982 280.438 290.69 281.73 290.69 283.313C290.682 284.897 291.974 286.188 293.565 286.188Z" fill="#C2C2C2"/>
<path d="M467.974 286.188C469.565 286.188 470.857 284.897 470.857 283.313C470.857 281.722 469.565 280.438 467.974 280.438C466.383 280.438 465.099 281.73 465.099 283.313C465.099 284.897 466.383 286.188 467.974 286.188Z" fill="#C2C2C2"/>
<path d="M479.6 286.188C481.191 286.188 482.475 284.897 482.475 283.313C482.475 281.722 481.191 280.438 479.6 280.438C478.009 280.438 476.725 281.73 476.725 283.313C476.725 284.897 478.017 286.188 479.6 286.188Z" fill="#C2C2C2"/>
<path d="M491.225 286.188C492.817 286.188 494.1 284.897 494.1 283.313C494.1 281.722 492.817 280.438 491.225 280.438C489.634 280.438 488.351 281.73 488.351 283.313C488.351 284.897 489.642 286.188 491.225 286.188Z" fill="#C2C2C2"/>
<path d="M502.859 286.188C504.45 286.188 505.734 284.897 505.734 283.313C505.734 281.722 504.442 280.438 502.859 280.438C501.268 280.438 499.984 281.73 499.984 283.313C499.977 284.897 501.268 286.188 502.859 286.188Z" fill="#C2C2C2"/>
<path d="M514.485 286.188C516.076 286.188 517.36 284.897 517.36 283.313C517.36 281.722 516.076 280.438 514.485 280.438C512.894 280.438 511.61 281.73 511.61 283.313C511.61 284.897 512.894 286.188 514.485 286.188Z" fill="#C2C2C2"/>
<path d="M526.111 286.188C527.702 286.188 528.985 284.897 528.985 283.313C528.985 281.722 527.702 280.438 526.111 280.438C524.519 280.438 523.236 281.73 523.236 283.313C523.236 284.897 524.519 286.188 526.111 286.188Z" fill="#C2C2C2"/>
<path d="M537.736 286.188C539.327 286.188 540.611 284.897 540.611 283.313C540.611 281.722 539.327 280.438 537.736 280.438C536.145 280.438 534.861 281.73 534.861 283.313C534.861 284.897 536.153 286.188 537.736 286.188Z" fill="#C2C2C2"/>
<path d="M549.362 286.188C550.953 286.188 552.237 284.897 552.237 283.313C552.237 281.722 550.953 280.438 549.362 280.438C547.771 280.438 546.487 281.73 546.487 283.313C546.487 284.897 547.779 286.188 549.362 286.188Z" fill="#C2C2C2"/>
<path d="M560.996 286.188C562.587 286.188 563.871 284.897 563.871 283.313C563.871 281.722 562.587 280.438 560.996 280.438C559.405 280.438 558.121 281.73 558.121 283.313C558.113 284.897 559.405 286.188 560.996 286.188Z" fill="#C2C2C2"/>
<path d="M572.621 286.188C574.212 286.188 575.496 284.897 575.496 283.313C575.496 281.722 574.212 280.438 572.621 280.438C571.03 280.438 569.746 281.73 569.746 283.313C569.746 284.897 571.03 286.188 572.621 286.188Z" fill="#C2C2C2"/>
<path d="M584.247 286.188C585.838 286.188 587.122 284.897 587.122 283.313C587.122 281.722 585.83 280.438 584.247 280.438C582.656 280.438 581.372 281.73 581.372 283.313C581.372 284.897 582.664 286.188 584.247 286.188Z" fill="#C2C2C2"/>
<path d="M595.873 286.188C597.464 286.188 598.748 284.897 598.748 283.313C598.748 281.722 597.456 280.438 595.873 280.438C594.282 280.438 592.998 281.73 592.998 283.313C592.998 284.897 594.282 286.188 595.873 286.188Z" fill="#C2C2C2"/>
<path d="M607.506 286.188C609.097 286.188 610.381 284.897 610.381 283.313C610.381 281.722 609.09 280.438 607.506 280.438C605.915 280.438 604.631 281.73 604.631 283.313C604.624 284.897 605.915 286.188 607.506 286.188Z" fill="#C2C2C2"/>
<path d="M607.506 297.208C609.097 297.208 610.381 295.916 610.381 294.333C610.381 292.75 609.089 291.458 607.506 291.458C605.915 291.458 604.631 292.75 604.631 294.333C604.631 295.916 605.915 297.208 607.506 297.208Z" fill="#C2C2C2"/>
<path d="M235.428 297.814C237.019 297.814 238.303 296.523 238.303 294.939C238.303 293.348 237.012 292.064 235.428 292.064C233.837 292.064 232.554 293.356 232.554 294.939C232.546 296.523 233.837 297.814 235.428 297.814Z" fill="#C2C2C2"/>
<path d="M247.054 297.814C248.645 297.814 249.929 296.523 249.929 294.939C249.929 293.348 248.637 292.064 247.054 292.064C245.463 292.064 244.179 293.356 244.179 294.939C244.179 296.523 245.463 297.814 247.054 297.814Z" fill="#C2C2C2"/>
<path d="M293.565 297.814C295.156 297.814 296.44 296.523 296.44 294.939C296.44 293.348 295.148 292.064 293.565 292.064C291.982 292.064 290.69 293.356 290.69 294.939C290.682 296.523 291.974 297.814 293.565 297.814Z" fill="#C2C2C2"/>
<path d="M467.974 297.814C469.565 297.814 470.857 296.523 470.857 294.939C470.857 293.348 469.565 292.064 467.974 292.064C466.383 292.064 465.099 293.356 465.099 294.939C465.099 296.523 466.383 297.814 467.974 297.814Z" fill="#C2C2C2"/>
<path d="M479.6 297.814C481.191 297.814 482.475 296.523 482.475 294.939C482.475 293.348 481.191 292.064 479.6 292.064C478.009 292.064 476.725 293.356 476.725 294.939C476.725 296.523 478.017 297.814 479.6 297.814Z" fill="#C2C2C2"/>
<path d="M491.225 297.814C492.817 297.814 494.1 296.523 494.1 294.939C494.1 293.348 492.817 292.064 491.225 292.064C489.634 292.064 488.351 293.356 488.351 294.939C488.351 296.523 489.642 297.814 491.225 297.814Z" fill="#C2C2C2"/>
<path d="M502.859 297.814C504.45 297.814 505.734 296.523 505.734 294.939C505.734 293.348 504.442 292.064 502.859 292.064C501.268 292.064 499.984 293.356 499.984 294.939C499.977 296.523 501.268 297.814 502.859 297.814Z" fill="#C2C2C2"/>
<path d="M514.485 297.814C516.076 297.814 517.36 296.523 517.36 294.939C517.36 293.348 516.076 292.064 514.485 292.064C512.894 292.064 511.61 293.356 511.61 294.939C511.61 296.523 512.894 297.814 514.485 297.814Z" fill="#C2C2C2"/>
<path d="M526.111 297.814C527.702 297.814 528.985 296.523 528.985 294.939C528.985 293.348 527.702 292.064 526.111 292.064C524.519 292.064 523.236 293.356 523.236 294.939C523.236 296.523 524.519 297.814 526.111 297.814Z" fill="#C2C2C2"/>
<path d="M537.736 297.814C539.327 297.814 540.611 296.523 540.611 294.939C540.611 293.348 539.327 292.064 537.736 292.064C536.145 292.064 534.861 293.356 534.861 294.939C534.861 296.523 536.153 297.814 537.736 297.814Z" fill="#C2C2C2"/>
<path d="M549.362 297.814C550.953 297.814 552.237 296.523 552.237 294.939C552.237 293.348 550.953 292.064 549.362 292.064C547.771 292.064 546.487 293.356 546.487 294.939C546.487 296.523 547.779 297.814 549.362 297.814Z" fill="#C2C2C2"/>
<path d="M560.996 297.814C562.587 297.814 563.871 296.523 563.871 294.939C563.871 293.348 562.587 292.064 560.996 292.064C559.405 292.064 558.121 293.356 558.121 294.939C558.113 296.523 559.405 297.814 560.996 297.814Z" fill="#C2C2C2"/>
<path d="M572.621 297.814C574.212 297.814 575.496 296.523 575.496 294.939C575.496 293.348 574.212 292.064 572.621 292.064C571.03 292.064 569.746 293.356 569.746 294.939C569.746 296.523 571.03 297.814 572.621 297.814Z" fill="#C2C2C2"/>
<path d="M584.247 297.814C585.838 297.814 587.122 296.523 587.122 294.939C587.122 293.348 585.83 292.064 584.247 292.064C582.656 292.064 581.372 293.356 581.372 294.939C581.372 296.523 582.664 297.814 584.247 297.814Z" fill="#C2C2C2"/>
<path d="M595.873 297.814C597.464 297.814 598.748 296.523 598.748 294.939C598.748 293.348 597.456 292.064 595.873 292.064C594.282 292.064 592.998 293.356 592.998 294.939C592.998 296.523 594.282 297.814 595.873 297.814Z" fill="#C2C2C2"/>
<path d="M293.565 309.44C295.156 309.44 296.44 308.148 296.44 306.565C296.44 304.974 295.148 303.69 293.565 303.69C291.982 303.69 290.69 304.982 290.69 306.565C290.682 308.156 291.974 309.44 293.565 309.44Z" fill="#C2C2C2"/>
<path d="M467.974 309.44C469.565 309.44 470.857 308.148 470.857 306.565C470.857 304.974 469.565 303.69 467.974 303.69C466.383 303.69 465.099 304.982 465.099 306.565C465.099 308.156 466.383 309.44 467.974 309.44Z" fill="#C2C2C2"/>
<path d="M479.6 309.44C481.191 309.44 482.475 308.148 482.475 306.565C482.475 304.974 481.191 303.69 479.6 303.69C478.009 303.69 476.725 304.982 476.725 306.565C476.725 308.156 478.017 309.44 479.6 309.44Z" fill="#C2C2C2"/>
<path d="M491.225 309.44C492.817 309.44 494.1 308.148 494.1 306.565C494.1 304.974 492.817 303.69 491.225 303.69C489.634 303.69 488.351 304.982 488.351 306.565C488.351 308.156 489.642 309.44 491.225 309.44Z" fill="#C2C2C2"/>
<path d="M502.859 309.44C504.45 309.44 505.734 308.148 505.734 306.565C505.734 304.974 504.442 303.69 502.859 303.69C501.268 303.69 499.984 304.982 499.984 306.565C499.977 308.156 501.268 309.44 502.859 309.44Z" fill="#C2C2C2"/>
<path d="M514.485 309.44C516.076 309.44 517.36 308.148 517.36 306.565C517.36 304.974 516.076 303.69 514.485 303.69C512.894 303.69 511.61 304.982 511.61 306.565C511.61 308.156 512.894 309.44 514.485 309.44Z" fill="#C2C2C2"/>
<path d="M526.111 309.44C527.702 309.44 528.985 308.148 528.985 306.565C528.985 304.974 527.702 303.69 526.111 303.69C524.519 303.69 523.236 304.982 523.236 306.565C523.236 308.156 524.519 309.44 526.111 309.44Z" fill="#C2C2C2"/>
<path d="M537.736 309.44C539.327 309.44 540.611 308.148 540.611 306.565C540.611 304.974 539.327 303.69 537.736 303.69C536.145 303.69 534.861 304.982 534.861 306.565C534.861 308.156 536.153 309.44 537.736 309.44Z" fill="#C2C2C2"/>
<path d="M549.362 309.44C550.953 309.44 552.237 308.148 552.237 306.565C552.237 304.974 550.953 303.69 549.362 303.69C547.771 303.69 546.487 304.982 546.487 306.565C546.487 308.156 547.779 309.44 549.362 309.44Z" fill="#C2C2C2"/>
<path d="M560.996 309.44C562.587 309.44 563.871 308.148 563.871 306.565C563.871 304.974 562.587 303.69 560.996 303.69C559.405 303.69 558.121 304.982 558.121 306.565C558.113 308.156 559.405 309.44 560.996 309.44Z" fill="#C2C2C2"/>
<path d="M572.621 309.44C574.212 309.44 575.496 308.148 575.496 306.565C575.496 304.974 574.212 303.69 572.621 303.69C571.03 303.69 569.746 304.982 569.746 306.565C569.746 308.156 571.03 309.44 572.621 309.44Z" fill="#C2C2C2"/>
<path d="M584.247 309.44C585.838 309.44 587.122 308.148 587.122 306.565C587.122 304.974 585.83 303.69 584.247 303.69C582.656 303.69 581.372 304.982 581.372 306.565C581.372 308.156 582.664 309.44 584.247 309.44Z" fill="#C2C2C2"/>
<path d="M595.873 309.44C597.464 309.44 598.748 308.148 598.748 306.565C598.748 304.974 597.456 303.69 595.873 303.69C594.282 303.69 592.998 304.982 592.998 306.565C592.998 308.156 594.282 309.44 595.873 309.44Z" fill="#C2C2C2"/>
<path d="M607.506 309.44C609.097 309.44 610.381 308.148 610.381 306.565C610.381 304.974 609.09 303.69 607.506 303.69C605.915 303.69 604.631 304.982 604.631 306.565C604.624 308.156 605.915 309.44 607.506 309.44Z" fill="#C2C2C2"/>
<path d="M293.565 321.065C295.156 321.065 296.44 319.774 296.44 318.19C296.44 316.599 295.148 315.315 293.565 315.315C291.982 315.315 290.69 316.607 290.69 318.19C290.682 319.781 291.974 321.065 293.565 321.065Z" fill="#C2C2C2"/>
<path d="M467.974 321.065C469.565 321.065 470.857 319.774 470.857 318.19C470.857 316.599 469.565 315.315 467.974 315.315C466.383 315.315 465.099 316.607 465.099 318.19C465.099 319.781 466.383 321.065 467.974 321.065Z" fill="#C2C2C2"/>
<path d="M479.6 321.065C481.191 321.065 482.475 319.774 482.475 318.19C482.475 316.599 481.191 315.315 479.6 315.315C478.009 315.315 476.725 316.607 476.725 318.19C476.725 319.781 478.017 321.065 479.6 321.065Z" fill="#C2C2C2"/>
<path d="M491.225 321.065C492.817 321.065 494.1 319.774 494.1 318.19C494.1 316.599 492.817 315.315 491.225 315.315C489.634 315.315 488.351 316.607 488.351 318.19C488.351 319.781 489.642 321.065 491.225 321.065Z" fill="#C2C2C2"/>
<path d="M502.859 321.065C504.45 321.065 505.734 319.774 505.734 318.19C505.734 316.599 504.442 315.315 502.859 315.315C501.268 315.315 499.984 316.607 499.984 318.19C499.977 319.781 501.268 321.065 502.859 321.065Z" fill="#C2C2C2"/>
<path d="M514.485 321.065C516.076 321.065 517.36 319.774 517.36 318.19C517.36 316.599 516.076 315.315 514.485 315.315C512.894 315.315 511.61 316.607 511.61 318.19C511.61 319.781 512.894 321.065 514.485 321.065Z" fill="#C2C2C2"/>
<path d="M526.111 321.065C527.702 321.065 528.985 319.774 528.985 318.19C528.985 316.599 527.702 315.315 526.111 315.315C524.519 315.315 523.236 316.607 523.236 318.19C523.236 319.781 524.519 321.065 526.111 321.065Z" fill="#C2C2C2"/>
<path d="M537.736 321.065C539.327 321.065 540.611 319.774 540.611 318.19C540.611 316.599 539.327 315.315 537.736 315.315C536.145 315.315 534.861 316.607 534.861 318.19C534.861 319.781 536.153 321.065 537.736 321.065Z" fill="#C2C2C2"/>
<path d="M549.362 321.065C550.953 321.065 552.237 319.774 552.237 318.19C552.237 316.599 550.953 315.315 549.362 315.315C547.771 315.315 546.487 316.607 546.487 318.19C546.487 319.781 547.779 321.065 549.362 321.065Z" fill="#C2C2C2"/>
<path d="M560.996 321.065C562.587 321.065 563.871 319.774 563.871 318.19C563.871 316.599 562.587 315.315 560.996 315.315C559.405 315.315 558.121 316.607 558.121 318.19C558.113 319.781 559.405 321.065 560.996 321.065Z" fill="#C2C2C2"/>
<path d="M572.621 321.065C574.212 321.065 575.496 319.774 575.496 318.19C575.496 316.599 574.212 315.315 572.621 315.315C571.03 315.315 569.746 316.607 569.746 318.19C569.746 319.781 571.03 321.065 572.621 321.065Z" fill="#C2C2C2"/>
<path d="M584.247 321.065C585.838 321.065 587.122 319.774 587.122 318.19C587.122 316.599 585.83 315.315 584.247 315.315C582.656 315.315 581.372 316.607 581.372 318.19C581.372 319.781 582.664 321.065 584.247 321.065Z" fill="#C2C2C2"/>
<path d="M595.873 321.065C597.464 321.065 598.748 319.774 598.748 318.19C598.748 316.599 597.456 315.315 595.873 315.315C594.282 315.315 592.998 316.607 592.998 318.19C592.998 319.781 594.282 321.065 595.873 321.065Z" fill="#C2C2C2"/>
<path d="M607.506 321.065C609.097 321.065 610.381 319.774 610.381 318.19C610.381 316.599 609.09 315.315 607.506 315.315C605.915 315.315 604.631 316.607 604.631 318.19C604.624 319.781 605.915 321.065 607.506 321.065Z" fill="#C2C2C2"/>
<path d="M619.132 321.065C620.723 321.065 622.007 319.774 622.007 318.19C622.007 316.599 620.715 315.315 619.132 315.315C617.541 315.315 616.257 316.607 616.257 318.19C616.257 319.781 617.541 321.065 619.132 321.065Z" fill="#C2C2C2"/>
<path d="M270.306 332.699C271.897 332.699 273.181 331.407 273.181 329.824C273.181 328.233 271.889 326.949 270.306 326.949C268.715 326.949 267.431 328.241 267.431 329.824C267.431 331.407 268.722 332.699 270.306 332.699Z" fill="#C2C2C2"/>
<path d="M281.939 332.699C283.53 332.699 284.814 331.407 284.814 329.824C284.814 328.233 283.522 326.949 281.939 326.949C280.348 326.949 279.064 328.241 279.064 329.824C279.056 331.407 280.348 332.699 281.939 332.699Z" fill="#C2C2C2"/>
<path d="M293.565 332.699C295.156 332.699 296.44 331.407 296.44 329.824C296.44 328.233 295.148 326.949 293.565 326.949C291.982 326.949 290.69 328.241 290.69 329.824C290.682 331.407 291.974 332.699 293.565 332.699Z" fill="#C2C2C2"/>
<path d="M467.974 332.699C469.565 332.699 470.857 331.407 470.857 329.824C470.857 328.233 469.565 326.949 467.974 326.949C466.383 326.949 465.099 328.241 465.099 329.824C465.099 331.407 466.383 332.699 467.974 332.699Z" fill="#C2C2C2"/>
<path d="M479.6 332.699C481.191 332.699 482.475 331.407 482.475 329.824C482.475 328.233 481.191 326.949 479.6 326.949C478.009 326.949 476.725 328.241 476.725 329.824C476.725 331.407 478.017 332.699 479.6 332.699Z" fill="#C2C2C2"/>
<path d="M491.225 332.699C492.817 332.699 494.1 331.407 494.1 329.824C494.1 328.233 492.817 326.949 491.225 326.949C489.634 326.949 488.351 328.241 488.351 329.824C488.351 331.407 489.642 332.699 491.225 332.699Z" fill="#C2C2C2"/>
<path d="M502.859 332.699C504.45 332.699 505.734 331.407 505.734 329.824C505.734 328.233 504.442 326.949 502.859 326.949C501.268 326.949 499.984 328.241 499.984 329.824C499.977 331.407 501.268 332.699 502.859 332.699Z" fill="#C2C2C2"/>
<path d="M514.485 332.699C516.076 332.699 517.36 331.407 517.36 329.824C517.36 328.233 516.076 326.949 514.485 326.949C512.894 326.949 511.61 328.241 511.61 329.824C511.61 331.407 512.894 332.699 514.485 332.699Z" fill="#C2C2C2"/>
<path d="M526.111 332.699C527.702 332.699 528.985 331.407 528.985 329.824C528.985 328.233 527.702 326.949 526.111 326.949C524.519 326.949 523.236 328.241 523.236 329.824C523.236 331.407 524.519 332.699 526.111 332.699Z" fill="#C2C2C2"/>
<path d="M537.736 332.699C539.327 332.699 540.611 331.407 540.611 329.824C540.611 328.233 539.327 326.949 537.736 326.949C536.145 326.949 534.861 328.241 534.861 329.824C534.861 331.407 536.153 332.699 537.736 332.699Z" fill="#C2C2C2"/>
<path d="M549.362 332.699C550.953 332.699 552.237 331.407 552.237 329.824C552.237 328.233 550.953 326.949 549.362 326.949C547.771 326.949 546.487 328.241 546.487 329.824C546.487 331.407 547.779 332.699 549.362 332.699Z" fill="#C2C2C2"/>
<path d="M560.996 332.699C562.587 332.699 563.871 331.407 563.871 329.824C563.871 328.233 562.587 326.949 560.996 326.949C559.405 326.949 558.121 328.241 558.121 329.824C558.113 331.407 559.405 332.699 560.996 332.699Z" fill="#C2C2C2"/>
<path d="M572.621 332.699C574.212 332.699 575.496 331.407 575.496 329.824C575.496 328.233 574.212 326.949 572.621 326.949C571.03 326.949 569.746 328.241 569.746 329.824C569.746 331.407 571.03 332.699 572.621 332.699Z" fill="#C2C2C2"/>
<path d="M584.247 332.699C585.838 332.699 587.122 331.407 587.122 329.824C587.122 328.233 585.83 326.949 584.247 326.949C582.656 326.949 581.372 328.241 581.372 329.824C581.372 331.407 582.664 332.699 584.247 332.699Z" fill="#C2C2C2"/>
<path d="M595.873 332.699C597.464 332.699 598.748 331.407 598.748 329.824C598.748 328.233 597.456 326.949 595.873 326.949C594.282 326.949 592.998 328.241 592.998 329.824C592.998 331.407 594.282 332.699 595.873 332.699Z" fill="#C2C2C2"/>
<path d="M607.506 332.699C609.097 332.699 610.381 331.407 610.381 329.824C610.381 328.233 609.09 326.949 607.506 326.949C605.915 326.949 604.631 328.241 604.631 329.824C604.624 331.407 605.915 332.699 607.506 332.699Z" fill="#C2C2C2"/>
<path d="M619.132 332.699C620.723 332.699 622.007 331.407 622.007 329.824C622.007 328.233 620.715 326.949 619.132 326.949C617.541 326.949 616.257 328.241 616.257 329.824C616.257 331.407 617.541 332.699 619.132 332.699Z" fill="#C2C2C2"/>
<path d="M630.758 332.699C632.349 332.699 633.633 331.407 633.633 329.824C633.633 328.233 632.341 326.949 630.758 326.949C629.167 326.949 627.883 328.241 627.883 329.824C627.883 331.407 629.167 332.699 630.758 332.699Z" fill="#C2C2C2"/>
<path d="M270.306 344.325C271.897 344.325 273.181 343.033 273.181 341.45C273.181 339.859 271.889 338.575 270.306 338.575C268.715 338.575 267.431 339.867 267.431 341.45C267.431 343.033 268.722 344.325 270.306 344.325Z" fill="#C2C2C2"/>
<path d="M281.939 344.325C283.53 344.325 284.814 343.033 284.814 341.45C284.814 339.859 283.522 338.575 281.939 338.575C280.348 338.575 279.064 339.867 279.064 341.45C279.056 343.033 280.348 344.325 281.939 344.325Z" fill="#C2C2C2"/>
<path d="M293.565 344.325C295.156 344.325 296.44 343.033 296.44 341.45C296.44 339.859 295.148 338.575 293.565 338.575C291.982 338.575 290.69 339.867 290.69 341.45C290.682 343.033 291.974 344.325 293.565 344.325Z" fill="#C2C2C2"/>
<path d="M467.974 344.325C469.565 344.325 470.857 343.033 470.857 341.45C470.857 339.859 469.565 338.575 467.974 338.575C466.383 338.575 465.099 339.867 465.099 341.45C465.099 343.033 466.383 344.325 467.974 344.325Z" fill="#C2C2C2"/>
<path d="M479.6 344.325C481.191 344.325 482.475 343.033 482.475 341.45C482.475 339.859 481.191 338.575 479.6 338.575C478.009 338.575 476.725 339.867 476.725 341.45C476.725 343.033 478.017 344.325 479.6 344.325Z" fill="#C2C2C2"/>
<path d="M491.225 344.325C492.817 344.325 494.1 343.033 494.1 341.45C494.1 339.859 492.817 338.575 491.225 338.575C489.634 338.575 488.351 339.867 488.351 341.45C488.351 343.033 489.642 344.325 491.225 344.325Z" fill="#C2C2C2"/>
<path d="M502.859 344.325C504.45 344.325 505.734 343.033 505.734 341.45C505.734 339.859 504.442 338.575 502.859 338.575C501.268 338.575 499.984 339.867 499.984 341.45C499.977 343.033 501.268 344.325 502.859 344.325Z" fill="#C2C2C2"/>
<path d="M514.485 344.325C516.076 344.325 517.36 343.033 517.36 341.45C517.36 339.859 516.076 338.575 514.485 338.575C512.894 338.575 511.61 339.867 511.61 341.45C511.61 343.033 512.894 344.325 514.485 344.325Z" fill="#C2C2C2"/>
<path d="M526.111 344.325C527.702 344.325 528.985 343.033 528.985 341.45C528.985 339.859 527.702 338.575 526.111 338.575C524.519 338.575 523.236 339.867 523.236 341.45C523.236 343.033 524.519 344.325 526.111 344.325Z" fill="#C2C2C2"/>
<path d="M537.736 344.325C539.327 344.325 540.611 343.033 540.611 341.45C540.611 339.859 539.327 338.575 537.736 338.575C536.145 338.575 534.861 339.867 534.861 341.45C534.861 343.033 536.153 344.325 537.736 344.325Z" fill="#C2C2C2"/>
<path d="M549.362 344.325C550.953 344.325 552.237 343.033 552.237 341.45C552.237 339.859 550.953 338.575 549.362 338.575C547.771 338.575 546.487 339.867 546.487 341.45C546.487 343.033 547.779 344.325 549.362 344.325Z" fill="#C2C2C2"/>
<path d="M560.996 344.325C562.587 344.325 563.871 343.033 563.871 341.45C563.871 339.859 562.587 338.575 560.996 338.575C559.405 338.575 558.121 339.867 558.121 341.45C558.113 343.033 559.405 344.325 560.996 344.325Z" fill="#C2C2C2"/>
<path d="M572.621 344.325C574.212 344.325 575.496 343.033 575.496 341.45C575.496 339.859 574.212 338.575 572.621 338.575C571.03 338.575 569.746 339.867 569.746 341.45C569.746 343.033 571.03 344.325 572.621 344.325Z" fill="#C2C2C2"/>
<path d="M584.247 344.325C585.838 344.325 587.122 343.033 587.122 341.45C587.122 339.859 585.83 338.575 584.247 338.575C582.656 338.575 581.372 339.867 581.372 341.45C581.372 343.033 582.664 344.325 584.247 344.325Z" fill="#C2C2C2"/>
<path d="M595.873 344.325C597.464 344.325 598.748 343.033 598.748 341.45C598.748 339.859 597.456 338.575 595.873 338.575C594.282 338.575 592.998 339.867 592.998 341.45C592.998 343.033 594.282 344.325 595.873 344.325Z" fill="#C2C2C2"/>
<path d="M607.506 344.325C609.097 344.325 610.381 343.033 610.381 341.45C610.381 339.859 609.09 338.575 607.506 338.575C605.915 338.575 604.631 339.867 604.631 341.45C604.624 343.033 605.915 344.325 607.506 344.325Z" fill="#C2C2C2"/>
<path d="M619.132 344.325C620.723 344.325 622.007 343.033 622.007 341.45C622.007 339.859 620.715 338.575 619.132 338.575C617.541 338.575 616.257 339.867 616.257 341.45C616.257 343.033 617.541 344.325 619.132 344.325Z" fill="#C2C2C2"/>
<path d="M630.758 344.325C632.349 344.325 633.633 343.033 633.633 341.45C633.633 339.859 632.341 338.575 630.758 338.575C629.167 338.575 627.883 339.867 627.883 341.45C627.883 343.033 629.167 344.325 630.758 344.325Z" fill="#C2C2C2"/>
<path d="M270.306 355.951C271.897 355.951 273.181 354.659 273.181 353.076C273.181 351.492 271.889 350.201 270.306 350.201C268.715 350.201 267.431 351.492 267.431 353.076C267.431 354.659 268.722 355.951 270.306 355.951Z" fill="#C2C2C2"/>
<path d="M281.939 355.951C283.53 355.951 284.814 354.659 284.814 353.076C284.814 351.492 283.522 350.201 281.939 350.201C280.348 350.201 279.064 351.492 279.064 353.076C279.064 354.659 280.348 355.951 281.939 355.951Z" fill="#C2C2C2"/>
<path d="M293.565 355.951C295.156 355.951 296.44 354.659 296.44 353.076C296.44 351.492 295.148 350.201 293.565 350.201C291.982 350.201 290.69 351.492 290.69 353.076C290.69 354.659 291.974 355.951 293.565 355.951Z" fill="#C2C2C2"/>
<path d="M467.974 355.951C469.565 355.951 470.857 354.659 470.857 353.076C470.857 351.492 469.565 350.201 467.974 350.201C466.383 350.201 465.099 351.492 465.099 353.076C465.099 354.659 466.383 355.951 467.974 355.951Z" fill="#C2C2C2"/>
<path d="M479.6 355.951C481.191 355.951 482.475 354.659 482.475 353.076C482.475 351.492 481.191 350.201 479.6 350.201C478.009 350.201 476.725 351.492 476.725 353.076C476.725 354.659 478.017 355.951 479.6 355.951Z" fill="#C2C2C2"/>
<path d="M491.225 355.951C492.817 355.951 494.1 354.659 494.1 353.076C494.1 351.492 492.817 350.201 491.225 350.201C489.634 350.201 488.351 351.492 488.351 353.076C488.351 354.659 489.642 355.951 491.225 355.951Z" fill="#C2C2C2"/>
<path d="M502.859 355.951C504.45 355.951 505.734 354.659 505.734 353.076C505.734 351.492 504.442 350.201 502.859 350.201C501.268 350.201 499.984 351.492 499.984 353.076C499.984 354.659 501.268 355.951 502.859 355.951Z" fill="#C2C2C2"/>
<path d="M514.485 355.951C516.076 355.951 517.36 354.659 517.36 353.076C517.36 351.492 516.076 350.201 514.485 350.201C512.894 350.201 511.61 351.492 511.61 353.076C511.61 354.659 512.894 355.951 514.485 355.951Z" fill="#C2C2C2"/>
<path d="M526.111 355.951C527.702 355.951 528.985 354.659 528.985 353.076C528.985 351.492 527.702 350.201 526.111 350.201C524.519 350.201 523.236 351.492 523.236 353.076C523.236 354.659 524.519 355.951 526.111 355.951Z" fill="#C2C2C2"/>
<path d="M537.736 355.951C539.327 355.951 540.611 354.659 540.611 353.076C540.611 351.492 539.327 350.201 537.736 350.201C536.145 350.201 534.861 351.492 534.861 353.076C534.861 354.659 536.153 355.951 537.736 355.951Z" fill="#C2C2C2"/>
<path d="M549.362 355.951C550.953 355.951 552.237 354.659 552.237 353.076C552.237 351.492 550.953 350.201 549.362 350.201C547.771 350.201 546.487 351.492 546.487 353.076C546.487 354.659 547.779 355.951 549.362 355.951Z" fill="#C2C2C2"/>
<path d="M560.996 355.951C562.587 355.951 563.87 354.659 563.87 353.076C563.87 351.492 562.587 350.201 560.996 350.201C559.404 350.201 558.121 351.492 558.121 353.076C558.121 354.659 559.404 355.951 560.996 355.951Z" fill="#C2C2C2"/>
<path d="M572.621 355.951C574.212 355.951 575.496 354.659 575.496 353.076C575.496 351.492 574.212 350.201 572.621 350.201C571.03 350.201 569.746 351.492 569.746 353.076C569.746 354.659 571.03 355.951 572.621 355.951Z" fill="#C2C2C2"/>
<path d="M584.247 355.951C585.838 355.951 587.122 354.659 587.122 353.076C587.122 351.492 585.83 350.201 584.247 350.201C582.656 350.201 581.372 351.492 581.372 353.076C581.372 354.659 582.664 355.951 584.247 355.951Z" fill="#C2C2C2"/>
<path d="M595.873 355.951C597.464 355.951 598.748 354.659 598.748 353.076C598.748 351.492 597.456 350.201 595.873 350.201C594.282 350.201 592.998 351.492 592.998 353.076C592.998 354.659 594.282 355.951 595.873 355.951Z" fill="#C2C2C2"/>
<path d="M607.506 355.951C609.097 355.951 610.381 354.659 610.381 353.076C610.381 351.492 609.089 350.201 607.506 350.201C605.915 350.201 604.631 351.492 604.631 353.076C604.631 354.659 605.915 355.951 607.506 355.951Z" fill="#C2C2C2"/>
<path d="M619.132 355.951C620.723 355.951 622.007 354.659 622.007 353.076C622.007 351.492 620.715 350.201 619.132 350.201C617.541 350.201 616.257 351.492 616.257 353.076C616.257 354.659 617.541 355.951 619.132 355.951Z" fill="#C2C2C2"/>
<path d="M630.758 355.951C632.349 355.951 633.633 354.659 633.633 353.076C633.633 351.492 632.341 350.201 630.758 350.201C629.167 350.201 627.883 351.492 627.883 353.076C627.883 354.659 629.167 355.951 630.758 355.951Z" fill="#C2C2C2"/>
<path d="M270.306 367.576C271.897 367.576 273.181 366.284 273.181 364.701C273.181 363.11 271.889 361.826 270.306 361.826C268.715 361.826 267.431 363.118 267.431 364.701C267.431 366.292 268.722 367.576 270.306 367.576Z" fill="#C2C2C2"/>
<path d="M281.939 367.576C283.53 367.576 284.814 366.284 284.814 364.701C284.814 363.11 283.522 361.826 281.939 361.826C280.348 361.826 279.064 363.118 279.064 364.701C279.056 366.292 280.348 367.576 281.939 367.576Z" fill="#C2C2C2"/>
<path d="M293.565 367.576C295.156 367.576 296.44 366.284 296.44 364.701C296.44 363.11 295.148 361.826 293.565 361.826C291.982 361.826 290.69 363.118 290.69 364.701C290.682 366.292 291.974 367.576 293.565 367.576Z" fill="#C2C2C2"/>
<path d="M467.974 367.576C469.565 367.576 470.857 366.284 470.857 364.701C470.857 363.11 469.565 361.826 467.974 361.826C466.383 361.826 465.099 363.118 465.099 364.701C465.099 366.292 466.383 367.576 467.974 367.576Z" fill="#C2C2C2"/>
<path d="M479.6 367.576C481.191 367.576 482.475 366.284 482.475 364.701C482.475 363.11 481.191 361.826 479.6 361.826C478.009 361.826 476.725 363.118 476.725 364.701C476.725 366.292 478.017 367.576 479.6 367.576Z" fill="#C2C2C2"/>
<path d="M491.225 367.576C492.817 367.576 494.1 366.284 494.1 364.701C494.1 363.11 492.817 361.826 491.225 361.826C489.634 361.826 488.351 363.118 488.351 364.701C488.351 366.292 489.642 367.576 491.225 367.576Z" fill="#C2C2C2"/>
<path d="M502.859 367.576C504.45 367.576 505.734 366.284 505.734 364.701C505.734 363.11 504.442 361.826 502.859 361.826C501.268 361.826 499.984 363.118 499.984 364.701C499.977 366.292 501.268 367.576 502.859 367.576Z" fill="#C2C2C2"/>
<path d="M514.485 367.576C516.076 367.576 517.36 366.284 517.36 364.701C517.36 363.11 516.076 361.826 514.485 361.826C512.894 361.826 511.61 363.118 511.61 364.701C511.61 366.292 512.894 367.576 514.485 367.576Z" fill="#C2C2C2"/>
<path d="M526.111 367.576C527.702 367.576 528.985 366.284 528.985 364.701C528.985 363.11 527.702 361.826 526.111 361.826C524.519 361.826 523.236 363.118 523.236 364.701C523.236 366.292 524.519 367.576 526.111 367.576Z" fill="#C2C2C2"/>
<path d="M537.736 367.576C539.327 367.576 540.611 366.284 540.611 364.701C540.611 363.11 539.327 361.826 537.736 361.826C536.145 361.826 534.861 363.118 534.861 364.701C534.861 366.292 536.153 367.576 537.736 367.576Z" fill="#C2C2C2"/>
<path d="M549.362 367.576C550.953 367.576 552.237 366.284 552.237 364.701C552.237 363.11 550.953 361.826 549.362 361.826C547.771 361.826 546.487 363.118 546.487 364.701C546.487 366.292 547.779 367.576 549.362 367.576Z" fill="#C2C2C2"/>
<path d="M560.996 367.576C562.587 367.576 563.871 366.284 563.871 364.701C563.871 363.11 562.587 361.826 560.996 361.826C559.405 361.826 558.121 363.118 558.121 364.701C558.113 366.292 559.405 367.576 560.996 367.576Z" fill="#C2C2C2"/>
<path d="M572.621 367.576C574.212 367.576 575.496 366.284 575.496 364.701C575.496 363.11 574.212 361.826 572.621 361.826C571.03 361.826 569.746 363.118 569.746 364.701C569.746 366.292 571.03 367.576 572.621 367.576Z" fill="#C2C2C2"/>
<path d="M584.247 367.576C585.838 367.576 587.122 366.284 587.122 364.701C587.122 363.11 585.83 361.826 584.247 361.826C582.656 361.826 581.372 363.118 581.372 364.701C581.372 366.292 582.664 367.576 584.247 367.576Z" fill="#C2C2C2"/>
<path d="M595.873 367.576C597.464 367.576 598.748 366.284 598.748 364.701C598.748 363.11 597.456 361.826 595.873 361.826C594.282 361.826 592.998 363.118 592.998 364.701C592.998 366.292 594.282 367.576 595.873 367.576Z" fill="#C2C2C2"/>
<path d="M607.506 367.576C609.097 367.576 610.381 366.284 610.381 364.701C610.381 363.11 609.09 361.826 607.506 361.826C605.915 361.826 604.631 363.118 604.631 364.701C604.624 366.292 605.915 367.576 607.506 367.576Z" fill="#C2C2C2"/>
<path d="M619.132 367.576C620.723 367.576 622.007 366.284 622.007 364.701C622.007 363.11 620.715 361.826 619.132 361.826C617.541 361.826 616.257 363.118 616.257 364.701C616.257 366.292 617.541 367.576 619.132 367.576Z" fill="#C2C2C2"/>
<path d="M270.306 379.202C271.897 379.202 273.181 377.91 273.181 376.327C273.181 374.736 271.889 373.452 270.306 373.452C268.715 373.452 267.431 374.744 267.431 376.327C267.431 377.918 268.722 379.202 270.306 379.202Z" fill="#C2C2C2"/>
<path d="M281.939 379.202C283.53 379.202 284.814 377.91 284.814 376.327C284.814 374.736 283.522 373.452 281.939 373.452C280.348 373.452 279.064 374.744 279.064 376.327C279.056 377.918 280.348 379.202 281.939 379.202Z" fill="#C2C2C2"/>
<path d="M293.565 379.202C295.156 379.202 296.44 377.91 296.44 376.327C296.44 374.736 295.148 373.452 293.565 373.452C291.982 373.452 290.69 374.744 290.69 376.327C290.682 377.918 291.974 379.202 293.565 379.202Z" fill="#C2C2C2"/>
<path d="M467.974 379.202C469.565 379.202 470.857 377.91 470.857 376.327C470.857 374.736 469.565 373.452 467.974 373.452C466.383 373.452 465.099 374.744 465.099 376.327C465.099 377.918 466.383 379.202 467.974 379.202Z" fill="#C2C2C2"/>
<path d="M479.6 379.202C481.188 379.202 482.475 377.915 482.475 376.327C482.475 374.739 481.188 373.452 479.6 373.452C478.012 373.452 476.725 374.739 476.725 376.327C476.725 377.915 478.012 379.202 479.6 379.202Z" fill="#C2C2C2"/>
<path d="M491.225 379.202C492.813 379.202 494.1 377.915 494.1 376.327C494.1 374.739 492.813 373.452 491.225 373.452C489.638 373.452 488.351 374.739 488.351 376.327C488.351 377.915 489.638 379.202 491.225 379.202Z" fill="#C2C2C2"/>
<path d="M502.859 379.202C504.45 379.202 505.734 377.91 505.734 376.327C505.734 374.736 504.442 373.452 502.859 373.452C501.268 373.452 499.984 374.744 499.984 376.327C499.977 377.918 501.268 379.202 502.859 379.202Z" fill="#C2C2C2"/>
<path d="M514.485 379.202C516.073 379.202 517.36 377.915 517.36 376.327C517.36 374.739 516.073 373.452 514.485 373.452C512.897 373.452 511.61 374.739 511.61 376.327C511.61 377.915 512.897 379.202 514.485 379.202Z" fill="#C2C2C2"/>
<path d="M526.111 379.202C527.702 379.202 528.985 377.91 528.985 376.327C528.985 374.736 527.702 373.452 526.111 373.452C524.519 373.452 523.236 374.744 523.236 376.327C523.236 377.918 524.519 379.202 526.111 379.202Z" fill="#C2C2C2"/>
<path d="M537.736 379.202C539.327 379.202 540.611 377.91 540.611 376.327C540.611 374.736 539.327 373.452 537.736 373.452C536.145 373.452 534.861 374.744 534.861 376.327C534.861 377.918 536.153 379.202 537.736 379.202Z" fill="#C2C2C2"/>
<path d="M549.362 379.202C550.953 379.202 552.237 377.91 552.237 376.327C552.237 374.736 550.953 373.452 549.362 373.452C547.771 373.452 546.487 374.744 546.487 376.327C546.487 377.918 547.779 379.202 549.362 379.202Z" fill="#C2C2C2"/>
<path d="M560.996 379.202C562.587 379.202 563.871 377.91 563.871 376.327C563.871 374.736 562.587 373.452 560.996 373.452C559.405 373.452 558.121 374.744 558.121 376.327C558.113 377.918 559.405 379.202 560.996 379.202Z" fill="#C2C2C2"/>
<path d="M572.621 379.202C574.212 379.202 575.496 377.91 575.496 376.327C575.496 374.736 574.212 373.452 572.621 373.452C571.03 373.452 569.746 374.744 569.746 376.327C569.746 377.918 571.03 379.202 572.621 379.202Z" fill="#C2C2C2"/>
<path d="M584.247 379.202C585.838 379.202 587.122 377.91 587.122 376.327C587.122 374.736 585.83 373.452 584.247 373.452C582.656 373.452 581.372 374.744 581.372 376.327C581.372 377.918 582.664 379.202 584.247 379.202Z" fill="#C2C2C2"/>
<path d="M270.306 390.836C271.897 390.836 273.181 389.544 273.181 387.961C273.181 386.378 271.889 385.086 270.306 385.086C268.715 385.086 267.431 386.378 267.431 387.961C267.431 389.544 268.722 390.836 270.306 390.836Z" fill="#C2C2C2"/>
<path d="M281.939 390.836C283.53 390.836 284.814 389.544 284.814 387.961C284.814 386.378 283.522 385.086 281.939 385.086C280.348 385.086 279.064 386.378 279.064 387.961C279.064 389.544 280.348 390.836 281.939 390.836Z" fill="#C2C2C2"/>
<path d="M293.565 390.836C295.156 390.836 296.44 389.544 296.44 387.961C296.44 386.378 295.148 385.086 293.565 385.086C291.982 385.086 290.69 386.378 290.69 387.961C290.69 389.544 291.974 390.836 293.565 390.836Z" fill="#C2C2C2"/>
<path d="M467.974 390.836C469.565 390.836 470.857 389.544 470.857 387.961C470.857 386.378 469.565 385.086 467.974 385.086C466.383 385.086 465.099 386.378 465.099 387.961C465.099 389.544 466.383 390.836 467.974 390.836Z" fill="#C2C2C2"/>
<path d="M479.6 390.827C481.188 390.827 482.475 389.54 482.475 387.953C482.475 386.365 481.188 385.078 479.6 385.078C478.012 385.078 476.725 386.365 476.725 387.953C476.725 389.54 478.012 390.827 479.6 390.827Z" fill="#C2C2C2"/>
<path d="M491.225 390.827C492.813 390.827 494.1 389.54 494.1 387.953C494.1 386.365 492.813 385.078 491.225 385.078C489.638 385.078 488.351 386.365 488.351 387.953C488.351 389.54 489.638 390.827 491.225 390.827Z" fill="#C2C2C2"/>
<path d="M502.859 390.836C504.45 390.836 505.734 389.544 505.734 387.961C505.734 386.378 504.442 385.086 502.859 385.086C501.268 385.086 499.984 386.378 499.984 387.961C499.984 389.544 501.268 390.836 502.859 390.836Z" fill="#C2C2C2"/>
<path d="M514.485 390.827C516.073 390.827 517.36 389.54 517.36 387.953C517.36 386.365 516.073 385.078 514.485 385.078C512.897 385.078 511.61 386.365 511.61 387.953C511.61 389.54 512.897 390.827 514.485 390.827Z" fill="#C2C2C2"/>
<path d="M526.111 390.836C527.702 390.836 528.985 389.544 528.985 387.961C528.985 386.378 527.702 385.086 526.111 385.086C524.519 385.086 523.236 386.378 523.236 387.961C523.236 389.544 524.519 390.836 526.111 390.836Z" fill="#C2C2C2"/>
<path d="M281.939 402.461C283.53 402.461 284.814 401.169 284.814 399.586C284.814 397.995 283.522 396.711 281.939 396.711C280.348 396.711 279.064 398.003 279.064 399.586C279.056 401.169 280.348 402.461 281.939 402.461Z" fill="#C2C2C2"/>
<path d="M293.565 402.461C295.156 402.461 296.44 401.169 296.44 399.586C296.44 397.995 295.148 396.711 293.565 396.711C291.982 396.711 290.69 398.003 290.69 399.586C290.682 401.169 291.974 402.461 293.565 402.461Z" fill="#C2C2C2"/>
<path d="M456.837 65.2684C458.428 65.2684 459.72 63.9767 459.72 62.3935C459.72 60.8024 458.428 59.5186 456.837 59.5186C455.246 59.5186 453.962 60.8103 453.962 62.3935C453.962 63.9767 455.254 65.2684 456.837 65.2684Z" fill="#C2C2C2"/>
<path d="M444.723 76.8939C446.314 76.8939 447.605 75.6021 447.605 74.019C447.605 72.4279 446.314 71.144 444.723 71.144C443.132 71.144 441.848 72.4358 441.848 74.019C441.84 75.6021 443.132 76.8939 444.723 76.8939Z" fill="#C2C2C2"/>
<path d="M444.723 65.2684C446.314 65.2684 447.605 63.9767 447.605 62.3935C447.605 60.8024 446.314 59.5186 444.723 59.5186C443.132 59.5186 441.848 60.8103 441.848 62.3935C441.84 63.9767 443.132 65.2684 444.723 65.2684Z" fill="#C2C2C2"/>
<path d="M432.601 76.8939C434.192 76.8939 435.484 75.6021 435.484 74.019C435.484 72.4279 434.192 71.144 432.601 71.144C431.01 71.144 429.726 72.4358 429.726 74.019C429.718 75.6021 431.01 76.8939 432.601 76.8939Z" fill="#C2C2C2"/>
<path d="M456.348 76.8939C457.939 76.8939 459.231 75.6021 459.231 74.019C459.231 72.4279 457.939 71.144 456.348 71.144C454.757 71.144 453.473 72.4358 453.473 74.019C453.466 75.6021 454.757 76.8939 456.348 76.8939Z" fill="#C2C2C2"/>
<path d="M316.816 88.5199C318.407 88.5199 319.691 87.2281 319.691 85.6449C319.691 84.0539 318.4 82.77 316.816 82.77C315.225 82.77 313.941 84.0618 313.941 85.6449C313.941 87.2281 315.225 88.5199 316.816 88.5199Z" fill="#C2C2C2"/>
<path d="M328.442 88.5199C330.033 88.5199 331.317 87.2281 331.317 85.6449C331.317 84.0539 330.025 82.77 328.442 82.77C326.851 82.77 325.567 84.0618 325.567 85.6449C325.567 87.2281 326.859 88.5199 328.442 88.5199Z" fill="#C2C2C2"/>
<path d="M340.076 88.5199C341.667 88.5199 342.951 87.2281 342.951 85.6449C342.951 84.0539 341.659 82.77 340.076 82.77C338.485 82.77 337.201 84.0618 337.201 85.6449C337.193 87.2281 338.485 88.5199 340.076 88.5199Z" fill="#C2C2C2"/>
<path d="M351.701 88.5199C353.292 88.5199 354.576 87.2281 354.576 85.6449C354.576 84.0539 353.285 82.77 351.701 82.77C350.11 82.77 348.826 84.0618 348.826 85.6449C348.826 87.2281 350.11 88.5199 351.701 88.5199Z" fill="#C2C2C2"/>
<path d="M363.327 88.5199C364.918 88.5199 366.202 87.2281 366.202 85.6449C366.202 84.0539 364.91 82.77 363.327 82.77C361.736 82.77 360.444 84.0618 360.444 85.6449C360.452 87.2281 361.736 88.5199 363.327 88.5199Z" fill="#C2C2C2"/>
<path d="M374.953 88.5199C376.544 88.5199 377.828 87.2281 377.828 85.6449C377.828 84.0539 376.536 82.77 374.953 82.77C373.362 82.77 372.07 84.0618 372.07 85.6449C372.078 87.2281 373.369 88.5199 374.953 88.5199Z" fill="#C2C2C2"/>
<path d="M456.348 88.5199C457.939 88.5199 459.231 87.2281 459.231 85.6449C459.231 84.0539 457.939 82.77 456.348 82.77C454.757 82.77 453.473 84.0618 453.473 85.6449C453.466 87.2281 454.757 88.5199 456.348 88.5199Z" fill="#C2C2C2"/>
<path d="M305.191 100.145C306.782 100.145 308.066 98.8536 308.066 97.2704C308.066 95.6794 306.774 94.3955 305.191 94.3955C303.6 94.3955 302.316 95.6873 302.316 97.2704C302.316 98.8615 303.6 100.145 305.191 100.145Z" fill="#C2C2C2"/>
<path d="M316.816 100.145C318.407 100.145 319.691 98.8536 319.691 97.2704C319.691 95.6794 318.4 94.3955 316.816 94.3955C315.225 94.3955 313.941 95.6873 313.941 97.2704C313.941 98.8615 315.225 100.145 316.816 100.145Z" fill="#C2C2C2"/>
<path d="M328.442 100.145C330.033 100.145 331.317 98.8536 331.317 97.2704C331.317 95.6794 330.025 94.3955 328.442 94.3955C326.851 94.3955 325.567 95.6873 325.567 97.2704C325.567 98.8615 326.859 100.145 328.442 100.145Z" fill="#C2C2C2"/>
<path d="M340.076 100.145C341.667 100.145 342.951 98.8536 342.951 97.2704C342.951 95.6794 341.659 94.3955 340.076 94.3955C338.485 94.3955 337.201 95.6873 337.201 97.2704C337.193 98.8615 338.485 100.145 340.076 100.145Z" fill="#C2C2C2"/>
<path d="M351.701 100.145C353.292 100.145 354.576 98.8536 354.576 97.2704C354.576 95.6794 353.285 94.3955 351.701 94.3955C350.11 94.3955 348.826 95.6873 348.826 97.2704C348.826 98.8615 350.11 100.145 351.701 100.145Z" fill="#C2C2C2"/>
<path d="M363.327 100.145C364.918 100.145 366.202 98.8536 366.202 97.2704C366.202 95.6794 364.91 94.3955 363.327 94.3955C361.736 94.3955 360.444 95.6873 360.444 97.2704C360.452 98.8615 361.736 100.145 363.327 100.145Z" fill="#C2C2C2"/>
<path d="M374.953 100.145C376.544 100.145 377.828 98.8536 377.828 97.2704C377.828 95.6794 376.536 94.3955 374.953 94.3955C373.362 94.3955 372.07 95.6873 372.07 97.2704C372.078 98.8615 373.369 100.145 374.953 100.145Z" fill="#C2C2C2"/>
<path d="M386.586 100.145C388.177 100.145 389.461 98.8536 389.461 97.2704C389.461 95.6794 388.17 94.3955 386.586 94.3955C384.995 94.3955 383.704 95.6873 383.704 97.2704C383.704 98.8615 384.995 100.145 386.586 100.145Z" fill="#C2C2C2"/>
<path d="M433.089 100.145C434.68 100.145 435.972 98.8536 435.972 97.2704C435.972 95.6794 434.68 94.3955 433.089 94.3955C431.498 94.3955 430.214 95.6873 430.214 97.2704C430.214 98.8615 431.506 100.145 433.089 100.145Z" fill="#C2C2C2"/>
<path d="M444.723 100.145C446.314 100.145 447.605 98.8536 447.605 97.2704C447.605 95.6794 446.314 94.3955 444.723 94.3955C443.132 94.3955 441.848 95.6873 441.848 97.2704C441.84 98.8615 443.132 100.145 444.723 100.145Z" fill="#C2C2C2"/>
<path d="M456.348 100.145C457.939 100.145 459.231 98.8536 459.231 97.2704C459.231 95.6794 457.939 94.3955 456.348 94.3955C454.757 94.3955 453.473 95.6873 453.473 97.2704C453.466 98.8615 454.757 100.145 456.348 100.145Z" fill="#C2C2C2"/>
<path d="M305.191 111.771C306.782 111.771 308.066 110.48 308.066 108.896C308.066 107.305 306.774 106.021 305.191 106.021C303.6 106.021 302.316 107.313 302.316 108.896C302.316 110.487 303.6 111.771 305.191 111.771Z" fill="#C2C2C2"/>
<path d="M316.816 111.771C318.407 111.771 319.691 110.48 319.691 108.896C319.691 107.305 318.4 106.021 316.816 106.021C315.225 106.021 313.941 107.313 313.941 108.896C313.941 110.487 315.225 111.771 316.816 111.771Z" fill="#C2C2C2"/>
<path d="M328.442 111.771C330.033 111.771 331.317 110.48 331.317 108.896C331.317 107.305 330.025 106.021 328.442 106.021C326.851 106.021 325.567 107.313 325.567 108.896C325.567 110.487 326.859 111.771 328.442 111.771Z" fill="#C2C2C2"/>
<path d="M340.076 111.771C341.667 111.771 342.951 110.48 342.951 108.896C342.951 107.305 341.659 106.021 340.076 106.021C338.485 106.021 337.201 107.313 337.201 108.896C337.193 110.487 338.485 111.771 340.076 111.771Z" fill="#C2C2C2"/>
<path d="M351.701 111.771C353.292 111.771 354.576 110.48 354.576 108.896C354.576 107.305 353.285 106.021 351.701 106.021C350.11 106.021 348.826 107.313 348.826 108.896C348.826 110.487 350.11 111.771 351.701 111.771Z" fill="#C2C2C2"/>
<path d="M363.327 111.771C364.918 111.771 366.202 110.48 366.202 108.896C366.202 107.305 364.91 106.021 363.327 106.021C361.736 106.021 360.444 107.313 360.444 108.896C360.452 110.487 361.736 111.771 363.327 111.771Z" fill="#C2C2C2"/>
<path d="M374.953 111.771C376.544 111.771 377.828 110.48 377.828 108.896C377.828 107.305 376.536 106.021 374.953 106.021C373.362 106.021 372.07 107.313 372.07 108.896C372.078 110.487 373.369 111.771 374.953 111.771Z" fill="#C2C2C2"/>
<path d="M386.586 111.771C388.177 111.771 389.461 110.48 389.461 108.896C389.461 107.305 388.17 106.021 386.586 106.021C384.995 106.021 383.704 107.313 383.704 108.896C383.704 110.487 384.995 111.771 386.586 111.771Z" fill="#C2C2C2"/>
<path d="M398.212 111.771C399.803 111.771 401.087 110.48 401.087 108.896C401.087 107.305 399.795 106.021 398.212 106.021C396.621 106.021 395.329 107.313 395.329 108.896C395.329 110.487 396.621 111.771 398.212 111.771Z" fill="#C2C2C2"/>
<path d="M409.838 111.771C411.429 111.771 412.713 110.48 412.713 108.896C412.713 107.305 411.421 106.021 409.838 106.021C408.247 106.021 406.955 107.313 406.955 108.896C406.955 110.487 408.247 111.771 409.838 111.771Z" fill="#C2C2C2"/>
<path d="M421.464 111.771C423.055 111.771 424.346 110.48 424.346 108.896C424.346 107.305 423.055 106.021 421.464 106.021C419.873 106.021 418.589 107.313 418.589 108.896C418.589 110.487 419.873 111.771 421.464 111.771Z" fill="#C2C2C2"/>
<path d="M433.089 111.771C434.68 111.771 435.972 110.48 435.972 108.896C435.972 107.305 434.68 106.021 433.089 106.021C431.498 106.021 430.214 107.313 430.214 108.896C430.214 110.487 431.506 111.771 433.089 111.771Z" fill="#C2C2C2"/>
<path d="M444.723 111.771C446.314 111.771 447.605 110.48 447.605 108.896C447.605 107.305 446.314 106.021 444.723 106.021C443.132 106.021 441.848 107.313 441.848 108.896C441.84 110.487 443.132 111.771 444.723 111.771Z" fill="#C2C2C2"/>
<path d="M456.348 111.771C457.939 111.771 459.231 110.48 459.231 108.896C459.231 107.305 457.939 106.021 456.348 106.021C454.757 106.021 453.473 107.313 453.473 108.896C453.466 110.487 454.757 111.771 456.348 111.771Z" fill="#C2C2C2"/>
<path d="M305.191 123.405C306.782 123.405 308.066 122.113 308.066 120.53C308.066 118.947 306.774 117.655 305.191 117.655C303.6 117.655 302.316 118.947 302.316 120.53C302.316 122.113 303.6 123.405 305.191 123.405Z" fill="#C2C2C2"/>
<path d="M316.816 123.405C318.407 123.405 319.691 122.113 319.691 120.53C319.691 118.947 318.4 117.655 316.816 117.655C315.225 117.655 313.941 118.947 313.941 120.53C313.941 122.113 315.225 123.405 316.816 123.405Z" fill="#C2C2C2"/>
<path d="M328.442 123.405C330.033 123.405 331.317 122.113 331.317 120.53C331.317 118.947 330.025 117.655 328.442 117.655C326.851 117.655 325.567 118.947 325.567 120.53C325.567 122.113 326.859 123.405 328.442 123.405Z" fill="#C2C2C2"/>
<path d="M340.076 123.405C341.667 123.405 342.951 122.113 342.951 120.53C342.951 118.947 341.659 117.655 340.076 117.655C338.485 117.655 337.201 118.947 337.201 120.53C337.201 122.113 338.485 123.405 340.076 123.405Z" fill="#C2C2C2"/>
<path d="M351.701 123.405C353.292 123.405 354.576 122.113 354.576 120.53C354.576 118.947 353.285 117.655 351.701 117.655C350.11 117.655 348.826 118.947 348.826 120.53C348.826 122.113 350.11 123.405 351.701 123.405Z" fill="#C2C2C2"/>
<path d="M363.327 123.405C364.918 123.405 366.202 122.113 366.202 120.53C366.202 118.947 364.91 117.655 363.327 117.655C361.736 117.655 360.444 118.947 360.444 120.53C360.444 122.113 361.736 123.405 363.327 123.405Z" fill="#C2C2C2"/>
<path d="M374.953 123.405C376.544 123.405 377.828 122.113 377.828 120.53C377.828 118.947 376.536 117.655 374.953 117.655C373.362 117.655 372.07 118.947 372.07 120.53C372.07 122.113 373.369 123.405 374.953 123.405Z" fill="#C2C2C2"/>
<path d="M386.586 123.405C388.177 123.405 389.461 122.113 389.461 120.53C389.461 118.947 388.17 117.655 386.586 117.655C384.995 117.655 383.704 118.947 383.704 120.53C383.704 122.113 384.995 123.405 386.586 123.405Z" fill="#C2C2C2"/>
<path d="M398.212 123.405C399.803 123.405 401.087 122.113 401.087 120.53C401.087 118.947 399.795 117.655 398.212 117.655C396.621 117.655 395.329 118.947 395.329 120.53C395.329 122.113 396.621 123.405 398.212 123.405Z" fill="#C2C2C2"/>
<path d="M409.838 123.405C411.429 123.405 412.713 122.113 412.713 120.53C412.713 118.947 411.421 117.655 409.838 117.655C408.247 117.655 406.955 118.947 406.955 120.53C406.955 122.113 408.247 123.405 409.838 123.405Z" fill="#C2C2C2"/>
<path d="M421.464 123.405C423.055 123.405 424.346 122.113 424.346 120.53C424.346 118.947 423.055 117.655 421.464 117.655C419.873 117.655 418.589 118.947 418.589 120.53C418.589 122.113 419.873 123.405 421.464 123.405Z" fill="#C2C2C2"/>
<path d="M433.089 123.405C434.68 123.405 435.972 122.113 435.972 120.53C435.972 118.947 434.68 117.655 433.089 117.655C431.498 117.655 430.214 118.947 430.214 120.53C430.214 122.113 431.506 123.405 433.089 123.405Z" fill="#C2C2C2"/>
<path d="M444.723 123.405C446.314 123.405 447.606 122.113 447.606 120.53C447.606 118.947 446.314 117.655 444.723 117.655C443.132 117.655 441.848 118.947 441.848 120.53C441.848 122.113 443.132 123.405 444.723 123.405Z" fill="#C2C2C2"/>
<path d="M456.349 123.405C457.94 123.405 459.231 122.113 459.231 120.53C459.231 118.947 457.94 117.655 456.349 117.655C454.758 117.655 453.474 118.947 453.474 120.53C453.474 122.113 454.758 123.405 456.349 123.405Z" fill="#C2C2C2"/>
<path d="M305.191 135.031C306.782 135.031 308.066 133.739 308.066 132.156C308.066 130.573 306.774 129.281 305.191 129.281C303.6 129.281 302.316 130.573 302.316 132.156C302.316 133.739 303.6 135.031 305.191 135.031Z" fill="#C2C2C2"/>
<path d="M316.816 135.031C318.407 135.031 319.691 133.739 319.691 132.156C319.691 130.573 318.4 129.281 316.816 129.281C315.225 129.281 313.941 130.573 313.941 132.156C313.941 133.739 315.225 135.031 316.816 135.031Z" fill="#C2C2C2"/>
<path d="M328.442 135.031C330.033 135.031 331.317 133.739 331.317 132.156C331.317 130.573 330.025 129.281 328.442 129.281C326.851 129.281 325.567 130.573 325.567 132.156C325.567 133.739 326.859 135.031 328.442 135.031Z" fill="#C2C2C2"/>
<path d="M340.076 135.031C341.667 135.031 342.951 133.739 342.951 132.156C342.951 130.573 341.659 129.281 340.076 129.281C338.485 129.281 337.201 130.573 337.201 132.156C337.201 133.739 338.485 135.031 340.076 135.031Z" fill="#C2C2C2"/>
<path d="M351.701 135.031C353.292 135.031 354.576 133.739 354.576 132.156C354.576 130.573 353.285 129.281 351.701 129.281C350.11 129.281 348.826 130.573 348.826 132.156C348.826 133.739 350.11 135.031 351.701 135.031Z" fill="#C2C2C2"/>
<path d="M363.327 135.031C364.918 135.031 366.202 133.739 366.202 132.156C366.202 130.573 364.91 129.281 363.327 129.281C361.736 129.281 360.444 130.573 360.444 132.156C360.444 133.739 361.736 135.031 363.327 135.031Z" fill="#C2C2C2"/>
<path d="M374.953 135.031C376.544 135.031 377.828 133.739 377.828 132.156C377.828 130.573 376.536 129.281 374.953 129.281C373.362 129.281 372.07 130.573 372.07 132.156C372.07 133.739 373.369 135.031 374.953 135.031Z" fill="#C2C2C2"/>
<path d="M386.586 135.031C388.177 135.031 389.461 133.739 389.461 132.156C389.461 130.573 388.17 129.281 386.586 129.281C384.995 129.281 383.704 130.573 383.704 132.156C383.704 133.739 384.995 135.031 386.586 135.031Z" fill="#C2C2C2"/>
<path d="M398.212 135.031C399.803 135.031 401.087 133.739 401.087 132.156C401.087 130.573 399.795 129.281 398.212 129.281C396.621 129.281 395.329 130.573 395.329 132.156C395.329 133.739 396.621 135.031 398.212 135.031Z" fill="#C2C2C2"/>
<path d="M409.838 135.031C411.429 135.031 412.713 133.739 412.713 132.156C412.713 130.573 411.421 129.281 409.838 129.281C408.247 129.281 406.955 130.573 406.955 132.156C406.955 133.739 408.247 135.031 409.838 135.031Z" fill="#C2C2C2"/>
<path d="M421.464 135.031C423.055 135.031 424.346 133.739 424.346 132.156C424.346 130.573 423.055 129.281 421.464 129.281C419.873 129.281 418.589 130.573 418.589 132.156C418.589 133.739 419.873 135.031 421.464 135.031Z" fill="#C2C2C2"/>
<path d="M433.089 135.031C434.68 135.031 435.972 133.739 435.972 132.156C435.972 130.573 434.68 129.281 433.089 129.281C431.498 129.281 430.214 130.573 430.214 132.156C430.214 133.739 431.506 135.031 433.089 135.031Z" fill="#C2C2C2"/>
<path d="M444.723 135.031C446.314 135.031 447.606 133.739 447.606 132.156C447.606 130.573 446.314 129.281 444.723 129.281C443.132 129.281 441.848 130.573 441.848 132.156C441.848 133.739 443.132 135.031 444.723 135.031Z" fill="#C2C2C2"/>
<path d="M456.349 135.031C457.94 135.031 459.231 133.739 459.231 132.156C459.231 130.573 457.94 129.281 456.349 129.281C454.758 129.281 453.474 130.573 453.474 132.156C453.474 133.739 454.758 135.031 456.349 135.031Z" fill="#C2C2C2"/>
<path d="M305.191 146.656C306.782 146.656 308.066 145.364 308.066 143.781C308.066 142.19 306.774 140.906 305.191 140.906C303.6 140.906 302.316 142.198 302.316 143.781C302.316 145.372 303.6 146.656 305.191 146.656Z" fill="#C2C2C2"/>
<path d="M316.816 146.656C318.407 146.656 319.691 145.364 319.691 143.781C319.691 142.19 318.4 140.906 316.816 140.906C315.225 140.906 313.941 142.198 313.941 143.781C313.941 145.372 315.225 146.656 316.816 146.656Z" fill="#C2C2C2"/>
<path d="M328.442 146.656C330.033 146.656 331.317 145.364 331.317 143.781C331.317 142.19 330.025 140.906 328.442 140.906C326.851 140.906 325.567 142.198 325.567 143.781C325.567 145.372 326.859 146.656 328.442 146.656Z" fill="#C2C2C2"/>
<path d="M340.076 146.656C341.667 146.656 342.951 145.364 342.951 143.781C342.951 142.19 341.659 140.906 340.076 140.906C338.485 140.906 337.201 142.198 337.201 143.781C337.193 145.372 338.485 146.656 340.076 146.656Z" fill="#C2C2C2"/>
<path d="M351.701 146.656C353.292 146.656 354.576 145.364 354.576 143.781C354.576 142.19 353.285 140.906 351.701 140.906C350.11 140.906 348.826 142.198 348.826 143.781C348.826 145.372 350.11 146.656 351.701 146.656Z" fill="#C2C2C2"/>
<path d="M363.327 146.656C364.918 146.656 366.202 145.364 366.202 143.781C366.202 142.19 364.91 140.906 363.327 140.906C361.736 140.906 360.444 142.198 360.444 143.781C360.452 145.372 361.736 146.656 363.327 146.656Z" fill="#C2C2C2"/>
<path d="M374.953 146.656C376.544 146.656 377.828 145.364 377.828 143.781C377.828 142.19 376.536 140.906 374.953 140.906C373.362 140.906 372.07 142.198 372.07 143.781C372.078 145.372 373.369 146.656 374.953 146.656Z" fill="#C2C2C2"/>
<path d="M386.586 146.656C388.177 146.656 389.461 145.364 389.461 143.781C389.461 142.19 388.17 140.906 386.586 140.906C384.995 140.906 383.704 142.198 383.704 143.781C383.704 145.372 384.995 146.656 386.586 146.656Z" fill="#C2C2C2"/>
<path d="M398.212 146.656C399.803 146.656 401.087 145.364 401.087 143.781C401.087 142.19 399.795 140.906 398.212 140.906C396.621 140.906 395.329 142.198 395.329 143.781C395.329 145.372 396.621 146.656 398.212 146.656Z" fill="#C2C2C2"/>
<path d="M409.838 146.656C411.429 146.656 412.713 145.364 412.713 143.781C412.713 142.19 411.421 140.906 409.838 140.906C408.247 140.906 406.955 142.198 406.955 143.781C406.955 145.372 408.247 146.656 409.838 146.656Z" fill="#C2C2C2"/>
<path d="M421.464 146.656C423.055 146.656 424.346 145.364 424.346 143.781C424.346 142.19 423.055 140.906 421.464 140.906C419.873 140.906 418.589 142.198 418.589 143.781C418.589 145.372 419.873 146.656 421.464 146.656Z" fill="#C2C2C2"/>
<path d="M433.089 146.656C434.68 146.656 435.972 145.364 435.972 143.781C435.972 142.19 434.68 140.906 433.089 140.906C431.498 140.906 430.214 142.198 430.214 143.781C430.214 145.372 431.506 146.656 433.089 146.656Z" fill="#C2C2C2"/>
<path d="M444.723 146.656C446.314 146.656 447.605 145.364 447.605 143.781C447.605 142.19 446.314 140.906 444.723 140.906C443.132 140.906 441.848 142.198 441.848 143.781C441.84 145.372 443.132 146.656 444.723 146.656Z" fill="#C2C2C2"/>
<path d="M456.348 146.656C457.939 146.656 459.231 145.364 459.231 143.781C459.231 142.19 457.939 140.906 456.348 140.906C454.757 140.906 453.473 142.198 453.473 143.781C453.466 145.372 454.757 146.656 456.348 146.656Z" fill="#C2C2C2"/>
<path d="M305.191 158.282C306.782 158.282 308.066 156.99 308.066 155.407C308.066 153.824 306.774 152.532 305.191 152.532C303.6 152.532 302.316 153.824 302.316 155.407C302.316 156.99 303.6 158.282 305.191 158.282Z" fill="#C2C2C2"/>
<path d="M316.816 158.282C318.407 158.282 319.691 156.99 319.691 155.407C319.691 153.824 318.4 152.532 316.816 152.532C315.225 152.532 313.941 153.824 313.941 155.407C313.941 156.99 315.225 158.282 316.816 158.282Z" fill="#C2C2C2"/>
<path d="M328.442 158.282C330.033 158.282 331.317 156.99 331.317 155.407C331.317 153.824 330.025 152.532 328.442 152.532C326.851 152.532 325.567 153.824 325.567 155.407C325.567 156.99 326.859 158.282 328.442 158.282Z" fill="#C2C2C2"/>
<path d="M340.076 158.282C341.667 158.282 342.951 156.99 342.951 155.407C342.951 153.824 341.659 152.532 340.076 152.532C338.485 152.532 337.201 153.824 337.201 155.407C337.201 156.99 338.485 158.282 340.076 158.282Z" fill="#C2C2C2"/>
<path d="M351.701 158.282C353.292 158.282 354.576 156.99 354.576 155.407C354.576 153.824 353.285 152.532 351.701 152.532C350.11 152.532 348.826 153.824 348.826 155.407C348.826 156.99 350.11 158.282 351.701 158.282Z" fill="#C2C2C2"/>
<path d="M363.327 158.282C364.918 158.282 366.202 156.99 366.202 155.407C366.202 153.824 364.91 152.532 363.327 152.532C361.736 152.532 360.444 153.824 360.444 155.407C360.444 156.99 361.736 158.282 363.327 158.282Z" fill="#C2C2C2"/>
<path d="M374.953 158.282C376.544 158.282 377.828 156.99 377.828 155.407C377.828 153.824 376.536 152.532 374.953 152.532C373.362 152.532 372.07 153.824 372.07 155.407C372.07 156.99 373.369 158.282 374.953 158.282Z" fill="#C2C2C2"/>
<path d="M386.586 158.282C388.177 158.282 389.461 156.99 389.461 155.407C389.461 153.824 388.17 152.532 386.586 152.532C384.995 152.532 383.704 153.824 383.704 155.407C383.704 156.99 384.995 158.282 386.586 158.282Z" fill="#C2C2C2"/>
<path d="M398.212 158.282C399.803 158.282 401.087 156.99 401.087 155.407C401.087 153.824 399.795 152.532 398.212 152.532C396.621 152.532 395.329 153.824 395.329 155.407C395.329 156.99 396.621 158.282 398.212 158.282Z" fill="#C2C2C2"/>
<path d="M409.838 158.282C411.429 158.282 412.713 156.99 412.713 155.407C412.713 153.824 411.421 152.532 409.838 152.532C408.247 152.532 406.955 153.824 406.955 155.407C406.955 156.99 408.247 158.282 409.838 158.282Z" fill="#C2C2C2"/>
<path d="M421.464 158.282C423.055 158.282 424.346 156.99 424.346 155.407C424.346 153.824 423.055 152.532 421.464 152.532C419.873 152.532 418.589 153.824 418.589 155.407C418.589 156.99 419.873 158.282 421.464 158.282Z" fill="#C2C2C2"/>
<path d="M433.089 158.282C434.68 158.282 435.972 156.99 435.972 155.407C435.972 153.824 434.68 152.532 433.089 152.532C431.498 152.532 430.214 153.824 430.214 155.407C430.214 156.99 431.506 158.282 433.089 158.282Z" fill="#C2C2C2"/>
<path d="M444.723 158.282C446.314 158.282 447.606 156.99 447.606 155.407C447.606 153.824 446.314 152.532 444.723 152.532C443.132 152.532 441.848 153.824 441.848 155.407C441.848 156.99 443.132 158.282 444.723 158.282Z" fill="#C2C2C2"/>
<path d="M456.349 158.282C457.94 158.282 459.231 156.99 459.231 155.407C459.231 153.824 457.94 152.532 456.349 152.532C454.758 152.532 453.474 153.824 453.474 155.407C453.474 156.99 454.758 158.282 456.349 158.282Z" fill="#C2C2C2"/>
<path d="M305.191 169.908C306.782 169.908 308.066 168.616 308.066 167.033C308.066 165.449 306.774 164.158 305.191 164.158C303.6 164.158 302.316 165.449 302.316 167.033C302.316 168.616 303.6 169.908 305.191 169.908Z" fill="#C2C2C2"/>
<path d="M316.816 169.908C318.407 169.908 319.691 168.616 319.691 167.033C319.691 165.449 318.4 164.158 316.816 164.158C315.225 164.158 313.941 165.449 313.941 167.033C313.941 168.616 315.225 169.908 316.816 169.908Z" fill="#C2C2C2"/>
<path d="M328.442 169.908C330.033 169.908 331.317 168.616 331.317 167.033C331.317 165.449 330.025 164.158 328.442 164.158C326.851 164.158 325.567 165.449 325.567 167.033C325.567 168.616 326.859 169.908 328.442 169.908Z" fill="#C2C2C2"/>
<path d="M340.076 169.908C341.667 169.908 342.951 168.616 342.951 167.033C342.951 165.449 341.659 164.158 340.076 164.158C338.485 164.158 337.201 165.449 337.201 167.033C337.201 168.616 338.485 169.908 340.076 169.908Z" fill="#C2C2C2"/>
<path d="M351.701 169.908C353.292 169.908 354.576 168.616 354.576 167.033C354.576 165.449 353.285 164.158 351.701 164.158C350.11 164.158 348.826 165.449 348.826 167.033C348.826 168.616 350.11 169.908 351.701 169.908Z" fill="#C2C2C2"/>
<path d="M363.327 169.908C364.918 169.908 366.202 168.616 366.202 167.033C366.202 165.449 364.91 164.158 363.327 164.158C361.736 164.158 360.444 165.449 360.444 167.033C360.444 168.616 361.736 169.908 363.327 169.908Z" fill="#C2C2C2"/>
<path d="M374.953 169.908C376.544 169.908 377.828 168.616 377.828 167.033C377.828 165.449 376.536 164.158 374.953 164.158C373.362 164.158 372.07 165.449 372.07 167.033C372.07 168.616 373.369 169.908 374.953 169.908Z" fill="#C2C2C2"/>
<path d="M386.586 169.908C388.177 169.908 389.461 168.616 389.461 167.033C389.461 165.449 388.17 164.158 386.586 164.158C384.995 164.158 383.704 165.449 383.704 167.033C383.704 168.616 384.995 169.908 386.586 169.908Z" fill="#C2C2C2"/>
<path d="M398.212 169.908C399.803 169.908 401.087 168.616 401.087 167.033C401.087 165.449 399.795 164.158 398.212 164.158C396.621 164.158 395.329 165.449 395.329 167.033C395.329 168.616 396.621 169.908 398.212 169.908Z" fill="#C2C2C2"/>
<path d="M409.838 169.908C411.429 169.908 412.713 168.616 412.713 167.033C412.713 165.449 411.421 164.158 409.838 164.158C408.247 164.158 406.955 165.449 406.955 167.033C406.955 168.616 408.247 169.908 409.838 169.908Z" fill="#C2C2C2"/>
<path d="M421.464 169.908C423.055 169.908 424.346 168.616 424.346 167.033C424.346 165.449 423.055 164.158 421.464 164.158C419.873 164.158 418.589 165.449 418.589 167.033C418.589 168.616 419.873 169.908 421.464 169.908Z" fill="#C2C2C2"/>
<path d="M433.089 169.908C434.68 169.908 435.972 168.616 435.972 167.033C435.972 165.449 434.68 164.158 433.089 164.158C431.498 164.158 430.214 165.449 430.214 167.033C430.214 168.616 431.506 169.908 433.089 169.908Z" fill="#C2C2C2"/>
<path d="M444.723 169.908C446.314 169.908 447.606 168.616 447.606 167.033C447.606 165.449 446.314 164.158 444.723 164.158C443.132 164.158 441.848 165.449 441.848 167.033C441.848 168.616 443.132 169.908 444.723 169.908Z" fill="#C2C2C2"/>
<path d="M456.349 169.908C457.94 169.908 459.231 168.616 459.231 167.033C459.231 165.449 457.94 164.158 456.349 164.158C454.758 164.158 453.474 165.449 453.474 167.033C453.474 168.616 454.758 169.908 456.349 169.908Z" fill="#C2C2C2"/>
<path d="M305.191 181.541C306.782 181.541 308.066 180.25 308.066 178.666C308.066 177.075 306.774 175.792 305.191 175.792C303.6 175.792 302.316 177.083 302.316 178.666C302.316 180.25 303.6 181.541 305.191 181.541Z" fill="#C2C2C2"/>
<path d="M316.816 181.541C318.407 181.541 319.691 180.25 319.691 178.666C319.691 177.075 318.4 175.792 316.816 175.792C315.225 175.792 313.941 177.083 313.941 178.666C313.941 180.25 315.225 181.541 316.816 181.541Z" fill="#C2C2C2"/>
<path d="M328.442 181.541C330.033 181.541 331.317 180.25 331.317 178.666C331.317 177.075 330.025 175.792 328.442 175.792C326.851 175.792 325.567 177.083 325.567 178.666C325.567 180.25 326.859 181.541 328.442 181.541Z" fill="#C2C2C2"/>
<path d="M340.076 181.541C341.667 181.541 342.951 180.25 342.951 178.666C342.951 177.075 341.659 175.792 340.076 175.792C338.485 175.792 337.201 177.083 337.201 178.666C337.193 180.25 338.485 181.541 340.076 181.541Z" fill="#C2C2C2"/>
<path d="M351.701 181.541C353.292 181.541 354.576 180.25 354.576 178.666C354.576 177.075 353.285 175.792 351.701 175.792C350.11 175.792 348.826 177.083 348.826 178.666C348.826 180.25 350.11 181.541 351.701 181.541Z" fill="#C2C2C2"/>
<path d="M363.327 181.541C364.918 181.541 366.202 180.25 366.202 178.666C366.202 177.075 364.91 175.792 363.327 175.792C361.736 175.792 360.444 177.083 360.444 178.666C360.452 180.25 361.736 181.541 363.327 181.541Z" fill="#C2C2C2"/>
<path d="M374.953 181.541C376.544 181.541 377.828 180.25 377.828 178.666C377.828 177.075 376.536 175.792 374.953 175.792C373.362 175.792 372.07 177.083 372.07 178.666C372.078 180.25 373.369 181.541 374.953 181.541Z" fill="#C2C2C2"/>
<path d="M386.586 181.541C388.177 181.541 389.461 180.25 389.461 178.666C389.461 177.075 388.17 175.792 386.586 175.792C384.995 175.792 383.704 177.083 383.704 178.666C383.704 180.25 384.995 181.541 386.586 181.541Z" fill="#C2C2C2"/>
<path d="M398.212 181.541C399.803 181.541 401.087 180.25 401.087 178.666C401.087 177.075 399.795 175.792 398.212 175.792C396.621 175.792 395.329 177.083 395.329 178.666C395.329 180.25 396.621 181.541 398.212 181.541Z" fill="#C2C2C2"/>
<path d="M409.838 181.541C411.429 181.541 412.713 180.25 412.713 178.666C412.713 177.075 411.421 175.792 409.838 175.792C408.247 175.792 406.955 177.083 406.955 178.666C406.955 180.25 408.247 181.541 409.838 181.541Z" fill="#C2C2C2"/>
<path d="M421.464 181.541C423.055 181.541 424.346 180.25 424.346 178.666C424.346 177.075 423.055 175.792 421.464 175.792C419.873 175.792 418.589 177.083 418.589 178.666C418.589 180.25 419.873 181.541 421.464 181.541Z" fill="#C2C2C2"/>
<path d="M433.089 181.541C434.68 181.541 435.972 180.25 435.972 178.666C435.972 177.075 434.68 175.792 433.089 175.792C431.498 175.792 430.214 177.083 430.214 178.666C430.214 180.25 431.506 181.541 433.089 181.541Z" fill="#C2C2C2"/>
<path d="M444.723 181.541C446.314 181.541 447.605 180.25 447.605 178.666C447.605 177.075 446.314 175.792 444.723 175.792C443.132 175.792 441.848 177.083 441.848 178.666C441.84 180.25 443.132 181.541 444.723 181.541Z" fill="#C2C2C2"/>
<path d="M456.348 181.541C457.939 181.541 459.231 180.25 459.231 178.666C459.231 177.075 457.939 175.792 456.348 175.792C454.757 175.792 453.473 177.083 453.473 178.666C453.466 180.25 454.757 181.541 456.348 181.541Z" fill="#C2C2C2"/>
<path d="M305.191 193.167C306.782 193.167 308.066 191.875 308.066 190.292C308.066 188.701 306.774 187.417 305.191 187.417C303.6 187.417 302.316 188.709 302.316 190.292C302.316 191.875 303.6 193.167 305.191 193.167Z" fill="#C2C2C2"/>
<path d="M316.816 193.167C318.407 193.167 319.691 191.875 319.691 190.292C319.691 188.701 318.4 187.417 316.816 187.417C315.225 187.417 313.941 188.709 313.941 190.292C313.941 191.875 315.225 193.167 316.816 193.167Z" fill="#C2C2C2"/>
<path d="M328.442 193.167C330.033 193.167 331.317 191.875 331.317 190.292C331.317 188.701 330.025 187.417 328.442 187.417C326.851 187.417 325.567 188.709 325.567 190.292C325.567 191.875 326.859 193.167 328.442 193.167Z" fill="#C2C2C2"/>
<path d="M340.076 193.167C341.667 193.167 342.951 191.875 342.951 190.292C342.951 188.701 341.659 187.417 340.076 187.417C338.485 187.417 337.201 188.709 337.201 190.292C337.193 191.875 338.485 193.167 340.076 193.167Z" fill="#C2C2C2"/>
<path d="M351.701 193.167C353.292 193.167 354.576 191.875 354.576 190.292C354.576 188.701 353.285 187.417 351.701 187.417C350.11 187.417 348.826 188.709 348.826 190.292C348.826 191.875 350.11 193.167 351.701 193.167Z" fill="#C2C2C2"/>
<path d="M363.327 193.167C364.918 193.167 366.202 191.875 366.202 190.292C366.202 188.701 364.91 187.417 363.327 187.417C361.736 187.417 360.444 188.709 360.444 190.292C360.452 191.875 361.736 193.167 363.327 193.167Z" fill="#C2C2C2"/>
<path d="M374.953 193.167C376.544 193.167 377.828 191.875 377.828 190.292C377.828 188.701 376.536 187.417 374.953 187.417C373.362 187.417 372.07 188.709 372.07 190.292C372.078 191.875 373.369 193.167 374.953 193.167Z" fill="#C2C2C2"/>
<path d="M386.586 193.167C388.177 193.167 389.461 191.875 389.461 190.292C389.461 188.701 388.17 187.417 386.586 187.417C384.995 187.417 383.704 188.709 383.704 190.292C383.704 191.875 384.995 193.167 386.586 193.167Z" fill="#C2C2C2"/>
<path d="M398.212 193.167C399.803 193.167 401.087 191.875 401.087 190.292C401.087 188.701 399.795 187.417 398.212 187.417C396.621 187.417 395.329 188.709 395.329 190.292C395.329 191.875 396.621 193.167 398.212 193.167Z" fill="#C2C2C2"/>
<path d="M409.838 193.167C411.429 193.167 412.713 191.875 412.713 190.292C412.713 188.701 411.421 187.417 409.838 187.417C408.247 187.417 406.955 188.709 406.955 190.292C406.955 191.875 408.247 193.167 409.838 193.167Z" fill="#C2C2C2"/>
<path d="M421.464 193.167C423.055 193.167 424.346 191.875 424.346 190.292C424.346 188.701 423.055 187.417 421.464 187.417C419.873 187.417 418.589 188.709 418.589 190.292C418.589 191.875 419.873 193.167 421.464 193.167Z" fill="#C2C2C2"/>
<path d="M433.089 193.167C434.68 193.167 435.972 191.875 435.972 190.292C435.972 188.701 434.68 187.417 433.089 187.417C431.498 187.417 430.214 188.709 430.214 190.292C430.214 191.875 431.506 193.167 433.089 193.167Z" fill="#C2C2C2"/>
<path d="M444.723 193.167C446.314 193.167 447.605 191.875 447.605 190.292C447.605 188.701 446.314 187.417 444.723 187.417C443.132 187.417 441.848 188.709 441.848 190.292C441.84 191.875 443.132 193.167 444.723 193.167Z" fill="#C2C2C2"/>
<path d="M456.348 193.167C457.939 193.167 459.231 191.875 459.231 190.292C459.231 188.701 457.939 187.417 456.348 187.417C454.757 187.417 453.473 188.709 453.473 190.292C453.466 191.875 454.757 193.167 456.348 193.167Z" fill="#C2C2C2"/>
<path d="M305.191 204.793C306.782 204.793 308.066 203.501 308.066 201.918C308.066 200.327 306.774 199.043 305.191 199.043C303.6 199.043 302.316 200.335 302.316 201.918C302.316 203.509 303.6 204.793 305.191 204.793Z" fill="#C2C2C2"/>
<path d="M316.816 204.793C318.407 204.793 319.691 203.501 319.691 201.918C319.691 200.327 318.4 199.043 316.816 199.043C315.225 199.043 313.941 200.335 313.941 201.918C313.941 203.509 315.225 204.793 316.816 204.793Z" fill="#C2C2C2"/>
<path d="M328.442 204.793C330.033 204.793 331.317 203.501 331.317 201.918C331.317 200.327 330.025 199.043 328.442 199.043C326.851 199.043 325.567 200.335 325.567 201.918C325.567 203.509 326.859 204.793 328.442 204.793Z" fill="#C2C2C2"/>
<path d="M340.076 204.793C341.667 204.793 342.951 203.501 342.951 201.918C342.951 200.327 341.659 199.043 340.076 199.043C338.485 199.043 337.201 200.335 337.201 201.918C337.193 203.509 338.485 204.793 340.076 204.793Z" fill="#C2C2C2"/>
<path d="M351.701 204.793C353.292 204.793 354.576 203.501 354.576 201.918C354.576 200.327 353.285 199.043 351.701 199.043C350.11 199.043 348.826 200.335 348.826 201.918C348.826 203.509 350.11 204.793 351.701 204.793Z" fill="#C2C2C2"/>
<path d="M363.327 204.793C364.918 204.793 366.202 203.501 366.202 201.918C366.202 200.327 364.91 199.043 363.327 199.043C361.736 199.043 360.444 200.335 360.444 201.918C360.452 203.509 361.736 204.793 363.327 204.793Z" fill="#C2C2C2"/>
<path d="M374.953 204.793C376.544 204.793 377.828 203.501 377.828 201.918C377.828 200.327 376.536 199.043 374.953 199.043C373.362 199.043 372.07 200.335 372.07 201.918C372.078 203.509 373.369 204.793 374.953 204.793Z" fill="#C2C2C2"/>
<path d="M386.586 204.793C388.177 204.793 389.461 203.501 389.461 201.918C389.461 200.327 388.17 199.043 386.586 199.043C384.995 199.043 383.704 200.335 383.704 201.918C383.704 203.509 384.995 204.793 386.586 204.793Z" fill="#C2C2C2"/>
<path d="M398.212 204.793C399.803 204.793 401.087 203.501 401.087 201.918C401.087 200.327 399.795 199.043 398.212 199.043C396.621 199.043 395.329 200.335 395.329 201.918C395.329 203.509 396.621 204.793 398.212 204.793Z" fill="#C2C2C2"/>
<path d="M409.838 204.793C411.429 204.793 412.713 203.501 412.713 201.918C412.713 200.327 411.421 199.043 409.838 199.043C408.247 199.043 406.955 200.335 406.955 201.918C406.955 203.509 408.247 204.793 409.838 204.793Z" fill="#C2C2C2"/>
<path d="M421.464 204.793C423.055 204.793 424.346 203.501 424.346 201.918C424.346 200.327 423.055 199.043 421.464 199.043C419.873 199.043 418.589 200.335 418.589 201.918C418.589 203.509 419.873 204.793 421.464 204.793Z" fill="#C2C2C2"/>
<path d="M433.089 204.793C434.68 204.793 435.972 203.501 435.972 201.918C435.972 200.327 434.68 199.043 433.089 199.043C431.498 199.043 430.214 200.335 430.214 201.918C430.214 203.509 431.506 204.793 433.089 204.793Z" fill="#C2C2C2"/>
<path d="M444.723 204.793C446.314 204.793 447.605 203.501 447.605 201.918C447.605 200.327 446.314 199.043 444.723 199.043C443.132 199.043 441.848 200.335 441.848 201.918C441.84 203.509 443.132 204.793 444.723 204.793Z" fill="#C2C2C2"/>
<path d="M456.348 204.793C457.939 204.793 459.231 203.501 459.231 201.918C459.231 200.327 457.939 199.043 456.348 199.043C454.757 199.043 453.473 200.335 453.473 201.918C453.466 203.509 454.757 204.793 456.348 204.793Z" fill="#C2C2C2"/>
<path d="M305.191 216.418C306.782 216.418 308.066 215.127 308.066 213.543C308.066 211.96 306.774 210.668 305.191 210.668C303.6 210.668 302.316 211.96 302.316 213.543C302.316 215.127 303.6 216.418 305.191 216.418Z" fill="#C2C2C2"/>
<path d="M316.816 216.418C318.407 216.418 319.691 215.127 319.691 213.543C319.691 211.96 318.4 210.668 316.816 210.668C315.225 210.668 313.941 211.96 313.941 213.543C313.941 215.127 315.225 216.418 316.816 216.418Z" fill="#C2C2C2"/>
<path d="M328.442 216.418C330.033 216.418 331.317 215.127 331.317 213.543C331.317 211.96 330.025 210.668 328.442 210.668C326.851 210.668 325.567 211.96 325.567 213.543C325.567 215.127 326.859 216.418 328.442 216.418Z" fill="#C2C2C2"/>
<path d="M340.076 216.418C341.667 216.418 342.951 215.127 342.951 213.543C342.951 211.96 341.659 210.668 340.076 210.668C338.485 210.668 337.201 211.96 337.201 213.543C337.201 215.127 338.485 216.418 340.076 216.418Z" fill="#C2C2C2"/>
<path d="M351.701 216.418C353.292 216.418 354.576 215.127 354.576 213.543C354.576 211.96 353.285 210.668 351.701 210.668C350.11 210.668 348.826 211.96 348.826 213.543C348.826 215.127 350.11 216.418 351.701 216.418Z" fill="#C2C2C2"/>
<path d="M363.327 216.418C364.918 216.418 366.202 215.127 366.202 213.543C366.202 211.96 364.91 210.668 363.327 210.668C361.736 210.668 360.444 211.96 360.444 213.543C360.444 215.127 361.736 216.418 363.327 216.418Z" fill="#C2C2C2"/>
<path d="M374.953 216.418C376.544 216.418 377.828 215.127 377.828 213.543C377.828 211.96 376.536 210.668 374.953 210.668C373.362 210.668 372.07 211.96 372.07 213.543C372.07 215.127 373.369 216.418 374.953 216.418Z" fill="#C2C2C2"/>
<path d="M386.586 216.418C388.177 216.418 389.461 215.127 389.461 213.543C389.461 211.96 388.17 210.668 386.586 210.668C384.995 210.668 383.704 211.96 383.704 213.543C383.704 215.127 384.995 216.418 386.586 216.418Z" fill="#C2C2C2"/>
<path d="M398.212 216.418C399.803 216.418 401.087 215.127 401.087 213.543C401.087 211.96 399.795 210.668 398.212 210.668C396.621 210.668 395.329 211.96 395.329 213.543C395.329 215.127 396.621 216.418 398.212 216.418Z" fill="#C2C2C2"/>
<path d="M409.838 216.418C411.429 216.418 412.713 215.127 412.713 213.543C412.713 211.96 411.421 210.668 409.838 210.668C408.247 210.668 406.955 211.96 406.955 213.543C406.955 215.127 408.247 216.418 409.838 216.418Z" fill="#C2C2C2"/>
<path d="M421.464 216.418C423.055 216.418 424.346 215.127 424.346 213.543C424.346 211.96 423.055 210.668 421.464 210.668C419.873 210.668 418.589 211.96 418.589 213.543C418.589 215.127 419.873 216.418 421.464 216.418Z" fill="#C2C2C2"/>
<path d="M433.089 216.418C434.68 216.418 435.972 215.127 435.972 213.543C435.972 211.96 434.68 210.668 433.089 210.668C431.498 210.668 430.214 211.96 430.214 213.543C430.214 215.127 431.506 216.418 433.089 216.418Z" fill="#C2C2C2"/>
<path d="M444.723 216.418C446.314 216.418 447.606 215.127 447.606 213.543C447.606 211.96 446.314 210.668 444.723 210.668C443.132 210.668 441.848 211.96 441.848 213.543C441.848 215.127 443.132 216.418 444.723 216.418Z" fill="#C2C2C2"/>
<path d="M456.349 216.418C457.94 216.418 459.231 215.127 459.231 213.543C459.231 211.96 457.94 210.668 456.349 210.668C454.758 210.668 453.474 211.96 453.474 213.543C453.474 215.127 454.758 216.418 456.349 216.418Z" fill="#C2C2C2"/>
<path d="M305.191 228.052C306.782 228.052 308.066 226.76 308.066 225.177C308.066 223.586 306.774 222.302 305.191 222.302C303.6 222.302 302.316 223.594 302.316 225.177C302.316 226.76 303.6 228.052 305.191 228.052Z" fill="#C2C2C2"/>
<path d="M316.816 228.052C318.407 228.052 319.691 226.76 319.691 225.177C319.691 223.586 318.4 222.302 316.816 222.302C315.225 222.302 313.941 223.594 313.941 225.177C313.941 226.76 315.225 228.052 316.816 228.052Z" fill="#C2C2C2"/>
<path d="M328.442 228.052C330.033 228.052 331.317 226.76 331.317 225.177C331.317 223.586 330.025 222.302 328.442 222.302C326.851 222.302 325.567 223.594 325.567 225.177C325.567 226.76 326.859 228.052 328.442 228.052Z" fill="#C2C2C2"/>
<path d="M340.076 228.052C341.667 228.052 342.951 226.76 342.951 225.177C342.951 223.586 341.659 222.302 340.076 222.302C338.485 222.302 337.201 223.594 337.201 225.177C337.193 226.76 338.485 228.052 340.076 228.052Z" fill="#C2C2C2"/>
<path d="M351.701 228.052C353.292 228.052 354.576 226.76 354.576 225.177C354.576 223.586 353.285 222.302 351.701 222.302C350.11 222.302 348.826 223.594 348.826 225.177C348.826 226.76 350.11 228.052 351.701 228.052Z" fill="#C2C2C2"/>
<path d="M363.327 228.052C364.918 228.052 366.202 226.76 366.202 225.177C366.202 223.586 364.91 222.302 363.327 222.302C361.736 222.302 360.444 223.594 360.444 225.177C360.452 226.76 361.736 228.052 363.327 228.052Z" fill="#C2C2C2"/>
<path d="M374.953 228.052C376.544 228.052 377.828 226.76 377.828 225.177C377.828 223.586 376.536 222.302 374.953 222.302C373.362 222.302 372.07 223.594 372.07 225.177C372.078 226.76 373.369 228.052 374.953 228.052Z" fill="#C2C2C2"/>
<path d="M386.586 228.052C388.177 228.052 389.461 226.76 389.461 225.177C389.461 223.586 388.17 222.302 386.586 222.302C384.995 222.302 383.704 223.594 383.704 225.177C383.704 226.76 384.995 228.052 386.586 228.052Z" fill="#C2C2C2"/>
<path d="M398.212 228.052C399.803 228.052 401.087 226.76 401.087 225.177C401.087 223.586 399.795 222.302 398.212 222.302C396.621 222.302 395.329 223.594 395.329 225.177C395.329 226.76 396.621 228.052 398.212 228.052Z" fill="#C2C2C2"/>
<path d="M409.838 228.052C411.429 228.052 412.713 226.76 412.713 225.177C412.713 223.586 411.421 222.302 409.838 222.302C408.247 222.302 406.955 223.594 406.955 225.177C406.955 226.76 408.247 228.052 409.838 228.052Z" fill="#C2C2C2"/>
<path d="M421.464 228.052C423.055 228.052 424.346 226.76 424.346 225.177C424.346 223.586 423.055 222.302 421.464 222.302C419.873 222.302 418.589 223.594 418.589 225.177C418.589 226.76 419.873 228.052 421.464 228.052Z" fill="#C2C2C2"/>
<path d="M433.089 228.052C434.68 228.052 435.972 226.76 435.972 225.177C435.972 223.586 434.68 222.302 433.089 222.302C431.498 222.302 430.214 223.594 430.214 225.177C430.214 226.76 431.506 228.052 433.089 228.052Z" fill="#C2C2C2"/>
<path d="M444.723 228.052C446.314 228.052 447.605 226.76 447.605 225.177C447.605 223.586 446.314 222.302 444.723 222.302C443.132 222.302 441.848 223.594 441.848 225.177C441.84 226.76 443.132 228.052 444.723 228.052Z" fill="#C2C2C2"/>
<path d="M456.348 228.052C457.939 228.052 459.231 226.76 459.231 225.177C459.231 223.586 457.939 222.302 456.348 222.302C454.757 222.302 453.473 223.594 453.473 225.177C453.466 226.76 454.757 228.052 456.348 228.052Z" fill="#C2C2C2"/>
<path d="M305.191 239.678C306.782 239.678 308.066 238.386 308.066 236.803C308.066 235.212 306.774 233.928 305.191 233.928C303.6 233.928 302.316 235.219 302.316 236.803C302.316 238.386 303.6 239.678 305.191 239.678Z" fill="#C2C2C2"/>
<path d="M316.816 239.678C318.407 239.678 319.691 238.386 319.691 236.803C319.691 235.212 318.4 233.928 316.816 233.928C315.225 233.928 313.941 235.219 313.941 236.803C313.941 238.386 315.225 239.678 316.816 239.678Z" fill="#C2C2C2"/>
<path d="M328.442 239.678C330.033 239.678 331.317 238.386 331.317 236.803C331.317 235.212 330.025 233.928 328.442 233.928C326.851 233.928 325.567 235.219 325.567 236.803C325.567 238.386 326.859 239.678 328.442 239.678Z" fill="#C2C2C2"/>
<path d="M340.076 239.678C341.667 239.678 342.951 238.386 342.951 236.803C342.951 235.212 341.659 233.928 340.076 233.928C338.485 233.928 337.201 235.219 337.201 236.803C337.193 238.386 338.485 239.678 340.076 239.678Z" fill="#C2C2C2"/>
<path d="M351.701 239.678C353.292 239.678 354.576 238.386 354.576 236.803C354.576 235.212 353.285 233.928 351.701 233.928C350.11 233.928 348.826 235.219 348.826 236.803C348.826 238.386 350.11 239.678 351.701 239.678Z" fill="#C2C2C2"/>
<path d="M363.327 239.678C364.918 239.678 366.202 238.386 366.202 236.803C366.202 235.212 364.91 233.928 363.327 233.928C361.736 233.928 360.444 235.219 360.444 236.803C360.452 238.386 361.736 239.678 363.327 239.678Z" fill="#C2C2C2"/>
<path d="M374.953 239.678C376.544 239.678 377.828 238.386 377.828 236.803C377.828 235.212 376.536 233.928 374.953 233.928C373.362 233.928 372.07 235.219 372.07 236.803C372.078 238.386 373.369 239.678 374.953 239.678Z" fill="#C2C2C2"/>
<path d="M386.586 239.678C388.177 239.678 389.461 238.386 389.461 236.803C389.461 235.212 388.17 233.928 386.586 233.928C384.995 233.928 383.704 235.219 383.704 236.803C383.704 238.386 384.995 239.678 386.586 239.678Z" fill="#C2C2C2"/>
<path d="M398.212 239.678C399.803 239.678 401.087 238.386 401.087 236.803C401.087 235.212 399.795 233.928 398.212 233.928C396.621 233.928 395.329 235.219 395.329 236.803C395.329 238.386 396.621 239.678 398.212 239.678Z" fill="#C2C2C2"/>
<path d="M409.838 239.678C411.429 239.678 412.713 238.386 412.713 236.803C412.713 235.212 411.421 233.928 409.838 233.928C408.247 233.928 406.955 235.219 406.955 236.803C406.955 238.386 408.247 239.678 409.838 239.678Z" fill="#C2C2C2"/>
<path d="M421.464 239.678C423.055 239.678 424.346 238.386 424.346 236.803C424.346 235.212 423.055 233.928 421.464 233.928C419.873 233.928 418.589 235.219 418.589 236.803C418.589 238.386 419.873 239.678 421.464 239.678Z" fill="#C2C2C2"/>
<path d="M433.089 239.678C434.68 239.678 435.972 238.386 435.972 236.803C435.972 235.212 434.68 233.928 433.089 233.928C431.498 233.928 430.214 235.219 430.214 236.803C430.214 238.386 431.506 239.678 433.089 239.678Z" fill="#C2C2C2"/>
<path d="M444.723 239.678C446.314 239.678 447.605 238.386 447.605 236.803C447.605 235.212 446.314 233.928 444.723 233.928C443.132 233.928 441.848 235.219 441.848 236.803C441.84 238.386 443.132 239.678 444.723 239.678Z" fill="#C2C2C2"/>
<path d="M456.348 239.678C457.939 239.678 459.231 238.386 459.231 236.803C459.231 235.212 457.939 233.928 456.348 233.928C454.757 233.928 453.473 235.219 453.473 236.803C453.466 238.386 454.757 239.678 456.348 239.678Z" fill="#C2C2C2"/>
<path d="M305.191 251.304C306.782 251.304 308.066 250.012 308.066 248.429C308.066 246.838 306.774 245.554 305.191 245.554C303.6 245.554 302.316 246.845 302.316 248.429C302.316 250.012 303.6 251.304 305.191 251.304Z" fill="#C2C2C2"/>
<path d="M316.816 251.304C318.407 251.304 319.691 250.012 319.691 248.429C319.691 246.838 318.4 245.554 316.816 245.554C315.225 245.554 313.941 246.845 313.941 248.429C313.941 250.012 315.225 251.304 316.816 251.304Z" fill="#C2C2C2"/>
<path d="M328.442 251.304C330.033 251.304 331.317 250.012 331.317 248.429C331.317 246.838 330.025 245.554 328.442 245.554C326.851 245.554 325.567 246.845 325.567 248.429C325.567 250.012 326.859 251.304 328.442 251.304Z" fill="#C2C2C2"/>
<path d="M340.076 251.304C341.667 251.304 342.951 250.012 342.951 248.429C342.951 246.838 341.659 245.554 340.076 245.554C338.485 245.554 337.201 246.845 337.201 248.429C337.193 250.012 338.485 251.304 340.076 251.304Z" fill="#C2C2C2"/>
<path d="M351.701 251.304C353.292 251.304 354.576 250.012 354.576 248.429C354.576 246.838 353.285 245.554 351.701 245.554C350.11 245.554 348.826 246.845 348.826 248.429C348.826 250.012 350.11 251.304 351.701 251.304Z" fill="#C2C2C2"/>
<path d="M363.327 251.304C364.918 251.304 366.202 250.012 366.202 248.429C366.202 246.838 364.91 245.554 363.327 245.554C361.736 245.554 360.444 246.845 360.444 248.429C360.452 250.012 361.736 251.304 363.327 251.304Z" fill="#C2C2C2"/>
<path d="M374.953 251.304C376.544 251.304 377.828 250.012 377.828 248.429C377.828 246.838 376.536 245.554 374.953 245.554C373.362 245.554 372.07 246.845 372.07 248.429C372.078 250.012 373.369 251.304 374.953 251.304Z" fill="#C2C2C2"/>
<path d="M386.586 251.304C388.177 251.304 389.461 250.012 389.461 248.429C389.461 246.838 388.17 245.554 386.586 245.554C384.995 245.554 383.704 246.845 383.704 248.429C383.704 250.012 384.995 251.304 386.586 251.304Z" fill="#C2C2C2"/>
<path d="M398.212 251.304C399.803 251.304 401.087 250.012 401.087 248.429C401.087 246.838 399.795 245.554 398.212 245.554C396.621 245.554 395.329 246.845 395.329 248.429C395.329 250.012 396.621 251.304 398.212 251.304Z" fill="#C2C2C2"/>
<path d="M409.838 251.304C411.429 251.304 412.713 250.012 412.713 248.429C412.713 246.838 411.421 245.554 409.838 245.554C408.247 245.554 406.955 246.845 406.955 248.429C406.955 250.012 408.247 251.304 409.838 251.304Z" fill="#C2C2C2"/>
<path d="M421.464 251.304C423.055 251.304 424.346 250.012 424.346 248.429C424.346 246.838 423.055 245.554 421.464 245.554C419.873 245.554 418.589 246.845 418.589 248.429C418.589 250.012 419.873 251.304 421.464 251.304Z" fill="#C2C2C2"/>
<path d="M433.089 251.304C434.68 251.304 435.972 250.012 435.972 248.429C435.972 246.838 434.68 245.554 433.089 245.554C431.498 245.554 430.214 246.845 430.214 248.429C430.214 250.012 431.506 251.304 433.089 251.304Z" fill="#C2C2C2"/>
<path d="M444.723 251.304C446.314 251.304 447.605 250.012 447.605 248.429C447.605 246.838 446.314 245.554 444.723 245.554C443.132 245.554 441.848 246.845 441.848 248.429C441.84 250.012 443.132 251.304 444.723 251.304Z" fill="#C2C2C2"/>
<path d="M456.348 251.304C457.939 251.304 459.231 250.012 459.231 248.429C459.231 246.838 457.939 245.554 456.348 245.554C454.757 245.554 453.473 246.845 453.473 248.429C453.466 250.012 454.757 251.304 456.348 251.304Z" fill="#C2C2C2"/>
<path d="M305.191 262.929C306.782 262.929 308.066 261.637 308.066 260.054C308.066 258.463 306.774 257.179 305.191 257.179C303.6 257.179 302.316 258.471 302.316 260.054C302.316 261.645 303.6 262.929 305.191 262.929Z" fill="#C2C2C2"/>
<path d="M316.816 262.929C318.407 262.929 319.691 261.637 319.691 260.054C319.691 258.463 318.4 257.179 316.816 257.179C315.225 257.179 313.941 258.471 313.941 260.054C313.941 261.645 315.225 262.929 316.816 262.929Z" fill="#C2C2C2"/>
<path d="M328.442 262.929C330.033 262.929 331.317 261.637 331.317 260.054C331.317 258.463 330.025 257.179 328.442 257.179C326.851 257.179 325.567 258.471 325.567 260.054C325.567 261.645 326.859 262.929 328.442 262.929Z" fill="#C2C2C2"/>
<path d="M340.076 262.929C341.667 262.929 342.951 261.637 342.951 260.054C342.951 258.463 341.659 257.179 340.076 257.179C338.485 257.179 337.201 258.471 337.201 260.054C337.193 261.645 338.485 262.929 340.076 262.929Z" fill="#C2C2C2"/>
<path d="M351.701 262.929C353.292 262.929 354.576 261.637 354.576 260.054C354.576 258.463 353.285 257.179 351.701 257.179C350.11 257.179 348.826 258.471 348.826 260.054C348.826 261.645 350.11 262.929 351.701 262.929Z" fill="#C2C2C2"/>
<path d="M363.327 262.929C364.918 262.929 366.202 261.637 366.202 260.054C366.202 258.463 364.91 257.179 363.327 257.179C361.736 257.179 360.444 258.471 360.444 260.054C360.452 261.645 361.736 262.929 363.327 262.929Z" fill="#C2C2C2"/>
<path d="M374.953 262.929C376.544 262.929 377.828 261.637 377.828 260.054C377.828 258.463 376.536 257.179 374.953 257.179C373.362 257.179 372.07 258.471 372.07 260.054C372.078 261.645 373.369 262.929 374.953 262.929Z" fill="#C2C2C2"/>
<path d="M386.586 262.929C388.177 262.929 389.461 261.637 389.461 260.054C389.461 258.463 388.17 257.179 386.586 257.179C384.995 257.179 383.704 258.471 383.704 260.054C383.704 261.645 384.995 262.929 386.586 262.929Z" fill="#C2C2C2"/>
<path d="M398.212 262.929C399.803 262.929 401.087 261.637 401.087 260.054C401.087 258.463 399.795 257.179 398.212 257.179C396.621 257.179 395.329 258.471 395.329 260.054C395.329 261.645 396.621 262.929 398.212 262.929Z" fill="#C2C2C2"/>
<path d="M409.838 262.929C411.429 262.929 412.713 261.637 412.713 260.054C412.713 258.463 411.421 257.179 409.838 257.179C408.247 257.179 406.955 258.471 406.955 260.054C406.955 261.645 408.247 262.929 409.838 262.929Z" fill="#C2C2C2"/>
<path d="M421.464 262.929C423.055 262.929 424.346 261.637 424.346 260.054C424.346 258.463 423.055 257.179 421.464 257.179C419.873 257.179 418.589 258.471 418.589 260.054C418.589 261.645 419.873 262.929 421.464 262.929Z" fill="#C2C2C2"/>
<path d="M433.089 262.929C434.68 262.929 435.972 261.637 435.972 260.054C435.972 258.463 434.68 257.179 433.089 257.179C431.498 257.179 430.214 258.471 430.214 260.054C430.214 261.645 431.506 262.929 433.089 262.929Z" fill="#C2C2C2"/>
<path d="M444.723 262.929C446.314 262.929 447.605 261.637 447.605 260.054C447.605 258.463 446.314 257.179 444.723 257.179C443.132 257.179 441.848 258.471 441.848 260.054C441.84 261.645 443.132 262.929 444.723 262.929Z" fill="#C2C2C2"/>
<path d="M456.348 262.929C457.939 262.929 459.231 261.637 459.231 260.054C459.231 258.463 457.939 257.179 456.348 257.179C454.757 257.179 453.473 258.471 453.473 260.054C453.466 261.645 454.757 262.929 456.348 262.929Z" fill="#C2C2C2"/>
<path d="M305.191 274.555C306.782 274.555 308.066 273.263 308.066 271.68C308.066 270.089 306.774 268.805 305.191 268.805C303.6 268.805 302.316 270.096 302.316 271.68C302.316 273.271 303.6 274.555 305.191 274.555Z" fill="#C2C2C2"/>
<path d="M316.816 274.555C318.407 274.555 319.691 273.263 319.691 271.68C319.691 270.089 318.4 268.805 316.816 268.805C315.225 268.805 313.941 270.096 313.941 271.68C313.941 273.271 315.225 274.555 316.816 274.555Z" fill="#C2C2C2"/>
<path d="M328.442 274.555C330.033 274.555 331.317 273.263 331.317 271.68C331.317 270.089 330.025 268.805 328.442 268.805C326.851 268.805 325.567 270.096 325.567 271.68C325.567 273.271 326.859 274.555 328.442 274.555Z" fill="#C2C2C2"/>
<path d="M340.076 274.555C341.667 274.555 342.951 273.263 342.951 271.68C342.951 270.089 341.659 268.805 340.076 268.805C338.485 268.805 337.201 270.096 337.201 271.68C337.193 273.271 338.485 274.555 340.076 274.555Z" fill="#C2C2C2"/>
<path d="M351.701 274.555C353.292 274.555 354.576 273.263 354.576 271.68C354.576 270.089 353.285 268.805 351.701 268.805C350.11 268.805 348.826 270.096 348.826 271.68C348.826 273.271 350.11 274.555 351.701 274.555Z" fill="#C2C2C2"/>
<path d="M363.327 274.555C364.918 274.555 366.202 273.263 366.202 271.68C366.202 270.089 364.91 268.805 363.327 268.805C361.736 268.805 360.444 270.096 360.444 271.68C360.452 273.271 361.736 274.555 363.327 274.555Z" fill="#C2C2C2"/>
<path d="M374.953 274.555C376.544 274.555 377.828 273.263 377.828 271.68C377.828 270.089 376.536 268.805 374.953 268.805C373.362 268.805 372.07 270.096 372.07 271.68C372.078 273.271 373.369 274.555 374.953 274.555Z" fill="#C2C2C2"/>
<path d="M386.586 274.555C388.177 274.555 389.461 273.263 389.461 271.68C389.461 270.089 388.17 268.805 386.586 268.805C384.995 268.805 383.704 270.096 383.704 271.68C383.704 273.271 384.995 274.555 386.586 274.555Z" fill="#C2C2C2"/>
<path d="M398.212 274.555C399.803 274.555 401.087 273.263 401.087 271.68C401.087 270.089 399.795 268.805 398.212 268.805C396.621 268.805 395.329 270.096 395.329 271.68C395.329 273.271 396.621 274.555 398.212 274.555Z" fill="#C2C2C2"/>
<path d="M409.838 274.555C411.429 274.555 412.713 273.263 412.713 271.68C412.713 270.089 411.421 268.805 409.838 268.805C408.247 268.805 406.955 270.096 406.955 271.68C406.955 273.271 408.247 274.555 409.838 274.555Z" fill="#C2C2C2"/>
<path d="M421.464 274.555C423.055 274.555 424.346 273.263 424.346 271.68C424.346 270.089 423.055 268.805 421.464 268.805C419.873 268.805 418.589 270.096 418.589 271.68C418.589 273.271 419.873 274.555 421.464 274.555Z" fill="#C2C2C2"/>
<path d="M433.089 274.555C434.68 274.555 435.972 273.263 435.972 271.68C435.972 270.089 434.68 268.805 433.089 268.805C431.498 268.805 430.214 270.096 430.214 271.68C430.214 273.271 431.506 274.555 433.089 274.555Z" fill="#C2C2C2"/>
<path d="M444.723 274.555C446.314 274.555 447.605 273.263 447.605 271.68C447.605 270.089 446.314 268.805 444.723 268.805C443.132 268.805 441.848 270.096 441.848 271.68C441.84 273.271 443.132 274.555 444.723 274.555Z" fill="#C2C2C2"/>
<path d="M456.348 274.555C457.939 274.555 459.231 273.263 459.231 271.68C459.231 270.089 457.939 268.805 456.348 268.805C454.757 268.805 453.473 270.096 453.473 271.68C453.466 273.271 454.757 274.555 456.348 274.555Z" fill="#C2C2C2"/>
<path d="M305.191 286.188C306.782 286.188 308.066 284.897 308.066 283.313C308.066 281.722 306.774 280.438 305.191 280.438C303.6 280.438 302.316 281.73 302.316 283.313C302.316 284.897 303.6 286.188 305.191 286.188Z" fill="#C2C2C2"/>
<path d="M316.816 286.188C318.407 286.188 319.691 284.897 319.691 283.313C319.691 281.722 318.4 280.438 316.816 280.438C315.225 280.438 313.941 281.73 313.941 283.313C313.941 284.897 315.225 286.188 316.816 286.188Z" fill="#C2C2C2"/>
<path d="M328.442 286.188C330.033 286.188 331.317 284.897 331.317 283.313C331.317 281.722 330.025 280.438 328.442 280.438C326.851 280.438 325.567 281.73 325.567 283.313C325.567 284.897 326.859 286.188 328.442 286.188Z" fill="#C2C2C2"/>
<path d="M340.076 286.188C341.667 286.188 342.951 284.897 342.951 283.313C342.951 281.722 341.659 280.438 340.076 280.438C338.485 280.438 337.201 281.73 337.201 283.313C337.193 284.897 338.485 286.188 340.076 286.188Z" fill="#C2C2C2"/>
<path d="M351.701 286.188C353.292 286.188 354.576 284.897 354.576 283.313C354.576 281.722 353.285 280.438 351.701 280.438C350.11 280.438 348.826 281.73 348.826 283.313C348.826 284.897 350.11 286.188 351.701 286.188Z" fill="#C2C2C2"/>
<path d="M363.327 286.188C364.918 286.188 366.202 284.897 366.202 283.313C366.202 281.722 364.91 280.438 363.327 280.438C361.736 280.438 360.444 281.73 360.444 283.313C360.452 284.897 361.736 286.188 363.327 286.188Z" fill="#C2C2C2"/>
<path d="M374.953 286.188C376.544 286.188 377.828 284.897 377.828 283.313C377.828 281.722 376.536 280.438 374.953 280.438C373.362 280.438 372.07 281.73 372.07 283.313C372.078 284.897 373.369 286.188 374.953 286.188Z" fill="#C2C2C2"/>
<path d="M386.586 286.188C388.177 286.188 389.461 284.897 389.461 283.313C389.461 281.722 388.17 280.438 386.586 280.438C384.995 280.438 383.704 281.73 383.704 283.313C383.704 284.897 384.995 286.188 386.586 286.188Z" fill="#C2C2C2"/>
<path d="M398.212 286.188C399.803 286.188 401.087 284.897 401.087 283.313C401.087 281.722 399.795 280.438 398.212 280.438C396.621 280.438 395.329 281.73 395.329 283.313C395.329 284.897 396.621 286.188 398.212 286.188Z" fill="#C2C2C2"/>
<path d="M409.838 286.188C411.429 286.188 412.713 284.897 412.713 283.313C412.713 281.722 411.421 280.438 409.838 280.438C408.247 280.438 406.955 281.73 406.955 283.313C406.955 284.897 408.247 286.188 409.838 286.188Z" fill="#C2C2C2"/>
<path d="M421.464 286.188C423.055 286.188 424.346 284.897 424.346 283.313C424.346 281.722 423.055 280.438 421.464 280.438C419.873 280.438 418.589 281.73 418.589 283.313C418.589 284.897 419.873 286.188 421.464 286.188Z" fill="#C2C2C2"/>
<path d="M433.089 286.188C434.68 286.188 435.972 284.897 435.972 283.313C435.972 281.722 434.68 280.438 433.089 280.438C431.498 280.438 430.214 281.73 430.214 283.313C430.214 284.897 431.506 286.188 433.089 286.188Z" fill="#C2C2C2"/>
<path d="M444.723 286.188C446.314 286.188 447.605 284.897 447.605 283.313C447.605 281.722 446.314 280.438 444.723 280.438C443.132 280.438 441.848 281.73 441.848 283.313C441.84 284.897 443.132 286.188 444.723 286.188Z" fill="#C2C2C2"/>
<path d="M456.348 286.188C457.939 286.188 459.231 284.897 459.231 283.313C459.231 281.722 457.939 280.438 456.348 280.438C454.757 280.438 453.473 281.73 453.473 283.313C453.466 284.897 454.757 286.188 456.348 286.188Z" fill="#C2C2C2"/>
<path d="M305.191 297.814C306.782 297.814 308.066 296.523 308.066 294.939C308.066 293.348 306.774 292.064 305.191 292.064C303.6 292.064 302.316 293.356 302.316 294.939C302.316 296.523 303.6 297.814 305.191 297.814Z" fill="#C2C2C2"/>
<path d="M316.816 297.814C318.407 297.814 319.691 296.523 319.691 294.939C319.691 293.348 318.4 292.064 316.816 292.064C315.225 292.064 313.941 293.356 313.941 294.939C313.941 296.523 315.225 297.814 316.816 297.814Z" fill="#C2C2C2"/>
<path d="M328.442 297.814C330.033 297.814 331.317 296.523 331.317 294.939C331.317 293.348 330.025 292.064 328.442 292.064C326.851 292.064 325.567 293.356 325.567 294.939C325.567 296.523 326.859 297.814 328.442 297.814Z" fill="#C2C2C2"/>
<path d="M340.076 297.814C341.667 297.814 342.951 296.523 342.951 294.939C342.951 293.348 341.659 292.064 340.076 292.064C338.485 292.064 337.201 293.356 337.201 294.939C337.193 296.523 338.485 297.814 340.076 297.814Z" fill="#C2C2C2"/>
<path d="M351.701 297.814C353.292 297.814 354.576 296.523 354.576 294.939C354.576 293.348 353.285 292.064 351.701 292.064C350.11 292.064 348.826 293.356 348.826 294.939C348.826 296.523 350.11 297.814 351.701 297.814Z" fill="#C2C2C2"/>
<path d="M363.327 297.814C364.918 297.814 366.202 296.523 366.202 294.939C366.202 293.348 364.91 292.064 363.327 292.064C361.736 292.064 360.444 293.356 360.444 294.939C360.452 296.523 361.736 297.814 363.327 297.814Z" fill="#C2C2C2"/>
<path d="M374.953 297.814C376.544 297.814 377.828 296.523 377.828 294.939C377.828 293.348 376.536 292.064 374.953 292.064C373.362 292.064 372.07 293.356 372.07 294.939C372.078 296.523 373.369 297.814 374.953 297.814Z" fill="#C2C2C2"/>
<path d="M386.586 297.814C388.177 297.814 389.461 296.523 389.461 294.939C389.461 293.348 388.17 292.064 386.586 292.064C384.995 292.064 383.704 293.356 383.704 294.939C383.704 296.523 384.995 297.814 386.586 297.814Z" fill="#C2C2C2"/>
<path d="M398.212 297.814C399.803 297.814 401.087 296.523 401.087 294.939C401.087 293.348 399.795 292.064 398.212 292.064C396.621 292.064 395.329 293.356 395.329 294.939C395.329 296.523 396.621 297.814 398.212 297.814Z" fill="#C2C2C2"/>
<path d="M409.838 297.814C411.429 297.814 412.713 296.523 412.713 294.939C412.713 293.348 411.421 292.064 409.838 292.064C408.247 292.064 406.955 293.356 406.955 294.939C406.955 296.523 408.247 297.814 409.838 297.814Z" fill="#C2C2C2"/>
<path d="M421.464 297.814C423.055 297.814 424.346 296.523 424.346 294.939C424.346 293.348 423.055 292.064 421.464 292.064C419.873 292.064 418.589 293.356 418.589 294.939C418.589 296.523 419.873 297.814 421.464 297.814Z" fill="#C2C2C2"/>
<path d="M433.089 297.814C434.68 297.814 435.972 296.523 435.972 294.939C435.972 293.348 434.68 292.064 433.089 292.064C431.498 292.064 430.214 293.356 430.214 294.939C430.214 296.523 431.506 297.814 433.089 297.814Z" fill="#C2C2C2"/>
<path d="M444.723 297.814C446.314 297.814 447.605 296.523 447.605 294.939C447.605 293.348 446.314 292.064 444.723 292.064C443.132 292.064 441.848 293.356 441.848 294.939C441.84 296.523 443.132 297.814 444.723 297.814Z" fill="#C2C2C2"/>
<path d="M456.348 297.814C457.939 297.814 459.231 296.523 459.231 294.939C459.231 293.348 457.939 292.064 456.348 292.064C454.757 292.064 453.473 293.356 453.473 294.939C453.466 296.523 454.757 297.814 456.348 297.814Z" fill="#C2C2C2"/>
<path d="M305.191 309.44C306.782 309.44 308.066 308.148 308.066 306.565C308.066 304.974 306.774 303.69 305.191 303.69C303.6 303.69 302.316 304.982 302.316 306.565C302.316 308.156 303.6 309.44 305.191 309.44Z" fill="#C2C2C2"/>
<path d="M316.816 309.44C318.407 309.44 319.691 308.148 319.691 306.565C319.691 304.974 318.4 303.69 316.816 303.69C315.225 303.69 313.941 304.982 313.941 306.565C313.941 308.156 315.225 309.44 316.816 309.44Z" fill="#C2C2C2"/>
<path d="M328.442 309.44C330.033 309.44 331.317 308.148 331.317 306.565C331.317 304.974 330.025 303.69 328.442 303.69C326.851 303.69 325.567 304.982 325.567 306.565C325.567 308.156 326.859 309.44 328.442 309.44Z" fill="#C2C2C2"/>
<path d="M340.076 309.44C341.667 309.44 342.951 308.148 342.951 306.565C342.951 304.974 341.659 303.69 340.076 303.69C338.485 303.69 337.201 304.982 337.201 306.565C337.193 308.156 338.485 309.44 340.076 309.44Z" fill="#C2C2C2"/>
<path d="M351.701 309.44C353.292 309.44 354.576 308.148 354.576 306.565C354.576 304.974 353.285 303.69 351.701 303.69C350.11 303.69 348.826 304.982 348.826 306.565C348.826 308.156 350.11 309.44 351.701 309.44Z" fill="#C2C2C2"/>
<path d="M363.327 309.44C364.918 309.44 366.202 308.148 366.202 306.565C366.202 304.974 364.91 303.69 363.327 303.69C361.736 303.69 360.444 304.982 360.444 306.565C360.452 308.156 361.736 309.44 363.327 309.44Z" fill="#C2C2C2"/>
<path d="M374.953 309.44C376.544 309.44 377.828 308.148 377.828 306.565C377.828 304.974 376.536 303.69 374.953 303.69C373.362 303.69 372.07 304.982 372.07 306.565C372.078 308.156 373.369 309.44 374.953 309.44Z" fill="#C2C2C2"/>
<path d="M386.586 309.44C388.177 309.44 389.461 308.148 389.461 306.565C389.461 304.974 388.17 303.69 386.586 303.69C384.995 303.69 383.704 304.982 383.704 306.565C383.704 308.156 384.995 309.44 386.586 309.44Z" fill="#C2C2C2"/>
<path d="M398.212 309.44C399.803 309.44 401.087 308.148 401.087 306.565C401.087 304.974 399.795 303.69 398.212 303.69C396.621 303.69 395.329 304.982 395.329 306.565C395.329 308.156 396.621 309.44 398.212 309.44Z" fill="#C2C2C2"/>
<path d="M409.838 309.44C411.429 309.44 412.713 308.148 412.713 306.565C412.713 304.974 411.421 303.69 409.838 303.69C408.247 303.69 406.955 304.982 406.955 306.565C406.955 308.156 408.247 309.44 409.838 309.44Z" fill="#C2C2C2"/>
<path d="M421.464 309.44C423.055 309.44 424.346 308.148 424.346 306.565C424.346 304.974 423.055 303.69 421.464 303.69C419.873 303.69 418.589 304.982 418.589 306.565C418.589 308.156 419.873 309.44 421.464 309.44Z" fill="#C2C2C2"/>
<path d="M433.089 309.44C434.68 309.44 435.972 308.148 435.972 306.565C435.972 304.974 434.68 303.69 433.089 303.69C431.498 303.69 430.214 304.982 430.214 306.565C430.214 308.156 431.506 309.44 433.089 309.44Z" fill="#C2C2C2"/>
<path d="M444.723 309.44C446.314 309.44 447.605 308.148 447.605 306.565C447.605 304.974 446.314 303.69 444.723 303.69C443.132 303.69 441.848 304.982 441.848 306.565C441.84 308.156 443.132 309.44 444.723 309.44Z" fill="#C2C2C2"/>
<path d="M456.348 309.44C457.939 309.44 459.231 308.148 459.231 306.565C459.231 304.974 457.939 303.69 456.348 303.69C454.757 303.69 453.473 304.982 453.473 306.565C453.466 308.156 454.757 309.44 456.348 309.44Z" fill="#C2C2C2"/>
<path d="M305.191 321.065C306.782 321.065 308.066 319.774 308.066 318.19C308.066 316.599 306.774 315.315 305.191 315.315C303.6 315.315 302.316 316.607 302.316 318.19C302.316 319.781 303.6 321.065 305.191 321.065Z" fill="#C2C2C2"/>
<path d="M316.816 321.065C318.407 321.065 319.691 319.774 319.691 318.19C319.691 316.599 318.4 315.315 316.816 315.315C315.225 315.315 313.941 316.607 313.941 318.19C313.941 319.781 315.225 321.065 316.816 321.065Z" fill="#C2C2C2"/>
<path d="M328.442 321.065C330.033 321.065 331.317 319.774 331.317 318.19C331.317 316.599 330.025 315.315 328.442 315.315C326.851 315.315 325.567 316.607 325.567 318.19C325.567 319.781 326.859 321.065 328.442 321.065Z" fill="#C2C2C2"/>
<path d="M340.076 321.065C341.667 321.065 342.951 319.774 342.951 318.19C342.951 316.599 341.659 315.315 340.076 315.315C338.485 315.315 337.201 316.607 337.201 318.19C337.193 319.781 338.485 321.065 340.076 321.065Z" fill="#C2C2C2"/>
<path d="M351.701 321.065C353.292 321.065 354.576 319.774 354.576 318.19C354.576 316.599 353.285 315.315 351.701 315.315C350.11 315.315 348.826 316.607 348.826 318.19C348.826 319.781 350.11 321.065 351.701 321.065Z" fill="#C2C2C2"/>
<path d="M363.327 321.065C364.918 321.065 366.202 319.774 366.202 318.19C366.202 316.599 364.91 315.315 363.327 315.315C361.736 315.315 360.444 316.607 360.444 318.19C360.452 319.781 361.736 321.065 363.327 321.065Z" fill="#C2C2C2"/>
<path d="M374.953 321.065C376.544 321.065 377.828 319.774 377.828 318.19C377.828 316.599 376.536 315.315 374.953 315.315C373.362 315.315 372.07 316.607 372.07 318.19C372.078 319.781 373.369 321.065 374.953 321.065Z" fill="#C2C2C2"/>
<path d="M386.586 321.065C388.177 321.065 389.461 319.774 389.461 318.19C389.461 316.599 388.17 315.315 386.586 315.315C384.995 315.315 383.704 316.607 383.704 318.19C383.704 319.781 384.995 321.065 386.586 321.065Z" fill="#C2C2C2"/>
<path d="M398.212 321.065C399.803 321.065 401.087 319.774 401.087 318.19C401.087 316.599 399.795 315.315 398.212 315.315C396.621 315.315 395.329 316.607 395.329 318.19C395.329 319.781 396.621 321.065 398.212 321.065Z" fill="#C2C2C2"/>
<path d="M409.838 321.065C411.429 321.065 412.713 319.774 412.713 318.19C412.713 316.599 411.421 315.315 409.838 315.315C408.247 315.315 406.955 316.607 406.955 318.19C406.955 319.781 408.247 321.065 409.838 321.065Z" fill="#C2C2C2"/>
<path d="M421.464 321.065C423.055 321.065 424.346 319.774 424.346 318.19C424.346 316.599 423.055 315.315 421.464 315.315C419.873 315.315 418.589 316.607 418.589 318.19C418.589 319.781 419.873 321.065 421.464 321.065Z" fill="#C2C2C2"/>
<path d="M433.089 321.065C434.68 321.065 435.972 319.774 435.972 318.19C435.972 316.599 434.68 315.315 433.089 315.315C431.498 315.315 430.214 316.607 430.214 318.19C430.214 319.781 431.506 321.065 433.089 321.065Z" fill="#C2C2C2"/>
<path d="M444.723 321.065C446.314 321.065 447.605 319.774 447.605 318.19C447.605 316.599 446.314 315.315 444.723 315.315C443.132 315.315 441.848 316.607 441.848 318.19C441.84 319.781 443.132 321.065 444.723 321.065Z" fill="#C2C2C2"/>
<path d="M456.348 321.065C457.939 321.065 459.231 319.774 459.231 318.19C459.231 316.599 457.939 315.315 456.348 315.315C454.757 315.315 453.473 316.607 453.473 318.19C453.466 319.781 454.757 321.065 456.348 321.065Z" fill="#C2C2C2"/>
<path d="M305.191 332.699C306.782 332.699 308.066 331.407 308.066 329.824C308.066 328.233 306.774 326.949 305.191 326.949C303.6 326.949 302.316 328.241 302.316 329.824C302.316 331.407 303.6 332.699 305.191 332.699Z" fill="#C2C2C2"/>
<path d="M316.816 332.699C318.407 332.699 319.691 331.407 319.691 329.824C319.691 328.233 318.4 326.949 316.816 326.949C315.225 326.949 313.941 328.241 313.941 329.824C313.941 331.407 315.225 332.699 316.816 332.699Z" fill="#C2C2C2"/>
<path d="M328.442 332.699C330.033 332.699 331.317 331.407 331.317 329.824C331.317 328.233 330.025 326.949 328.442 326.949C326.851 326.949 325.567 328.241 325.567 329.824C325.567 331.407 326.859 332.699 328.442 332.699Z" fill="#C2C2C2"/>
<path d="M340.076 332.699C341.667 332.699 342.951 331.407 342.951 329.824C342.951 328.233 341.659 326.949 340.076 326.949C338.485 326.949 337.201 328.241 337.201 329.824C337.193 331.407 338.485 332.699 340.076 332.699Z" fill="#C2C2C2"/>
<path d="M351.701 332.699C353.292 332.699 354.576 331.407 354.576 329.824C354.576 328.233 353.285 326.949 351.701 326.949C350.11 326.949 348.826 328.241 348.826 329.824C348.826 331.407 350.11 332.699 351.701 332.699Z" fill="#C2C2C2"/>
<path d="M363.327 332.699C364.918 332.699 366.202 331.407 366.202 329.824C366.202 328.233 364.91 326.949 363.327 326.949C361.736 326.949 360.444 328.241 360.444 329.824C360.452 331.407 361.736 332.699 363.327 332.699Z" fill="#C2C2C2"/>
<path d="M374.953 332.699C376.544 332.699 377.828 331.407 377.828 329.824C377.828 328.233 376.536 326.949 374.953 326.949C373.362 326.949 372.07 328.241 372.07 329.824C372.078 331.407 373.369 332.699 374.953 332.699Z" fill="#C2C2C2"/>
<path d="M386.586 332.699C388.177 332.699 389.461 331.407 389.461 329.824C389.461 328.233 388.17 326.949 386.586 326.949C384.995 326.949 383.704 328.241 383.704 329.824C383.704 331.407 384.995 332.699 386.586 332.699Z" fill="#C2C2C2"/>
<path d="M398.212 332.699C399.803 332.699 401.087 331.407 401.087 329.824C401.087 328.233 399.795 326.949 398.212 326.949C396.621 326.949 395.329 328.241 395.329 329.824C395.329 331.407 396.621 332.699 398.212 332.699Z" fill="#C2C2C2"/>
<path d="M409.838 332.699C411.429 332.699 412.713 331.407 412.713 329.824C412.713 328.233 411.421 326.949 409.838 326.949C408.247 326.949 406.955 328.241 406.955 329.824C406.955 331.407 408.247 332.699 409.838 332.699Z" fill="#C2C2C2"/>
<path d="M421.464 332.699C423.055 332.699 424.346 331.407 424.346 329.824C424.346 328.233 423.055 326.949 421.464 326.949C419.873 326.949 418.589 328.241 418.589 329.824C418.589 331.407 419.873 332.699 421.464 332.699Z" fill="#C2C2C2"/>
<path d="M433.089 332.699C434.68 332.699 435.972 331.407 435.972 329.824C435.972 328.233 434.68 326.949 433.089 326.949C431.498 326.949 430.214 328.241 430.214 329.824C430.214 331.407 431.506 332.699 433.089 332.699Z" fill="#C2C2C2"/>
<path d="M444.723 332.699C446.314 332.699 447.605 331.407 447.605 329.824C447.605 328.233 446.314 326.949 444.723 326.949C443.132 326.949 441.848 328.241 441.848 329.824C441.84 331.407 443.132 332.699 444.723 332.699Z" fill="#C2C2C2"/>
<path d="M456.348 332.699C457.939 332.699 459.231 331.407 459.231 329.824C459.231 328.233 457.939 326.949 456.348 326.949C454.757 326.949 453.473 328.241 453.473 329.824C453.466 331.407 454.757 332.699 456.348 332.699Z" fill="#C2C2C2"/>
<path d="M305.191 344.325C306.782 344.325 308.066 343.033 308.066 341.45C308.066 339.859 306.774 338.575 305.191 338.575C303.6 338.575 302.316 339.867 302.316 341.45C302.316 343.033 303.6 344.325 305.191 344.325Z" fill="#C2C2C2"/>
<path d="M316.816 344.325C318.407 344.325 319.691 343.033 319.691 341.45C319.691 339.859 318.4 338.575 316.816 338.575C315.225 338.575 313.941 339.867 313.941 341.45C313.941 343.033 315.225 344.325 316.816 344.325Z" fill="#C2C2C2"/>
<path d="M328.442 344.325C330.033 344.325 331.317 343.033 331.317 341.45C331.317 339.859 330.025 338.575 328.442 338.575C326.851 338.575 325.567 339.867 325.567 341.45C325.567 343.033 326.859 344.325 328.442 344.325Z" fill="#C2C2C2"/>
<path d="M340.076 344.325C341.667 344.325 342.951 343.033 342.951 341.45C342.951 339.859 341.659 338.575 340.076 338.575C338.485 338.575 337.201 339.867 337.201 341.45C337.193 343.033 338.485 344.325 340.076 344.325Z" fill="#C2C2C2"/>
<path d="M351.701 344.325C353.292 344.325 354.576 343.033 354.576 341.45C354.576 339.859 353.285 338.575 351.701 338.575C350.11 338.575 348.826 339.867 348.826 341.45C348.826 343.033 350.11 344.325 351.701 344.325Z" fill="#C2C2C2"/>
<path d="M363.327 344.325C364.918 344.325 366.202 343.033 366.202 341.45C366.202 339.859 364.91 338.575 363.327 338.575C361.736 338.575 360.444 339.867 360.444 341.45C360.452 343.033 361.736 344.325 363.327 344.325Z" fill="#C2C2C2"/>
<path d="M374.953 344.325C376.544 344.325 377.828 343.033 377.828 341.45C377.828 339.859 376.536 338.575 374.953 338.575C373.362 338.575 372.07 339.867 372.07 341.45C372.078 343.033 373.369 344.325 374.953 344.325Z" fill="#C2C2C2"/>
<path d="M386.586 344.325C388.177 344.325 389.461 343.033 389.461 341.45C389.461 339.859 388.17 338.575 386.586 338.575C384.995 338.575 383.704 339.867 383.704 341.45C383.704 343.033 384.995 344.325 386.586 344.325Z" fill="#C2C2C2"/>
<path d="M398.212 344.325C399.803 344.325 401.087 343.033 401.087 341.45C401.087 339.859 399.795 338.575 398.212 338.575C396.621 338.575 395.329 339.867 395.329 341.45C395.329 343.033 396.621 344.325 398.212 344.325Z" fill="#C2C2C2"/>
<path d="M409.838 344.325C411.429 344.325 412.713 343.033 412.713 341.45C412.713 339.859 411.421 338.575 409.838 338.575C408.247 338.575 406.955 339.867 406.955 341.45C406.955 343.033 408.247 344.325 409.838 344.325Z" fill="#C2C2C2"/>
<path d="M421.464 344.325C423.055 344.325 424.346 343.033 424.346 341.45C424.346 339.859 423.055 338.575 421.464 338.575C419.873 338.575 418.589 339.867 418.589 341.45C418.589 343.033 419.873 344.325 421.464 344.325Z" fill="#C2C2C2"/>
<path d="M433.089 344.325C434.68 344.325 435.972 343.033 435.972 341.45C435.972 339.859 434.68 338.575 433.089 338.575C431.498 338.575 430.214 339.867 430.214 341.45C430.214 343.033 431.506 344.325 433.089 344.325Z" fill="#C2C2C2"/>
<path d="M444.723 344.325C446.314 344.325 447.605 343.033 447.605 341.45C447.605 339.859 446.314 338.575 444.723 338.575C443.132 338.575 441.848 339.867 441.848 341.45C441.84 343.033 443.132 344.325 444.723 344.325Z" fill="#C2C2C2"/>
<path d="M456.348 344.325C457.939 344.325 459.231 343.033 459.231 341.45C459.231 339.859 457.939 338.575 456.348 338.575C454.757 338.575 453.473 339.867 453.473 341.45C453.466 343.033 454.757 344.325 456.348 344.325Z" fill="#C2C2C2"/>
<path d="M305.191 355.951C306.782 355.951 308.066 354.659 308.066 353.076C308.066 351.492 306.774 350.201 305.191 350.201C303.6 350.201 302.316 351.492 302.316 353.076C302.316 354.659 303.6 355.951 305.191 355.951Z" fill="#C2C2C2"/>
<path d="M316.816 355.951C318.407 355.951 319.691 354.659 319.691 353.076C319.691 351.492 318.4 350.201 316.816 350.201C315.225 350.201 313.941 351.492 313.941 353.076C313.941 354.659 315.225 355.951 316.816 355.951Z" fill="#C2C2C2"/>
<path d="M328.442 355.951C330.033 355.951 331.317 354.659 331.317 353.076C331.317 351.492 330.025 350.201 328.442 350.201C326.851 350.201 325.567 351.492 325.567 353.076C325.567 354.659 326.859 355.951 328.442 355.951Z" fill="#C2C2C2"/>
<path d="M340.076 355.951C341.667 355.951 342.951 354.659 342.951 353.076C342.951 351.492 341.659 350.201 340.076 350.201C338.485 350.201 337.201 351.492 337.201 353.076C337.201 354.659 338.485 355.951 340.076 355.951Z" fill="#C2C2C2"/>
<path d="M351.701 355.951C353.292 355.951 354.576 354.659 354.576 353.076C354.576 351.492 353.285 350.201 351.701 350.201C350.11 350.201 348.826 351.492 348.826 353.076C348.826 354.659 350.11 355.951 351.701 355.951Z" fill="#C2C2C2"/>
<path d="M363.327 355.951C364.918 355.951 366.202 354.659 366.202 353.076C366.202 351.492 364.91 350.201 363.327 350.201C361.736 350.201 360.444 351.492 360.444 353.076C360.444 354.659 361.736 355.951 363.327 355.951Z" fill="#C2C2C2"/>
<path d="M374.953 355.951C376.544 355.951 377.828 354.659 377.828 353.076C377.828 351.492 376.536 350.201 374.953 350.201C373.362 350.201 372.07 351.492 372.07 353.076C372.07 354.659 373.369 355.951 374.953 355.951Z" fill="#C2C2C2"/>
<path d="M386.586 355.951C388.177 355.951 389.461 354.659 389.461 353.076C389.461 351.492 388.17 350.201 386.586 350.201C384.995 350.201 383.704 351.492 383.704 353.076C383.704 354.659 384.995 355.951 386.586 355.951Z" fill="#C2C2C2"/>
<path d="M398.212 355.951C399.803 355.951 401.087 354.659 401.087 353.076C401.087 351.492 399.795 350.201 398.212 350.201C396.621 350.201 395.329 351.492 395.329 353.076C395.329 354.659 396.621 355.951 398.212 355.951Z" fill="#C2C2C2"/>
<path d="M409.838 355.951C411.429 355.951 412.713 354.659 412.713 353.076C412.713 351.492 411.421 350.201 409.838 350.201C408.247 350.201 406.955 351.492 406.955 353.076C406.955 354.659 408.247 355.951 409.838 355.951Z" fill="#C2C2C2"/>
<path d="M421.464 355.951C423.055 355.951 424.346 354.659 424.346 353.076C424.346 351.492 423.055 350.201 421.464 350.201C419.873 350.201 418.589 351.492 418.589 353.076C418.589 354.659 419.873 355.951 421.464 355.951Z" fill="#C2C2C2"/>
<path d="M433.089 355.951C434.68 355.951 435.972 354.659 435.972 353.076C435.972 351.492 434.68 350.201 433.089 350.201C431.498 350.201 430.214 351.492 430.214 353.076C430.214 354.659 431.506 355.951 433.089 355.951Z" fill="#C2C2C2"/>
<path d="M444.723 355.951C446.314 355.951 447.606 354.659 447.606 353.076C447.606 351.492 446.314 350.201 444.723 350.201C443.132 350.201 441.848 351.492 441.848 353.076C441.848 354.659 443.132 355.951 444.723 355.951Z" fill="#C2C2C2"/>
<path d="M456.349 355.951C457.94 355.951 459.231 354.659 459.231 353.076C459.231 351.492 457.94 350.201 456.349 350.201C454.758 350.201 453.474 351.492 453.474 353.076C453.474 354.659 454.758 355.951 456.349 355.951Z" fill="#C2C2C2"/>
<path d="M305.191 367.576C306.782 367.576 308.066 366.284 308.066 364.701C308.066 363.11 306.774 361.826 305.191 361.826C303.6 361.826 302.316 363.118 302.316 364.701C302.316 366.292 303.6 367.576 305.191 367.576Z" fill="#C2C2C2"/>
<path d="M316.816 367.576C318.407 367.576 319.691 366.284 319.691 364.701C319.691 363.11 318.4 361.826 316.816 361.826C315.225 361.826 313.941 363.118 313.941 364.701C313.941 366.292 315.225 367.576 316.816 367.576Z" fill="#C2C2C2"/>
<path d="M328.442 367.576C330.033 367.576 331.317 366.284 331.317 364.701C331.317 363.11 330.025 361.826 328.442 361.826C326.851 361.826 325.567 363.118 325.567 364.701C325.567 366.292 326.859 367.576 328.442 367.576Z" fill="#C2C2C2"/>
<path d="M340.076 367.576C341.667 367.576 342.951 366.284 342.951 364.701C342.951 363.11 341.659 361.826 340.076 361.826C338.485 361.826 337.201 363.118 337.201 364.701C337.193 366.292 338.485 367.576 340.076 367.576Z" fill="#C2C2C2"/>
<path d="M351.701 367.576C353.292 367.576 354.576 366.284 354.576 364.701C354.576 363.11 353.285 361.826 351.701 361.826C350.11 361.826 348.826 363.118 348.826 364.701C348.826 366.292 350.11 367.576 351.701 367.576Z" fill="#C2C2C2"/>
<path d="M363.327 367.576C364.918 367.576 366.202 366.284 366.202 364.701C366.202 363.11 364.91 361.826 363.327 361.826C361.736 361.826 360.444 363.118 360.444 364.701C360.452 366.292 361.736 367.576 363.327 367.576Z" fill="#C2C2C2"/>
<path d="M374.953 367.576C376.544 367.576 377.828 366.284 377.828 364.701C377.828 363.11 376.536 361.826 374.953 361.826C373.362 361.826 372.07 363.118 372.07 364.701C372.078 366.292 373.369 367.576 374.953 367.576Z" fill="#C2C2C2"/>
<path d="M386.586 367.576C388.177 367.576 389.461 366.284 389.461 364.701C389.461 363.11 388.17 361.826 386.586 361.826C384.995 361.826 383.704 363.118 383.704 364.701C383.704 366.292 384.995 367.576 386.586 367.576Z" fill="#C2C2C2"/>
<path d="M398.212 367.576C399.803 367.576 401.087 366.284 401.087 364.701C401.087 363.11 399.795 361.826 398.212 361.826C396.621 361.826 395.329 363.118 395.329 364.701C395.329 366.292 396.621 367.576 398.212 367.576Z" fill="#C2C2C2"/>
<path d="M409.838 367.576C411.429 367.576 412.713 366.284 412.713 364.701C412.713 363.11 411.421 361.826 409.838 361.826C408.247 361.826 406.955 363.118 406.955 364.701C406.955 366.292 408.247 367.576 409.838 367.576Z" fill="#C2C2C2"/>
<path d="M421.464 367.576C423.055 367.576 424.346 366.284 424.346 364.701C424.346 363.11 423.055 361.826 421.464 361.826C419.873 361.826 418.589 363.118 418.589 364.701C418.589 366.292 419.873 367.576 421.464 367.576Z" fill="#C2C2C2"/>
<path d="M433.089 367.576C434.68 367.576 435.972 366.284 435.972 364.701C435.972 363.11 434.68 361.826 433.089 361.826C431.498 361.826 430.214 363.118 430.214 364.701C430.214 366.292 431.506 367.576 433.089 367.576Z" fill="#C2C2C2"/>
<path d="M444.723 367.576C446.314 367.576 447.605 366.284 447.605 364.701C447.605 363.11 446.314 361.826 444.723 361.826C443.132 361.826 441.848 363.118 441.848 364.701C441.84 366.292 443.132 367.576 444.723 367.576Z" fill="#C2C2C2"/>
<path d="M456.348 367.576C457.939 367.576 459.231 366.284 459.231 364.701C459.231 363.11 457.939 361.826 456.348 361.826C454.757 361.826 453.473 363.118 453.473 364.701C453.466 366.292 454.757 367.576 456.348 367.576Z" fill="#C2C2C2"/>
<path d="M305.191 379.202C306.782 379.202 308.066 377.91 308.066 376.327C308.066 374.736 306.774 373.452 305.191 373.452C303.6 373.452 302.316 374.744 302.316 376.327C302.316 377.918 303.6 379.202 305.191 379.202Z" fill="#C2C2C2"/>
<path d="M316.816 379.202C318.407 379.202 319.691 377.91 319.691 376.327C319.691 374.736 318.4 373.452 316.816 373.452C315.225 373.452 313.941 374.744 313.941 376.327C313.941 377.918 315.225 379.202 316.816 379.202Z" fill="#C2C2C2"/>
<path d="M328.442 379.202C330.033 379.202 331.317 377.91 331.317 376.327C331.317 374.736 330.025 373.452 328.442 373.452C326.851 373.452 325.567 374.744 325.567 376.327C325.567 377.918 326.859 379.202 328.442 379.202Z" fill="#C2C2C2"/>
<path d="M340.076 379.202C341.667 379.202 342.951 377.91 342.951 376.327C342.951 374.736 341.659 373.452 340.076 373.452C338.485 373.452 337.201 374.744 337.201 376.327C337.193 377.918 338.485 379.202 340.076 379.202Z" fill="#C2C2C2"/>
<path d="M351.701 379.202C353.292 379.202 354.576 377.91 354.576 376.327C354.576 374.736 353.285 373.452 351.701 373.452C350.11 373.452 348.826 374.744 348.826 376.327C348.826 377.918 350.11 379.202 351.701 379.202Z" fill="#C2C2C2"/>
<path d="M363.327 379.202C364.918 379.202 366.202 377.91 366.202 376.327C366.202 374.736 364.91 373.452 363.327 373.452C361.736 373.452 360.444 374.744 360.444 376.327C360.452 377.918 361.736 379.202 363.327 379.202Z" fill="#C2C2C2"/>
<path d="M374.953 379.202C376.544 379.202 377.828 377.91 377.828 376.327C377.828 374.736 376.536 373.452 374.953 373.452C373.362 373.452 372.07 374.744 372.07 376.327C372.078 377.918 373.369 379.202 374.953 379.202Z" fill="#C2C2C2"/>
<path d="M386.586 379.202C388.177 379.202 389.461 377.91 389.461 376.327C389.461 374.736 388.17 373.452 386.586 373.452C384.995 373.452 383.704 374.744 383.704 376.327C383.704 377.918 384.995 379.202 386.586 379.202Z" fill="#C2C2C2"/>
<path d="M398.212 379.202C399.803 379.202 401.087 377.91 401.087 376.327C401.087 374.736 399.795 373.452 398.212 373.452C396.621 373.452 395.329 374.744 395.329 376.327C395.329 377.918 396.621 379.202 398.212 379.202Z" fill="#C2C2C2"/>
<path d="M409.838 379.202C411.429 379.202 412.713 377.91 412.713 376.327C412.713 374.736 411.421 373.452 409.838 373.452C408.247 373.452 406.955 374.744 406.955 376.327C406.955 377.918 408.247 379.202 409.838 379.202Z" fill="#C2C2C2"/>
<path d="M421.464 379.202C423.055 379.202 424.346 377.91 424.346 376.327C424.346 374.736 423.055 373.452 421.464 373.452C419.873 373.452 418.589 374.744 418.589 376.327C418.589 377.918 419.873 379.202 421.464 379.202Z" fill="#C2C2C2"/>
<path d="M433.089 379.202C434.68 379.202 435.972 377.91 435.972 376.327C435.972 374.736 434.68 373.452 433.089 373.452C431.498 373.452 430.214 374.744 430.214 376.327C430.214 377.918 431.506 379.202 433.089 379.202Z" fill="#C2C2C2"/>
<path d="M444.723 379.202C446.314 379.202 447.605 377.91 447.605 376.327C447.605 374.736 446.314 373.452 444.723 373.452C443.132 373.452 441.848 374.744 441.848 376.327C441.84 377.918 443.132 379.202 444.723 379.202Z" fill="#C2C2C2"/>
<path d="M456.348 379.202C457.939 379.202 459.231 377.91 459.231 376.327C459.231 374.736 457.939 373.452 456.348 373.452C454.757 373.452 453.473 374.744 453.473 376.327C453.466 377.918 454.757 379.202 456.348 379.202Z" fill="#C2C2C2"/>
<path d="M305.191 390.836C306.782 390.836 308.066 389.544 308.066 387.961C308.066 386.378 306.774 385.086 305.191 385.086C303.6 385.086 302.316 386.378 302.316 387.961C302.316 389.544 303.6 390.836 305.191 390.836Z" fill="#C2C2C2"/>
<path d="M316.816 390.836C318.407 390.836 319.691 389.544 319.691 387.961C319.691 386.378 318.4 385.086 316.816 385.086C315.225 385.086 313.941 386.378 313.941 387.961C313.941 389.544 315.225 390.836 316.816 390.836Z" fill="#C2C2C2"/>
<path d="M328.442 390.836C330.033 390.836 331.317 389.544 331.317 387.961C331.317 386.378 330.025 385.086 328.442 385.086C326.851 385.086 325.567 386.378 325.567 387.961C325.567 389.544 326.859 390.836 328.442 390.836Z" fill="#C2C2C2"/>
<path d="M340.076 390.836C341.667 390.836 342.951 389.544 342.951 387.961C342.951 386.378 341.659 385.086 340.076 385.086C338.485 385.086 337.201 386.378 337.201 387.961C337.201 389.544 338.485 390.836 340.076 390.836Z" fill="#C2C2C2"/>
<path d="M351.701 390.836C353.292 390.836 354.576 389.544 354.576 387.961C354.576 386.378 353.285 385.086 351.701 385.086C350.11 385.086 348.826 386.378 348.826 387.961C348.826 389.544 350.11 390.836 351.701 390.836Z" fill="#C2C2C2"/>
<path d="M363.327 390.836C364.918 390.836 366.202 389.544 366.202 387.961C366.202 386.378 364.91 385.086 363.327 385.086C361.736 385.086 360.444 386.378 360.444 387.961C360.444 389.544 361.736 390.836 363.327 390.836Z" fill="#C2C2C2"/>
<path d="M374.953 390.836C376.544 390.836 377.828 389.544 377.828 387.961C377.828 386.378 376.536 385.086 374.953 385.086C373.362 385.086 372.07 386.378 372.07 387.961C372.07 389.544 373.369 390.836 374.953 390.836Z" fill="#C2C2C2"/>
<path d="M386.586 390.836C388.177 390.836 389.461 389.544 389.461 387.961C389.461 386.378 388.17 385.086 386.586 385.086C384.995 385.086 383.704 386.378 383.704 387.961C383.704 389.544 384.995 390.836 386.586 390.836Z" fill="#C2C2C2"/>
<path d="M398.212 390.836C399.803 390.836 401.087 389.544 401.087 387.961C401.087 386.378 399.795 385.086 398.212 385.086C396.621 385.086 395.329 386.378 395.329 387.961C395.329 389.544 396.621 390.836 398.212 390.836Z" fill="#C2C2C2"/>
<path d="M409.838 390.836C411.429 390.836 412.713 389.544 412.713 387.961C412.713 386.378 411.421 385.086 409.838 385.086C408.247 385.086 406.955 386.378 406.955 387.961C406.955 389.544 408.247 390.836 409.838 390.836Z" fill="#C2C2C2"/>
<path d="M421.464 390.836C423.055 390.836 424.346 389.544 424.346 387.961C424.346 386.378 423.055 385.086 421.464 385.086C419.873 385.086 418.589 386.378 418.589 387.961C418.589 389.544 419.873 390.836 421.464 390.836Z" fill="#C2C2C2"/>
<path d="M433.089 390.836C434.68 390.836 435.972 389.544 435.972 387.961C435.972 386.378 434.68 385.086 433.089 385.086C431.498 385.086 430.214 386.378 430.214 387.961C430.214 389.544 431.506 390.836 433.089 390.836Z" fill="#C2C2C2"/>
<path d="M444.723 390.836C446.314 390.836 447.606 389.544 447.606 387.961C447.606 386.378 446.314 385.086 444.723 385.086C443.132 385.086 441.848 386.378 441.848 387.961C441.848 389.544 443.132 390.836 444.723 390.836Z" fill="#C2C2C2"/>
<path d="M456.349 390.836C457.94 390.836 459.231 389.544 459.231 387.961C459.231 386.378 457.94 385.086 456.349 385.086C454.758 385.086 453.474 386.378 453.474 387.961C453.474 389.544 454.758 390.836 456.349 390.836Z" fill="#C2C2C2"/>
<path d="M305.191 402.461C306.782 402.461 308.066 401.169 308.066 399.586C308.066 397.995 306.774 396.711 305.191 396.711C303.6 396.711 302.316 398.003 302.316 399.586C302.316 401.169 303.6 402.461 305.191 402.461Z" fill="#C2C2C2"/>
<path d="M316.816 402.461C318.407 402.461 319.691 401.169 319.691 399.586C319.691 397.995 318.4 396.711 316.816 396.711C315.225 396.711 313.941 398.003 313.941 399.586C313.941 401.169 315.225 402.461 316.816 402.461Z" fill="#C2C2C2"/>
<path d="M328.442 402.461C330.033 402.461 331.317 401.169 331.317 399.586C331.317 397.995 330.025 396.711 328.442 396.711C326.851 396.711 325.567 398.003 325.567 399.586C325.567 401.169 326.859 402.461 328.442 402.461Z" fill="#C2C2C2"/>
<path d="M340.076 402.461C341.667 402.461 342.951 401.169 342.951 399.586C342.951 397.995 341.659 396.711 340.076 396.711C338.485 396.711 337.201 398.003 337.201 399.586C337.193 401.169 338.485 402.461 340.076 402.461Z" fill="#C2C2C2"/>
<path d="M351.701 402.461C353.292 402.461 354.576 401.169 354.576 399.586C354.576 397.995 353.285 396.711 351.701 396.711C350.11 396.711 348.826 398.003 348.826 399.586C348.826 401.169 350.11 402.461 351.701 402.461Z" fill="#C2C2C2"/>
<path d="M363.327 402.461C364.918 402.461 366.202 401.169 366.202 399.586C366.202 397.995 364.91 396.711 363.327 396.711C361.736 396.711 360.444 398.003 360.444 399.586C360.452 401.169 361.736 402.461 363.327 402.461Z" fill="#C2C2C2"/>
<path d="M374.953 402.461C376.544 402.461 377.828 401.169 377.828 399.586C377.828 397.995 376.536 396.711 374.953 396.711C373.362 396.711 372.07 398.003 372.07 399.586C372.078 401.169 373.369 402.461 374.953 402.461Z" fill="#C2C2C2"/>
<path d="M386.586 402.461C388.177 402.461 389.461 401.169 389.461 399.586C389.461 397.995 388.17 396.711 386.586 396.711C384.995 396.711 383.704 398.003 383.704 399.586C383.704 401.169 384.995 402.461 386.586 402.461Z" fill="#C2C2C2"/>
<path d="M398.212 402.461C399.803 402.461 401.087 401.169 401.087 399.586C401.087 397.995 399.795 396.711 398.212 396.711C396.621 396.711 395.329 398.003 395.329 399.586C395.329 401.169 396.621 402.461 398.212 402.461Z" fill="#C2C2C2"/>
<path d="M409.838 402.461C411.429 402.461 412.713 401.169 412.713 399.586C412.713 397.995 411.421 396.711 409.838 396.711C408.247 396.711 406.955 398.003 406.955 399.586C406.955 401.169 408.247 402.461 409.838 402.461Z" fill="#C2C2C2"/>
<path d="M421.464 402.461C423.055 402.461 424.346 401.169 424.346 399.586C424.346 397.995 423.055 396.711 421.464 396.711C419.873 396.711 418.589 398.003 418.589 399.586C418.589 401.169 419.873 402.461 421.464 402.461Z" fill="#C2C2C2"/>
<path d="M433.089 402.461C434.68 402.461 435.972 401.169 435.972 399.586C435.972 397.995 434.68 396.711 433.089 396.711C431.498 396.711 430.214 398.003 430.214 399.586C430.214 401.169 431.506 402.461 433.089 402.461Z" fill="#C2C2C2"/>
<path d="M444.723 402.461C446.314 402.461 447.605 401.169 447.605 399.586C447.605 397.995 446.314 396.711 444.723 396.711C443.132 396.711 441.848 398.003 441.848 399.586C441.84 401.169 443.132 402.461 444.723 402.461Z" fill="#C2C2C2"/>
<path d="M281.939 414.087C283.53 414.087 284.814 412.795 284.814 411.212C284.814 409.621 283.522 408.337 281.939 408.337C280.348 408.337 279.064 409.629 279.064 411.212C279.056 412.803 280.348 414.087 281.939 414.087Z" fill="#C2C2C2"/>
<path d="M293.565 414.087C295.156 414.087 296.44 412.795 296.44 411.212C296.44 409.621 295.148 408.337 293.565 408.337C291.982 408.337 290.69 409.629 290.69 411.212C290.682 412.803 291.974 414.087 293.565 414.087Z" fill="#C2C2C2"/>
<path d="M305.191 414.087C306.782 414.087 308.066 412.795 308.066 411.212C308.066 409.621 306.774 408.337 305.191 408.337C303.6 408.337 302.316 409.629 302.316 411.212C302.316 412.803 303.6 414.087 305.191 414.087Z" fill="#C2C2C2"/>
<path d="M316.816 414.087C318.407 414.087 319.691 412.795 319.691 411.212C319.691 409.621 318.4 408.337 316.816 408.337C315.225 408.337 313.941 409.629 313.941 411.212C313.941 412.803 315.225 414.087 316.816 414.087Z" fill="#C2C2C2"/>
<path d="M328.442 414.087C330.033 414.087 331.317 412.795 331.317 411.212C331.317 409.621 330.025 408.337 328.442 408.337C326.851 408.337 325.567 409.629 325.567 411.212C325.567 412.803 326.859 414.087 328.442 414.087Z" fill="#C2C2C2"/>
<path d="M340.076 414.087C341.667 414.087 342.951 412.795 342.951 411.212C342.951 409.621 341.659 408.337 340.076 408.337C338.485 408.337 337.201 409.629 337.201 411.212C337.193 412.803 338.485 414.087 340.076 414.087Z" fill="#C2C2C2"/>
<path d="M351.701 414.087C353.292 414.087 354.576 412.795 354.576 411.212C354.576 409.621 353.285 408.337 351.701 408.337C350.11 408.337 348.826 409.629 348.826 411.212C348.826 412.803 350.11 414.087 351.701 414.087Z" fill="#C2C2C2"/>
<path d="M363.327 414.087C364.918 414.087 366.202 412.795 366.202 411.212C366.202 409.621 364.91 408.337 363.327 408.337C361.736 408.337 360.444 409.629 360.444 411.212C360.452 412.803 361.736 414.087 363.327 414.087Z" fill="#C2C2C2"/>
<path d="M374.953 414.087C376.544 414.087 377.828 412.795 377.828 411.212C377.828 409.621 376.536 408.337 374.953 408.337C373.362 408.337 372.07 409.629 372.07 411.212C372.078 412.803 373.369 414.087 374.953 414.087Z" fill="#C2C2C2"/>
<path d="M386.586 414.087C388.177 414.087 389.461 412.795 389.461 411.212C389.461 409.621 388.17 408.337 386.586 408.337C384.995 408.337 383.704 409.629 383.704 411.212C383.704 412.803 384.995 414.087 386.586 414.087Z" fill="#C2C2C2"/>
<path d="M398.212 414.087C399.803 414.087 401.087 412.795 401.087 411.212C401.087 409.621 399.795 408.337 398.212 408.337C396.621 408.337 395.329 409.629 395.329 411.212C395.329 412.803 396.621 414.087 398.212 414.087Z" fill="#C2C2C2"/>
<path d="M409.838 414.087C411.429 414.087 412.713 412.795 412.713 411.212C412.713 409.621 411.421 408.337 409.838 408.337C408.247 408.337 406.955 409.629 406.955 411.212C406.955 412.803 408.247 414.087 409.838 414.087Z" fill="#C2C2C2"/>
<path d="M421.464 414.087C423.055 414.087 424.346 412.795 424.346 411.212C424.346 409.621 423.055 408.337 421.464 408.337C419.873 408.337 418.589 409.629 418.589 411.212C418.589 412.803 419.873 414.087 421.464 414.087Z" fill="#C2C2C2"/>
<path d="M433.089 414.087C434.68 414.087 435.972 412.795 435.972 411.212C435.972 409.621 434.68 408.337 433.089 408.337C431.498 408.337 430.214 409.629 430.214 411.212C430.214 412.803 431.506 414.087 433.089 414.087Z" fill="#C2C2C2"/>
<path d="M444.723 414.087C446.314 414.087 447.605 412.795 447.605 411.212C447.605 409.621 446.314 408.337 444.723 408.337C443.132 408.337 441.848 409.629 441.848 411.212C441.84 412.803 443.132 414.087 444.723 414.087Z" fill="#C2C2C2"/>
<path d="M281.939 425.713C283.53 425.713 284.814 424.421 284.814 422.838C284.814 421.247 283.522 419.963 281.939 419.963C280.348 419.963 279.064 421.255 279.064 422.838C279.056 424.429 280.348 425.713 281.939 425.713Z" fill="#C2C2C2"/>
<path d="M293.565 425.713C295.156 425.713 296.44 424.421 296.44 422.838C296.44 421.247 295.148 419.963 293.565 419.963C291.982 419.963 290.69 421.255 290.69 422.838C290.682 424.429 291.974 425.713 293.565 425.713Z" fill="#C2C2C2"/>
<path d="M305.191 425.713C306.782 425.713 308.066 424.421 308.066 422.838C308.066 421.247 306.774 419.963 305.191 419.963C303.6 419.963 302.316 421.255 302.316 422.838C302.316 424.429 303.6 425.713 305.191 425.713Z" fill="#C2C2C2"/>
<path d="M316.816 425.713C318.407 425.713 319.691 424.421 319.691 422.838C319.691 421.247 318.4 419.963 316.816 419.963C315.225 419.963 313.941 421.255 313.941 422.838C313.941 424.429 315.225 425.713 316.816 425.713Z" fill="#C2C2C2"/>
<path d="M328.442 425.713C330.033 425.713 331.317 424.421 331.317 422.838C331.317 421.247 330.025 419.963 328.442 419.963C326.851 419.963 325.567 421.255 325.567 422.838C325.567 424.429 326.859 425.713 328.442 425.713Z" fill="#C2C2C2"/>
<path d="M340.076 425.713C341.667 425.713 342.951 424.421 342.951 422.838C342.951 421.247 341.659 419.963 340.076 419.963C338.485 419.963 337.201 421.255 337.201 422.838C337.193 424.429 338.485 425.713 340.076 425.713Z" fill="#C2C2C2"/>
<path d="M351.701 425.713C353.292 425.713 354.576 424.421 354.576 422.838C354.576 421.247 353.285 419.963 351.701 419.963C350.11 419.963 348.826 421.255 348.826 422.838C348.826 424.429 350.11 425.713 351.701 425.713Z" fill="#C2C2C2"/>
<path d="M363.327 425.713C364.918 425.713 366.202 424.421 366.202 422.838C366.202 421.247 364.91 419.963 363.327 419.963C361.736 419.963 360.444 421.255 360.444 422.838C360.452 424.429 361.736 425.713 363.327 425.713Z" fill="#C2C2C2"/>
<path d="M374.953 425.713C376.544 425.713 377.828 424.421 377.828 422.838C377.828 421.247 376.536 419.963 374.953 419.963C373.362 419.963 372.07 421.255 372.07 422.838C372.078 424.429 373.369 425.713 374.953 425.713Z" fill="#C2C2C2"/>
<path d="M386.586 425.713C388.177 425.713 389.461 424.421 389.461 422.838C389.461 421.247 388.17 419.963 386.586 419.963C384.995 419.963 383.704 421.255 383.704 422.838C383.704 424.429 384.995 425.713 386.586 425.713Z" fill="#C2C2C2"/>
<path d="M398.212 425.713C399.803 425.713 401.087 424.421 401.087 422.838C401.087 421.247 399.795 419.963 398.212 419.963C396.621 419.963 395.329 421.255 395.329 422.838C395.329 424.429 396.621 425.713 398.212 425.713Z" fill="#C2C2C2"/>
<path d="M409.838 425.713C411.429 425.713 412.713 424.421 412.713 422.838C412.713 421.247 411.421 419.963 409.838 419.963C408.247 419.963 406.955 421.255 406.955 422.838C406.955 424.429 408.247 425.713 409.838 425.713Z" fill="#C2C2C2"/>
<path d="M421.464 425.713C423.055 425.713 424.346 424.421 424.346 422.838C424.346 421.247 423.055 419.963 421.464 419.963C419.873 419.963 418.589 421.255 418.589 422.838C418.589 424.429 419.873 425.713 421.464 425.713Z" fill="#C2C2C2"/>
<path d="M433.089 425.713C434.68 425.713 435.972 424.421 435.972 422.838C435.972 421.247 434.68 419.963 433.089 419.963C431.498 419.963 430.214 421.255 430.214 422.838C430.214 424.429 431.506 425.713 433.089 425.713Z" fill="#C2C2C2"/>
<path d="M444.723 425.713C446.314 425.713 447.605 424.421 447.605 422.838C447.605 421.247 446.314 419.963 444.723 419.963C443.132 419.963 441.848 421.255 441.848 422.838C441.84 424.429 443.132 425.713 444.723 425.713Z" fill="#C2C2C2"/>
<path d="M270.306 437.346C271.897 437.346 273.181 436.054 273.181 434.471C273.181 432.88 271.889 431.596 270.306 431.596C268.715 431.596 267.431 432.888 267.431 434.471C267.431 436.054 268.722 437.346 270.306 437.346Z" fill="#C2C2C2"/>
<path d="M281.939 437.346C283.53 437.346 284.814 436.054 284.814 434.471C284.814 432.88 283.522 431.596 281.939 431.596C280.348 431.596 279.064 432.888 279.064 434.471C279.056 436.054 280.348 437.346 281.939 437.346Z" fill="#C2C2C2"/>
<path d="M293.565 437.346C295.156 437.346 296.44 436.054 296.44 434.471C296.44 432.88 295.148 431.596 293.565 431.596C291.982 431.596 290.69 432.888 290.69 434.471C290.682 436.054 291.974 437.346 293.565 437.346Z" fill="#C2C2C2"/>
<path d="M305.191 437.346C306.782 437.346 308.066 436.054 308.066 434.471C308.066 432.88 306.774 431.596 305.191 431.596C303.6 431.596 302.316 432.888 302.316 434.471C302.316 436.054 303.6 437.346 305.191 437.346Z" fill="#C2C2C2"/>
<path d="M316.816 437.346C318.407 437.346 319.691 436.054 319.691 434.471C319.691 432.88 318.4 431.596 316.816 431.596C315.225 431.596 313.941 432.888 313.941 434.471C313.941 436.054 315.225 437.346 316.816 437.346Z" fill="#C2C2C2"/>
<path d="M328.442 437.346C330.033 437.346 331.317 436.054 331.317 434.471C331.317 432.88 330.025 431.596 328.442 431.596C326.851 431.596 325.567 432.888 325.567 434.471C325.567 436.054 326.859 437.346 328.442 437.346Z" fill="#C2C2C2"/>
<path d="M340.076 437.346C341.667 437.346 342.951 436.054 342.951 434.471C342.951 432.88 341.659 431.596 340.076 431.596C338.485 431.596 337.201 432.888 337.201 434.471C337.193 436.054 338.485 437.346 340.076 437.346Z" fill="#C2C2C2"/>
<path d="M351.701 437.346C353.292 437.346 354.576 436.054 354.576 434.471C354.576 432.88 353.285 431.596 351.701 431.596C350.11 431.596 348.826 432.888 348.826 434.471C348.826 436.054 350.11 437.346 351.701 437.346Z" fill="#C2C2C2"/>
<path d="M363.327 437.346C364.918 437.346 366.202 436.054 366.202 434.471C366.202 432.88 364.91 431.596 363.327 431.596C361.736 431.596 360.444 432.888 360.444 434.471C360.452 436.054 361.736 437.346 363.327 437.346Z" fill="#C2C2C2"/>
<path d="M374.953 437.346C376.544 437.346 377.828 436.054 377.828 434.471C377.828 432.88 376.536 431.596 374.953 431.596C373.362 431.596 372.07 432.888 372.07 434.471C372.078 436.054 373.369 437.346 374.953 437.346Z" fill="#C2C2C2"/>
<path d="M386.586 437.346C388.177 437.346 389.461 436.054 389.461 434.471C389.461 432.88 388.17 431.596 386.586 431.596C384.995 431.596 383.704 432.888 383.704 434.471C383.704 436.054 384.995 437.346 386.586 437.346Z" fill="#C2C2C2"/>
<path d="M398.212 437.346C399.803 437.346 401.087 436.054 401.087 434.471C401.087 432.88 399.795 431.596 398.212 431.596C396.621 431.596 395.329 432.888 395.329 434.471C395.329 436.054 396.621 437.346 398.212 437.346Z" fill="#C2C2C2"/>
<path d="M409.838 437.346C411.429 437.346 412.713 436.054 412.713 434.471C412.713 432.88 411.421 431.596 409.838 431.596C408.247 431.596 406.955 432.888 406.955 434.471C406.955 436.054 408.247 437.346 409.838 437.346Z" fill="#C2C2C2"/>
<path d="M421.464 437.346C423.055 437.346 424.346 436.054 424.346 434.471C424.346 432.88 423.055 431.596 421.464 431.596C419.873 431.596 418.589 432.888 418.589 434.471C418.589 436.054 419.873 437.346 421.464 437.346Z" fill="#C2C2C2"/>
<path d="M433.089 437.346C434.68 437.346 435.972 436.054 435.972 434.471C435.972 432.88 434.68 431.596 433.089 431.596C431.498 431.596 430.214 432.888 430.214 434.471C430.214 436.054 431.506 437.346 433.089 437.346Z" fill="#C2C2C2"/>
<path d="M444.723 437.346C446.314 437.346 447.605 436.054 447.605 434.471C447.605 432.88 446.314 431.596 444.723 431.596C443.132 431.596 441.848 432.888 441.848 434.471C441.84 436.054 443.132 437.346 444.723 437.346Z" fill="#C2C2C2"/>
<path d="M270.306 448.972C271.897 448.972 273.181 447.68 273.181 446.097C273.181 444.506 271.889 443.222 270.306 443.222C268.715 443.222 267.431 444.514 267.431 446.097C267.431 447.68 268.722 448.972 270.306 448.972Z" fill="#C2C2C2"/>
<path d="M281.939 448.972C283.53 448.972 284.814 447.68 284.814 446.097C284.814 444.506 283.522 443.222 281.939 443.222C280.348 443.222 279.064 444.514 279.064 446.097C279.056 447.68 280.348 448.972 281.939 448.972Z" fill="#C2C2C2"/>
<path d="M293.565 448.972C295.156 448.972 296.44 447.68 296.44 446.097C296.44 444.506 295.148 443.222 293.565 443.222C291.982 443.222 290.69 444.514 290.69 446.097C290.682 447.68 291.974 448.972 293.565 448.972Z" fill="#C2C2C2"/>
<path d="M305.191 448.972C306.782 448.972 308.066 447.68 308.066 446.097C308.066 444.506 306.774 443.222 305.191 443.222C303.6 443.222 302.316 444.514 302.316 446.097C302.316 447.68 303.6 448.972 305.191 448.972Z" fill="#C2C2C2"/>
<path d="M316.816 448.972C318.407 448.972 319.691 447.68 319.691 446.097C319.691 444.506 318.4 443.222 316.816 443.222C315.225 443.222 313.941 444.514 313.941 446.097C313.941 447.68 315.225 448.972 316.816 448.972Z" fill="#C2C2C2"/>
<path d="M328.442 448.972C330.033 448.972 331.317 447.68 331.317 446.097C331.317 444.506 330.025 443.222 328.442 443.222C326.851 443.222 325.567 444.514 325.567 446.097C325.567 447.68 326.859 448.972 328.442 448.972Z" fill="#C2C2C2"/>
<path d="M340.076 448.972C341.667 448.972 342.951 447.68 342.951 446.097C342.951 444.506 341.659 443.222 340.076 443.222C338.485 443.222 337.201 444.514 337.201 446.097C337.193 447.68 338.485 448.972 340.076 448.972Z" fill="#C2C2C2"/>
<path d="M351.701 448.972C353.292 448.972 354.576 447.68 354.576 446.097C354.576 444.506 353.285 443.222 351.701 443.222C350.11 443.222 348.826 444.514 348.826 446.097C348.826 447.68 350.11 448.972 351.701 448.972Z" fill="#C2C2C2"/>
<path d="M363.327 448.972C364.918 448.972 366.202 447.68 366.202 446.097C366.202 444.506 364.91 443.222 363.327 443.222C361.736 443.222 360.444 444.514 360.444 446.097C360.452 447.68 361.736 448.972 363.327 448.972Z" fill="#C2C2C2"/>
<path d="M374.953 448.972C376.544 448.972 377.828 447.68 377.828 446.097C377.828 444.506 376.536 443.222 374.953 443.222C373.362 443.222 372.07 444.514 372.07 446.097C372.078 447.68 373.369 448.972 374.953 448.972Z" fill="#C2C2C2"/>
<path d="M386.586 448.972C388.177 448.972 389.461 447.68 389.461 446.097C389.461 444.506 388.17 443.222 386.586 443.222C384.995 443.222 383.704 444.514 383.704 446.097C383.704 447.68 384.995 448.972 386.586 448.972Z" fill="#C2C2C2"/>
<path d="M398.212 448.972C399.803 448.972 401.087 447.68 401.087 446.097C401.087 444.506 399.795 443.222 398.212 443.222C396.621 443.222 395.329 444.514 395.329 446.097C395.329 447.68 396.621 448.972 398.212 448.972Z" fill="#C2C2C2"/>
<path d="M409.838 448.972C411.429 448.972 412.713 447.68 412.713 446.097C412.713 444.506 411.421 443.222 409.838 443.222C408.247 443.222 406.955 444.514 406.955 446.097C406.955 447.68 408.247 448.972 409.838 448.972Z" fill="#C2C2C2"/>
<path d="M421.464 448.972C423.055 448.972 424.346 447.68 424.346 446.097C424.346 444.506 423.055 443.222 421.464 443.222C419.873 443.222 418.589 444.514 418.589 446.097C418.589 447.68 419.873 448.972 421.464 448.972Z" fill="#C2C2C2"/>
<path d="M433.089 448.972C434.68 448.972 435.972 447.68 435.972 446.097C435.972 444.506 434.68 443.222 433.089 443.222C431.498 443.222 430.214 444.514 430.214 446.097C430.214 447.68 431.506 448.972 433.089 448.972Z" fill="#C2C2C2"/>
<path d="M444.723 448.972C446.314 448.972 447.605 447.68 447.605 446.097C447.605 444.506 446.314 443.222 444.723 443.222C443.132 443.222 441.848 444.514 441.848 446.097C441.84 447.68 443.132 448.972 444.723 448.972Z" fill="#C2C2C2"/>
<path d="M281.939 460.598C283.53 460.598 284.814 459.306 284.814 457.723C284.814 456.132 283.522 454.848 281.939 454.848C280.348 454.848 279.064 456.139 279.064 457.723C279.056 459.306 280.348 460.598 281.939 460.598Z" fill="#C2C2C2"/>
<path d="M293.565 460.598C295.156 460.598 296.44 459.306 296.44 457.723C296.44 456.132 295.148 454.848 293.565 454.848C291.982 454.848 290.69 456.139 290.69 457.723C290.682 459.306 291.974 460.598 293.565 460.598Z" fill="#C2C2C2"/>
<path d="M305.191 460.598C306.782 460.598 308.066 459.306 308.066 457.723C308.066 456.132 306.774 454.848 305.191 454.848C303.6 454.848 302.316 456.139 302.316 457.723C302.316 459.306 303.6 460.598 305.191 460.598Z" fill="#C2C2C2"/>
<path d="M316.816 460.598C318.407 460.598 319.691 459.306 319.691 457.723C319.691 456.132 318.4 454.848 316.816 454.848C315.225 454.848 313.941 456.139 313.941 457.723C313.941 459.306 315.225 460.598 316.816 460.598Z" fill="#C2C2C2"/>
<path d="M328.442 460.598C330.033 460.598 331.317 459.306 331.317 457.723C331.317 456.132 330.025 454.848 328.442 454.848C326.851 454.848 325.567 456.139 325.567 457.723C325.567 459.306 326.859 460.598 328.442 460.598Z" fill="#C2C2C2"/>
<path d="M340.076 460.598C341.667 460.598 342.951 459.306 342.951 457.723C342.951 456.132 341.659 454.848 340.076 454.848C338.485 454.848 337.201 456.139 337.201 457.723C337.193 459.306 338.485 460.598 340.076 460.598Z" fill="#C2C2C2"/>
<path d="M351.701 460.598C353.292 460.598 354.576 459.306 354.576 457.723C354.576 456.132 353.285 454.848 351.701 454.848C350.11 454.848 348.826 456.139 348.826 457.723C348.826 459.306 350.11 460.598 351.701 460.598Z" fill="#C2C2C2"/>
<path d="M363.327 460.598C364.918 460.598 366.202 459.306 366.202 457.723C366.202 456.132 364.91 454.848 363.327 454.848C361.736 454.848 360.444 456.139 360.444 457.723C360.452 459.306 361.736 460.598 363.327 460.598Z" fill="#C2C2C2"/>
<path d="M374.953 460.598C376.544 460.598 377.828 459.306 377.828 457.723C377.828 456.132 376.536 454.848 374.953 454.848C373.362 454.848 372.07 456.139 372.07 457.723C372.078 459.306 373.369 460.598 374.953 460.598Z" fill="#C2C2C2"/>
<path d="M386.586 460.598C388.177 460.598 389.461 459.306 389.461 457.723C389.461 456.132 388.17 454.848 386.586 454.848C384.995 454.848 383.704 456.139 383.704 457.723C383.704 459.306 384.995 460.598 386.586 460.598Z" fill="#C2C2C2"/>
<path d="M398.212 460.598C399.803 460.598 401.087 459.306 401.087 457.723C401.087 456.132 399.795 454.848 398.212 454.848C396.621 454.848 395.329 456.139 395.329 457.723C395.329 459.306 396.621 460.598 398.212 460.598Z" fill="#C2C2C2"/>
<path d="M409.838 460.598C411.429 460.598 412.713 459.306 412.713 457.723C412.713 456.132 411.421 454.848 409.838 454.848C408.247 454.848 406.955 456.139 406.955 457.723C406.955 459.306 408.247 460.598 409.838 460.598Z" fill="#C2C2C2"/>
<path d="M421.464 460.598C423.055 460.598 424.346 459.306 424.346 457.723C424.346 456.132 423.055 454.848 421.464 454.848C419.873 454.848 418.589 456.139 418.589 457.723C418.589 459.306 419.873 460.598 421.464 460.598Z" fill="#C2C2C2"/>
<path d="M433.089 460.598C434.68 460.598 435.972 459.306 435.972 457.723C435.972 456.132 434.68 454.848 433.089 454.848C431.498 454.848 430.214 456.139 430.214 457.723C430.214 459.306 431.506 460.598 433.089 460.598Z" fill="#C2C2C2"/>
<path d="M444.723 460.598C446.314 460.598 447.605 459.306 447.605 457.723C447.605 456.132 446.314 454.848 444.723 454.848C443.132 454.848 441.848 456.139 441.848 457.723C441.84 459.306 443.132 460.598 444.723 460.598Z" fill="#C2C2C2"/>
<path d="M456.348 460.598C457.939 460.598 459.231 459.306 459.231 457.723C459.231 456.132 457.939 454.848 456.348 454.848C454.757 454.848 453.473 456.139 453.473 457.723C453.466 459.306 454.757 460.598 456.348 460.598Z" fill="#C2C2C2"/>
<path d="M467.974 460.598C469.565 460.598 470.857 459.306 470.857 457.723C470.857 456.132 469.565 454.848 467.974 454.848C466.383 454.848 465.099 456.139 465.099 457.723C465.099 459.306 466.383 460.598 467.974 460.598Z" fill="#C2C2C2"/>
<path d="M479.6 460.598C481.191 460.598 482.475 459.306 482.475 457.723C482.475 456.132 481.191 454.848 479.6 454.848C478.009 454.848 476.725 456.139 476.725 457.723C476.725 459.306 478.017 460.598 479.6 460.598Z" fill="#C2C2C2"/>
<path d="M491.225 460.598C492.817 460.598 494.1 459.306 494.1 457.723C494.1 456.132 492.817 454.848 491.225 454.848C489.634 454.848 488.351 456.139 488.351 457.723C488.351 459.306 489.642 460.598 491.225 460.598Z" fill="#C2C2C2"/>
<path d="M293.565 472.224C295.156 472.224 296.44 470.932 296.44 469.349C296.44 467.758 295.148 466.474 293.565 466.474C291.982 466.474 290.69 467.765 290.69 469.349C290.682 470.94 291.974 472.224 293.565 472.224Z" fill="#C2C2C2"/>
<path d="M305.191 472.224C306.782 472.224 308.066 470.932 308.066 469.349C308.066 467.758 306.774 466.474 305.191 466.474C303.6 466.474 302.316 467.765 302.316 469.349C302.316 470.94 303.6 472.224 305.191 472.224Z" fill="#C2C2C2"/>
<path d="M316.816 472.224C318.407 472.224 319.691 470.932 319.691 469.349C319.691 467.758 318.4 466.474 316.816 466.474C315.225 466.474 313.941 467.765 313.941 469.349C313.941 470.94 315.225 472.224 316.816 472.224Z" fill="#C2C2C2"/>
<path d="M328.442 472.224C330.033 472.224 331.317 470.932 331.317 469.349C331.317 467.758 330.025 466.474 328.442 466.474C326.851 466.474 325.567 467.765 325.567 469.349C325.567 470.94 326.859 472.224 328.442 472.224Z" fill="#C2C2C2"/>
<path d="M340.076 472.224C341.667 472.224 342.951 470.932 342.951 469.349C342.951 467.758 341.659 466.474 340.076 466.474C338.485 466.474 337.201 467.765 337.201 469.349C337.193 470.94 338.485 472.224 340.076 472.224Z" fill="#C2C2C2"/>
<path d="M351.701 472.224C353.292 472.224 354.576 470.932 354.576 469.349C354.576 467.758 353.285 466.474 351.701 466.474C350.11 466.474 348.826 467.765 348.826 469.349C348.826 470.94 350.11 472.224 351.701 472.224Z" fill="#C2C2C2"/>
<path d="M363.327 472.224C364.918 472.224 366.202 470.932 366.202 469.349C366.202 467.758 364.91 466.474 363.327 466.474C361.736 466.474 360.444 467.765 360.444 469.349C360.452 470.94 361.736 472.224 363.327 472.224Z" fill="#C2C2C2"/>
<path d="M374.953 472.224C376.544 472.224 377.828 470.932 377.828 469.349C377.828 467.758 376.536 466.474 374.953 466.474C373.362 466.474 372.07 467.765 372.07 469.349C372.078 470.94 373.369 472.224 374.953 472.224Z" fill="#C2C2C2"/>
<path d="M386.586 472.224C388.177 472.224 389.461 470.932 389.461 469.349C389.461 467.758 388.17 466.474 386.586 466.474C384.995 466.474 383.704 467.765 383.704 469.349C383.704 470.94 384.995 472.224 386.586 472.224Z" fill="#C2C2C2"/>
<path d="M398.212 472.224C399.803 472.224 401.087 470.932 401.087 469.349C401.087 467.758 399.795 466.474 398.212 466.474C396.621 466.474 395.329 467.765 395.329 469.349C395.329 470.94 396.621 472.224 398.212 472.224Z" fill="#C2C2C2"/>
<path d="M409.838 472.224C411.429 472.224 412.713 470.932 412.713 469.349C412.713 467.758 411.421 466.474 409.838 466.474C408.247 466.474 406.955 467.765 406.955 469.349C406.955 470.94 408.247 472.224 409.838 472.224Z" fill="#C2C2C2"/>
<path d="M421.464 472.224C423.055 472.224 424.346 470.932 424.346 469.349C424.346 467.758 423.055 466.474 421.464 466.474C419.873 466.474 418.589 467.765 418.589 469.349C418.589 470.94 419.873 472.224 421.464 472.224Z" fill="#C2C2C2"/>
<path d="M433.089 472.224C434.68 472.224 435.972 470.932 435.972 469.349C435.972 467.758 434.68 466.474 433.089 466.474C431.498 466.474 430.214 467.765 430.214 469.349C430.214 470.94 431.506 472.224 433.089 472.224Z" fill="#C2C2C2"/>
<path d="M444.723 472.224C446.314 472.224 447.605 470.932 447.605 469.349C447.605 467.758 446.314 466.474 444.723 466.474C443.132 466.474 441.848 467.765 441.848 469.349C441.84 470.94 443.132 472.224 444.723 472.224Z" fill="#C2C2C2"/>
<path d="M456.348 472.224C457.939 472.224 459.231 470.932 459.231 469.349C459.231 467.758 457.939 466.474 456.348 466.474C454.757 466.474 453.473 467.765 453.473 469.349C453.466 470.94 454.757 472.224 456.348 472.224Z" fill="#C2C2C2"/>
<path d="M467.974 472.224C469.565 472.224 470.857 470.932 470.857 469.349C470.857 467.758 469.565 466.474 467.974 466.474C466.383 466.474 465.099 467.765 465.099 469.349C465.099 470.94 466.383 472.224 467.974 472.224Z" fill="#C2C2C2"/>
<path d="M479.6 472.224C481.191 472.224 482.475 470.932 482.475 469.349C482.475 467.758 481.191 466.474 479.6 466.474C478.009 466.474 476.725 467.765 476.725 469.349C476.725 470.94 478.017 472.224 479.6 472.224Z" fill="#C2C2C2"/>
<path d="M491.225 472.224C492.817 472.224 494.1 470.932 494.1 469.349C494.1 467.758 492.817 466.474 491.225 466.474C489.634 466.474 488.351 467.765 488.351 469.349C488.351 470.94 489.642 472.224 491.225 472.224Z" fill="#C2C2C2"/>
<path d="M293.565 483.849C295.156 483.849 296.44 482.557 296.44 480.974C296.44 479.383 295.148 478.099 293.565 478.099C291.982 478.099 290.69 479.391 290.69 480.974C290.682 482.565 291.974 483.849 293.565 483.849Z" fill="#C2C2C2"/>
<path d="M305.191 483.849C306.782 483.849 308.066 482.557 308.066 480.974C308.066 479.383 306.774 478.099 305.191 478.099C303.6 478.099 302.316 479.391 302.316 480.974C302.316 482.565 303.6 483.849 305.191 483.849Z" fill="#C2C2C2"/>
<path d="M316.816 483.849C318.407 483.849 319.691 482.557 319.691 480.974C319.691 479.383 318.4 478.099 316.816 478.099C315.225 478.099 313.941 479.391 313.941 480.974C313.941 482.565 315.225 483.849 316.816 483.849Z" fill="#C2C2C2"/>
<path d="M328.442 483.849C330.033 483.849 331.317 482.557 331.317 480.974C331.317 479.383 330.025 478.099 328.442 478.099C326.851 478.099 325.567 479.391 325.567 480.974C325.567 482.565 326.859 483.849 328.442 483.849Z" fill="#C2C2C2"/>
<path d="M340.076 483.849C341.667 483.849 342.951 482.557 342.951 480.974C342.951 479.383 341.659 478.099 340.076 478.099C338.485 478.099 337.201 479.391 337.201 480.974C337.193 482.565 338.485 483.849 340.076 483.849Z" fill="#C2C2C2"/>
<path d="M351.701 483.849C353.292 483.849 354.576 482.557 354.576 480.974C354.576 479.383 353.285 478.099 351.701 478.099C350.11 478.099 348.826 479.391 348.826 480.974C348.826 482.565 350.11 483.849 351.701 483.849Z" fill="#C2C2C2"/>
<path d="M363.327 483.849C364.918 483.849 366.202 482.557 366.202 480.974C366.202 479.383 364.91 478.099 363.327 478.099C361.736 478.099 360.444 479.391 360.444 480.974C360.452 482.565 361.736 483.849 363.327 483.849Z" fill="#C2C2C2"/>
<path d="M374.953 483.849C376.544 483.849 377.828 482.557 377.828 480.974C377.828 479.383 376.536 478.099 374.953 478.099C373.362 478.099 372.07 479.391 372.07 480.974C372.078 482.565 373.369 483.849 374.953 483.849Z" fill="#C2C2C2"/>
<path d="M386.586 483.849C388.177 483.849 389.461 482.557 389.461 480.974C389.461 479.383 388.17 478.099 386.586 478.099C384.995 478.099 383.704 479.391 383.704 480.974C383.704 482.565 384.995 483.849 386.586 483.849Z" fill="#C2C2C2"/>
<path d="M398.212 483.849C399.803 483.849 401.087 482.557 401.087 480.974C401.087 479.383 399.795 478.099 398.212 478.099C396.621 478.099 395.329 479.391 395.329 480.974C395.329 482.565 396.621 483.849 398.212 483.849Z" fill="#C2C2C2"/>
<path d="M409.838 483.849C411.429 483.849 412.713 482.557 412.713 480.974C412.713 479.383 411.421 478.099 409.838 478.099C408.247 478.099 406.955 479.391 406.955 480.974C406.955 482.565 408.247 483.849 409.838 483.849Z" fill="#C2C2C2"/>
<path d="M421.464 483.849C423.055 483.849 424.346 482.557 424.346 480.974C424.346 479.383 423.055 478.099 421.464 478.099C419.873 478.099 418.589 479.391 418.589 480.974C418.589 482.565 419.873 483.849 421.464 483.849Z" fill="#C2C2C2"/>
<path d="M433.089 483.849C434.68 483.849 435.972 482.557 435.972 480.974C435.972 479.383 434.68 478.099 433.089 478.099C431.498 478.099 430.214 479.391 430.214 480.974C430.214 482.565 431.506 483.849 433.089 483.849Z" fill="#C2C2C2"/>
<path d="M444.723 483.849C446.314 483.849 447.605 482.557 447.605 480.974C447.605 479.383 446.314 478.099 444.723 478.099C443.132 478.099 441.848 479.391 441.848 480.974C441.84 482.565 443.132 483.849 444.723 483.849Z" fill="#C2C2C2"/>
<path d="M456.348 483.849C457.939 483.849 459.231 482.557 459.231 480.974C459.231 479.383 457.939 478.099 456.348 478.099C454.757 478.099 453.473 479.391 453.473 480.974C453.466 482.565 454.757 483.849 456.348 483.849Z" fill="#C2C2C2"/>
<path d="M467.974 483.849C469.565 483.849 470.857 482.557 470.857 480.974C470.857 479.383 469.565 478.099 467.974 478.099C466.383 478.099 465.099 479.391 465.099 480.974C465.099 482.565 466.383 483.849 467.974 483.849Z" fill="#C2C2C2"/>
<path d="M479.6 483.849C481.191 483.849 482.475 482.557 482.475 480.974C482.475 479.383 481.191 478.099 479.6 478.099C478.009 478.099 476.725 479.391 476.725 480.974C476.725 482.565 478.017 483.849 479.6 483.849Z" fill="#C2C2C2"/>
<path d="M305.191 495.483C306.782 495.483 308.066 494.191 308.066 492.608C308.066 491.017 306.774 489.733 305.191 489.733C303.6 489.733 302.316 491.025 302.316 492.608C302.316 494.191 303.6 495.483 305.191 495.483Z" fill="#C2C2C2"/>
<path d="M316.816 495.483C318.407 495.483 319.691 494.191 319.691 492.608C319.691 491.017 318.4 489.733 316.816 489.733C315.225 489.733 313.941 491.025 313.941 492.608C313.941 494.191 315.225 495.483 316.816 495.483Z" fill="#C2C2C2"/>
<path d="M328.442 495.483C330.033 495.483 331.317 494.191 331.317 492.608C331.317 491.017 330.025 489.733 328.442 489.733C326.851 489.733 325.567 491.025 325.567 492.608C325.567 494.191 326.859 495.483 328.442 495.483Z" fill="#C2C2C2"/>
<path d="M340.076 495.483C341.667 495.483 342.951 494.191 342.951 492.608C342.951 491.017 341.659 489.733 340.076 489.733C338.485 489.733 337.201 491.025 337.201 492.608C337.193 494.191 338.485 495.483 340.076 495.483Z" fill="#C2C2C2"/>
<path d="M351.701 495.483C353.292 495.483 354.576 494.191 354.576 492.608C354.576 491.017 353.285 489.733 351.701 489.733C350.11 489.733 348.826 491.025 348.826 492.608C348.826 494.191 350.11 495.483 351.701 495.483Z" fill="#C2C2C2"/>
<path d="M363.327 495.483C364.918 495.483 366.202 494.191 366.202 492.608C366.202 491.017 364.91 489.733 363.327 489.733C361.736 489.733 360.444 491.025 360.444 492.608C360.452 494.191 361.736 495.483 363.327 495.483Z" fill="#C2C2C2"/>
<path d="M374.953 495.483C376.544 495.483 377.828 494.191 377.828 492.608C377.828 491.017 376.536 489.733 374.953 489.733C373.362 489.733 372.07 491.025 372.07 492.608C372.078 494.191 373.369 495.483 374.953 495.483Z" fill="#C2C2C2"/>
<path d="M386.586 495.483C388.177 495.483 389.461 494.191 389.461 492.608C389.461 491.017 388.17 489.733 386.586 489.733C384.995 489.733 383.704 491.025 383.704 492.608C383.704 494.191 384.995 495.483 386.586 495.483Z" fill="#C2C2C2"/>
<path d="M398.212 495.483C399.803 495.483 401.087 494.191 401.087 492.608C401.087 491.017 399.795 489.733 398.212 489.733C396.621 489.733 395.329 491.025 395.329 492.608C395.329 494.191 396.621 495.483 398.212 495.483Z" fill="#C2C2C2"/>
<path d="M409.838 495.483C411.429 495.483 412.713 494.191 412.713 492.608C412.713 491.017 411.421 489.733 409.838 489.733C408.247 489.733 406.955 491.025 406.955 492.608C406.955 494.191 408.247 495.483 409.838 495.483Z" fill="#C2C2C2"/>
<path d="M421.464 495.483C423.055 495.483 424.346 494.191 424.346 492.608C424.346 491.017 423.055 489.733 421.464 489.733C419.873 489.733 418.589 491.025 418.589 492.608C418.589 494.191 419.873 495.483 421.464 495.483Z" fill="#C2C2C2"/>
<path d="M433.089 495.483C434.68 495.483 435.972 494.191 435.972 492.608C435.972 491.017 434.68 489.733 433.089 489.733C431.498 489.733 430.214 491.025 430.214 492.608C430.214 494.191 431.506 495.483 433.089 495.483Z" fill="#C2C2C2"/>
<path d="M444.723 495.483C446.314 495.483 447.605 494.191 447.605 492.608C447.605 491.017 446.314 489.733 444.723 489.733C443.132 489.733 441.848 491.025 441.848 492.608C441.84 494.191 443.132 495.483 444.723 495.483Z" fill="#C2C2C2"/>
<path d="M456.348 495.483C457.939 495.483 459.231 494.191 459.231 492.608C459.231 491.017 457.939 489.733 456.348 489.733C454.757 489.733 453.473 491.025 453.473 492.608C453.466 494.191 454.757 495.483 456.348 495.483Z" fill="#C2C2C2"/>
<path d="M305.191 507.108C306.782 507.108 308.066 505.816 308.066 504.233C308.066 502.642 306.774 501.358 305.191 501.358C303.6 501.358 302.316 502.65 302.316 504.233C302.316 505.816 303.6 507.108 305.191 507.108Z" fill="#C2C2C2"/>
<path d="M316.816 507.108C318.407 507.108 319.691 505.816 319.691 504.233C319.691 502.642 318.4 501.358 316.816 501.358C315.225 501.358 313.941 502.65 313.941 504.233C313.941 505.816 315.225 507.108 316.816 507.108Z" fill="#C2C2C2"/>
<path d="M328.442 507.108C330.033 507.108 331.317 505.816 331.317 504.233C331.317 502.642 330.025 501.358 328.442 501.358C326.851 501.358 325.567 502.65 325.567 504.233C325.567 505.816 326.859 507.108 328.442 507.108Z" fill="#C2C2C2"/>
<path d="M340.076 507.108C341.667 507.108 342.951 505.816 342.951 504.233C342.951 502.642 341.659 501.358 340.076 501.358C338.485 501.358 337.201 502.65 337.201 504.233C337.193 505.816 338.485 507.108 340.076 507.108Z" fill="#C2C2C2"/>
<path d="M351.701 507.108C353.292 507.108 354.576 505.816 354.576 504.233C354.576 502.642 353.285 501.358 351.701 501.358C350.11 501.358 348.826 502.65 348.826 504.233C348.826 505.816 350.11 507.108 351.701 507.108Z" fill="#C2C2C2"/>
<path d="M363.327 507.108C364.918 507.108 366.202 505.816 366.202 504.233C366.202 502.642 364.91 501.358 363.327 501.358C361.736 501.358 360.444 502.65 360.444 504.233C360.452 505.816 361.736 507.108 363.327 507.108Z" fill="#C2C2C2"/>
<path d="M374.953 507.108C376.544 507.108 377.828 505.816 377.828 504.233C377.828 502.642 376.536 501.358 374.953 501.358C373.362 501.358 372.07 502.65 372.07 504.233C372.078 505.816 373.369 507.108 374.953 507.108Z" fill="#C2C2C2"/>
<path d="M386.586 507.108C388.177 507.108 389.461 505.816 389.461 504.233C389.461 502.642 388.17 501.358 386.586 501.358C384.995 501.358 383.704 502.65 383.704 504.233C383.704 505.816 384.995 507.108 386.586 507.108Z" fill="#C2C2C2"/>
<path d="M398.212 507.108C399.803 507.108 401.087 505.816 401.087 504.233C401.087 502.642 399.795 501.358 398.212 501.358C396.621 501.358 395.329 502.65 395.329 504.233C395.329 505.816 396.621 507.108 398.212 507.108Z" fill="#C2C2C2"/>
<path d="M409.838 507.108C411.429 507.108 412.713 505.816 412.713 504.233C412.713 502.642 411.421 501.358 409.838 501.358C408.247 501.358 406.955 502.65 406.955 504.233C406.955 505.816 408.247 507.108 409.838 507.108Z" fill="#C2C2C2"/>
<path d="M421.464 507.108C423.055 507.108 424.346 505.816 424.346 504.233C424.346 502.642 423.055 501.358 421.464 501.358C419.873 501.358 418.589 502.65 418.589 504.233C418.589 505.816 419.873 507.108 421.464 507.108Z" fill="#C2C2C2"/>
<path d="M433.089 507.108C434.68 507.108 435.972 505.816 435.972 504.233C435.972 502.642 434.68 501.358 433.089 501.358C431.498 501.358 430.214 502.65 430.214 504.233C430.214 505.816 431.506 507.108 433.089 507.108Z" fill="#C2C2C2"/>
<path d="M444.723 507.108C446.314 507.108 447.605 505.816 447.605 504.233C447.605 502.642 446.314 501.358 444.723 501.358C443.132 501.358 441.848 502.65 441.848 504.233C441.84 505.816 443.132 507.108 444.723 507.108Z" fill="#C2C2C2"/>
<path d="M316.816 518.734C318.407 518.734 319.691 517.442 319.691 515.859C319.691 514.268 318.4 512.984 316.816 512.984C315.225 512.984 313.941 514.276 313.941 515.859C313.941 517.442 315.225 518.734 316.816 518.734Z" fill="#C2C2C2"/>
<path d="M328.442 518.734C330.033 518.734 331.317 517.442 331.317 515.859C331.317 514.268 330.025 512.984 328.442 512.984C326.851 512.984 325.567 514.276 325.567 515.859C325.567 517.442 326.859 518.734 328.442 518.734Z" fill="#C2C2C2"/>
<path d="M340.076 518.734C341.667 518.734 342.951 517.442 342.951 515.859C342.951 514.268 341.659 512.984 340.076 512.984C338.485 512.984 337.201 514.276 337.201 515.859C337.193 517.442 338.485 518.734 340.076 518.734Z" fill="#C2C2C2"/>
<path d="M351.701 518.734C353.292 518.734 354.576 517.442 354.576 515.859C354.576 514.268 353.285 512.984 351.701 512.984C350.11 512.984 348.826 514.276 348.826 515.859C348.826 517.442 350.11 518.734 351.701 518.734Z" fill="#C2C2C2"/>
<path d="M363.327 518.734C364.918 518.734 366.202 517.442 366.202 515.859C366.202 514.268 364.91 512.984 363.327 512.984C361.736 512.984 360.444 514.276 360.444 515.859C360.452 517.442 361.736 518.734 363.327 518.734Z" fill="#C2C2C2"/>
<path d="M374.953 518.734C376.544 518.734 377.828 517.442 377.828 515.859C377.828 514.268 376.536 512.984 374.953 512.984C373.362 512.984 372.07 514.276 372.07 515.859C372.078 517.442 373.369 518.734 374.953 518.734Z" fill="#C2C2C2"/>
<path d="M386.586 518.734C388.177 518.734 389.461 517.442 389.461 515.859C389.461 514.268 388.17 512.984 386.586 512.984C384.995 512.984 383.704 514.276 383.704 515.859C383.704 517.442 384.995 518.734 386.586 518.734Z" fill="#C2C2C2"/>
<path d="M398.212 518.734C399.803 518.734 401.087 517.442 401.087 515.859C401.087 514.268 399.795 512.984 398.212 512.984C396.621 512.984 395.329 514.276 395.329 515.859C395.329 517.442 396.621 518.734 398.212 518.734Z" fill="#C2C2C2"/>
<path d="M409.838 518.734C411.429 518.734 412.713 517.442 412.713 515.859C412.713 514.268 411.421 512.984 409.838 512.984C408.247 512.984 406.955 514.276 406.955 515.859C406.955 517.442 408.247 518.734 409.838 518.734Z" fill="#C2C2C2"/>
<path d="M421.464 518.734C423.055 518.734 424.346 517.442 424.346 515.859C424.346 514.268 423.055 512.984 421.464 512.984C419.873 512.984 418.589 514.276 418.589 515.859C418.589 517.442 419.873 518.734 421.464 518.734Z" fill="#C2C2C2"/>
<path d="M316.816 530.36C318.407 530.36 319.691 529.068 319.691 527.485C319.691 525.894 318.4 524.61 316.816 524.61C315.225 524.61 313.941 525.902 313.941 527.485C313.941 529.076 315.225 530.36 316.816 530.36Z" fill="#C2C2C2"/>
<path d="M328.442 530.36C330.033 530.36 331.317 529.068 331.317 527.485C331.317 525.894 330.025 524.61 328.442 524.61C326.851 524.61 325.567 525.902 325.567 527.485C325.567 529.076 326.859 530.36 328.442 530.36Z" fill="#C2C2C2"/>
<path d="M340.076 530.36C341.667 530.36 342.951 529.068 342.951 527.485C342.951 525.894 341.659 524.61 340.076 524.61C338.485 524.61 337.201 525.902 337.201 527.485C337.193 529.076 338.485 530.36 340.076 530.36Z" fill="#C2C2C2"/>
<path d="M351.701 530.36C353.292 530.36 354.576 529.068 354.576 527.485C354.576 525.894 353.285 524.61 351.701 524.61C350.11 524.61 348.826 525.902 348.826 527.485C348.826 529.076 350.11 530.36 351.701 530.36Z" fill="#C2C2C2"/>
<path d="M363.327 530.36C364.918 530.36 366.202 529.068 366.202 527.485C366.202 525.894 364.91 524.61 363.327 524.61C361.736 524.61 360.444 525.902 360.444 527.485C360.452 529.076 361.736 530.36 363.327 530.36Z" fill="#C2C2C2"/>
<path d="M374.953 530.36C376.544 530.36 377.828 529.068 377.828 527.485C377.828 525.894 376.536 524.61 374.953 524.61C373.362 524.61 372.07 525.902 372.07 527.485C372.078 529.076 373.369 530.36 374.953 530.36Z" fill="#C2C2C2"/>
<path d="M386.586 530.36C388.177 530.36 389.461 529.068 389.461 527.485C389.461 525.894 388.17 524.61 386.586 524.61C384.995 524.61 383.704 525.902 383.704 527.485C383.704 529.076 384.995 530.36 386.586 530.36Z" fill="#C2C2C2"/>
<path d="M398.212 530.36C399.803 530.36 401.087 529.068 401.087 527.485C401.087 525.894 399.795 524.61 398.212 524.61C396.621 524.61 395.329 525.902 395.329 527.485C395.329 529.076 396.621 530.36 398.212 530.36Z" fill="#C2C2C2"/>
<path d="M409.838 530.36C411.429 530.36 412.713 529.068 412.713 527.485C412.713 525.894 411.421 524.61 409.838 524.61C408.247 524.61 406.955 525.902 406.955 527.485C406.955 529.076 408.247 530.36 409.838 530.36Z" fill="#C2C2C2"/>
<path d="M340.076 541.993C341.667 541.993 342.951 540.701 342.951 539.118C342.951 537.527 341.659 536.243 340.076 536.243C338.485 536.243 337.201 537.535 337.201 539.118C337.193 540.701 338.485 541.993 340.076 541.993Z" fill="#C2C2C2"/>
<path d="M351.701 541.993C353.292 541.993 354.576 540.701 354.576 539.118C354.576 537.527 353.285 536.243 351.701 536.243C350.11 536.243 348.826 537.535 348.826 539.118C348.826 540.701 350.11 541.993 351.701 541.993Z" fill="#C2C2C2"/>
<path d="M363.327 541.993C364.918 541.993 366.202 540.701 366.202 539.118C366.202 537.527 364.91 536.243 363.327 536.243C361.736 536.243 360.444 537.535 360.444 539.118C360.452 540.701 361.736 541.993 363.327 541.993Z" fill="#C2C2C2"/>
<path d="M374.953 541.993C376.544 541.993 377.828 540.701 377.828 539.118C377.828 537.527 376.536 536.243 374.953 536.243C373.362 536.243 372.07 537.535 372.07 539.118C372.078 540.701 373.369 541.993 374.953 541.993Z" fill="#C2C2C2"/>
<path d="M386.586 541.993C388.177 541.993 389.461 540.701 389.461 539.118C389.461 537.527 388.17 536.243 386.586 536.243C384.995 536.243 383.704 537.535 383.704 539.118C383.704 540.701 384.995 541.993 386.586 541.993Z" fill="#C2C2C2"/>
<path d="M363.327 553.619C364.918 553.619 366.202 552.327 366.202 550.744C366.202 549.153 364.91 547.869 363.327 547.869C361.736 547.869 360.444 549.161 360.444 550.744C360.452 552.327 361.736 553.619 363.327 553.619Z" fill="#C2C2C2"/>
<path d="M654.096 216.332C655.687 216.332 656.971 215.04 656.971 213.457C656.971 211.866 655.679 210.582 654.096 210.582C652.505 210.582 651.221 211.874 651.221 213.457C651.221 215.048 652.512 216.332 654.096 216.332Z" fill="#DDDDDD"/>
<path d="M665.729 216.332C667.32 216.332 668.604 215.04 668.604 213.457C668.604 211.866 667.313 210.582 665.729 210.582C664.138 210.582 662.855 211.874 662.855 213.457C662.847 215.048 664.138 216.332 665.729 216.332Z" fill="#DDDDDD"/>
<path d="M677.355 216.332C678.946 216.332 680.23 215.04 680.23 213.457C680.23 211.866 678.938 210.582 677.355 210.582C675.764 210.582 674.472 211.874 674.472 213.457C674.472 215.048 675.764 216.332 677.355 216.332Z" fill="#DDDDDD"/>
<path d="M688.981 216.332C690.572 216.332 691.856 215.04 691.856 213.457C691.856 211.866 690.564 210.582 688.981 210.582C687.39 210.582 686.098 211.874 686.098 213.457C686.106 215.048 687.39 216.332 688.981 216.332Z" fill="#DDDDDD"/>
<path d="M642.47 227.957C644.061 227.957 645.345 226.666 645.345 225.082C645.345 223.491 644.053 222.208 642.47 222.208C640.879 222.208 639.595 223.499 639.595 225.082C639.595 226.674 640.879 227.957 642.47 227.957Z" fill="#DDDDDD"/>
<path d="M654.096 227.957C655.687 227.957 656.971 226.666 656.971 225.082C656.971 223.491 655.679 222.208 654.096 222.208C652.505 222.208 651.221 223.499 651.221 225.082C651.221 226.674 652.512 227.957 654.096 227.957Z" fill="#DDDDDD"/>
<path d="M665.729 227.957C667.32 227.957 668.604 226.666 668.604 225.082C668.604 223.491 667.313 222.208 665.729 222.208C664.138 222.208 662.855 223.499 662.855 225.082C662.847 226.674 664.138 227.957 665.729 227.957Z" fill="#DDDDDD"/>
<path d="M677.355 227.957C678.946 227.957 680.23 226.666 680.23 225.082C680.23 223.491 678.938 222.208 677.355 222.208C675.764 222.208 674.472 223.499 674.472 225.082C674.472 226.674 675.764 227.957 677.355 227.957Z" fill="#DDDDDD"/>
<path d="M688.981 227.957C690.572 227.957 691.856 226.666 691.856 225.082C691.856 223.491 690.564 222.208 688.981 222.208C687.39 222.208 686.098 223.499 686.098 225.082C686.106 226.674 687.39 227.957 688.981 227.957Z" fill="#DDDDDD"/>
<path d="M700.606 227.957C702.198 227.957 703.481 226.666 703.481 225.082C703.481 223.491 702.19 222.208 700.606 222.208C699.015 222.208 697.724 223.499 697.724 225.082C697.732 226.674 699.015 227.957 700.606 227.957Z" fill="#DDDDDD"/>
<path d="M654.096 239.591C655.687 239.591 656.971 238.299 656.971 236.716C656.971 235.125 655.679 233.841 654.096 233.841C652.505 233.841 651.221 235.133 651.221 236.716C651.221 238.299 652.512 239.591 654.096 239.591Z" fill="#DDDDDD"/>
<path d="M665.729 239.591C667.32 239.591 668.604 238.299 668.604 236.716C668.604 235.125 667.313 233.841 665.729 233.841C664.138 233.841 662.855 235.133 662.855 236.716C662.847 238.299 664.138 239.591 665.729 239.591Z" fill="#DDDDDD"/>
<path d="M677.355 239.591C678.946 239.591 680.23 238.299 680.23 236.716C680.23 235.125 678.938 233.841 677.355 233.841C675.764 233.841 674.472 235.133 674.472 236.716C674.472 238.299 675.764 239.591 677.355 239.591Z" fill="#DDDDDD"/>
<path d="M688.981 239.591C690.572 239.591 691.856 238.299 691.856 236.716C691.856 235.125 690.564 233.841 688.981 233.841C687.39 233.841 686.098 235.133 686.098 236.716C686.106 238.299 687.39 239.591 688.981 239.591Z" fill="#DDDDDD"/>
<path d="M700.606 239.591C702.198 239.591 703.481 238.299 703.481 236.716C703.481 235.125 702.19 233.841 700.606 233.841C699.015 233.841 697.724 235.133 697.724 236.716C697.732 238.299 699.015 239.591 700.606 239.591Z" fill="#DDDDDD"/>
<path d="M712.24 239.591C713.831 239.591 715.115 238.299 715.115 236.716C715.115 235.125 713.823 233.841 712.24 233.841C710.649 233.841 709.357 235.133 709.357 236.716C709.357 238.299 710.649 239.591 712.24 239.591Z" fill="#DDDDDD"/>
<path d="M723.866 239.591C725.457 239.591 726.741 238.299 726.741 236.716C726.741 235.125 725.449 233.841 723.866 233.841C722.275 233.841 720.983 235.133 720.983 236.716C720.983 238.299 722.275 239.591 723.866 239.591Z" fill="#DDDDDD"/>
<path d="M665.729 251.217C667.32 251.217 668.604 249.925 668.604 248.342C668.604 246.759 667.313 245.467 665.729 245.467C664.138 245.467 662.854 246.759 662.854 248.342C662.854 249.925 664.138 251.217 665.729 251.217Z" fill="#DDDDDD"/>
<path d="M677.355 251.217C678.946 251.217 680.23 249.925 680.23 248.342C680.23 246.759 678.938 245.467 677.355 245.467C675.764 245.467 674.472 246.759 674.472 248.342C674.472 249.925 675.764 251.217 677.355 251.217Z" fill="#DDDDDD"/>
<path d="M688.981 251.217C690.572 251.217 691.856 249.925 691.856 248.342C691.856 246.759 690.564 245.467 688.981 245.467C687.39 245.467 686.098 246.759 686.098 248.342C686.098 249.925 687.39 251.217 688.981 251.217Z" fill="#DDDDDD"/>
<path d="M700.606 251.217C702.198 251.217 703.481 249.925 703.481 248.342C703.481 246.759 702.19 245.467 700.606 245.467C699.015 245.467 697.724 246.759 697.724 248.342C697.724 249.925 699.015 251.217 700.606 251.217Z" fill="#DDDDDD"/>
<path d="M712.24 251.217C713.831 251.217 715.115 249.925 715.115 248.342C715.115 246.759 713.823 245.467 712.24 245.467C710.649 245.467 709.357 246.759 709.357 248.342C709.357 249.925 710.649 251.217 712.24 251.217Z" fill="#DDDDDD"/>
<path d="M723.866 251.217C725.457 251.217 726.741 249.925 726.741 248.342C726.741 246.759 725.449 245.467 723.866 245.467C722.275 245.467 720.983 246.759 720.983 248.342C720.983 249.925 722.275 251.217 723.866 251.217Z" fill="#DDDDDD"/>
<path d="M735.492 251.217C737.083 251.217 738.374 249.925 738.374 248.342C738.374 246.759 737.083 245.467 735.492 245.467C733.901 245.467 732.617 246.759 732.617 248.342C732.617 249.925 733.901 251.217 735.492 251.217Z" fill="#DDDDDD"/>
<path d="M665.729 262.843C667.32 262.843 668.604 261.551 668.604 259.968C668.604 258.377 667.313 257.093 665.729 257.093C664.138 257.093 662.855 258.385 662.855 259.968C662.847 261.559 664.138 262.843 665.729 262.843Z" fill="#DDDDDD"/>
<path d="M677.355 262.843C678.946 262.843 680.23 261.551 680.23 259.968C680.23 258.377 678.938 257.093 677.355 257.093C675.764 257.093 674.472 258.385 674.472 259.968C674.472 261.559 675.764 262.843 677.355 262.843Z" fill="#DDDDDD"/>
<path d="M688.981 262.843C690.572 262.843 691.856 261.551 691.856 259.968C691.856 258.377 690.564 257.093 688.981 257.093C687.39 257.093 686.098 258.385 686.098 259.968C686.106 261.559 687.39 262.843 688.981 262.843Z" fill="#DDDDDD"/>
<path d="M700.606 262.843C702.198 262.843 703.481 261.551 703.481 259.968C703.481 258.377 702.19 257.093 700.606 257.093C699.015 257.093 697.724 258.385 697.724 259.968C697.732 261.559 699.015 262.843 700.606 262.843Z" fill="#DDDDDD"/>
<path d="M712.24 262.843C713.831 262.843 715.115 261.551 715.115 259.968C715.115 258.377 713.823 257.093 712.24 257.093C710.649 257.093 709.357 258.385 709.357 259.968C709.357 261.559 710.649 262.843 712.24 262.843Z" fill="#DDDDDD"/>
<path d="M723.866 262.843C725.457 262.843 726.741 261.551 726.741 259.968C726.741 258.377 725.449 257.093 723.866 257.093C722.275 257.093 720.983 258.385 720.983 259.968C720.983 261.559 722.275 262.843 723.866 262.843Z" fill="#DDDDDD"/>
<path d="M735.492 262.843C737.083 262.843 738.374 261.551 738.374 259.968C738.374 258.377 737.083 257.093 735.492 257.093C733.901 257.093 732.617 258.385 732.617 259.968C732.617 261.559 733.901 262.843 735.492 262.843Z" fill="#DDDDDD"/>
<path d="M747.117 262.843C748.708 262.843 750 261.551 750 259.968C750 258.377 748.708 257.093 747.117 257.093C745.526 257.093 744.242 258.385 744.242 259.968C744.242 261.559 745.526 262.843 747.117 262.843Z" fill="#DDDDDD"/>
<path d="M619.219 274.468C620.81 274.468 622.094 273.176 622.094 271.593C622.094 270.002 620.802 268.718 619.219 268.718C617.635 268.718 616.344 270.01 616.344 271.593C616.336 273.184 617.628 274.468 619.219 274.468Z" fill="#DDDDDD"/>
<path d="M630.844 274.468C632.435 274.468 633.719 273.176 633.719 271.593C633.719 270.002 632.427 268.718 630.844 268.718C629.253 268.718 627.969 270.01 627.969 271.593C627.969 273.184 629.253 274.468 630.844 274.468Z" fill="#DDDDDD"/>
<path d="M642.47 274.468C644.061 274.468 645.345 273.176 645.345 271.593C645.345 270.002 644.053 268.718 642.47 268.718C640.879 268.718 639.595 270.01 639.595 271.593C639.595 273.184 640.879 274.468 642.47 274.468Z" fill="#DDDDDD"/>
<path d="M654.096 274.468C655.687 274.468 656.971 273.176 656.971 271.593C656.971 270.002 655.679 268.718 654.096 268.718C652.505 268.718 651.221 270.01 651.221 271.593C651.221 273.184 652.512 274.468 654.096 274.468Z" fill="#DDDDDD"/>
<path d="M665.729 274.468C667.32 274.468 668.604 273.176 668.604 271.593C668.604 270.002 667.313 268.718 665.729 268.718C664.138 268.718 662.855 270.01 662.855 271.593C662.847 273.184 664.138 274.468 665.729 274.468Z" fill="#DDDDDD"/>
<path d="M677.355 274.468C678.946 274.468 680.23 273.176 680.23 271.593C680.23 270.002 678.938 268.718 677.355 268.718C675.764 268.718 674.472 270.01 674.472 271.593C674.472 273.184 675.764 274.468 677.355 274.468Z" fill="#DDDDDD"/>
<path d="M688.981 274.468C690.572 274.468 691.856 273.176 691.856 271.593C691.856 270.002 690.564 268.718 688.981 268.718C687.39 268.718 686.098 270.01 686.098 271.593C686.106 273.184 687.39 274.468 688.981 274.468Z" fill="#DDDDDD"/>
<path d="M700.606 274.468C702.198 274.468 703.481 273.176 703.481 271.593C703.481 270.002 702.19 268.718 700.606 268.718C699.015 268.718 697.724 270.01 697.724 271.593C697.732 273.184 699.015 274.468 700.606 274.468Z" fill="#DDDDDD"/>
<path d="M712.24 274.468C713.831 274.468 715.115 273.176 715.115 271.593C715.115 270.002 713.823 268.718 712.24 268.718C710.649 268.718 709.357 270.01 709.357 271.593C709.357 273.184 710.649 274.468 712.24 274.468Z" fill="#DDDDDD"/>
<path d="M723.866 274.468C725.457 274.468 726.741 273.176 726.741 271.593C726.741 270.002 725.449 268.718 723.866 268.718C722.275 268.718 720.983 270.01 720.983 271.593C720.983 273.184 722.275 274.468 723.866 274.468Z" fill="#DDDDDD"/>
<path d="M735.492 274.468C737.083 274.468 738.374 273.176 738.374 271.593C738.374 270.002 737.083 268.718 735.492 268.718C733.901 268.718 732.617 270.01 732.617 271.593C732.617 273.184 733.901 274.468 735.492 274.468Z" fill="#DDDDDD"/>
<path d="M747.117 274.468C748.708 274.468 750 273.176 750 271.593C750 270.002 748.708 268.718 747.117 268.718C745.526 268.718 744.242 270.01 744.242 271.593C744.242 273.184 745.526 274.468 747.117 274.468Z" fill="#DDDDDD"/>
<path d="M619.219 286.102C620.81 286.102 622.094 284.81 622.094 283.227C622.094 281.644 620.802 280.352 619.219 280.352C617.635 280.352 616.344 281.644 616.344 283.227C616.344 284.81 617.628 286.102 619.219 286.102Z" fill="#DDDDDD"/>
<path d="M630.844 286.102C632.435 286.102 633.719 284.81 633.719 283.227C633.719 281.644 632.427 280.352 630.844 280.352C629.253 280.352 627.969 281.644 627.969 283.227C627.969 284.81 629.253 286.102 630.844 286.102Z" fill="#DDDDDD"/>
<path d="M642.47 286.102C644.061 286.102 645.345 284.81 645.345 283.227C645.345 281.644 644.053 280.352 642.47 280.352C640.879 280.352 639.595 281.644 639.595 283.227C639.595 284.81 640.879 286.102 642.47 286.102Z" fill="#DDDDDD"/>
<path d="M654.096 286.102C655.687 286.102 656.971 284.81 656.971 283.227C656.971 281.644 655.679 280.352 654.096 280.352C652.505 280.352 651.221 281.644 651.221 283.227C651.221 284.81 652.512 286.102 654.096 286.102Z" fill="#DDDDDD"/>
<path d="M665.729 286.102C667.32 286.102 668.604 284.81 668.604 283.227C668.604 281.644 667.313 280.352 665.729 280.352C664.138 280.352 662.854 281.644 662.854 283.227C662.854 284.81 664.138 286.102 665.729 286.102Z" fill="#DDDDDD"/>
<path d="M677.355 286.102C678.946 286.102 680.23 284.81 680.23 283.227C680.23 281.644 678.938 280.352 677.355 280.352C675.764 280.352 674.472 281.644 674.472 283.227C674.472 284.81 675.764 286.102 677.355 286.102Z" fill="#DDDDDD"/>
<path d="M688.981 286.102C690.572 286.102 691.856 284.81 691.856 283.227C691.856 281.644 690.564 280.352 688.981 280.352C687.39 280.352 686.098 281.644 686.098 283.227C686.098 284.81 687.39 286.102 688.981 286.102Z" fill="#DDDDDD"/>
<path d="M700.606 286.102C702.198 286.102 703.481 284.81 703.481 283.227C703.481 281.644 702.19 280.352 700.606 280.352C699.015 280.352 697.724 281.644 697.724 283.227C697.724 284.81 699.015 286.102 700.606 286.102Z" fill="#DDDDDD"/>
<path d="M712.24 286.102C713.831 286.102 715.115 284.81 715.115 283.227C715.115 281.644 713.823 280.352 712.24 280.352C710.649 280.352 709.357 281.644 709.357 283.227C709.357 284.81 710.649 286.102 712.24 286.102Z" fill="#DDDDDD"/>
<path d="M723.866 286.102C725.457 286.102 726.741 284.81 726.741 283.227C726.741 281.644 725.449 280.352 723.866 280.352C722.275 280.352 720.983 281.644 720.983 283.227C720.983 284.81 722.275 286.102 723.866 286.102Z" fill="#DDDDDD"/>
<path d="M735.492 286.102C737.083 286.102 738.374 284.81 738.374 283.227C738.374 281.644 737.083 280.352 735.492 280.352C733.901 280.352 732.617 281.644 732.617 283.227C732.617 284.81 733.901 286.102 735.492 286.102Z" fill="#DDDDDD"/>
<path d="M747.117 286.102C748.708 286.102 750 284.81 750 283.227C750 281.644 748.708 280.352 747.117 280.352C745.526 280.352 744.242 281.644 744.242 283.227C744.242 284.81 745.526 286.102 747.117 286.102Z" fill="#DDDDDD"/>
<path d="M619.219 297.727C620.81 297.727 622.094 296.436 622.094 294.852C622.094 293.261 620.802 291.978 619.219 291.978C617.635 291.978 616.344 293.269 616.344 294.852C616.336 296.436 617.628 297.727 619.219 297.727Z" fill="#DDDDDD"/>
<path d="M630.844 297.727C632.435 297.727 633.719 296.436 633.719 294.852C633.719 293.261 632.427 291.978 630.844 291.978C629.253 291.978 627.969 293.269 627.969 294.852C627.969 296.436 629.253 297.727 630.844 297.727Z" fill="#DDDDDD"/>
<path d="M642.47 297.727C644.061 297.727 645.345 296.436 645.345 294.852C645.345 293.261 644.053 291.978 642.47 291.978C640.879 291.978 639.595 293.269 639.595 294.852C639.595 296.436 640.879 297.727 642.47 297.727Z" fill="#DDDDDD"/>
<path d="M654.096 297.727C655.687 297.727 656.971 296.436 656.971 294.852C656.971 293.261 655.679 291.978 654.096 291.978C652.505 291.978 651.221 293.269 651.221 294.852C651.221 296.436 652.512 297.727 654.096 297.727Z" fill="#DDDDDD"/>
<path d="M665.729 297.727C667.32 297.727 668.604 296.436 668.604 294.852C668.604 293.261 667.313 291.978 665.729 291.978C664.138 291.978 662.855 293.269 662.855 294.852C662.847 296.436 664.138 297.727 665.729 297.727Z" fill="#DDDDDD"/>
<path d="M677.355 297.727C678.946 297.727 680.23 296.436 680.23 294.852C680.23 293.261 678.938 291.978 677.355 291.978C675.764 291.978 674.472 293.269 674.472 294.852C674.472 296.436 675.764 297.727 677.355 297.727Z" fill="#DDDDDD"/>
<path d="M688.981 297.727C690.572 297.727 691.856 296.436 691.856 294.852C691.856 293.261 690.564 291.978 688.981 291.978C687.39 291.978 686.098 293.269 686.098 294.852C686.106 296.436 687.39 297.727 688.981 297.727Z" fill="#DDDDDD"/>
<path d="M700.606 297.727C702.198 297.727 703.481 296.436 703.481 294.852C703.481 293.261 702.19 291.978 700.606 291.978C699.015 291.978 697.724 293.269 697.724 294.852C697.732 296.436 699.015 297.727 700.606 297.727Z" fill="#DDDDDD"/>
<path d="M712.24 297.727C713.831 297.727 715.115 296.436 715.115 294.852C715.115 293.261 713.823 291.978 712.24 291.978C710.649 291.978 709.357 293.269 709.357 294.852C709.357 296.436 710.649 297.727 712.24 297.727Z" fill="#DDDDDD"/>
<path d="M723.866 297.727C725.457 297.727 726.741 296.436 726.741 294.852C726.741 293.261 725.449 291.978 723.866 291.978C722.275 291.978 720.983 293.269 720.983 294.852C720.983 296.436 722.275 297.727 723.866 297.727Z" fill="#DDDDDD"/>
<path d="M735.492 297.727C737.083 297.727 738.374 296.436 738.374 294.852C738.374 293.261 737.083 291.978 735.492 291.978C733.901 291.978 732.617 293.269 732.617 294.852C732.617 296.436 733.901 297.727 735.492 297.727Z" fill="#DDDDDD"/>
<path d="M747.117 297.727C748.708 297.727 750 296.436 750 294.852C750 293.261 748.708 291.978 747.117 291.978C745.526 291.978 744.242 293.269 744.242 294.852C744.242 296.436 745.526 297.727 747.117 297.727Z" fill="#DDDDDD"/>
<path d="M619.219 309.353C620.81 309.353 622.094 308.062 622.094 306.478C622.094 304.887 620.802 303.604 619.219 303.604C617.635 303.604 616.344 304.895 616.344 306.478C616.336 308.062 617.628 309.353 619.219 309.353Z" fill="#DDDDDD"/>
<path d="M630.844 309.353C632.435 309.353 633.719 308.062 633.719 306.478C633.719 304.887 632.427 303.604 630.844 303.604C629.253 303.604 627.969 304.895 627.969 306.478C627.969 308.062 629.253 309.353 630.844 309.353Z" fill="#DDDDDD"/>
<path d="M642.47 309.353C644.061 309.353 645.345 308.062 645.345 306.478C645.345 304.887 644.053 303.604 642.47 303.604C640.879 303.604 639.595 304.895 639.595 306.478C639.595 308.062 640.879 309.353 642.47 309.353Z" fill="#DDDDDD"/>
<path d="M654.096 309.353C655.687 309.353 656.971 308.062 656.971 306.478C656.971 304.887 655.679 303.604 654.096 303.604C652.505 303.604 651.221 304.895 651.221 306.478C651.221 308.062 652.512 309.353 654.096 309.353Z" fill="#DDDDDD"/>
<path d="M665.729 309.353C667.32 309.353 668.604 308.062 668.604 306.478C668.604 304.887 667.313 303.604 665.729 303.604C664.138 303.604 662.855 304.895 662.855 306.478C662.847 308.062 664.138 309.353 665.729 309.353Z" fill="#DDDDDD"/>
<path d="M677.355 309.353C678.946 309.353 680.23 308.062 680.23 306.478C680.23 304.887 678.938 303.604 677.355 303.604C675.764 303.604 674.472 304.895 674.472 306.478C674.472 308.062 675.764 309.353 677.355 309.353Z" fill="#DDDDDD"/>
<path d="M688.981 309.353C690.572 309.353 691.856 308.062 691.856 306.478C691.856 304.887 690.564 303.604 688.981 303.604C687.39 303.604 686.098 304.895 686.098 306.478C686.106 308.062 687.39 309.353 688.981 309.353Z" fill="#DDDDDD"/>
<path d="M700.606 309.353C702.198 309.353 703.481 308.062 703.481 306.478C703.481 304.887 702.19 303.604 700.606 303.604C699.015 303.604 697.724 304.895 697.724 306.478C697.732 308.062 699.015 309.353 700.606 309.353Z" fill="#DDDDDD"/>
<path d="M712.24 309.353C713.831 309.353 715.115 308.062 715.115 306.478C715.115 304.887 713.823 303.604 712.24 303.604C710.649 303.604 709.357 304.895 709.357 306.478C709.357 308.062 710.649 309.353 712.24 309.353Z" fill="#DDDDDD"/>
<path d="M723.866 309.353C725.457 309.353 726.741 308.062 726.741 306.478C726.741 304.887 725.449 303.604 723.866 303.604C722.275 303.604 720.983 304.895 720.983 306.478C720.983 308.062 722.275 309.353 723.866 309.353Z" fill="#DDDDDD"/>
<path d="M735.492 309.353C737.083 309.353 738.374 308.062 738.374 306.478C738.374 304.887 737.083 303.604 735.492 303.604C733.901 303.604 732.617 304.895 732.617 306.478C732.617 308.062 733.901 309.353 735.492 309.353Z" fill="#DDDDDD"/>
<path d="M630.844 320.979C632.435 320.979 633.719 319.687 633.719 318.104C633.719 316.513 632.427 315.229 630.844 315.229C629.253 315.229 627.969 316.521 627.969 318.104C627.969 319.695 629.253 320.979 630.844 320.979Z" fill="#DDDDDD"/>
<path d="M642.47 320.979C644.061 320.979 645.345 319.687 645.345 318.104C645.345 316.513 644.053 315.229 642.47 315.229C640.879 315.229 639.595 316.521 639.595 318.104C639.595 319.695 640.879 320.979 642.47 320.979Z" fill="#DDDDDD"/>
<path d="M654.096 320.979C655.687 320.979 656.971 319.687 656.971 318.104C656.971 316.513 655.679 315.229 654.096 315.229C652.505 315.229 651.221 316.521 651.221 318.104C651.221 319.695 652.512 320.979 654.096 320.979Z" fill="#DDDDDD"/>
<path d="M665.729 320.979C667.32 320.979 668.604 319.687 668.604 318.104C668.604 316.513 667.313 315.229 665.729 315.229C664.138 315.229 662.855 316.521 662.855 318.104C662.847 319.695 664.138 320.979 665.729 320.979Z" fill="#DDDDDD"/>
<path d="M677.355 320.979C678.946 320.979 680.23 319.687 680.23 318.104C680.23 316.513 678.938 315.229 677.355 315.229C675.764 315.229 674.472 316.521 674.472 318.104C674.472 319.695 675.764 320.979 677.355 320.979Z" fill="#DDDDDD"/>
<path d="M688.981 320.979C690.572 320.979 691.856 319.687 691.856 318.104C691.856 316.513 690.564 315.229 688.981 315.229C687.39 315.229 686.098 316.521 686.098 318.104C686.106 319.695 687.39 320.979 688.981 320.979Z" fill="#DDDDDD"/>
<path d="M700.606 320.979C702.198 320.979 703.481 319.687 703.481 318.104C703.481 316.513 702.19 315.229 700.606 315.229C699.015 315.229 697.724 316.521 697.724 318.104C697.732 319.695 699.015 320.979 700.606 320.979Z" fill="#DDDDDD"/>
<path d="M712.24 320.979C713.831 320.979 715.115 319.687 715.115 318.104C715.115 316.513 713.823 315.229 712.24 315.229C710.649 315.229 709.357 316.521 709.357 318.104C709.357 319.695 710.649 320.979 712.24 320.979Z" fill="#DDDDDD"/>
<path d="M723.866 320.979C725.457 320.979 726.741 319.687 726.741 318.104C726.741 316.513 725.449 315.229 723.866 315.229C722.275 315.229 720.983 316.521 720.983 318.104C720.983 319.695 722.275 320.979 723.866 320.979Z" fill="#DDDDDD"/>
<path d="M735.492 320.979C737.083 320.979 738.374 319.687 738.374 318.104C738.374 316.513 737.083 315.229 735.492 315.229C733.901 315.229 732.617 316.521 732.617 318.104C732.617 319.695 733.901 320.979 735.492 320.979Z" fill="#DDDDDD"/>
<path d="M642.47 332.604C644.061 332.604 645.345 331.313 645.345 329.729C645.345 328.138 644.053 326.854 642.47 326.854C640.879 326.854 639.595 328.146 639.595 329.729C639.595 331.32 640.879 332.604 642.47 332.604Z" fill="#DDDDDD"/>
<path d="M654.096 332.604C655.687 332.604 656.971 331.313 656.971 329.729C656.971 328.138 655.679 326.854 654.096 326.854C652.505 326.854 651.221 328.146 651.221 329.729C651.221 331.32 652.512 332.604 654.096 332.604Z" fill="#DDDDDD"/>
<path d="M665.729 332.604C667.32 332.604 668.604 331.313 668.604 329.729C668.604 328.138 667.313 326.854 665.729 326.854C664.138 326.854 662.855 328.146 662.855 329.729C662.847 331.32 664.138 332.604 665.729 332.604Z" fill="#DDDDDD"/>
<path d="M677.355 332.604C678.946 332.604 680.23 331.313 680.23 329.729C680.23 328.138 678.938 326.854 677.355 326.854C675.764 326.854 674.472 328.146 674.472 329.729C674.472 331.32 675.764 332.604 677.355 332.604Z" fill="#DDDDDD"/>
<path d="M688.981 332.604C690.572 332.604 691.856 331.313 691.856 329.729C691.856 328.138 690.564 326.854 688.981 326.854C687.39 326.854 686.098 328.146 686.098 329.729C686.106 331.32 687.39 332.604 688.981 332.604Z" fill="#DDDDDD"/>
<path d="M700.606 332.604C702.198 332.604 703.481 331.313 703.481 329.729C703.481 328.138 702.19 326.854 700.606 326.854C699.015 326.854 697.724 328.146 697.724 329.729C697.732 331.32 699.015 332.604 700.606 332.604Z" fill="#DDDDDD"/>
<path d="M712.24 332.604C713.831 332.604 715.115 331.313 715.115 329.729C715.115 328.138 713.823 326.854 712.24 326.854C710.649 326.854 709.357 328.146 709.357 329.729C709.357 331.32 710.649 332.604 712.24 332.604Z" fill="#DDDDDD"/>
<path d="M723.866 332.604C725.457 332.604 726.741 331.313 726.741 329.729C726.741 328.138 725.449 326.854 723.866 326.854C722.275 326.854 720.983 328.146 720.983 329.729C720.983 331.32 722.275 332.604 723.866 332.604Z" fill="#DDDDDD"/>
<path d="M735.492 332.604C737.083 332.604 738.374 331.313 738.374 329.729C738.374 328.138 737.083 326.854 735.492 326.854C733.901 326.854 732.617 328.146 732.617 329.729C732.617 331.32 733.901 332.604 735.492 332.604Z" fill="#DDDDDD"/>
<path d="M642.47 344.238C644.061 344.238 645.345 342.946 645.345 341.363C645.345 339.772 644.053 338.488 642.47 338.488C640.879 338.488 639.595 339.78 639.595 341.363C639.595 342.946 640.879 344.238 642.47 344.238Z" fill="#DDDDDD"/>
<path d="M654.096 344.238C655.687 344.238 656.971 342.946 656.971 341.363C656.971 339.772 655.679 338.488 654.096 338.488C652.505 338.488 651.221 339.78 651.221 341.363C651.221 342.946 652.512 344.238 654.096 344.238Z" fill="#DDDDDD"/>
<path d="M665.729 344.238C667.32 344.238 668.604 342.946 668.604 341.363C668.604 339.772 667.313 338.488 665.729 338.488C664.138 338.488 662.855 339.78 662.855 341.363C662.847 342.946 664.138 344.238 665.729 344.238Z" fill="#DDDDDD"/>
<path d="M677.355 344.238C678.946 344.238 680.23 342.946 680.23 341.363C680.23 339.772 678.938 338.488 677.355 338.488C675.764 338.488 674.472 339.78 674.472 341.363C674.472 342.946 675.764 344.238 677.355 344.238Z" fill="#DDDDDD"/>
<path d="M688.981 344.238C690.572 344.238 691.856 342.946 691.856 341.363C691.856 339.772 690.564 338.488 688.981 338.488C687.39 338.488 686.098 339.78 686.098 341.363C686.106 342.946 687.39 344.238 688.981 344.238Z" fill="#DDDDDD"/>
<path d="M700.606 344.238C702.198 344.238 703.481 342.946 703.481 341.363C703.481 339.772 702.19 338.488 700.606 338.488C699.015 338.488 697.724 339.78 697.724 341.363C697.732 342.946 699.015 344.238 700.606 344.238Z" fill="#DDDDDD"/>
<path d="M712.24 344.238C713.831 344.238 715.115 342.946 715.115 341.363C715.115 339.772 713.823 338.488 712.24 338.488C710.649 338.488 709.357 339.78 709.357 341.363C709.357 342.946 710.649 344.238 712.24 344.238Z" fill="#DDDDDD"/>
<path d="M723.866 344.238C725.457 344.238 726.741 342.946 726.741 341.363C726.741 339.772 725.449 338.488 723.866 338.488C722.275 338.488 720.983 339.78 720.983 341.363C720.983 342.946 722.275 344.238 723.866 344.238Z" fill="#DDDDDD"/>
<path d="M735.492 344.238C737.083 344.238 738.374 342.946 738.374 341.363C738.374 339.772 737.083 338.488 735.492 338.488C733.901 338.488 732.617 339.78 732.617 341.363C732.617 342.946 733.901 344.238 735.492 344.238Z" fill="#DDDDDD"/>
<path d="M642.47 355.864C644.061 355.864 645.345 354.572 645.345 352.989C645.345 351.398 644.053 350.114 642.47 350.114C640.879 350.114 639.595 351.406 639.595 352.989C639.595 354.572 640.879 355.864 642.47 355.864Z" fill="#DDDDDD"/>
<path d="M654.096 355.864C655.687 355.864 656.971 354.572 656.971 352.989C656.971 351.398 655.679 350.114 654.096 350.114C652.505 350.114 651.221 351.406 651.221 352.989C651.221 354.572 652.512 355.864 654.096 355.864Z" fill="#DDDDDD"/>
<path d="M665.729 355.864C667.32 355.864 668.604 354.572 668.604 352.989C668.604 351.398 667.313 350.114 665.729 350.114C664.138 350.114 662.855 351.406 662.855 352.989C662.847 354.572 664.138 355.864 665.729 355.864Z" fill="#DDDDDD"/>
<path d="M677.355 355.864C678.946 355.864 680.23 354.572 680.23 352.989C680.23 351.398 678.938 350.114 677.355 350.114C675.764 350.114 674.472 351.406 674.472 352.989C674.472 354.572 675.764 355.864 677.355 355.864Z" fill="#DDDDDD"/>
<path d="M688.981 355.864C690.572 355.864 691.856 354.572 691.856 352.989C691.856 351.398 690.564 350.114 688.981 350.114C687.39 350.114 686.098 351.406 686.098 352.989C686.106 354.572 687.39 355.864 688.981 355.864Z" fill="#DDDDDD"/>
<path d="M700.606 355.864C702.198 355.864 703.481 354.572 703.481 352.989C703.481 351.398 702.19 350.114 700.606 350.114C699.015 350.114 697.724 351.406 697.724 352.989C697.732 354.572 699.015 355.864 700.606 355.864Z" fill="#DDDDDD"/>
<path d="M712.24 355.864C713.831 355.864 715.115 354.572 715.115 352.989C715.115 351.398 713.823 350.114 712.24 350.114C710.649 350.114 709.357 351.406 709.357 352.989C709.357 354.572 710.649 355.864 712.24 355.864Z" fill="#DDDDDD"/>
<path d="M723.866 355.864C725.457 355.864 726.741 354.572 726.741 352.989C726.741 351.398 725.449 350.114 723.866 350.114C722.275 350.114 720.983 351.406 720.983 352.989C720.983 354.572 722.275 355.864 723.866 355.864Z" fill="#DDDDDD"/>
<path d="M735.492 355.864C737.083 355.864 738.374 354.572 738.374 352.989C738.374 351.398 737.083 350.114 735.492 350.114C733.901 350.114 732.617 351.406 732.617 352.989C732.617 354.572 733.901 355.864 735.492 355.864Z" fill="#DDDDDD"/>
<path d="M630.844 367.49C632.435 367.49 633.719 366.198 633.719 364.615C633.719 363.024 632.427 361.74 630.844 361.74C629.253 361.74 627.969 363.031 627.969 364.615C627.969 366.206 629.253 367.49 630.844 367.49Z" fill="#DDDDDD"/>
<path d="M642.47 367.49C644.061 367.49 645.345 366.198 645.345 364.615C645.345 363.024 644.053 361.74 642.47 361.74C640.879 361.74 639.595 363.031 639.595 364.615C639.595 366.206 640.879 367.49 642.47 367.49Z" fill="#DDDDDD"/>
<path d="M654.096 367.49C655.687 367.49 656.971 366.198 656.971 364.615C656.971 363.024 655.679 361.74 654.096 361.74C652.505 361.74 651.221 363.031 651.221 364.615C651.221 366.206 652.512 367.49 654.096 367.49Z" fill="#DDDDDD"/>
<path d="M665.729 367.49C667.32 367.49 668.604 366.198 668.604 364.615C668.604 363.024 667.313 361.74 665.729 361.74C664.138 361.74 662.855 363.031 662.855 364.615C662.847 366.206 664.138 367.49 665.729 367.49Z" fill="#DDDDDD"/>
<path d="M677.355 367.49C678.946 367.49 680.23 366.198 680.23 364.615C680.23 363.024 678.938 361.74 677.355 361.74C675.764 361.74 674.472 363.031 674.472 364.615C674.472 366.206 675.764 367.49 677.355 367.49Z" fill="#DDDDDD"/>
<path d="M688.981 367.49C690.572 367.49 691.856 366.198 691.856 364.615C691.856 363.024 690.564 361.74 688.981 361.74C687.39 361.74 686.098 363.031 686.098 364.615C686.106 366.206 687.39 367.49 688.981 367.49Z" fill="#DDDDDD"/>
<path d="M700.606 367.49C702.198 367.49 703.481 366.198 703.481 364.615C703.481 363.024 702.19 361.74 700.606 361.74C699.015 361.74 697.724 363.031 697.724 364.615C697.732 366.206 699.015 367.49 700.606 367.49Z" fill="#DDDDDD"/>
<path d="M712.24 367.49C713.831 367.49 715.115 366.198 715.115 364.615C715.115 363.024 713.823 361.74 712.24 361.74C710.649 361.74 709.357 363.031 709.357 364.615C709.357 366.206 710.649 367.49 712.24 367.49Z" fill="#DDDDDD"/>
<path d="M723.866 367.49C725.457 367.49 726.741 366.198 726.741 364.615C726.741 363.024 725.449 361.74 723.866 361.74C722.275 361.74 720.983 363.031 720.983 364.615C720.983 366.206 722.275 367.49 723.866 367.49Z" fill="#DDDDDD"/>
<path d="M735.492 367.49C737.083 367.49 738.374 366.198 738.374 364.615C738.374 363.024 737.083 361.74 735.492 361.74C733.901 361.74 732.617 363.031 732.617 364.615C732.617 366.206 733.901 367.49 735.492 367.49Z" fill="#DDDDDD"/>
<path d="M619.219 379.115C620.81 379.115 622.094 377.823 622.094 376.24C622.094 374.649 620.802 373.365 619.219 373.365C617.635 373.365 616.344 374.657 616.344 376.24C616.336 377.831 617.628 379.115 619.219 379.115Z" fill="#DDDDDD"/>
<path d="M630.844 379.115C632.435 379.115 633.719 377.823 633.719 376.24C633.719 374.649 632.427 373.365 630.844 373.365C629.253 373.365 627.969 374.657 627.969 376.24C627.969 377.831 629.253 379.115 630.844 379.115Z" fill="#DDDDDD"/>
<path d="M642.47 379.115C644.061 379.115 645.345 377.823 645.345 376.24C645.345 374.649 644.053 373.365 642.47 373.365C640.879 373.365 639.595 374.657 639.595 376.24C639.595 377.831 640.879 379.115 642.47 379.115Z" fill="#DDDDDD"/>
<path d="M654.096 379.115C655.687 379.115 656.971 377.823 656.971 376.24C656.971 374.649 655.679 373.365 654.096 373.365C652.505 373.365 651.221 374.657 651.221 376.24C651.221 377.831 652.512 379.115 654.096 379.115Z" fill="#DDDDDD"/>
<path d="M665.729 379.115C667.32 379.115 668.604 377.823 668.604 376.24C668.604 374.649 667.313 373.365 665.729 373.365C664.138 373.365 662.855 374.657 662.855 376.24C662.847 377.831 664.138 379.115 665.729 379.115Z" fill="#DDDDDD"/>
<path d="M677.355 379.115C678.946 379.115 680.23 377.823 680.23 376.24C680.23 374.649 678.938 373.365 677.355 373.365C675.764 373.365 674.472 374.657 674.472 376.24C674.472 377.831 675.764 379.115 677.355 379.115Z" fill="#DDDDDD"/>
<path d="M688.981 379.115C690.572 379.115 691.856 377.823 691.856 376.24C691.856 374.649 690.564 373.365 688.981 373.365C687.39 373.365 686.098 374.657 686.098 376.24C686.106 377.831 687.39 379.115 688.981 379.115Z" fill="#DDDDDD"/>
<path d="M700.606 379.115C702.198 379.115 703.481 377.823 703.481 376.24C703.481 374.649 702.19 373.365 700.606 373.365C699.015 373.365 697.724 374.657 697.724 376.24C697.732 377.831 699.015 379.115 700.606 379.115Z" fill="#DDDDDD"/>
<path d="M712.24 379.115C713.831 379.115 715.115 377.823 715.115 376.24C715.115 374.649 713.823 373.365 712.24 373.365C710.649 373.365 709.357 374.657 709.357 376.24C709.357 377.831 710.649 379.115 712.24 379.115Z" fill="#DDDDDD"/>
<path d="M723.866 379.115C725.457 379.115 726.741 377.823 726.741 376.24C726.741 374.649 725.449 373.365 723.866 373.365C722.275 373.365 720.983 374.657 720.983 376.24C720.983 377.831 722.275 379.115 723.866 379.115Z" fill="#DDDDDD"/>
<path d="M642.47 390.749C644.061 390.749 645.345 389.457 645.345 387.874C645.345 386.283 644.053 384.999 642.47 384.999C640.879 384.999 639.595 386.291 639.595 387.874C639.595 389.457 640.879 390.749 642.47 390.749Z" fill="#DDDDDD"/>
<path d="M654.096 390.749C655.687 390.749 656.971 389.457 656.971 387.874C656.971 386.283 655.679 384.999 654.096 384.999C652.505 384.999 651.221 386.291 651.221 387.874C651.221 389.457 652.512 390.749 654.096 390.749Z" fill="#DDDDDD"/>
<path d="M665.729 390.749C667.32 390.749 668.604 389.457 668.604 387.874C668.604 386.283 667.313 384.999 665.729 384.999C664.138 384.999 662.855 386.291 662.855 387.874C662.847 389.457 664.138 390.749 665.729 390.749Z" fill="#DDDDDD"/>
<path d="M677.355 390.749C678.946 390.749 680.23 389.457 680.23 387.874C680.23 386.283 678.938 384.999 677.355 384.999C675.764 384.999 674.472 386.291 674.472 387.874C674.472 389.457 675.764 390.749 677.355 390.749Z" fill="#DDDDDD"/>
<path d="M688.981 390.749C690.572 390.749 691.856 389.457 691.856 387.874C691.856 386.283 690.564 384.999 688.981 384.999C687.39 384.999 686.098 386.291 686.098 387.874C686.106 389.457 687.39 390.749 688.981 390.749Z" fill="#DDDDDD"/>
<path d="M700.606 390.749C702.198 390.749 703.481 389.457 703.481 387.874C703.481 386.283 702.19 384.999 700.606 384.999C699.015 384.999 697.724 386.291 697.724 387.874C697.732 389.457 699.015 390.749 700.606 390.749Z" fill="#DDDDDD"/>
<path d="M712.24 390.749C713.831 390.749 715.115 389.457 715.115 387.874C715.115 386.283 713.823 384.999 712.24 384.999C710.649 384.999 709.357 386.291 709.357 387.874C709.357 389.457 710.649 390.749 712.24 390.749Z" fill="#DDDDDD"/>
<path d="M642.47 402.374C644.061 402.374 645.345 401.083 645.345 399.499C645.345 397.908 644.053 396.625 642.47 396.625C640.879 396.625 639.595 397.916 639.595 399.499C639.595 401.083 640.879 402.374 642.47 402.374Z" fill="#DDDDDD"/>
<path d="M654.096 402.374C655.687 402.374 656.971 401.083 656.971 399.499C656.971 397.908 655.679 396.625 654.096 396.625C652.505 396.625 651.221 397.916 651.221 399.499C651.221 401.083 652.512 402.374 654.096 402.374Z" fill="#DDDDDD"/>
<path d="M665.729 402.374C667.32 402.374 668.604 401.083 668.604 399.499C668.604 397.908 667.313 396.625 665.729 396.625C664.138 396.625 662.855 397.916 662.855 399.499C662.847 401.083 664.138 402.374 665.729 402.374Z" fill="#DDDDDD"/>
<path d="M677.355 402.374C678.946 402.374 680.23 401.083 680.23 399.499C680.23 397.908 678.938 396.625 677.355 396.625C675.764 396.625 674.472 397.916 674.472 399.499C674.472 401.083 675.764 402.374 677.355 402.374Z" fill="#DDDDDD"/>
<path d="M688.981 402.374C690.572 402.374 691.856 401.083 691.856 399.499C691.856 397.908 690.564 396.625 688.981 396.625C687.39 396.625 686.098 397.916 686.098 399.499C686.106 401.083 687.39 402.374 688.981 402.374Z" fill="#DDDDDD"/>
<path d="M700.606 402.374C702.198 402.374 703.481 401.083 703.481 399.499C703.481 397.908 702.19 396.625 700.606 396.625C699.015 396.625 697.724 397.916 697.724 399.499C697.732 401.083 699.015 402.374 700.606 402.374Z" fill="#DDDDDD"/>
<path d="M712.24 402.374C713.831 402.374 715.115 401.083 715.115 399.499C715.115 397.908 713.823 396.625 712.24 396.625C710.649 396.625 709.357 397.916 709.357 399.499C709.357 401.083 710.649 402.374 712.24 402.374Z" fill="#DDDDDD"/>
<path d="M642.47 414C644.061 414 645.345 412.709 645.345 411.125C645.345 409.534 644.053 408.25 642.47 408.25C640.879 408.25 639.595 409.542 639.595 411.125C639.595 412.709 640.879 414 642.47 414Z" fill="#DDDDDD"/>
<path d="M654.096 414C655.687 414 656.971 412.709 656.971 411.125C656.971 409.534 655.679 408.25 654.096 408.25C652.505 408.25 651.221 409.542 651.221 411.125C651.221 412.709 652.512 414 654.096 414Z" fill="#DDDDDD"/>
<path d="M665.729 414C667.32 414 668.604 412.709 668.604 411.125C668.604 409.534 667.313 408.25 665.729 408.25C664.138 408.25 662.855 409.542 662.855 411.125C662.847 412.709 664.138 414 665.729 414Z" fill="#DDDDDD"/>
<path d="M677.355 414C678.946 414 680.23 412.709 680.23 411.125C680.23 409.534 678.938 408.25 677.355 408.25C675.764 408.25 674.472 409.542 674.472 411.125C674.472 412.709 675.764 414 677.355 414Z" fill="#DDDDDD"/>
<path d="M688.981 414C690.572 414 691.856 412.709 691.856 411.125C691.856 409.534 690.564 408.25 688.981 408.25C687.39 408.25 686.098 409.542 686.098 411.125C686.106 412.709 687.39 414 688.981 414Z" fill="#DDDDDD"/>
<path d="M700.606 414C702.198 414 703.481 412.709 703.481 411.125C703.481 409.534 702.19 408.25 700.606 408.25C699.015 408.25 697.724 409.542 697.724 411.125C697.732 412.709 699.015 414 700.606 414Z" fill="#DDDDDD"/>
<path d="M712.24 414C713.831 414 715.115 412.709 715.115 411.125C715.115 409.534 713.823 408.25 712.24 408.25C710.649 408.25 709.357 409.542 709.357 411.125C709.357 412.709 710.649 414 712.24 414Z" fill="#DDDDDD"/>
<path d="M642.47 425.626C644.061 425.626 645.345 424.334 645.345 422.751C645.345 421.16 644.053 419.876 642.47 419.876C640.879 419.876 639.595 421.168 639.595 422.751C639.595 424.342 640.879 425.626 642.47 425.626Z" fill="#DDDDDD"/>
<path d="M654.096 425.626C655.687 425.626 656.971 424.334 656.971 422.751C656.971 421.16 655.679 419.876 654.096 419.876C652.505 419.876 651.221 421.168 651.221 422.751C651.221 424.342 652.512 425.626 654.096 425.626Z" fill="#DDDDDD"/>
<path d="M665.729 425.626C667.32 425.626 668.604 424.334 668.604 422.751C668.604 421.16 667.313 419.876 665.729 419.876C664.138 419.876 662.855 421.168 662.855 422.751C662.847 424.342 664.138 425.626 665.729 425.626Z" fill="#DDDDDD"/>
<path d="M677.355 425.626C678.946 425.626 680.23 424.334 680.23 422.751C680.23 421.16 678.938 419.876 677.355 419.876C675.764 419.876 674.472 421.168 674.472 422.751C674.472 424.342 675.764 425.626 677.355 425.626Z" fill="#DDDDDD"/>
<path d="M688.981 425.626C690.572 425.626 691.856 424.334 691.856 422.751C691.856 421.16 690.564 419.876 688.981 419.876C687.39 419.876 686.098 421.168 686.098 422.751C686.106 424.342 687.39 425.626 688.981 425.626Z" fill="#DDDDDD"/>
<path d="M700.606 425.626C702.198 425.626 703.481 424.334 703.481 422.751C703.481 421.16 702.19 419.876 700.606 419.876C699.015 419.876 697.724 421.168 697.724 422.751C697.732 424.342 699.015 425.626 700.606 425.626Z" fill="#DDDDDD"/>
<path d="M712.24 425.626C713.831 425.626 715.115 424.334 715.115 422.751C715.115 421.16 713.823 419.876 712.24 419.876C710.649 419.876 709.357 421.168 709.357 422.751C709.357 424.342 710.649 425.626 712.24 425.626Z" fill="#DDDDDD"/>
<path d="M642.47 437.252C644.061 437.252 645.345 435.96 645.345 434.377C645.345 432.786 644.053 431.502 642.47 431.502C640.879 431.502 639.595 432.794 639.595 434.377C639.595 435.968 640.879 437.252 642.47 437.252Z" fill="#DDDDDD"/>
<path d="M654.096 437.252C655.687 437.252 656.971 435.96 656.971 434.377C656.971 432.786 655.679 431.502 654.096 431.502C652.505 431.502 651.221 432.794 651.221 434.377C651.221 435.968 652.512 437.252 654.096 437.252Z" fill="#DDDDDD"/>
<path d="M665.729 437.252C667.32 437.252 668.604 435.96 668.604 434.377C668.604 432.786 667.313 431.502 665.729 431.502C664.138 431.502 662.855 432.794 662.855 434.377C662.847 435.968 664.138 437.252 665.729 437.252Z" fill="#DDDDDD"/>
<path d="M677.355 437.252C678.946 437.252 680.23 435.96 680.23 434.377C680.23 432.786 678.938 431.502 677.355 431.502C675.764 431.502 674.472 432.794 674.472 434.377C674.472 435.968 675.764 437.252 677.355 437.252Z" fill="#DDDDDD"/>
<path d="M688.981 437.252C690.572 437.252 691.856 435.96 691.856 434.377C691.856 432.786 690.564 431.502 688.981 431.502C687.39 431.502 686.098 432.794 686.098 434.377C686.106 435.968 687.39 437.252 688.981 437.252Z" fill="#DDDDDD"/>
<path d="M700.606 437.252C702.198 437.252 703.481 435.96 703.481 434.377C703.481 432.786 702.19 431.502 700.606 431.502C699.015 431.502 697.724 432.794 697.724 434.377C697.732 435.968 699.015 437.252 700.606 437.252Z" fill="#DDDDDD"/>
<path d="M712.24 437.252C713.831 437.252 715.115 435.96 715.115 434.377C715.115 432.786 713.823 431.502 712.24 431.502C710.649 431.502 709.357 432.794 709.357 434.377C709.357 435.968 710.649 437.252 712.24 437.252Z" fill="#DDDDDD"/>
<path d="M723.866 437.252C725.457 437.252 726.741 435.96 726.741 434.377C726.741 432.786 725.449 431.502 723.866 431.502C722.275 431.502 720.983 432.794 720.983 434.377C720.983 435.968 722.275 437.252 723.866 437.252Z" fill="#DDDDDD"/>
<path d="M642.47 448.886C644.061 448.886 645.345 447.594 645.345 446.011C645.345 444.42 644.053 443.136 642.47 443.136C640.879 443.136 639.595 444.428 639.595 446.011C639.595 447.594 640.879 448.886 642.47 448.886Z" fill="#DDDDDD"/>
<path d="M654.096 448.886C655.687 448.886 656.971 447.594 656.971 446.011C656.971 444.42 655.679 443.136 654.096 443.136C652.505 443.136 651.221 444.428 651.221 446.011C651.221 447.594 652.512 448.886 654.096 448.886Z" fill="#DDDDDD"/>
<path d="M665.729 448.886C667.32 448.886 668.604 447.594 668.604 446.011C668.604 444.42 667.313 443.136 665.729 443.136C664.138 443.136 662.855 444.428 662.855 446.011C662.847 447.594 664.138 448.886 665.729 448.886Z" fill="#DDDDDD"/>
<path d="M677.355 448.886C678.946 448.886 680.23 447.594 680.23 446.011C680.23 444.42 678.938 443.136 677.355 443.136C675.764 443.136 674.472 444.428 674.472 446.011C674.472 447.594 675.764 448.886 677.355 448.886Z" fill="#DDDDDD"/>
<path d="M688.981 448.886C690.572 448.886 691.856 447.594 691.856 446.011C691.856 444.42 690.564 443.136 688.981 443.136C687.39 443.136 686.098 444.428 686.098 446.011C686.106 447.594 687.39 448.886 688.981 448.886Z" fill="#DDDDDD"/>
<path d="M700.606 448.886C702.198 448.886 703.481 447.594 703.481 446.011C703.481 444.42 702.19 443.136 700.606 443.136C699.015 443.136 697.724 444.428 697.724 446.011C697.732 447.594 699.015 448.886 700.606 448.886Z" fill="#DDDDDD"/>
<path d="M712.24 448.886C713.831 448.886 715.115 447.594 715.115 446.011C715.115 444.42 713.823 443.136 712.24 443.136C710.649 443.136 709.357 444.428 709.357 446.011C709.357 447.594 710.649 448.886 712.24 448.886Z" fill="#DDDDDD"/>
<path d="M723.866 448.886C725.457 448.886 726.741 447.594 726.741 446.011C726.741 444.42 725.449 443.136 723.866 443.136C722.275 443.136 720.983 444.428 720.983 446.011C720.983 447.594 722.275 448.886 723.866 448.886Z" fill="#DDDDDD"/>
<path d="M735.492 448.886C737.083 448.886 738.374 447.594 738.374 446.011C738.374 444.42 737.083 443.136 735.492 443.136C733.901 443.136 732.617 444.428 732.617 446.011C732.617 447.594 733.901 448.886 735.492 448.886Z" fill="#DDDDDD"/>
<path d="M630.844 460.511C632.435 460.511 633.719 459.219 633.719 457.636C633.719 456.045 632.427 454.761 630.844 454.761C629.253 454.761 627.969 456.053 627.969 457.636C627.969 459.219 629.253 460.511 630.844 460.511Z" fill="#DDDDDD"/>
<path d="M642.47 460.511C644.061 460.511 645.345 459.219 645.345 457.636C645.345 456.045 644.053 454.761 642.47 454.761C640.879 454.761 639.595 456.053 639.595 457.636C639.595 459.219 640.879 460.511 642.47 460.511Z" fill="#DDDDDD"/>
<path d="M654.096 460.511C655.687 460.511 656.971 459.219 656.971 457.636C656.971 456.045 655.679 454.761 654.096 454.761C652.505 454.761 651.221 456.053 651.221 457.636C651.221 459.219 652.512 460.511 654.096 460.511Z" fill="#DDDDDD"/>
<path d="M665.729 460.511C667.32 460.511 668.604 459.219 668.604 457.636C668.604 456.045 667.313 454.761 665.729 454.761C664.138 454.761 662.855 456.053 662.855 457.636C662.847 459.219 664.138 460.511 665.729 460.511Z" fill="#DDDDDD"/>
<path d="M677.355 460.511C678.946 460.511 680.23 459.219 680.23 457.636C680.23 456.045 678.938 454.761 677.355 454.761C675.764 454.761 674.472 456.053 674.472 457.636C674.472 459.219 675.764 460.511 677.355 460.511Z" fill="#DDDDDD"/>
<path d="M688.981 460.511C690.572 460.511 691.856 459.219 691.856 457.636C691.856 456.045 690.564 454.761 688.981 454.761C687.39 454.761 686.098 456.053 686.098 457.636C686.106 459.219 687.39 460.511 688.981 460.511Z" fill="#DDDDDD"/>
<path d="M700.606 460.511C702.198 460.511 703.481 459.219 703.481 457.636C703.481 456.045 702.19 454.761 700.606 454.761C699.015 454.761 697.724 456.053 697.724 457.636C697.732 459.219 699.015 460.511 700.606 460.511Z" fill="#DDDDDD"/>
<path d="M712.24 460.511C713.831 460.511 715.115 459.219 715.115 457.636C715.115 456.045 713.823 454.761 712.24 454.761C710.649 454.761 709.357 456.053 709.357 457.636C709.357 459.219 710.649 460.511 712.24 460.511Z" fill="#DDDDDD"/>
<path d="M723.866 460.511C725.457 460.511 726.741 459.219 726.741 457.636C726.741 456.045 725.449 454.761 723.866 454.761C722.275 454.761 720.983 456.053 720.983 457.636C720.983 459.219 722.275 460.511 723.866 460.511Z" fill="#DDDDDD"/>
<path d="M735.492 460.511C737.083 460.511 738.374 459.219 738.374 457.636C738.374 456.045 737.083 454.761 735.492 454.761C733.901 454.761 732.617 456.053 732.617 457.636C732.617 459.219 733.901 460.511 735.492 460.511Z" fill="#DDDDDD"/>
<path d="M630.844 472.137C632.435 472.137 633.719 470.845 633.719 469.262C633.719 467.671 632.427 466.387 630.844 466.387C629.253 466.387 627.969 467.678 627.969 469.262C627.969 470.845 629.253 472.137 630.844 472.137Z" fill="#DDDDDD"/>
<path d="M642.47 472.137C644.061 472.137 645.345 470.845 645.345 469.262C645.345 467.671 644.053 466.387 642.47 466.387C640.879 466.387 639.595 467.678 639.595 469.262C639.595 470.845 640.879 472.137 642.47 472.137Z" fill="#DDDDDD"/>
<path d="M654.096 472.137C655.687 472.137 656.971 470.845 656.971 469.262C656.971 467.671 655.679 466.387 654.096 466.387C652.505 466.387 651.221 467.678 651.221 469.262C651.221 470.845 652.512 472.137 654.096 472.137Z" fill="#DDDDDD"/>
<path d="M665.729 472.137C667.32 472.137 668.604 470.845 668.604 469.262C668.604 467.671 667.313 466.387 665.729 466.387C664.138 466.387 662.855 467.678 662.855 469.262C662.847 470.845 664.138 472.137 665.729 472.137Z" fill="#DDDDDD"/>
<path d="M677.355 472.137C678.946 472.137 680.23 470.845 680.23 469.262C680.23 467.671 678.938 466.387 677.355 466.387C675.764 466.387 674.472 467.678 674.472 469.262C674.472 470.845 675.764 472.137 677.355 472.137Z" fill="#DDDDDD"/>
<path d="M688.981 472.137C690.572 472.137 691.856 470.845 691.856 469.262C691.856 467.671 690.564 466.387 688.981 466.387C687.39 466.387 686.098 467.678 686.098 469.262C686.106 470.845 687.39 472.137 688.981 472.137Z" fill="#DDDDDD"/>
<path d="M700.606 472.137C702.198 472.137 703.481 470.845 703.481 469.262C703.481 467.671 702.19 466.387 700.606 466.387C699.015 466.387 697.724 467.678 697.724 469.262C697.732 470.845 699.015 472.137 700.606 472.137Z" fill="#DDDDDD"/>
<path d="M712.24 472.137C713.831 472.137 715.115 470.845 715.115 469.262C715.115 467.671 713.823 466.387 712.24 466.387C710.649 466.387 709.357 467.678 709.357 469.262C709.357 470.845 710.649 472.137 712.24 472.137Z" fill="#DDDDDD"/>
<path d="M723.866 472.137C725.457 472.137 726.741 470.845 726.741 469.262C726.741 467.671 725.449 466.387 723.866 466.387C722.275 466.387 720.983 467.678 720.983 469.262C720.983 470.845 722.275 472.137 723.866 472.137Z" fill="#DDDDDD"/>
<path d="M735.492 472.137C737.083 472.137 738.374 470.845 738.374 469.262C738.374 467.671 737.083 466.387 735.492 466.387C733.901 466.387 732.617 467.678 732.617 469.262C732.617 470.845 733.901 472.137 735.492 472.137Z" fill="#DDDDDD"/>
<path d="M630.844 483.763C632.435 483.763 633.719 482.471 633.719 480.888C633.719 479.297 632.427 478.013 630.844 478.013C629.253 478.013 627.969 479.304 627.969 480.888C627.969 482.479 629.253 483.763 630.844 483.763Z" fill="#DDDDDD"/>
<path d="M642.47 483.763C644.061 483.763 645.345 482.471 645.345 480.888C645.345 479.297 644.053 478.013 642.47 478.013C640.879 478.013 639.595 479.304 639.595 480.888C639.595 482.479 640.879 483.763 642.47 483.763Z" fill="#DDDDDD"/>
<path d="M654.096 483.763C655.687 483.763 656.971 482.471 656.971 480.888C656.971 479.297 655.679 478.013 654.096 478.013C652.505 478.013 651.221 479.304 651.221 480.888C651.221 482.479 652.512 483.763 654.096 483.763Z" fill="#DDDDDD"/>
<path d="M665.729 483.763C667.32 483.763 668.604 482.471 668.604 480.888C668.604 479.297 667.313 478.013 665.729 478.013C664.138 478.013 662.855 479.304 662.855 480.888C662.847 482.479 664.138 483.763 665.729 483.763Z" fill="#DDDDDD"/>
<path d="M677.355 483.763C678.946 483.763 680.23 482.471 680.23 480.888C680.23 479.297 678.938 478.013 677.355 478.013C675.764 478.013 674.472 479.304 674.472 480.888C674.472 482.479 675.764 483.763 677.355 483.763Z" fill="#DDDDDD"/>
<path d="M688.981 483.763C690.572 483.763 691.856 482.471 691.856 480.888C691.856 479.297 690.564 478.013 688.981 478.013C687.39 478.013 686.098 479.304 686.098 480.888C686.106 482.479 687.39 483.763 688.981 483.763Z" fill="#DDDDDD"/>
<path d="M700.606 483.763C702.198 483.763 703.481 482.471 703.481 480.888C703.481 479.297 702.19 478.013 700.606 478.013C699.015 478.013 697.724 479.304 697.724 480.888C697.732 482.479 699.015 483.763 700.606 483.763Z" fill="#DDDDDD"/>
<path d="M712.24 483.763C713.831 483.763 715.115 482.471 715.115 480.888C715.115 479.297 713.823 478.013 712.24 478.013C710.649 478.013 709.357 479.304 709.357 480.888C709.357 482.479 710.649 483.763 712.24 483.763Z" fill="#DDDDDD"/>
<path d="M723.866 483.763C725.457 483.763 726.741 482.471 726.741 480.888C726.741 479.297 725.449 478.013 723.866 478.013C722.275 478.013 720.983 479.304 720.983 480.888C720.983 482.479 722.275 483.763 723.866 483.763Z" fill="#DDDDDD"/>
<path d="M735.492 483.763C737.083 483.763 738.374 482.471 738.374 480.888C738.374 479.297 737.083 478.013 735.492 478.013C733.901 478.013 732.617 479.304 732.617 480.888C732.617 482.479 733.901 483.763 735.492 483.763Z" fill="#DDDDDD"/>
<path d="M642.47 495.396C644.061 495.396 645.345 494.105 645.345 492.521C645.345 490.93 644.053 489.646 642.47 489.646C640.879 489.646 639.595 490.938 639.595 492.521C639.595 494.105 640.879 495.396 642.47 495.396Z" fill="#DDDDDD"/>
<path d="M654.096 495.396C655.687 495.396 656.971 494.105 656.971 492.521C656.971 490.93 655.679 489.646 654.096 489.646C652.505 489.646 651.221 490.938 651.221 492.521C651.221 494.105 652.512 495.396 654.096 495.396Z" fill="#DDDDDD"/>
<path d="M665.729 495.396C667.32 495.396 668.604 494.105 668.604 492.521C668.604 490.93 667.313 489.646 665.729 489.646C664.138 489.646 662.855 490.938 662.855 492.521C662.847 494.105 664.138 495.396 665.729 495.396Z" fill="#DDDDDD"/>
<path d="M677.355 495.396C678.946 495.396 680.23 494.105 680.23 492.521C680.23 490.93 678.938 489.646 677.355 489.646C675.764 489.646 674.472 490.938 674.472 492.521C674.472 494.105 675.764 495.396 677.355 495.396Z" fill="#DDDDDD"/>
<path d="M688.981 495.396C690.572 495.396 691.856 494.105 691.856 492.521C691.856 490.93 690.564 489.646 688.981 489.646C687.39 489.646 686.098 490.938 686.098 492.521C686.106 494.105 687.39 495.396 688.981 495.396Z" fill="#DDDDDD"/>
<path d="M700.606 495.396C702.198 495.396 703.481 494.105 703.481 492.521C703.481 490.93 702.19 489.646 700.606 489.646C699.015 489.646 697.724 490.938 697.724 492.521C697.732 494.105 699.015 495.396 700.606 495.396Z" fill="#DDDDDD"/>
<path d="M712.24 495.396C713.831 495.396 715.115 494.105 715.115 492.521C715.115 490.93 713.823 489.646 712.24 489.646C710.649 489.646 709.357 490.938 709.357 492.521C709.357 494.105 710.649 495.396 712.24 495.396Z" fill="#DDDDDD"/>
<path d="M723.866 495.396C725.457 495.396 726.741 494.105 726.741 492.521C726.741 490.93 725.449 489.646 723.866 489.646C722.275 489.646 720.983 490.938 720.983 492.521C720.983 494.105 722.275 495.396 723.866 495.396Z" fill="#DDDDDD"/>
<path d="M735.492 495.396C737.083 495.396 738.374 494.105 738.374 492.521C738.374 490.93 737.083 489.646 735.492 489.646C733.901 489.646 732.617 490.938 732.617 492.521C732.617 494.105 733.901 495.396 735.492 495.396Z" fill="#DDDDDD"/>
<path d="M642.47 507.022C644.061 507.022 645.345 505.73 645.345 504.147C645.345 502.556 644.053 501.272 642.47 501.272C640.879 501.272 639.595 502.564 639.595 504.147C639.595 505.73 640.879 507.022 642.47 507.022Z" fill="#DDDDDD"/>
<path d="M654.096 507.022C655.687 507.022 656.971 505.73 656.971 504.147C656.971 502.556 655.679 501.272 654.096 501.272C652.505 501.272 651.221 502.564 651.221 504.147C651.221 505.73 652.512 507.022 654.096 507.022Z" fill="#DDDDDD"/>
<path d="M665.729 507.022C667.32 507.022 668.604 505.73 668.604 504.147C668.604 502.556 667.313 501.272 665.729 501.272C664.138 501.272 662.855 502.564 662.855 504.147C662.847 505.73 664.138 507.022 665.729 507.022Z" fill="#DDDDDD"/>
<path d="M677.355 507.022C678.946 507.022 680.23 505.73 680.23 504.147C680.23 502.556 678.938 501.272 677.355 501.272C675.764 501.272 674.472 502.564 674.472 504.147C674.472 505.73 675.764 507.022 677.355 507.022Z" fill="#DDDDDD"/>
<path d="M688.981 507.022C690.572 507.022 691.856 505.73 691.856 504.147C691.856 502.556 690.564 501.272 688.981 501.272C687.39 501.272 686.098 502.564 686.098 504.147C686.106 505.73 687.39 507.022 688.981 507.022Z" fill="#DDDDDD"/>
<path d="M700.606 507.022C702.198 507.022 703.481 505.73 703.481 504.147C703.481 502.556 702.19 501.272 700.606 501.272C699.015 501.272 697.724 502.564 697.724 504.147C697.732 505.73 699.015 507.022 700.606 507.022Z" fill="#DDDDDD"/>
<path d="M712.24 507.022C713.831 507.022 715.115 505.73 715.115 504.147C715.115 502.556 713.823 501.272 712.24 501.272C710.649 501.272 709.357 502.564 709.357 504.147C709.357 505.73 710.649 507.022 712.24 507.022Z" fill="#DDDDDD"/>
<path d="M723.866 507.022C725.457 507.022 726.741 505.73 726.741 504.147C726.741 502.556 725.449 501.272 723.866 501.272C722.275 501.272 720.983 502.564 720.983 504.147C720.983 505.73 722.275 507.022 723.866 507.022Z" fill="#DDDDDD"/>
<path d="M654.096 518.647C655.687 518.647 656.971 517.356 656.971 515.772C656.971 514.181 655.679 512.897 654.096 512.897C652.505 512.897 651.221 514.189 651.221 515.772C651.221 517.356 652.512 518.647 654.096 518.647Z" fill="#DDDDDD"/>
<path d="M665.729 518.647C667.32 518.647 668.604 517.356 668.604 515.772C668.604 514.181 667.313 512.897 665.729 512.897C664.138 512.897 662.855 514.189 662.855 515.772C662.847 517.356 664.138 518.647 665.729 518.647Z" fill="#DDDDDD"/>
<path d="M677.355 518.647C678.946 518.647 680.23 517.356 680.23 515.772C680.23 514.181 678.938 512.897 677.355 512.897C675.764 512.897 674.472 514.189 674.472 515.772C674.472 517.356 675.764 518.647 677.355 518.647Z" fill="#DDDDDD"/>
<path d="M688.981 518.647C690.572 518.647 691.856 517.356 691.856 515.772C691.856 514.181 690.564 512.897 688.981 512.897C687.39 512.897 686.098 514.189 686.098 515.772C686.106 517.356 687.39 518.647 688.981 518.647Z" fill="#DDDDDD"/>
<path d="M700.606 518.647C702.198 518.647 703.481 517.356 703.481 515.772C703.481 514.181 702.19 512.897 700.606 512.897C699.015 512.897 697.724 514.189 697.724 515.772C697.732 517.356 699.015 518.647 700.606 518.647Z" fill="#DDDDDD"/>
<path d="M712.24 518.647C713.831 518.647 715.115 517.356 715.115 515.772C715.115 514.181 713.823 512.897 712.24 512.897C710.649 512.897 709.357 514.189 709.357 515.772C709.357 517.356 710.649 518.647 712.24 518.647Z" fill="#DDDDDD"/>
<path d="M723.866 518.647C725.457 518.647 726.741 517.356 726.741 515.772C726.741 514.181 725.449 512.897 723.866 512.897C722.275 512.897 720.983 514.189 720.983 515.772C720.983 517.356 722.275 518.647 723.866 518.647Z" fill="#DDDDDD"/>
<path d="M654.096 530.273C655.687 530.273 656.971 528.982 656.971 527.398C656.971 525.807 655.679 524.523 654.096 524.523C652.505 524.523 651.221 525.815 651.221 527.398C651.221 528.989 652.512 530.273 654.096 530.273Z" fill="#DDDDDD"/>
<path d="M665.729 530.273C667.32 530.273 668.604 528.982 668.604 527.398C668.604 525.807 667.313 524.523 665.729 524.523C664.138 524.523 662.855 525.815 662.855 527.398C662.847 528.989 664.138 530.273 665.729 530.273Z" fill="#DDDDDD"/>
<path d="M677.355 530.273C678.946 530.273 680.23 528.982 680.23 527.398C680.23 525.807 678.938 524.523 677.355 524.523C675.764 524.523 674.472 525.815 674.472 527.398C674.472 528.989 675.764 530.273 677.355 530.273Z" fill="#DDDDDD"/>
<path d="M688.981 530.273C690.572 530.273 691.856 528.982 691.856 527.398C691.856 525.807 690.564 524.523 688.981 524.523C687.39 524.523 686.098 525.815 686.098 527.398C686.106 528.989 687.39 530.273 688.981 530.273Z" fill="#DDDDDD"/>
<path d="M700.606 530.273C702.198 530.273 703.481 528.982 703.481 527.398C703.481 525.807 702.19 524.523 700.606 524.523C699.015 524.523 697.724 525.815 697.724 527.398C697.732 528.989 699.015 530.273 700.606 530.273Z" fill="#DDDDDD"/>
<path d="M677.355 542.395C678.946 542.395 680.23 541.104 680.23 539.52C680.23 537.929 678.938 536.646 677.355 536.646C675.764 536.646 674.472 537.937 674.472 539.52C674.472 541.104 675.764 542.395 677.355 542.395Z" fill="#DDDDDD"/>
<path d="M688.981 542.395C690.572 542.395 691.856 541.104 691.856 539.52C691.856 537.929 690.564 536.646 688.981 536.646C687.39 536.646 686.098 537.937 686.098 539.52C686.106 541.104 687.39 542.395 688.981 542.395Z" fill="#DDDDDD"/>
<path d="M700.606 542.395C702.198 542.395 703.481 541.104 703.481 539.52C703.481 537.929 702.19 536.646 700.606 536.646C699.015 536.646 697.724 537.937 697.724 539.52C697.732 541.104 699.015 542.395 700.606 542.395Z" fill="#DDDDDD"/>
<path d="M712.24 530.273C713.831 530.273 715.115 528.982 715.115 527.398C715.115 525.807 713.823 524.523 712.24 524.523C710.649 524.523 709.357 525.815 709.357 527.398C709.357 528.989 710.649 530.273 712.24 530.273Z" fill="#DDDDDD"/>
<path d="M723.866 530.273C725.457 530.273 726.741 528.982 726.741 527.398C726.741 525.807 725.449 524.523 723.866 524.523C722.275 524.523 720.983 525.815 720.983 527.398C720.983 528.989 722.275 530.273 723.866 530.273Z" fill="#DDDDDD"/>



<defs>
<clipPath id="clip0">
<path d="M29 74H47V92H29V74Z" fill="white"/>
</clipPath>
<clipPath id="clip1">
<path d="M75 175H93V193H75V175Z" fill="white"/>
</clipPath>
<clipPath id="clip2">
<path d="M224 77H242V95H224V77Z" fill="white"/>
</clipPath>
<clipPath id="clip3">
<path d="M262 105H280V123H262V105Z" fill="white"/>
</clipPath>
<clipPath id="clip4">
<path d="M314 78H332V96H314V78Z" fill="white"/>
</clipPath>
<clipPath id="clip5">
<path d="M465 83H483V101H465V83Z" fill="white"/>
</clipPath>
<clipPath id="clip6">
<path d="M331 132H349V150H331V132Z" fill="white"/>
</clipPath>
<clipPath id="clip7">
<path d="M220 132H238V150H220V132Z" fill="white"/>
</clipPath>
<clipPath id="clip8">
<path d="M201 225H219V243H201V225Z" fill="white"/>
</clipPath>
<clipPath id="clip9">
<path d="M378 245H396V263H378V245Z" fill="white"/>
</clipPath>
<clipPath id="clip10">
<path d="M575 174H593V192H575V174Z" fill="white"/>
</clipPath>
</defs>
</svg>
  <div className='content-marker' 
    style={
        (window.innerWidth<=768)?
        {
            position: 'absolute',
            top: '43%',
            left: '37%',
            transform: 'translate(-50%, -50%)',
            height: '250px',
            width: '310px',
        }
        :
        null
    }
  >
            <Marker
                title={'Caracas'}
                text={'56%'}
                top={'0.17'}
                left={'37'}
                zIndex={5}

            />
             <Marker
                 title={'Zulia'}
                 text={'11%'}
                 top={'4.17'}
                 left={'-1.17'}
             />
             <Marker
                 title={'Falcón'}
                 text={'5%'}
                 top={'-7'}
                 left={'17'}
                 zIndex={5}
             />
               <Marker
                 title={'Bolívar'}
                 text={'5%'}
                 top={'30'}
                 left={'50'}
             />
 
             <Marker
                 title={'Táchira'}
                 text={'10%'}
                 top={'21'}
                 left={'4'}
             />
             <Marker
                 title={'Sucre'}
                 text={'3%'}
                 top={'0.17'}
                 left={'56'}
                 zIndex={5}
             />
               <Marker
                 title={'Apure'}
                 text={'4%'}
                 top={'28'}
                 left={'19'}
             />
              <Marker
                 title={'Guárico'}
                 text={'2%'}
                 top={'18'}
                 left={'38'}
             />
              <Marker
                 title={'Monagas'}
                 text={'1%'}
                 top={'15'}
                 left={'65'}
             />
             <Marker
             title={'Cojedes'}
             text={'3%'}
             top={'10'}
             left={'26'}
             />
      </div>        
        
         
            </React.Fragment>
        )
    }

}