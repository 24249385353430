import React from "react";
import Footer from "../views/Footer/Footer";
import Menu from "../Components/Menu/Menu";

import Home from "../views/home/Home";
import AboutUs from "../views/AboutUs/About";
import Login from "../views/Register/login";
import Require from "../views/Require/Require";
import Products from "../views/Products/Products";
import Contact from "../views/Contact/ContactUs";
import Confirm from "../views/Confirm/Confirm";
import Loading from "../Components/Loading/Loading";

// productos
import ProductD200T from "../views/Products/product-D200T";
import ProductS920 from "../views/Products/product-S920";
import ProductD220 from "../views/Products/product-D220";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      open: false,
      loading: true,
    };
  }
  handleClick() {
    this.setState({ open: !this.state.open });
  }
  showSettings(event) {
    event.preventDefault();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }
  render() {
    return (
      <React.Fragment>
        {this.state.loading ? <Loading /> : null}
        <Menu />

        <div
          style={{
            marginBottom: window.innerWidth <= 768 ? "" : 600,
          }}
        >
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/nosotros">
                <AboutUs />
              </Route>
              {/* <Route exact path="/pre-registro">
                            <Register/>
                        </Route> */}
              <Route exact path="/requisitos">
                <Require />
              </Route>
              <Route exact path="/productos">
                <Products />
              </Route>
              <Route exact path="/productos/D200T">
                <ProductD200T />
              </Route>
              <Route exact path="/productos/S920">
                <ProductS920 />
              </Route>
              <Route exact path="/productos/D220">
                <ProductD220 />
              </Route>
              <Route exact path="/contacto">
                <Contact />
              </Route>
              <Route exact path="/confirm">
                <Confirm />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
            </Switch>
          </Router>
        </div>

        <Footer />
        {/* <Transition  from="#FFF" to="#0032a0">
                        <div id="product"> 
                            <About  id='aboutUs'/>
                        </div>
                    </Transition>
                    <Transition  from="#0032a0" to="#FFF">
                        <div id="products2"> 
                            <About  id='products'/>
                        </div>
                    </Transition>
                    <Transition  from="#FFF" to="#0032a0">
                        <div id="products3"> 
                            <About id='products2'/>
                        </div>
                    </Transition>
                    <Transition  from="#0032a0" to="#FFF">
                        <div id="products4"> 
                            <About id="products3"/>
                        </div>
                    </Transition> */}
      </React.Fragment>
    );
  }
}
