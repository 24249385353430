import React from 'react'
import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Form,
    Modal,
    } from 'react-bootstrap'


import  {domainServer }  from '../../connection'
import Modalregistrer from '../../Components/ModalSession/Modalregistrer'
import Modalnotregister from '../../Components/ModalSession/modalNotregister'
export default class Login extends React.Component{

    constructor(props){
        super(props)
        this.state={
            show:false,
            errors:[],
            email:'',//email para login
            password:'',//password para login
            loadingLogin:false,//password para login

            show:false,
            showCreate:false,
        }
    }

    login(){

        var{email,password}=this.state
        var errors=this.state.errors
        this.setState({loadingLogin:true})
        if(email===''||password===''){
            alert('el correo y la contraseña son requeridos')
            if(email===''){
                errors['emailLogin']='Este campo es obligatorio'
            }
            if(password===''){
                var errors=this.state.errors
                errors['passwordLogin']='Este campo es obligatorio'
            }
            this.setState({
                            loadingLogin:false,
                            errors:errors,
                        })
        }
        else{
            errors['emailLogin']=''
            errors['passwordLogin']=''
            
            this.setState({
                errors:errors,
            })
            var propierities=this.props
            fetch(domainServer+'/api/login',{
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    username: email,
                    password: password
                })
            
            }).then((response)=>{ 
                    return response.json()
            }).then((res)=>{
                if(res.error!==undefined&&res.error!==null&&res.error!==''){
                    alert(res.mensaje)
                }

                this.setState({
                                loadingLogin:false,
                                token:res.token,
                            })
                propierities.login(res.token)
                localStorage.setItem("tokenPOS",res.token)
                localStorage.setItem("userPOS",JSON.stringify(res.info.user))
                localStorage.setItem("rolPOS",JSON.stringify(res.info.user.rol))
                window.location.reload()

                this.handleModal()//cerramos el modal
                // this.submit()//ejecutamos el envio de formulario
                
            })
            .catch((err)=>{
                this.setState({
                    showNotice:true,
                    messageNotice:'Error intentar conexion',
                    loadingLogin:false,
                })
                
            })
        }
      
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
         this.login()
        }
      }
    render(){
        return(
            <React.Fragment>
                <div className="container-fluid" style={{backgroundColor:'#F5F5F5'}}>
                <div className="container" style={{
                                                    minHeight:'100vh',
                                                    }}>
                    <div style={{
                                position:'absolute',
                                width:330,
                                backgroundColor:'#FFFFFF',
                                padding:20,
                                top:'50%',
                                left:'50%',
                                transform:'translate(-50%,-50%)',
                                }} >

                        <div className="row">
                            <div className="col-md-10  d-flex " style={{justifyContent: 'space-between',}}>
                                    <p style={{fontSize:'19px'}}>Inicio de sesión</p>
                            </div>
                        </div>
                        <div className="row" >
                                <div className="col-md-12">
                                    <Form.Group >
                                        <Form.Control 
                                                    type="text" ref="name" 
                                                    placeholder="Correo"
                                                    onChange={(e)=>this.setState({email:e.target.value})} 
                                                    />
                                        <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["emailLogin"]}</p>
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group >
                                        <Form.Control 
                                                    type="password" ref="name" 
                                                    placeholder="Contraseña"
                                                    onChange={(e)=>this.setState({password:e.target.value})} 
                                                    onKeyDown={this._handleKeyDown} 
                                                    />
                                        <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["passwordLogin"]}</p>
                                    </Form.Group>
                                </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-12  text-center">

                                <Button variant="primary" 
                                        style={{width:'100%'}}
                                        disabled={this.state.loadingLogin} 
                                        onClick={()=>this.login()}>
                                    {
                                        (this.state.loadingLogin)?'Espere...':'Iniciar Sesión'
                                    }
                                </Button>
                                <div style={{marginTop:'30px'}}>
                                <a 
                                    href='#' 
                                    style={{fontSize:'16px', marginTop:'60px'}}
                                    onClick={()=>{
                                                    
                                                    this.setState({showCreate:true})
                                                }}>
                                        Crea una cuenta</a>
                                        </div>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
                {/* <Modalregistrer
                    show={this.state.showCreate}
                    close={(e)=>{this.setState({showCreate:e})}}
                /> */}
                <Modalnotregister    show={this.state.showCreate}
                    close={(e)=>{this.setState({showCreate:e})}}/>
            </React.Fragment>
        )}




}