import React from "react";
import { Nav, Navbar, Button } from "react-bootstrap";
import MenuMobile from "./MenuMobile";
import Logo from "../../assets/images/LogoMenu.svg";
import Logo2 from "../../assets/images/logoBlancoAzul.svg";
import "./Menu.css";
import { domainServer } from "../../connection";
import Login from "../../views/Register/login";

var originHeight;

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      animation: false,
      logo: true,
      classButton: "menu-cta",
      active: "/",

      token: "",
      user: {},
      show: false,
      showCreate: false,
      login: false,
    };
  }
  updateDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }
  backgroundNavBar() {
    var parent = document.getElementById("cambiar-color");

    var elem = document.getElementsByClassName("background-navbar");

    var nav = document.querySelector(".menu-custom");
    var logo = document.querySelector(".menu-custom img");
    var track = window.scrollY; //total recorrido con scroll

    if (nav !== null && nav !== undefined && nav !== "") {
      var links = nav.getElementsByTagName("a"); //links del menu

      if (parent !== undefined && parent !== null && parent !== "") {
        var child = parent.firstChild;

        var color = child.style.backgroundColor; // get background color of transtion component
        color = color.replace(")", ",0.9)"); //transparencia

        if (
          child.offsetTop - 65 <= track &&
          track < child.offsetHeight + child.offsetTop
        ) {
          nav.style.backgroundColor = color; // assing background color
          if (parent.dataset.logo === "white") {
            this.setState({
              logo: false,
              classButton: "menu-cta-white",
            });
          } else {
            this.setState({
              logo: true,
              classButton: "menu-cta",
            });
          }
          for (var j = 0; j < links.length; j++) {
            links[j].style.color = parent.dataset.color;
          }
        } else {
          for (var i = 0; i < elem.length; i++) {
            if (
              elem[i].offsetTop - 65 <= track &&
              track < elem[i].offsetHeight + elem[i].offsetTop
            ) {
              nav.style.backgroundColor = elem[i].dataset.backgroundColor;
              if (elem[i].dataset.logo === "white") {
                this.setState({
                  logo: false,
                  classButton: "menu-cta-white",
                });
              } else {
                this.setState({
                  logo: true,
                  classButton: "menu-cta",
                });
              }
              for (var j = 0; j < links.length; j++) {
                links[j].style.color = elem[i].dataset.color;
              }
            }
          }
        }
      } else {
        if (elem.length > 0) {
          for (var i = 0; i < elem.length; i++) {
            if (
              elem[i].offsetTop - 65 <= track &&
              track < elem[i].offsetHeight + elem[i].offsetTop
            ) {
              nav.style.backgroundColor = elem[i].dataset.backgroundColor;
              if (elem[i].dataset.logo === "white") {
                this.setState({
                  logo: false,
                  classButton: "menu-cta-white",
                });
              } else {
                this.setState({
                  logo: true,
                  classButton: "menu-cta",
                });
              }
              for (var j = 0; j < links.length; j++) {
                links[j].style.color = elem[i].dataset.color;
              }
            }
          }
        } else {
          nav.style.backgroundColor = "#FFF";
          for (var j = 0; j < links.length; j++) {
            links[j].style.color = "rgba(0,0,0,.5)";
          }
        }
      }

      if (track > 60) {
        nav.style.height = `${originHeight - originHeight * 0.3}px`;
        logo.height = 50;
      } else {
        logo.height = 55;
        nav.style.height = `${originHeight}px`;
      }
    }

    /************** Aqui vamos a hacer la animacion de reducir tama#o el menu ******************* */
  }
  closeSession() {
    localStorage.setItem("tokenPOS", "");
    localStorage.setItem("userPOS", "");
    localStorage.setItem("rolPOS", "");
    window.location.reload();
  }
  handleBacktop() {
    var back = document.getElementById("back-top");
    var sizeBody = document.body.scrollHeight;
    if (sizeBody / 2 <= window.scrollY) {
      back.classList.add("back-show");
    } else {
      back.classList.remove("back-show");
    }
    //total recorrido con scroll
  }
  componentDidMount() {
    this.backgroundNavBar();
    window.addEventListener("resize", () => {
      this.updateDimensions();
    });
    window.addEventListener("scroll", () => {
      this.backgroundNavBar();
      this.handleBacktop();
    });

    /** con esto colocamos el active en el menu */
    var url = window.location.href;
    var page = url.split("/");
    this.setState({
      active: "/" + page[3],
    });

    var nav = document.querySelector(".menu-custom");

    var token = localStorage.getItem("tokenPOS");
    var user = localStorage.getItem("userPOS");
    user =
      user !== "" && user !== null && user !== undefined
        ? JSON.parse(user)
        : "";
    this.setState({
      token: token,
      user: user,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.updateDimensions();
    });
    window.addEventListener("scroll", () => {
      this.backgroundNavBar();
      this.handleBacktop();
    });
  }
  render() {
    return this.state.width < 1024 ? (
      <MenuMobile />
    ) : (
      <React.Fragment>
        <Navbar fixed="top" expand="lg" bg="#FFF" className="menu-custom">
          <Navbar.Brand href="/">
            <img height={55} src={this.state.logo ? Logo : Logo2} alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              this.setState({ animation: !this.state.animation });
              var nav = document.getElementsByClassName("navbar");
            }}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="d-flex flex-column  d-highlight"
          >
            {/* {
                        (this.state.token!==''&&this.state.token!==null&&this.state.token!==undefined)?
                            <Nav 
                                className={"mr-auto text-center justify-content-end"} 
                                style={{ 
                                        width: "100%",
                                        borderBottom:'1px solid #e4e4e4',
                                        marginBottom:'10px',
                                    }}
                                activeKey={this.state.active}
                                onSelect={(selectedKey) => {
                                                                this.setState({active:selectedKey}) 

                                                            }}
                                >
                                    <Nav.Item>
                                        <Nav.Link   className='item-menu' style={{borderBottom:'none',}} href="#">{(this.state.user.fullName!==undefined&&this.state.user.fullName!==''&&this.state.user.fullName!==null)?this.state.user.fullName.toUpperCase():''}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link   className='item-menu' href="#"  style={{borderBottom:'none',}} onClick={()=>this.closeSession()}>CERRAR SESIÓN</Nav.Link>
                                    </Nav.Item>
                                    
                            </Nav>
                        
                        :null
                    } */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Nav
                className={"mr-auto text-center justify-content-center"}
                style={{ width: "100%" }}
                activeKey={this.state.active}
                onSelect={(selectedKey) => {
                  this.setState({ active: selectedKey });
                }}
              >
                <Nav.Item>
                  <Nav.Link className="item-menu" href="/">
                    Inicio
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="item-menu" href="/nosotros">
                    Nosotros
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="item-menu" href="/productos">
                    Productos
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link className="item-menu" href="/requisitos">
                    Requisitos
                  </Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                                    <Nav.Link    className='item-menu' href="/certificaciones">CERTIFICACIONES</Nav.Link>
                                </Nav.Item> */}
                {/* <Nav.Item>
                  <Nav.Link className="item-menu" href="/contacto">
                    Contacto
                  </Nav.Link>
                </Nav.Item> */}

                {/* <Nav.Item>
                                    <Nav.Link className='item-menu' href='#' onClick={()=>{this.setState({show:true})}}>INICIAR SESÓN</Nav.Link>
                                </Nav.Item>                                
                                <Nav.Item>
                                    <Nav.Link className='item-menu' href='#' onClick={()=>{this.setState({showCreate:true})}}>CREAR CUENTA</Nav.Link>
                                </Nav.Item> */}
              </Nav>
              {/* <div
                style={{
                  display: "flex",
                  width: "200px",
                }}
              >
                <a
                href="/login"
                  id="call-to-action"
                  className={
                    // this.state.classButton
                    "menu-cta2"
                  }
                >
                  Solicitud Online
                </a>
              </div> */}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}
