import React from 'react'

import { Container, Row, Col,Form,Button,Modal } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';

import Notice from '../../Components/Notice/Notice'
import  {domainServer }  from '../../connection'
import Loading from '../../Components/Loading/Loading'
import img1 from '../../assets/images/contaco/agua.png'
import img2 from '../../assets/images/contaco/cafe.png'
import img3 from '../../assets/images/contaco/moca.png'
import img4 from '../../assets/images/contaco/te.png'

const arrImg=[img1,img2,img3,img4]

export default class ContatUs extends React.Component{

    constructor(props){
        super(props)
        this.state={
            loading:true,
            selected:'',
            POSselected:'',
            marginBottom:'',
            show:false,
            showPos:false,
            responsive:false,

            fullName:'',
            phone:'',
            email:'',
            message:'',

            showNotice:false,
            messageNotice:'',

            reasonList:[{id:1,name:'visita'},{id:2,name:'pos'},],
            drinkList:[
                        {id:1,name:'cafe'},
                        {id:2,name:'moccacino'},
                        {id:3,name:'cappuccino'},
                        {id:4,name:'te'},
            ],
            typePosList:[],
            

        }
    }
    handleModal(){
        this.setState({
            show:!this.state.show,
        })
    }
    handleSelectPos(){
        this.setState({
            show:!this.state.showPos,
        })
    }
    selectDrink(data){
        var {
                drinkList,
            }=this.state

        for(var i=0;i<drinkList.length;i++){
            if(data.id==drinkList[i].id){
                if(drinkList[i].selected){// la bebida esta seleccionada y vamos a deseleccionar
                    drinkList[i].selected=false
                    this.setState({drinkList:drinkList})
                }
                else{
                    drinkList[i].selected=true
                    this.setState({drinkList:drinkList})
                }
            }
        }


    }
    send(){
        var {
                fullName,
                phone,
                email,
                message,
                POSselected,
                selected,
                drinkList
            }=this.state

        var bebidas=[]
        for(var i=0;i<drinkList.length;i++){
            if(drinkList[i].selected){
                bebidas.push({drink:{id:drinkList[i].id}})
            }
        }

        var data={
            "fullName": fullName,
            "phone": phone,
            "email": email,
            "message": message,
            "emailPos": "support@email.com",
            "reason": {
                "id": selected
            },
            "typePos": {
                "id": POSselected
            },
            "contactDrink": bebidas
        }
           
        fetch(domainServer+'/api/contact/save',{
            method: 'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
            body:JSON.stringify(data)
        }).then((response)=>{ 
            if(response.status!==200&&response.status!=="200"){
                alert(response.message)
            }
            else{
                this.setState({
                    showNotice:true,
                    messageNotice:'Gracias por comunicarte con nosotros!',

                    fullName:'',
                    phone:'',
                    email:'',
                    message:'',
                    POSselected:'',
                    selected:'',
                })
            }
        })
        .catch((err)=>{
            this.setState({
                showNotice:true,
                messageNotice:'Error intentar conexion'
            })
        })
            // setTimeout(()=>{this.setState({showNotice:false,messageNotice:''})}, 10000)
        
    }
    componentDidMount(){
        if(window.innerWidth<=768)
        {
            this.setState({
                marginBottom: '85vh',
                responsive:true,
            })
        }


        fetch(domainServer+'/api/utils/list',{
            method: 'GET',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
        }).then((response)=>{ 
            if(response.status!==200&&response.status!=="200"){
                alert(response.message)
            }
            else{
                return response.json()
            }
        })
        .then((res)=>{
            console.log(res);

            this.setState({
                            reasonList:res.reason,
                            drinkList:res.drink,
                            typePosList:res.typePos,
                            loading:false,
                    })
        })
        .catch((err)=>{
            console.log('Error intentar conexion '+err)
            this.setState({
                showNotice:true,
                messageNotice:'Error de conexión',
                loading:false,
            })
        })
        
    }
    render(){
        
        if(this.state.loading){
            return(<Loading/>)
        }
        else{
            return(
                <React.Fragment>
                    
                    <Container fluid  style={{backgroundColor:'#FFF',}}>
                        {/* {
                            (this.state.showNotice)?
                                <Notice show={this.state.showNotice}>
                                    {this.state.messageNotice}
                                </Notice>
                            :null
                        } */}
                                <Notice 
                                    show={this.state.showNotice}
                                    onClick={()=>{this.setState({showNotice:false})}}
                                    >
                                    {this.state.messageNotice}
                                </Notice>
                        <Row style={{paddingTop:200,paddingBottom:50,}}>
                            <Col md={12}>
                                <h1 className="text-center sectionTitle" >¿Quieres decirnos algo?</h1>
                                <p className="text-center sectionSubTitle  ">
                                    Contacta con alguno de nosotros de forma programada. Uno de nuestros
                                    especialistas te contactará tan pronto como sea posible
                                </p>
                            </Col>
                        </Row>
                        <Row >
                            
                            <Col md={6}  className='mx-auto' >
                                <Col md={10} className='mx-auto' style={{paddingBottom:200}}>
                                    <Form.Group >
                                
                                        <Form.Control  
                                                    type="text" 
                                                    style={{marginTop:0}}
                                                    defaultValue={this.state.fullName} 
                                                    value={this.state.fullName} 
                                                    placeholder="Nombre y apellido" 
                                                    onChange={(e)=>{
                                                                        this.setState({
                                                                        fullName:e.target.value,
                                                                        })}}
                                                    />
                                        <Form.Control  
                                                    type="phone" 
                                                    defaultValue={this.state.phone} 
                                                    value={this.state.phone} 
                                                    placeholder="Teléfono" 
                                                    onChange={(e)=>{
                                                                    this.setState({
                                                                        phone:e.target.value,
                                                                    })}}
                                                    />
                                        <Form.Control  
                                                    type="email" 
                                                    defaultValue={this.state.email} 
                                                    value={this.state.email} 
                                                    placeholder="Correo electrónico" 
                                                    onChange={(e)=>{
                                                                    this.setState({
                                                                        email:e.target.value,
                                                                    })}}
                                                    />
                                    <select 
                                            placeholder='¿De qué nos quieres hablar?' 
                                            defaultValue={this.state.selected} 
                                            value={this.state.selected} 
                                            className="select-custom" 
                                            onChange={(e)=>{
                                                                this.setState({
                                                                                selected:e.target.value,
                                                                                show:(e.target.value==1 && this.state.responsive)?true:false,
                                                                                showPos:(e.target.value==2)?true:false,
                                                                            })
                                                            }}>
                                            
                                            <option value="" selected>¿De qué nos quieres hablar?</option>
                                            {/* <option value="1">visita</option>
                                            <option value="2" >rep</option> */}
                                            {
                                            (this.state.reasonList!==undefined&&this.state.reasonList!==null&&this.state.reasonList!=='')?
                                                
                                                this.state.reasonList.map((data, i)=>{
                                                    if(data.status){
                                                        return<option key={i} value={data.id} >{data.name}</option>
                                                    }
                                                })
                                            
                                            :null
                                            
                                            }
                                        
                                        
                                        
                                    </select>

                                


                                    {
                                        (this.state.showPos)?
                                        <ScrollAnimation animateIn="fadeIn">
                                            <select  
                                                    placeholder='Seleccione el modelo' 
                                                    defaultValue={this.state.POSselected} 
                                                    value={this.state.POSselected} 
                                                    className="select-custom" 
                                                    onChange={(e)=>{
                                                                        this.setState({
                                                                                        POSselected:e.target.value,
                                                                                    })
                                                                    }}>
                                                    
                                                    <option value="" selected>Seleccione el modelo</option>
                                                    {
                                                (this.state.typePosList!==undefined&&this.state.typePosList!==null&&this.state.typePosList!=='')?
                                                    
                                                    this.state.typePosList.map((data, i)=>{
                                                        if(data.status){
                                                            return<option key={i} value={data.id} selected>{data.name}</option>
                                                        }
                                                    })
                                                
                                                :null
                                                
                                                }
                                                
                                                
                                            </select >
                                        </ScrollAnimation>
                                        :null
                                    }

                                    <Form.Control  
                                            as='textArea'
                                            type="text" 
                                            defaultValue={this.state.message} 
                                            value={this.state.message} 
                                            placeholder="Escribir aquí..." 
                                            onChange={(e)=>{
                                                            this.setState({
                                                                message:e.target.value,
                                                            })}}
                                            />
                                    </Form.Group>

                                    <Button variant="outline-primary" style={{width:'100%'}} onClick={()=>{  this.setState({showNotice:false,messageNotice:''}) ;this.send()}}>
                                        Enviar
                                    </Button>
                                </Col>
                            </Col>
                            <Col md={6} style={{ 
                                                paddingBottom:100,
                                                display:(this.state.selected==1 && !this.state.responsive)?'block':'none',
                                                
                                                }}
                                                className={(this.state.selected==1 && !this.state.responsive)?'coffee':''}
                                                >
                                <Row className='text-center'>
                                    <Col md={12}>
                                        <h2 className="text-center " >¿Qué bebida prefieres? </h2>
                                    </Col>
                                    <Container style={{paddingTop:0,paddingLeft:100,paddingRight:100,}}>
                                    <Row  className='text-center' >

                                                {/* <Col xs={6}>
                                                    <div
                                                        className={`coffee-img active`}
                                                        style={{backgroundColor:' #4B3621',
                                                                backgroundImage:`url(${img1})`,

                                                        }}
                                                        width={300}
                                                        >
                                                    </div>
                                                    <p className='text-center'>hola</p>
                                                </Col> */}
                                        {
                                        (this.state.drinkList!==undefined&&this.state.drinkList!==null&&this.state.drinkList!=='')?
                                                
                                            this.state.drinkList.map((data, i)=>{
                                                if(data.status){
                                                    return(
                                                            <Col xs={6} key={i}>
                                                            <div
                                                                key={i}
                                                                onClick={()=>{this.selectDrink(data)}}
                                                                className={`coffee-img ${(data.selected)?'active':''}`}
                                                                style={{
                                                                        backgroundImage:`url(${arrImg[i]})`,

                                                                }}
                                                                width={300}
                                                                >
                                                            </div>
                                                            <p className='text-center'>{data.name}</p>
                                                            </Col>)
                                                }
                                            })
                                            :null
                                        }
                                    
                                        {/* <Col xs={6}>
                                                <div
                                                        onClick={()=>{this.setState({coffe2:!this.state.coffe2})}}
                                                        className={`coffee-img ${(this.state.coffe2)?'active':''}`}
                                                        width={300}
                                                        style={{backgroundColor:' #A18262'}}
                                                        >
                                                    
                                                    </div>
                                                    <p className='text-center'>Bebida 2</p>
                                        </Col>
                                    </Row>
                                    <Row  className='text-center'>
                                        <Col xs={6}>
                                                    <div
                                                        onClick={()=>{this.setState({coffe3:!this.state.coffe3})}}
                                                        className={`coffee-img ${(this.state.coffe3)?'active':''}`}
                                                        width={300}
                                                        style={{backgroundColor:' #804000'}}
                                                        >
                                                        
                                                    
                                                    </div>
                                                    <p className='text-center'>Bebida 3</p>           
                                        </Col>
                                        <Col xs={6}>
                                                    <div
                                                        onClick={()=>{this.setState({coffe4:!this.state.coffe4})}}
                                                        className={`coffee-img ${(this.state.coffe4)?'active':''}`}
                                                        width={300}
                                                        style={{backgroundColor:' #EED09D'}}
                                                        >
                                                        
                                                    </div>
                                                    <p className='text-center'>Bebida 4</p>
                                    </Col>*/}
                                    </Row> 
                                    </Container>
                                </Row>
                                

                            </Col>
                        </Row>
                        
                    </Container>

                    <Modal 
                        show={this.state.show} onHide={this.handleModal.bind(this)}
                        size="lg">
                        <Modal.Header closeButton>
                        <Modal.Title>¿Qué bebida prefieres?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='coffee' style={{minHeight:'0'}}>
                            {/* <Coffee/> */}
                        <Row 
                            className={(this.state.selected==1 && !this.state.responsive)?'coffee':''}
                            style={{minHeight:'0',paddingTop:0}}>
                                
                                    <Container fluid>
                                    <Row  style={{paddingTop:0}} className='text-center'>
                                    {
                                        (this.state.drinkList!==undefined&&this.state.drinkList!==null&&this.state.drinkList!=='')?
                                                
                                            this.state.drinkList.map((data, i)=>{
                                                if(data.status){
                                                    return(
                                                            <Col xs={12} sm={6} key={i}>
                                                            <div
                                                                key={i}
                                                                onClick={()=>{this.selectDrink(data)}}
                                                                className={`coffee-img ${(data.selected)?'active':''}`}
                                                                style={{
                                                                        backgroundImage:`url(${arrImg[i]})`,
                                                                        backgroundPosition:'center',
                                                                        margin: '20px 0',
                                                                    }}
                                                                width={300}
                                                                >
                                                            </div>
                                                            <p className='text-center'>{data.name}</p>
                                                            </Col>)
                                                }
                                            })
                                        
                                        :null
                                        }
                                    </Row>
                                </Container>
                        </Row>
                                
                            
                        </Modal.Body>
                        <Modal.Footer style={{justifyContent:'center'}}>
                            <Button variant="primary" onClick={this.handleModal.bind(this)}>
                                Listo
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
            )
        }
    }


}