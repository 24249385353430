import React from 'react'
import Lottie from "react-lottie-wrapper";
import * as animationData from "./loader-blu.json"
import loader from "./loader.gif"
 

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    pause:false
  };

export default class Loading extends React.Component{

    render(){
        return(

            
            <div id='loading'>
            <div>
               <img width={200} src={loader} alt='loader'/>
            </div>
        </div>
        )
    }


}