import React from 'react'


export default class Notice extends React.Component{

    constructor(props){
        super(props)
        this.state={
            width:0,
            show:true,
            text:''
        }
    }

    hide(){
        this.setState({
            show:false,
        })
    }

    componentDidMount(){

      
        if(1024<=window.innerWidth){
           this.setState({width: window.innerWidth*0.3})
        }
        else{
            if(768<=window.innerWidth && window.innerWidth<1024){
                this.setState({width: window.innerWidth*0.4})

            }
            else{
                this.setState({width: window.innerWidth*0.9})
            }
        }
    }
    render(){
        if(this.props.show){
        return (
                <div 
                    className='notice'
                    style={{    
                                width: this.state.width,
                                backgroundColor:(this.props.background!==''&&this.props.background!==undefined&&this.props.background!==null)?this.props.background:'#3cb4e5'
                        }}
                    onClick={()=>{this.props.onClick()}}
                >
                    {this.props.children}
                </div>

        )}
        else{return null}
    }


} 