
import React from "react"
import { AnimatedBg, Transition } from 'scroll-background';
import ScrollAnimation from 'react-animate-on-scroll';

import ProductLeft from './ProductLeft'
import ProductRight from './ProductRight'
import PostSale from './PostSale'

import D220 from '../../assets/images/pos/D220.png'
import D200T from '../../assets/images/pos/D200T.png'
import S920 from '../../assets/images/pos/S920.png'


import EspD200T from '../../assets/files/D200T.pdf'
import EspD220 from '../../assets/files/D220.pdf'
import EspS920 from '../../assets/files/S920.pdf'



export default class Products extends React.Component{

    render(){
        return(
            <React.Fragment>
             <div id='cambiar-color'
                  data-background-color='#FFF' 
                  data-logo='white' 
                  data-color='#FFF' 
                  style={{
                            overflow:'hidden',
                            
                            }}>
                <AnimatedBg >
                    <Transition  from="#001740" to="#2E8FD5">
                        <div id="productos"   
                                                    style={{
                                                        // background:`linear-gradient(-180deg, #0033A1 0%,  rgba(46, 143, 213, 0.04) 60.08%)`,
                                                        color:'#FFF',
                                                        paddingTop:250,
                                                        }}>
                            <div >
                            <ScrollAnimation animateIn="fadeInUp">

                                <h1 className="text-center sectionTitle" >Nuestros productos</h1>
                                <p className="text-center sectionSubTitle" >
                                    POSITIVO te ofrece una gama de productos de alta tecnología digital que te permitirá llegar donde quieras.</p>
                            </ScrollAnimation>
                            </div>
                            <ProductLeft
                                img={D200T}
                                title='POS Portátil'
                                subtitle='Traslada tu negocio a cada rincón del país con la mejor tecnología y plataforma.'
                                codeColor='rgba(132, 19, 244, 1)'
                                code='D200T'
                                text='El equipo portátil con la gran capacidad
                                operativa que su negocio necesita.'
                                textButton='Ver más'
                                href="/productos/D200T"
                            />
                          
                        </div>
                    </Transition>
                    <Transition  from="#2E8FD5" to="#6121a2">
                        <div >
                            <ProductRight
                                    img={S920}
                                    title='POS Inalámbrico'
                                    subtitle='Convertir lo tradicional en lo innovador, es una de las funciones principales de este equipo.'
                                    codeColor='rgba(132, 19, 244, 1)'
                                    code='S920'
                                    text='El equipo que mezcla durabilidad y
                                    versatilidad de manera única y portátil'
                                    textButton='Ver más'
                                    href="/productos/S920"
                            />
                        </div>
                    </Transition>
                    <Transition  from="#6121a2" to="#333333" >
                        <div >
                        <ProductLeft
                                img={D220}
                                title='POS ligero'
                                subtitle='La exclusividad, delicadeza y Robustez en un solo equipo.'
                                codeColor='rgba(132, 19, 244, 0.2)'
                                code='D220'
                                text='El equipo que ofrece una nueva experiencia
                                con altos estándares de calidad, seguridad y
                                comodidad.'
                                textButton='Ver más'
                                href="/productos/D220"
                            />
                        </div>
                    </Transition>
                
                </AnimatedBg>
                <div className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)' >
                    <PostSale/>
                </div>
            </div> 
            </React.Fragment>
        )
    }
}