
import React from "react"
import { AnimatedBg, Transition } from 'scroll-background';
import {Carousel, Row, Col, Container, Accordion, Card} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';

import ProductLeft from './ProductLeft'
import ProductRight from './ProductRight'
import PostSale from './PostSale'

import D220 from '../../assets/images/pos/D220.png'
import D200T_1 from '../../assets/images/equipos550/9.png'
import D200T_2 from '../../assets/images/equipos550/10.png'
import D200T_3 from '../../assets/images/equipos550/11.png'
import D200T_4 from '../../assets/images/equipos550/12.png'
// import Img_1 from '../../assets/images/requisitos/1.jpg'
import Img_1 from '../../assets/images/equipos550/pos.png'

import PDF from '../../assets/pdf/D220.pdf'



export default class ProductD220 extends React.Component{

    constructor(props){
        super(props)
        this.state={
            down1:false,
            down2:false,
            down3:false,
            down4:false,
            down5:false,
            down6:false,
        }
    }

    render(){
        return(
            <React.Fragment>
             <div id='cambiar-color'
                  data-background-color='#FFF' 
                  data-logo='white' 
                  data-color='#FFF' 
                  style={{
                            overflow:'hidden',
                            
                            }}>
                <AnimatedBg >
                    <Transition  from="#001740" to="#2E8FD5">
                        <div id="productos"   
                                                    style={{
                                                        // background:`linear-gradient(-180deg, #0033A1 0%,  rgba(46, 143, 213, 0.04) 60.08%)`,
                                                        color:'#FFF',
                                                        paddingTop:200,
                                                        }}>
                            
                            <Row>
                                <Col style={{paddingLeft:' 65px'}}  md={6}>

                                    <h1 className="sectionTitle">D220</h1>
                                    <h5 className="subtitle-product">Punto de venta portátil</h5>
                                    {/* <div className="mt-3 mx-3"> */}
                                        <p className="mt-3" style={{fontSize:'16px',maxWidth:'500px'}}>El dispositivo PAX D220 es el terminal de pago más innovador del mercado. Tan poderoso y portátil como un
teléfono inteligente, el sencillo y moderno D220 está equipado con un procesador de alto rendimiento (Cortex A9),
y posee una pantalla táctil capacitiva de 4 pulgadas que se adapta a los hábitos operacionales de todo usuario.
                                        </p>
                                    {/* </div> */}
                                    <a 
                                        href={PDF}
                                        target='blank' 
                                        style={{
                                                display:'flex',
                                                justifyContent:'space-between',
                                                color:'#52b8fc',
                                                fontSize:'15px',
                                                maxWidth:'218px',
                                                }}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cloud-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={{marginTop:'5px'}}>
                                                    <path fill='#52b8fc'fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                                    <path fill='#52b8fc'fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                                                </svg>
                                                <p style={{marginLef:'10px'}}>Descarga documento técnico</p>
                                        </a>

                                </Col>
                                <Col md={6} >
                                    <Carousel 
                                    indicators={false} 
                                    interval={3500}                                    
                                    >
                                        <Carousel.Item>
                                            <img className="d-block mx-auto pb-5" src={D200T_1} alt="second img"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block mx-auto pb-5" src={D200T_2} alt="second img"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block mx-auto pb-5" src={D200T_3} alt="second img"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block mx-auto pb-5" src={D200T_4} alt="second img"/>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            
                            </Row>
                            
                            <Container fluid className="text-center p-5">
                                <h2 className="pb-5">Características</h2>
                                <Row style={{marginTop:0, paddingTop:0}}>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                        <strong className='product-detail-strong'>Lector de chip EMV y banda magnética</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Conexión inalámbrica (WiFi/GPRS)</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Pantalla táctil a color</strong>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:0, paddingTop:0}}>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>PCI PTS 4.x, SRED</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Tecnología NFC para pagos contactless</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Seguridad sin precedentes</strong>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:0, paddingTop:0, paddingBottom:'3rem'}}>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Conexión flexible <br/> Conectividad 3G y 4G</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Rápido procesamiento de información</strong>
                                    </Col>
                                    <Col className="text-dark p-5 bg-white p-0 border hover-target">
                                    <strong className='product-detail-strong'>Diseño ligero y refinado</strong>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </Transition>
                
                </AnimatedBg>
                <div className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)' >
                
                    <img style={{width:'100%'}} src={Img_1}/>
                        
                <Container fluid  style={{backgroundColor:'#FFF',}}>
                    <Container >

                    <Row style={{marginTop:10}}>
                        <Col md={12} >
                            <h1 className='text-center sectionTitle'>Especificaciones Técnicas</h1>
                        </Col>
                    </Row>

                        <Row >
                            <Col md={12}>
                                <ScrollAnimation animateIn="fadeInUp">
                                <Row >
                        <Col md={8} className='mx-auto'>
                            <Accordion defaultActiveKey="">
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="0" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                                                        down1:!this.state.down1,
                                                                        down2:false,
                                                                        down3:false,
                                                                        down4:false,
                                                                        down5:false,
                                                                    
                                                                    })}}
                                        >
                                    <div style={{
                                                display: 'flex',
                                                justifyContent:' space-between'
                                                }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent:' space-between'
                                                }}
                                        >
                                            <span style={{marginTop:6,color:(!this.state.down1)?'#212529':'#ff6600'}}>Componentes</span>
                                        </div>
                                         <div className={(!this.state.down1)?'down':'down-active'}>
                                               
                                            </div>
                                    </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul style={{listStyle:'none'}}>
                                            <li><strong>Procesador</strong> : Cortex A9</li>
                                            <li><strong>Memoria</strong>: Flash 512MB+DDR 512MB</li>
                                            <li><strong>Pantalla</strong>: Pantalla táctil a color de 4 pulgadas (480 x 800 pixeles)</li>
                                            <li><strong>Puerto</strong>: 1 Micro USB</li>
                                            <li><strong>Teclas</strong>: Enter, Cancel, Clear (teclas táctiles), 0-9 (teclado virtual)</li>
                                            <li><strong>Lector de banda magnética</strong>: Bi-directional Track 1 / 2 / 3</li>
                                        </ul>
                                        <strong>Ranuras para tarjetas</strong>
                                        <ul>
                                            <li>1 Micro SAM</li>
                                            <li>1 Micro SIM</li>
                                        </ul>
                                        <strong>Lector de tarjetas inteligentes</strong>
                                        <ul>
                                            <li>ECertificado para chip EMV de niveles 1 y 2 </li>
                                        </ul>
                                        <strong>Lector de tarjetas contactless (Pago sin contacto - NFC) </strong>
                                        <ul>
                                            <li>MasterCard PayPass & Visa payWave </li>
                                            <li>American Express ExpressPay </li>
                                            <li>Discover DPAS </li>
                                            <li>Apple Pay & Android Wallet </li>
                                        </ul>
                                        <strong>Audio</strong>
                                        <ul>
                                            <li>Altavoz</li>
                                        </ul>


                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="1" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                                                        down1:false,
                                                                        down2:!this.state.down2,
                                                                        down3:false,
                                                                        down4:false,
                                                                        down5:false,
                                                                        down6:false,
                                                                        
                                                                    })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <span style={{marginTop:6,color:(!this.state.down2)?'#212529':'#ff6600'}}>Batería</span>
                                            </div>
                                            <div className={(!this.state.down2)?'down':'down-active'}>
                                               
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                    <strong>Batería</strong>
                                        <ul>
                                            <li>Batería de Li-ion de 3050mAh</li>
                                        </ul>
                                        <strong>Voltaje</strong>
                                        <ul>
                                            <li>Entrada: 110 – 240V AC, 50/60Hz</li>
                                            <li>Salida: 5V DC, 2A</li>
                                        </ul>
                                        <strong>Medio Ambiente</strong>
                                        <ul>
                                            <li>Temperatura durante uso: 0ºC a 50ºC (32ºF a 122ºF) </li>
                                            <li>Temperatura durante almacenamiento: -20ºC a 70ºC (-4ºF a 158ºF) </li>
                                            <li>Humedad relativa, sin condensar: 10% a 93% </li>
                                        </ul>

                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="2" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:!this.state.down3,
                                            down4:false,
                                            down5:false,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <span style={{marginTop:6,color:(!this.state.down3)?'#212529':'#ff6600'}}>Comunicación</span>
                                            </div>
                                            <div className={(!this.state.down3)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <ul>
                                            <li>GPRS</li>
                                            <li>WiFi</li>
                                        </ul>
                                        <strong>Seguridad</strong>
                                        <ul>
                                            <li>DUKPT, 3DES</li>
                                            <li>Algoritmo de cifrado de llaves PIN</li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="3" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:false,
                                            down4:!this.state.down4,
                                            down5:false,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <span style={{marginTop:6,color:(!this.state.down4)?'#212529':'#ff6600'}}>Dimensiones físicas</span>
                                            </div>
                                            <div className={(!this.state.down4)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <strong>Medidas</strong>
                                        <ul>
                                            <li>Largo: 13,6 cm </li>
                                            <li>Ancho: 7,01 cm </li>
                                            <li>Alto: 1,75 cm </li>
                                        </ul>
                                        <strong>Peso</strong>
                                        <ul>
                                            <li>182 gr </li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="4" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:false,
                                            down4:false,
                                            down5:!this.state.down5,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <span style={{marginTop:6,color:(!this.state.down5)?'#212529':'#ff6600'}}>Certificaciones</span>
                                            </div>
                                            <div className={(!this.state.down5)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <ul>
                                            <li>PCI PTS 4.x, SRED </li>
                                            <li>EMV L1 & L2  </li>
                                            <li>EMV Contactless L1 </li>
                                            <li>Maestro </li>
                                            <li>MasterCard </li>
                                            <li>MasterCard PayPass </li>
                                            <li>Visa Electron </li>
                                            <li>Visa payWave </li>
                                            <li>American Express</li>
                                            <li>ExpressPay</li>
                                            <li>Discover</li>
                                            <li>Discover DPAS</li>
                                            <li>Apple Pay</li>
                                            <li>Android Wallet </li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                               
                            </Accordion>
                        </Col>
                    </Row>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        
                        </Container>
                    </Container>
               
                </div>
            </div> 
            </React.Fragment>
        )
    }
}