import React from 'react'
import Header from "../../Components/Header/Header"
import {Container,Row,Col,Image,Button} from 'react-bootstrap'
import back from "../../assets/images/header/background.jpg"
import Map from '../../Components/Map/Map'
import Phone from '../../assets/images/nosotros/phone.png'
import Certifications from '../Certifications/Certifications'
export default class About extends React.Component{

    render(){
        return(
            <React.Fragment>
                <div className='background-navbar'  data-background-color='#FFF'  style={{backgroundColor:'#FFF',}}>
                    {/* <Header
                        backgroundImage={`url('${back}')`}
                        filterColor='rgba(0, 23, 64, 0.9)'
                    /> */}
                <Container fluid style={{
                                        background:`url('${back}')`,
                                        backgroundPosition:'center',
                                        backgroundSize:'cover',
                                        backgroundColor:"#001740", 
                                        color:'#FFF',
                                        minHeight:600,
                                        }}>
                                        
                <Row style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                paddingTop: '150px', 
                                minHeight:600
                            
                            }}>
                </Row>
                </Container>

                </div>  
                <Container fluid style={{backgroundColor:'#FFF',marginTop:0, minHeight:500, }}>
                    <Container style={{paddingBottom:60}} >
                        <Row >
                            <Col md={12}>
                                <h1 className="sectionTitle" >La Empresa</h1>
                            </Col>
                            <Col md={8}>
                                <p style={{fontSize:18}} >
                                    Somos una empresa comercializadora de Puntos de Ventas marca flexipos, equipos de alta tecnología y certificada con los mas altos
                                    estándares de Calidad y Seguridad, el cual se adaptan a todos los mercados y necesidades de cada tipo de comercio. La robustez y
                                    calidad de los equipos flexipos se encuentran amparados por el 3er fabricante de puntos de venta más grande del mundo PAX y 1er
                                    fabricante para Latinoamérica.
                                    <br/>
                                    <br/>
                                    Positivo Plus, se encuentra certificada por el ente SUDEBAN y la empresa procesadora Consorcio Credicard, permitiendo de esta manera
                                    suscribir con las instituciones bancarias, nuestros productos y servicios, con el respaldo que ello garantiza.
                                    <br/>
                                    <br/>
                                    El objetivo principal es convertirnos en su aliado comercial para el crecimiento de la plataforma en su cadena de tiendas, siendo
                                    participes de una alianza estratégica comercial, que nos permita ofrecerles el mejor servicio a un menor costo.
                                </p>
                            </Col>
                            {
                                (window.innerWidth>935)?
                                    <img 
                                    style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '70%',
                                            }}
                                    src={Phone} 
                                    alt="D220"/>
                                :null

                            }
                         
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h1 className="sectionTitle" >Nuestros puntos</h1>
                            </Col>
                            <Col md={12} style={{maxWidth:750,margin: '0 auto',}}>
                                <div className='text-center'>
                                   {
                                    (window.innerWidth<768)?//si estamos en responsive se muestra esta leyenda
                                    <div className='leyenda'
                                        style={{
                                            position:'absolute',
                                            display:'flex',
                                            flexDirection:'row',
                                        }}
                                    >
                                        <ul>
                                            <li style={{fontSize: 16,float: 'left',}}>Apure: 4%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Bolívar: 5%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Caracas: 56%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Cojedes: 3%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Falcón: 5%</li>
                                        </ul>
                                        <ul>
                                            <li style={{fontSize: 16,float: 'left',}}>Guárico: 2%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Monagas: 1%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Sucre: 3%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Táchira: 10%</li>
                                            <li style={{fontSize: 16,float: 'left',}}>Zulia: 11%</li>
                                        </ul>
                                    </div>
                                    :null
                                    }
                                    
                                    <Map/>
                                  
                                </div>
                            </Col>
                        </Row>
                        
                
                    </Container>
                </Container>
                <div id="certificaciones" className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)' >
                    <Certifications />
                </div>
            </React.Fragment>
     )
    }


}