import React from 'react'
import {Container,Row,Col,Button,Accordion,Card} from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';
import img1 from '../../assets/images/requisitos/requisito-3.jpg'
import img2 from '../../assets/images/requisitos/requisito-1.jpg'
import img3 from '../../assets/images/requisitos/requisito-2.jpg'

import bank1 from '../../assets/images/requisitos/plaza.png'
import bank2 from '../../assets/images/requisitos/bancrecer.png'
import bank3 from '../../assets/images/requisitos/bod.png'
import bank4 from '../../assets/images/requisitos/delsur.png'
import bank5 from '../../assets/images/requisitos/bancaribe.png'
import bank6 from '../../assets/images/requisitos/bfc.png'

import Pdf from '../../assets/files/requisitos.pdf'

export default class Require extends React.Component{
constructor(props){
    super(props)
    this.state={
        down1:false,
        down2:false,
        down3:false,
        down4:false,
        down5:false,
        down6:false,
    }
}
render(){
    return(
        <React.Fragment>
            <Container fluid  style={{marginTop:94,height:536, backgroundColor:'#001740', color:'#FFF',}}>
                    <Container>
                        <Row>
                                <Col md={12} >
                                        <h1 >¿Cómo obtener un POS?</h1>
                                      
                                </Col>
                                <Col md={6} >
                                     
                                        <p style={{marginTop:40}}> 
                                            <strong>El POS que te gusta, en 3 simples pasos.</strong>
                                            <br/>
                                            <br/>

                                            Debes cumplir los pasos a continuación para solicitar un punto de venta y dejarlo preparado en tu negocio.
                                        </p>
                                </Col>
                            </Row>
                    </Container>
                </Container>

        <Container fluid style={{backgroundColor:'#FFF', }}>
            <Container style={{
                                    position:'relative',
                                    top:'-190px',}} >
                   
                    <Row>
                         <Col md={4}>
                                 <div  className='cardPos' style={{
                                                 backgroundImage: `url(${img1})`,
                                                 }}>
                                         <h3 className='card-title'>
                                            <p style={{fontSize:'0.94rem'}}>Paso</p>
                                            <p style={{fontSize:'2.3rem',fontWeight:'bold'}}>01</p>
                                        </h3>
                                         <p className='card-content' > 
                                            Debes tener una cuenta bancaria y gestionar tu código de afiliado 
                                            comercial con cualquiera de nuestros bancos aliados.
                                         </p>
                                    
                                        {/* <a className='card-more' href='#'>LEER</a> */}

                                 </div>
                                 
                         </Col>
                         <Col md={4}>
                                     <div  className='cardPos' style={{
                                                     backgroundImage: `url(${img2})`,
                                                     }}>
                                            <h3 className='card-title'>
                                                <p style={{fontSize:'0.94rem'}}>Paso</p>
                                                <p style={{fontSize:'2.3rem',fontWeight:'bold'}}>02</p>
                                            </h3>
                                             <p className='card-content' > 
                                                Debes coordinar tu pago con tu ejecutivo de venta Positivo Plus o a través de nuestro Whatsapp 
                                                Corporativo 0414-2782250 para completar tu proceso de afiliación y procesos administrativos 
                                             </p>
                                        {/* <a className='card-more' href='#'>LEER</a> */}

                                     </div>
                          
                         </Col>
                         <Col md={4}>
                                     <div  className='cardPos' style={{
                                         backgroundImage: `url(${img3})`,
                                         }}>
                                            <h3 className='card-title'>
                                                <p style={{fontSize:'0.94rem'}}>Paso</p>
                                                <p style={{fontSize:'2.3rem',fontWeight:'bold'}}>03</p>
                                            </h3>
                                             <p className='card-content' > 
                                                Recibe tu equipo en nuestras oficinas comerciales o en tu 
                                                localidad a través de nuestra empresa aliada de encomienda
                                             </p>
                                        {/* <a className='card-more' href='#'>LEER</a> */}

                                     </div>
                         </Col>
                     </Row>
                    <Row style={{marginTop:10}}>
                        <Col md={12} >
                            <h1 className='text-center sectionTitle'> Requisitos por banco Aliado</h1>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={8} className='mx-auto'>
                            <Accordion defaultActiveKey="">
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="0" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                                                        down1:!this.state.down1,
                                                                        down2:false,
                                                                        down3:false,
                                                                        down4:false,
                                                                        down5:false,
                                                                    
                                                                    })}}
                                        >
                                    <div style={{
                                                display: 'flex',
                                                justifyContent:' space-between'
                                                }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent:' space-between'
                                                }}
                                        >
                                            <img
                                                width={60}
                                                height={11}
                                                src={bank1} 
                                                alt="BOD"
                                                style={{marginRight:25,marginTop: 17,}}
                                                />
                                            <span style={{marginTop:6,color:(!this.state.down1)?'#212529':'#ff6600'}}>Banco Plaza</span>
                                        </div>
                                         <div className={(!this.state.down1)?'down':'down-active'}>
                                               
                                            </div>
                                    </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul style={{listStyle:'none',marginTop:'10px'}}>
                                            <li style={{paddingLeft:'10px'}}>Original y copia del Registro Mercantil y sus posteriores modificaciones debidamente registrados. </li>
                                            <li style={{paddingLeft:'10px'}}>Original y copia del Registro de Identificación Fiscal (RIF) vigente, que corresponda con la Razón Social y con la dirección actualizada de la empresa solicitante. </li>
                                            <li style={{paddingLeft:'10px'}}>Copia legible y completa de la última Declaración de Impuesto Sobre la Renta (ISLR) en lo aplicable. En caso de que la declaración sea electrónica deberá consignar el certificado electrónico de recepción por internet.</li>
                                            <li style={{paddingLeft:'10px'}}>Una referencia bancaria o comercial (excepto para personas que abren cuentas por primera vez).</li>
                                            <li style={{paddingLeft:'10px'}}>Original y copia de la Cédula de Identidad laminada vigente, de quienes establecen la relación de negocios en representación del ente jurídico</li>
                                            <li style={{paddingLeft:'10px'}}>Original y copia del Registro de Identificación Fiscal (RIF) vigente, de quienes establecen la relación de negocios en representación del ente jurídico, con la dirección actualizada del solicitante.</li>
                                            <li style={{paddingLeft:'10px'}}>Una (1) Referencia Bancaria o Comercial, de quienes establecen la relación de negocios en representación del ente jurídico (excepto para personas que abren cuentas por primera vez).</li>
                                            <li style={{paddingLeft:'10px'}}>Cualquier otro documento relacionado con quienes establecen la relación de negocios en representación del ente jurídico y sus actividades, solicitado por el Banco.</li>
                                        </ul>

                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="1" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                                                        down1:false,
                                                                        down2:!this.state.down2,
                                                                        down3:false,
                                                                        down4:false,
                                                                        down5:false,
                                                                        down6:false,
                                                                        
                                                                    })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <img
                                                    width={60}
                                                    height={16}
                                                    src={bank2} 
                                                    alt="BOD"
                                                    style={{marginRight:28,marginTop:11}}
                                                    />
                                                <span style={{marginTop:6,color:(!this.state.down2)?'#212529':'#ff6600'}}>Bancrecer</span>
                                            </div>
                                            <div className={(!this.state.down2)?'down':'down-active'}>
                                               
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <ul>
                                            <li>Copia de cédulas de los accionistas. </li>
                                            <li>Copia del rif de los accionistas.</li>
                                            <li>Copia del rif jurídico.</li>
                                            <li>Copia del acta constitutiva.</li>
                                            <li>Copia del acta constitutiva.</li>
                                            <li>Copia de acta de asamble en caso que aplique.</li>
                                            <li>Referenciá bancaria en caso de poseer CTA con otro banco.</li>
                                            <li>Dos referencias comerciales.</li>
                                            <li>Declaración de islr 2019 o 2018 si la empresa tiene más de un año constitutiva.</li>
                                            <li>Carta de movilización de cuenta indicando tipo de firma si son conjuntas o indistintas o separadas o única.</li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="2" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:!this.state.down3,
                                            down4:false,
                                            down5:false,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <img
                                                    width={36}
                                                    height={36}
                                                    src={bank3} 
                                                    alt="BOD"
                                                    style={{marginRight:50}}
                                                    />
                                                <span style={{marginTop:6,color:(!this.state.down3)?'#212529':'#ff6600'}}>BOD</span>
                                            </div>
                                            <div className={(!this.state.down3)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <ul>
                                            <li>Original y fotocopia de la Cédula de Identidad de los firmantes o autorizados a movilizar la cuenta. </li>
                                            <li>Original y fotocopia del Registro de Información Fiscal (R.I.F.) de la empresa. </li>
                                            <li>Original y fotocopia del Documento Constitutivo de la Empresa y de las Reformas, registrados (esta última en caso de aplicar). </li>
                                            <li>Original y fotocopia de Última Declaración de I.S.L.R. (en caso de aplicar).</li>
                                            <li>Original y/o fotocopia de algún recibo de Servicio público o privado donde reside la empresa (Agua, Electricidad, Teléfono, Televisión por cable) (indistintamente que no esté a su nombre) o contrato de arrendamiento. (Fecha máxima de vigencia no mayor a 3 meses).</li>
                                            <li>Dos (2) referencias bancarias o comerciales de la empresa (o combinación de ambas). En caso de no poseer referencias bancarias o comerciales; cualquiera de las personas autorizadas a movilizar la cuenta deberá consignar dos (2) referencias bancarias o personales (esta última con un número de teléfono fijo y la fotocopia de la Cédula de Identidad de la persona que emite la referencia). Todas las referencias deben contar con las acciones de verificación por un funcionario o ejecutivo de la oficina. (Fecha de emisión no mayor a 3 meses).</li>
                                            <li>IMPORTANTE La(s) persona(s) autorizada(s) para movilizar la cuenta se le(s) exigirá la documentación correspondiente al cliente persona natural.</li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="3" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:false,
                                            down4:!this.state.down4,
                                            down5:false,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <img
                                                    width={36}
                                                    height={36}
                                                    src={bank4} 
                                                    alt="BOD"
                                                    style={{marginRight:50}}
                                                    />
                                                <span style={{marginTop:6,color:(!this.state.down4)?'#212529':'#ff6600'}}>Del Sur</span>
                                            </div>
                                            <div className={(!this.state.down4)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <ul>
                                            <li>Documento Constitutivo de la Empresa (Original y fotocopia).  </li>
                                            <li>Documento Constitutivo de la Empresa y su última actualización debidamente inscrito en el Registro Mercantil.  </li>
                                            <li>Poderes Otorgados (Original y fotocopia). Al (los) representante(s) legal(es) de la empresa, así como de la(s) persona(s) designada(s) como firma(s) autorizada(s) en la cuenta. (Original y fotocopia).  </li>
                                            <li>Última declaración del Impuesto Sobre la Renta (ISRL) (Original y fotocopia). </li>
                                            <li>1 Referencia Bancaria (Fecha de emisión no mayor a 90 días). En caso de no poseer cuenta en otros bancos deberá llenar el Formulario 15-004 A “Declaración Jurada de Apertura de Cuenta por primera vez en una Institución Financiera P.J”. </li>
                                            <li>Recibo de Servicio (agua, luz, telefonía fija, cable) (Original y fotocopia) Este recaudo corresponderá solo a la Empresa. De no poseerlo, presentar contrato de arrendamiento del local (si aplica) o constancia de domiciliación expedida por la municipalidad donde reside, con fecha de emisión menor a 90 días. </li>
                                            <li>Registro de Información Fiscal (RIF) vigente (Original y fotocopia). Correspondiente a la empresa y los Representantes Legales y firmantes, en la cuenta. </li>
                                            <li>Cédula de Identidad vigente (Original y fotocopia). De los Representantes Legales y firmantes, en la cuenta. En caso de ser extranjero, copia del Pasaporte expedido en su país de origen. Debe ser fiel al documento original presentando.</li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle 
                                        as={Card.Header} 
                                        eventKey="4" 
                                        style={{paddingBottom:15,backgroundColor:'#FFF'}}
                                        onClick={()=>{this.setState({
                                            down1:false,
                                            down2:false,
                                            down3:false,
                                            down4:false,
                                            down5:!this.state.down5,
                                            down6:false,

                                        })}}
                                        >
                                        <div style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:' space-between'
                                                    }}
                                            >
                                                <img
                                                    width={60}
                                                    height={13}
                                                    src={bank5} 
                                                    alt="BOD"
                                                    style={{marginRight:28,marginTop:14}}
                                                    />
                                                <span style={{marginTop:6,color:(!this.state.down5)?'#212529':'#ff6600'}}>Bancaribe</span>
                                            </div>
                                            <div className={(!this.state.down5)?'down':'down-active'}>
                                               
                                               </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <p>
                                            Los siguientes documentos deberán ser entregados en una (1) carpeta manila tamaño oficio, en el orden descrito a continuación:
                                        </p>
                                        <ul>
                                            <li>Original y fotocopia legible del Documento Constitutivo-Estatutario y sus modificaciones vigentes (según el caso). </li>
                                            <li>Original y fotocopia legible del Registro de Información Fiscal (RIF). </li>
                                            <li>Original y fotocopia de la cédula de Identidad para las personas naturales venezolanas o extranjeras residentes en el país ,autorizado(s) para movilizar la cuenta.  </li>
                                            <li>Original y fotocopia del Pasaporte para las personas naturales extranjeras no residentes en el país, autorizado(s) para movilizar la cuenta. </li>
                                            <li>Original de dos (2) Referencias Bancarias o Comerciales emitidas en un lapso no mayor de 30 días. </li>
                                            <li>Original y fotocopia legible de la última Declaración de Impuesto Sobre la Renta (ISRL) (si tiene obligación de declarar). </li>
                                            <li>Original y fotocopia del documento donde consten las facultades de la(s) persona(s) autorizada(s) para movilizar la cuenta (Poder, Certificación de Acta, Carta de Autorización, entre otros); salvo que éstas consten en el Documento Consitutivo-Estatutario. (Ver modelo de Carta de Autorización). </li>
                                            <li>Original y fotocopia de la Autorización de Funcionamiento emitida por la autoridad competente, en caso de empresas cuya actividad económica sea casinos, casas de juego, casas de cambio u operadores fronterizos cambiarios.</li>
                                            <li>Original y copia de un recibo de cualquier servicio público y/o privado en el cual se evidencia la dirección de la empresa. En caso contrario podrá presentar copia del contrato de arrendamiento emitida por la autoridad civil correspondiente.</li>
                                            <li>Contrato de apertura de cuenta donde se expongan las condiciones de la relación de negocios. Este documento debe estar suscrito por el cliente a fin de que se dé por enterado de sus derechos y obligaciones.</li>
                                        </ul>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                               
                            </Accordion>
                        </Col>
                    </Row>
                    
                     <Row style={{marginTop:90, marginBottom:40}}>
                    <Col md={12} >
                        <h1 className='text-center sectionTitle'> Descargue los requisitos aquí</h1>
                        <p className='text-center sectionSubTitle'>
                        Al momento del envío de su pago, debe consignar los siguientes documentos copia de la <strong> cédula de Identidad</strong>, 
                        copia del  <strong>RIF de la empresa</strong>,
                        copia del <strong>cheque o estado de cuenta bancaria</strong> con su número de cuenta.
                         </p>
                        <div className='text-center'>
                            <Button variant="outline-secondary" style={{width:300}} href={Pdf} target='blank'>
                                    Descargar Requisitos
                                    <svg className='ic ic-arrow' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.12 3.69">
                                        <title>arrow1</title>
                                        <polygon class="arrow-fill"  points="7.53 0.92 5.93 0 6.56 1.44 0 1.44 0 2.24 6.56 2.24 5.93 3.69 7.53 2.77 9.12 1.84 7.53 0.92"/>
                                    </svg>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

           
        </React.Fragment>
    )
}


} 