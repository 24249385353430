import React from 'react'
import {
   
    Button,
    Form,
    Modal,
    } from 'react-bootstrap'


import  {domainServer }  from '../../connection'
import Lottie from "react-lottie-wrapper";
import * as animationData from "./checkmark.json"

export default class Modalregistrer extends React.Component{

    constructor(props){
        super(props)
        this.state={
            show:false,
            errors:[],
            name:'',
            email:'',//email para registro
            password:'',//password para registro
            retryPassword:'',//password para registro
            loadingLogin:false,//password para registro
            check:false,//password para registro
        }
    }
    

    handleModal(show){
        this.props.close(false)
        this.setState({
            show:show,
            check:false,
        })
    }
   
    registrer(){

        var{
            email,
            password,
            retryPassword,
            name
            }=this.state
        var errors=this.state.errors
        this.setState({loadingLogin:true})

        if(email===''||password===''||retryPassword===''||name===''){
            alert('Todos los campos son requeridos')
            if(email===''){
                errors['emailLogin']='Este campo es obligatorio'
            }
            if(password===''){
                var errors=this.state.errors
                errors['passwordLogin']='Este campo es obligatorio'
            }
            if(retryPassword==''){
                var errors=this.state.errors
                errors['retryPasswordLogin']='Este campo es obligatorio'

            }
            if(name==''){
                var errors=this.state.errors
                errors['nameLogin']='Este campo es obligatorio'

            }
            this.setState({
                            loadingLogin:false,
                            errors:errors,
                        })
        }
        else{
            errors['nameLogin']=''
            errors['emailLogin']=''
            errors['passwordLogin']=''
            errors['retryPasswordLogin']=''

            if(password!==retryPassword){
                errors['retryPasswordLogin']='Las contraseñas no coinciden'
                this.setState({
                    errors:errors,  
                    loadingLogin:false,
                    })
            }
            else{
                errors['retryPasswordLogin']=''
                this.setState({
                    errors:errors,
                })

                if(password.length<6||retryPassword.length<6){
                    if(password.length<6){
                        errors['passwordLogin']=' la contraseña debe contener mínimo 6 caracteres'
                        this.setState({
                            errors:errors,
                            loadingLogin:false,
                        })
                    }
                    if(retryPassword.length<6){
                        errors['retryPasswordLogin']=' la contraseña debe contener mínimo 6 caracteres'
                        this.setState({
                            errors:errors,
                            loadingLogin:false,
                        })
                    }
                }
                else{
                    errors['passwordLogin']=''
                    errors['retryPasswordLogin']=''
                    this.setState({
                        errors:errors,
                    })
                    fetch(domainServer+'/api/user/save',{
                        method: 'POST',
                        headers:{
                            'Accept':'application/json',
                            'Content-Type':'application/json'
                        },
                        body: JSON.stringify({
                            fullName: name,
                            username: email,
                            password: password,
                        })
                    
                    }).then((response)=>{
                        
                            return response.json()
                    }).then((res)=>{
                        console.log(res.status);
                        
                        if(res.status!=undefined){
                            alert(res.message)
                            this.setState({
                                loadingLogin:false,
                            })
                        }
                        else{
                            this.setState({
                                loadingLogin:false,
                                check:true,
                                token:res.token,
                            })
                        }
                        })
                    .catch((err)=>{
                        this.setState({
                            showNotice:true,
                            messageNotice:'Error intentar conexion'
                        })
                    })
                }

               
            }



            
           
           
        }
      
    }

    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar los props):
        if (this.props.show !== prevProps.show) {
            
            if(this.props.show==true){
                this.handleModal(this.props.show);
                this.props.close(false)
            }
        }
      }
    componentDidMount(){
        this.handleModal(this.props.show)
    }
    render(){
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          };
        return(
            <React.Fragment>
                
               <Modal 
                        show={this.state.show} onHide={this.handleModal.bind(this)}
                        size="md"
                        centered
                        >
                        {/* <a href='#'style={{
                                            fontSize:'19px',
                                            textDecoration:'none',
                                            
                                            
                                            }}>x</a> */}
                        <button 
                                type="button"
                                className="close"
                                style={{
                                        position: 'absolute',
                                        width: '30px',
                                        top: '15px',
                                        right: '16px',
                                        /* cursor: pointer; */
                                        zIndex: '1000000',
                                }}
                                onClick={()=>{this.handleModal()}}
                                ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <Modal.Body  style={{paddingBottom:45}}>
                            {
                                (!this.state.check)?
                                <div>
                                    <div className="row  d-flex justify-content-center">
                                    <div className="col-md-10  d-flex " style={{justifyContent: 'space-between',}}>
                                        <p style={{fontSize:'19px'}}>Crear cuenta</p>
                                    
                                    </div>
                                    </div>
                                    <div className="row d-flex justify-content-center" >
                                        <div className="col-md-10">
                                            <Form.Group >
                                                <Form.Control 
                                                            type="text" ref="name" 
                                                            placeholder="Nombre"
                                                            onChange={(e)=>{
                                                                                    this.setState({name:e.target.value})
                                                                            }} 
                                                            />
                                                <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["nameLogin"]}</p>
                                            </Form.Group>
                                            <Form.Group >
                                                <Form.Control 
                                                            type="email" ref="name" 
                                                            placeholder="Correo"
                                                            onChange={(e)=>{
                                                                                var errors=this.state.errors
                                                                                var email=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    
                                                                                if(!email.test(e.target.value.toLowerCase())){
                                                                                    this.setState({email:e.target.value})
                                                                                    errors['emailLogin']='Correo no valido'
                                                                                    return false
                                                                                } 
                                                                                else{
                                                                                    this.setState({email:e.target.value})
                                                                                    errors['emailLogin']=''
                                                                                    return true
                                                                                }      
                                                                            }} 
                                                            />
                                                <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["emailLogin"]}</p>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-10">
                                            <Form.Group >
                                                <Form.Control 
                                                            type="password" ref="name" 
                                                            placeholder="Contraseña"
                                                            onChange={(e)=>{
                                                                                this.setState({password:e.target.value})
                                                                                var errors=this.state.errors

                                                                                if(e.target.value.length<6){
                                                                                    errors['passwordLogin']=' la contraseña debe contener mínimo 6 caracteres'
                                                                                    this.setState({
                                                                                        errors:errors,
                                                                                    })
                                                                                }
                                                                                else{
                                                                                    errors['passwordLogin']=''
                                                                                    this.setState({
                                                                                        errors:errors,
                                                                                    })
                                                                                }
                                                                            }} 
                                                            
                                                            />
                                                <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["passwordLogin"]}</p>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-10">
                                            <Form.Group >
                                                <Form.Control 
                                                            type="password" ref="name" 
                                                            placeholder="Repetir Contraseña"
                                                            onChange={(e)=>{
                                                                                var errors=this.state.errors
                                                                                this.setState({
                                                                                    retryPassword:e.target.value,
                                                                                    errors:errors,  
                                                                                    })
                                                                                if(this.state.password!==e.target.value){
                                                                                    this.setState({
                                                                                        retryPassword:e.target.value,
                                                                                        errors:errors,  
                                                                                        })
                                                                                        errors['retryPasswordLogin']='Las contraseñas no coinciden'
                                                                                        return false
                                                                                }
                                                                                else{
                                                                                    this.setState({
                                                                                        retryPassword:e.target.value,
                                                                                        errors:errors,  
                                                                                        })
                                                                                    errors['retryPasswordLogin']=''
                                                                                    if(e.target.value.length<6){
                                                                                        errors['retryPasswordLogin']=' la contraseña debe contener mínimo 6 caracteres'
                                                                                        this.setState({
                                                                                            errors:errors,
                                                                                        })
                                                                                    }
                                                                                    else{
                                                                                        errors['retryPasswordLogin']=''
                                                                                        this.setState({
                                                                                            errors:errors,
                                                                                        })
                                                                                    }
                                                                                    return true
                                                                                }
                                                                                

                                                                            }} 
                                                            
                                                            />
                                                <p className={(this.state.errorName!=='')?'error-form':'hide-error'} >{this.state.errors["retryPasswordLogin"]}</p>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row  d-flex justify-content-center" >
                             
                                <div className="col-md-10 " style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent:'center',
                                                                    alignItems: 'center',
                                                                }}>

                                    <Button variant="primary" 
                                            style={{width:'100%'}}
                                            disabled={this.state.loadingLogin} 
                                            onClick={()=>this.registrer()}>
                                        {
                                            (this.state.loadingLogin)?'Espere...':'Enviar'
                                        }
                                    </Button>
                                </div>
                            </div>
                                </div>
                                :
                                <div className="row  d-flex justify-content-center">
                                    <h2>Registro Exitoso </h2>
                                    <Lottie 
                                        options={defaultOptions}
                                        height={300}
                                        width={300}
                                        />
                                    <p style={{
                                                textAlign:'center',
                                                fontSize:'18px',
                                                maxWidth: '300px',
                                                }}>
                                        Se ha enviado un correo con el link de verificación a su correo
                                    </p>
                                </div>
                            }
                               
                        </Modal.Body>
                        {/* <Modal.Footer style={{justifyContent:'center'}}>
                            <Button variant="primary" onClick={this.handleModal.bind(this)}>
                                cerrar
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
               

            </React.Fragment>
        )
    }
}
