import React from "react"
import { AnimatedBg, Transition } from 'scroll-background';
import Header from "../../Components/Header/Header"
import Testimony from "./Sections/Testimony"
import Services from "./Sections/Services"
import ProductLeft from "../Products/ProductLeft"
import ProductRight from "../Products/ProductRight"
import GetPos from "./Sections/getPos"
import Banks from "./Sections/Banks"
import Contact from "../Contact/ContactUs"

import Loading from '../../Components/Loading/Loading'

import NextParticle from "../../assets/js/nextparticle"//don´t delete 
import ScrollAnimation from 'react-animate-on-scroll';


import D220 from '../../assets/images/pos/D220.png'
import D200T from '../../assets/images/pos/D200T.png'
import S920 from '../../assets/images/pos/S920.png'

import EspD200T from '../../assets/files/D200T.pdf'
import EspD220 from '../../assets/files/D220.pdf'
import EspS920 from '../../assets/files/S920.pdf'

import Lottie from "react-lottie-wrapper";
import * as animationData from "../../assets/Neon.json"
import * as animationData992 from "../../assets/Neon.json"
import * as animationData764 from "../../assets/Neon.json"
import * as animationData365 from "../../assets/Neon.json"



const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions992 = {
    loop: true,
    autoplay: true, 
    animationData: animationData992.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };const defaultOptions764 = {
    loop: true,
    autoplay: true, 
    animationData: animationData764.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };const defaultOptions365 = {
    loop: true,
    autoplay: true, 
    animationData: animationData365.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  

export default class Page extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          width: window.innerWidth,
          open:false,
        };


      }
      handleClick(){
          this.setState({open:!this.state.open})
      }
   
    render(){
        
            return(
                <React.Fragment>
                {/* stick button */}
                 

                <div 
                    id="inicio" 
                    className='background-navbar' 
                    data-logo='white' 
                    data-background-color='#001740' 
                    data-color='#FFF' 
                    style={{backgroundColor:'#FFF',}}>
                    <Header/> 

                 
                    {/* <Lottie 
                        options={  
                                    (764<window.innerWidth<993)?defaultOptions992:
                                    (365<window.innerWidth<765)?defaultOptions764:
                                    (window.innerWidth<366)?defaultOptions365:
                                    defaultOptions365
                                }
                        // height={'100vh'}
                        // width={'100%'}
                        /> */}
                </div>
                
                <div id="nostros" className='background-navbar' data-background-color='rgb(232, 232, 232)' data-color='rgba(0,0,0,.8)'  style={{backgroundColor:'#E8E8E8'}}>
                    <Services/>
                </div>
                <div id='cambiar-color' 
                     style={{overflow:'hidden'}}//esto es para que el menu no se desfase con las animaciones fadeInLeft - fadeInRight
                     data-background-color='#FFF' 
                     data-logo='white' 
                     data-color='#FFF'>
                <AnimatedBg >
                    <Transition  from="#001740" to="#2E8FD5">
                        <div id="productos"   
                                                    style={{
                                                        // background:`linear-gradient(-180deg, #0033A1 0%,  rgba(46, 143, 213, 0.04) 60.08%)`,
                                                        color:'#FFF',
                                                        paddingTop:100,
                                                        }}>
                            <div >
                            <ScrollAnimation animateIn="fadeInUp">

                                <h1 className="text-center sectionTitle" >Nuestros productos</h1>
                                <p className="text-center sectionSubTitle" >
                                    POSITIVO te ofrece una gama de productos de alta tecnología digital que te permitirá llegar donde quieras.</p>
                            </ScrollAnimation>
                            </div>
                            <ProductLeft
                                img={D200T}
                                title='POS Portátil'
                                subtitle='Traslada tu negocio a cada rincón del país con la mejor tecnología y plataforma.'
                                codeColor='rgba(132, 19, 244, 1)'
                                code='D200T'
                                text='El equipo portátil con la gran capacidad
                                operativa que su negocio necesita.'
                                textButton='Ver más'
                                href="/productos/D200T"
                            />
                           
                        </div>
                    </Transition>
                    <Transition  from="#2E8FD5" to="#6121a2">
                        <div >
                            <ProductRight
                                    img={S920}
                                    title='POS Inalámbrico'
                                    subtitle='Convertir lo tradicional en lo innovador, es una de las funciones principales de este equipo. '
                                    codeColor='rgba(132, 19, 244, 1)'
                                    code='S920'
                                    text='El equipo que mezcla durabilidad y
                                    versatilidad de manera única y portátil'
                                    textButton='Ver más'
                                    href="/productos/S920"
                            />
                        </div>
                    </Transition>
                    <Transition  from="#6121a2" to="#333333" >
                        <div >
                        
                             <ProductLeft
                                img={D220}
                                title='POS ligero'
                                subtitle='La exclusividad, delicadeza y Robustez en un solo equipo.'
                                codeColor='rgba(132, 19, 244, 0.2)'
                                code='D220'
                                text='El equipo que ofrece una nueva experiencia
                                con altos estándares de calidad, seguridad y
                                comodidad.'
                                textButton='Ver más'
                                href="/productos/D220"
                            />
                        </div>
                    </Transition>
                
                </AnimatedBg>
              
              
               </div>
               
                {/* <div id="certificaciones" className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)' >
                    <Certifications/>
                </div> */}
                {/* <div className='background-navbar' data-logo='white' data-background-color='rgba(0, 51, 161, 0.9)' data-color='white'>
                    <PostSale/>
                </div> */}
                <div id="certificaciones" className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)' >
                    <Banks/>   
                </div>
                <div id="testimonios" className='background-navbar' data-background-color='#16181c' data-logo='white' data-color='#FFF'>
                    <Testimony/>
                </div>
                {/* <div id="contacto" className='background-navbar' data-background-color='#FFF' data-color='rgba(0,0,0,.5)'  style={{marginBottom:(window.innerWidth<=768)?'100vh':400}}>
                    <Contact/>
                </div>                                 */}
                    {/* <Transition  from="#FFF" to="#0032a0">
                        <div id="product"> 
                            <About  id='aboutUs'/>
                        </div>
                    </Transition>
                    <Transition  from="#0032a0" to="#FFF">
                        <div id="products2"> 
                            <About  id='products'/>
                        </div>
                    </Transition>
                    <Transition  from="#FFF" to="#0032a0">
                        <div id="products3"> 
                            <About id='products2'/>
                        </div>
                    </Transition>
                    <Transition  from="#0032a0" to="#FFF">
                        <div id="products4"> 
                            <About id="products3"/>
                        </div>
                    </Transition> */}
                
                </React.Fragment>
            )
    }
}