import React from 'react';
//import './assets/css/App.css';
// import './assets/css/Animate.css';
import Page from './layout/Page'




function App() {
  return (
    <Page/>
    )
    
}

export default App;
