import React from 'react'
import { 
            Container, 
            Row, 
            Col,
            Button,
            Image,
            Form,
            Spinner, 
        } from "react-bootstrap";
import logo from '../../assets/images/positivoBlanco.svg'
import ig from '../../assets/images/social/IgRA.svg'
import linkedin from '../../assets/images/social/linkedin.svg'
import facebook from '../../assets/images/social/facebook.svg'
import twitter from '../../assets/images/social/twitter.svg'
import  {domainServer }  from '../../connection'
import Notice from '../../Components/Notice/Notice'


function Response(props){
    return(
        <React.Fragment>
            {
                (props.succesful)?
                    <div 
                        style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%',
                                minHeight:'40px',
                                backgroundColor:'#56C568',
                                borderRadius:'4px',
                        }}

                        >
                            <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                }}
                            >
                              
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg-footer'>
                                        <circle cx="11" cy="11" r="11" fill="white"/>
                                        <path d="M17.0547 8.42187C17.0547 8.22656 16.9766 8.03125 16.8359 7.89062L15.7734 6.82812C15.6328 6.6875 15.4375 6.60937 15.2422 6.60937C15.0469 6.60937 14.8516 6.6875 14.7109 6.82812L9.58594 11.9609L7.28906 9.65625C7.14844 9.51562 6.95313 9.4375 6.75781 9.4375C6.5625 9.4375 6.36719 9.51562 6.22656 9.65625L5.16406 10.7187C5.02344 10.8594 4.94531 11.0547 4.94531 11.25C4.94531 11.4453 5.02344 11.6406 5.16406 11.7812L7.99219 14.6094L9.05469 15.6719C9.19531 15.8125 9.39063 15.8906 9.58594 15.8906C9.78125 15.8906 9.97656 15.8125 10.1172 15.6719L11.1797 14.6094L16.8359 8.95312C16.9766 8.8125 17.0547 8.61719 17.0547 8.42187Z" fill="#56C568"/>
                                    </svg>

                                <p style={{
                                            margin:'auto',
                                            fontSize:'14px',
                                            fontWeight:'200px',

                                            }}>Gracias por Registrarte</p>
                            </div>
                            <button type='submit'
                                    style={{
                                            background: 'transparent',
                                            border: 'none',
                                            margin: 'auto 10px',
                                    }}   
                                    onClick={()=>{props.onClick()}}
                                                            >
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6436 9.9802C12.1188 10.4554 12.1188 11.1683 11.6436 11.6436C11.4059 11.8812 11.1089 12 10.8119 12C10.5149 12 10.2178 11.8812 9.9802 11.6436L6 7.66337L2.0198 11.6436C1.78218 11.8812 1.48515 12 1.18812 12C0.891089 12 0.594059 11.8812 0.356436 11.6436C-0.118812 11.1683 -0.118812 10.4554 0.356436 9.9802L4.33663 6L0.356436 2.0198C-0.118812 1.54455 -0.118812 0.831683 0.356436 0.356436C0.831683 -0.118812 1.54455 -0.118812 2.0198 0.356436L6 4.33663L9.9802 0.356436C10.4554 -0.118812 11.1683 -0.118812 11.6436 0.356436C12.1188 0.831683 12.1188 1.54455 11.6436 2.0198L7.66337 6L11.6436 9.9802Z" fill="white"/>
                                    </svg>
                            </button>
                    </div>
                :
                <div 
                        style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%',
                                minHeight:'40px',
                                backgroundColor:'#EE2028',
                                borderRadius:'4px',
                        }}

                        >
                            <div
                                style={{
                                    display:'flex',
                                    alignItems:'center',
                                }}
                            >
                              
                                    <svg width="22" height="22" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg-footer'>
                                        <circle cx="18" cy="18" r="18" fill="white"/>
                                        <path d="M17.9512 22.0059C17.5122 21.9891 17.1382 21.8294 16.8293 21.5267C16.5203 21.2072 16.3577 20.8205 16.3415 20.3665V11.6395C16.439 10.6306 16.9756 10.0841 17.9512 10C18.4065 10 18.7967 10.1597 19.122 10.4792C19.4634 10.7819 19.6341 11.1686 19.6341 11.6395V20.3665C19.6179 20.8373 19.4472 21.2324 19.122 21.5519C18.7967 21.8546 18.4065 22.0059 17.9512 22.0059ZM20 25.2849C20 25.7557 19.8049 26.1592 19.4146 26.4955C19.0244 26.8318 18.5528 27 18 27C17.4472 27 16.9756 26.8318 16.5854 26.4955C16.1951 26.1592 16 25.7557 16 25.2849C16 24.7972 16.1951 24.3853 16.5854 24.049C16.9756 23.7127 17.4472 23.5445 18 23.5445C18.5528 23.5445 19.0244 23.7127 19.4146 24.049C19.8049 24.3853 20 24.7972 20 25.2849Z" fill="#EE2028"/>
                                    </svg>

                                <p style={{
                                            margin:'auto',
                                            fontSize:'14px',
                                            fontWeight:'200px',

                                            }}>
                                                {
                                                    (props.message!==''&&props.message!==null&&props.message!==undefined)?
                                                        props.message
                                                    :
                                                        'Ocurrió un error intente más tarde'
                                                }
                                            </p>
                            </div>
                            <button type='submit'
                                    style={{
                                            background: 'transparent',
                                            border: 'none',
                                            margin: 'auto 10px',
                                    }}   
                                    onClick={()=>{props.onClick()}}
                                                            >
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M11.6436 9.9802C12.1188 10.4554 12.1188 11.1683 11.6436 11.6436C11.4059 11.8812 11.1089 12 10.8119 12C10.5149 12 10.2178 11.8812 9.9802 11.6436L6 7.66337L2.0198 11.6436C1.78218 11.8812 1.48515 12 1.18812 12C0.891089 12 0.594059 11.8812 0.356436 11.6436C-0.118812 11.1683 -0.118812 10.4554 0.356436 9.9802L4.33663 6L0.356436 2.0198C-0.118812 1.54455 -0.118812 0.831683 0.356436 0.356436C0.831683 -0.118812 1.54455 -0.118812 2.0198 0.356436L6 4.33663L9.9802 0.356436C10.4554 -0.118812 11.1683 -0.118812 11.6436 0.356436C12.1188 0.831683 12.1188 1.54455 11.6436 2.0198L7.66337 6L11.6436 9.9802Z" fill="white"/>
                                    </svg>
                            </button>
                    </div>
            }
        </React.Fragment>
    )
}

export default class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state={
            showNotice:false,
            email:"",
            messageNotice:"",
            succesful:false,
            wrong:false,
            wrongMessage:'',
            loading:false,
        }
    }
    send(){
    if(this.state.email!==''&&this.state.email!==null&&this.state.email!==undefined){
        var data={
            "email": this.state.email,
        }
        var emailValidation=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
            if(!emailValidation.test(this.state.email.toLowerCase())){
                this.setState({
                    wrong:true,
                    wrongMessage:'Correo no valido'
                })
            } 
            else{
                this.setState({
                    loading:true
                })
                fetch(domainServer+'/api/subscription/save',{
                    method: 'POST',
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                    },
                    body:JSON.stringify(data)
                }).then((response)=>{ 
                        return (response.json())
                    }).then((res)=>{
                    if(res.status!==''&&res.status!==null&&res.status!==undefined){
                        this.setState({
                            loading:false,
                            wrong:true,
                            wrongMessage:res.message,
                        })
                    }
                    else{
                        this.setState({
                            loading:false,
                            email:'',
                            succesful:true,
                        })
                    }
                }).catch((err)=>{
                    this.setState({
                        showNotice:true,
                        messageNotice:'Error intentar conexion'
                    })
                })
            }      
       
    }        
    else{
        this.setState({
            wrong:true,
            wrongMessage:'Debe indicar un correo'
        })
    }
    }
    render(){
        return(
            <React.Fragment>
                <Notice 
                        show={this.state.showNotice}
                        onClick={()=>{this.setState({showNotice:false})}}
                        >
                        {this.state.messageNotice}
                </Notice>
                <footer>
                    <Container fluid className={(window.innerWidth<=768)?'':"fixed-bottom"} 
                                style={{
                                        zIndex:-1, 
                                        paddingTop: 20,
                                        backgroundColor:"#001740", 
                                        color:'#FFF', 
                                        minHeight:(window.innerWidth<=768)?window.innerHeight:400
                                        }}>
                    <Container  style={{paddingTop:0,paddingBottom:0}}>
                            <Row style={{marginTop:'20px', gap: "120px"}}>
                                <Col md={3}>
                                    {/* <Image
                                        height={50}
                                        src={logo}
                                    /> */}
                                    <svg width="110" height="40" viewBox="0 0 110 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                        <path d="M0 8.09285H5.57805V12.1736C7.37743 9.62435 9.91114 7.58398 13.8308 7.58398C19.5937 7.58398 25.1718 12.1736 25.1718 20.291V20.384C25.1718 28.5014 19.6423 33.091 13.8308 33.091C9.81874 33.091 7.28503 31.0506 5.57805 28.7803V40.0048H0V8.09285ZM19.1609 20.384V20.291C19.1609 15.5595 16.3159 12.4525 12.5372 12.4525C8.75857 12.4525 5.43702 15.6036 5.43702 20.291V20.384C5.43702 25.1155 8.7537 28.2225 12.5372 28.2225C16.3646 28.2225 19.1609 25.2084 19.1609 20.384Z" fill="white"/>
                                        <path d="M26.7817 20.477V20.384C26.7817 13.3821 32.3112 7.58398 39.781 7.58398C47.246 7.58398 52.7316 13.2892 52.7316 20.291V20.384C52.7316 27.3418 47.2022 33.1351 39.6886 33.1351C32.2674 33.1399 26.7817 27.4347 26.7817 20.477ZM47.1536 20.477V20.384C47.1536 16.0733 44.0654 12.5014 39.6886 12.5014C35.2193 12.5014 32.3598 16.0244 32.3598 20.291V20.384C32.3598 24.6507 35.4479 28.2666 39.781 28.2666C44.294 28.2666 47.1536 24.6996 47.1536 20.477Z" fill="white"/>
                                        <path d="M52.7314 29.3822L55.2214 25.5804C57.8475 27.5767 60.707 28.5944 63.197 28.5944C65.5945 28.5944 66.9757 27.5767 66.9757 25.9522V25.8593C66.9757 23.9559 64.3933 23.31 61.5386 22.4293C57.9448 21.4067 53.9326 19.9241 53.9326 15.2415V15.1486C53.9326 10.51 57.76 7.68188 62.5988 7.68188C65.6432 7.68188 68.9598 8.74855 71.5422 10.466L69.3294 14.4538C66.9805 13.0152 64.4906 12.1345 62.4626 12.1345C60.2985 12.1345 59.0536 13.1571 59.0536 14.5908V14.6788C59.0536 16.4403 61.6797 17.184 64.5392 18.1088C68.0893 19.2195 72.0966 20.844 72.0966 25.2525V25.3455C72.0966 30.4929 68.1331 33.0421 63.0608 33.0421C59.5982 33.047 55.7709 31.7944 52.7314 29.3822Z" fill="white"/>
                                        <path d="M96.4221 0H92.6775C89.4435 0 86.8174 2.63731 86.8174 5.89602V22.3364L96.4221 12.663V0Z" fill="white"/>
                                        <path d="M86.078 12.6729H79.3425C76.1085 12.6729 73.4824 15.3102 73.4824 18.5689V22.3365H86.0634L86.0732 22.3267V12.6729H86.078Z" fill="white"/>
                                        <path d="M109.995 12.6729V16.4404C109.995 19.6943 107.374 22.3365 104.135 22.3365H87.7949L97.4094 12.6729H109.995Z" fill="white"/>
                                        <path d="M96.422 13.6709V13.6611L92.1084 18.0061L96.422 13.6709Z" fill="white"/>
                                        <path d="M86.8125 23.0801V36.7412H90.5571C93.7911 36.7412 96.4172 34.1039 96.4172 30.8452V23.0801H86.8125Z" fill="white"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0">
                                        <rect width="110" height="40" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>

                                    <p style={{fontSize:16, marginTop:10,lineHeight: '26px',}}> 
                                        <strong>  Horario atención:</strong> <br/>
                                        <span  style={{fontSize:15}}>8:30 am - 5:30 pm</span> <br/>
                                        <span  style={{fontSize:15}}>Lunes a Viernes Días Hábiles</span> <br/>

                                    </p>
                                    <p style={{fontSize:16, marginTop:10,lineHeight: '26px',}}>
                                        <strong>  Contacto:</strong><br/>
                                        <a style={{color:'#FFF',fontSize:15}} href='tel:+582122746280'> +58-212-274.62.80 </a><br/>
                                        <a style={{color:'#FFF',fontSize:15}} href='tel:+584142782250'> +58-414-278.22.50 </a><br/>
                                        <a style={{color:'#FFF',fontSize:15}} href='tel:+584123236933'> +58-412-323.69.33 </a><br/>
                                        <a style={{color:'#FFF',fontSize:15}} href='mailto:info@pos.tienda'>info@pos.tienda</a>
                                    </p>

                                    <p style={{color:'#FFF',fontSize:16,marginTop:10,}}>
                                        <strong>Administración/Cobranza</strong><br/>
                                        <a style={{color:'#FFF',fontSize:15}} href='tel:+584123230989'> +58-412-323.09.89 </a><br/>
                                        <a style={{color:'#FFF'}} href='mailto:cobranza@pos.tienda'>cobranza@pos.tienda</a>
                                    </p>

                                </Col>
                                <Col md={3} >
                               
                                    <p style={{fontSize:24,marginTop:10,}}>Nuestras Sedes</p>
                                    <p style={{fontSize:16,marginTop:10,}}> 
                                        <strong>Caracas</strong><br/>
                                        Torre Letonia , PB Local I-01 Positivo Plus, La Castellana Caracas. 

                                    </p>
                                    {/* <p style={{fontSize:16,marginTop:10,}}> 
                                        <strong>Maracay</strong><br/>
                                        C.C Global, Pasillo A. Local 96. Municipio Girardot, Edo Aragua. Representaciones Scott y Asociados <br/>  
                                            Tlf: <a style={{color:'#FFF',fontSize:15}} href='tel:+584121335061'> +58-412-133.50.61</a><br/>  
                                    </p> */}
                                  
                                </Col>
                                {/* <Col md={3} style={{paddingTop:(window.innerWidth>768)?53:0}}>
                                    <p style={{fontSize:16,marginTop:10,}}> 
                                        <strong>Puerto Ordaz</strong><br/>
                                        Centro Comercial Doña Delia, P-3. Of. 3-04. Puerto Ordaz. Municipio Caroní. Innovative Solutions CA <br/>  
                                            Tlf: <a style={{color:'#FFF',fontSize:15}} href='tel:+ +584120912826'>  +58-412-091.28.26</a><br/>  
                                    </p>
                                    <p style={{fontSize:16,marginTop:10,}}> 

                                        <strong>Anzoátegui</strong><br/>
                                            Av. Ppal de Lecheria, CC Aventura Plaza. Nivel PB<br/>
                                            Local 23. Anzoategui<br/>  
                                            Tlf: <a style={{color:'#FFF',fontSize:15}} href='tel:+582812814177'> +58-281-281.41.77</a><br/>  
                                    </p>
                                </Col> */}
                                
                                <Col md={3}>
                                    <p style={{fontSize:24}}>Regístrate.</p>
                                    <p style={{fontSize:16}}>Y recibe toda la información acerca de nuestros productos y servicios. </p>
                                    <div className="text-center" >
                                        <Form.Group
                                            style={{
                                                    display:'flex',
                                                    flexDirection:'row',
                                            }}>
                                            {(this.state.succesful||this.state.wrong)?
                                               <Response
                                                        succesful={this.state.succesful}
                                                        message={this.state.wrongMessage}
                                                        onClick={()=>{
                                                                        this.setState({
                                                                                        succesful:false,
                                                                                        wrong:false,
                                                                                        })
                                                        }}
                                               />
                                                :
                                                <React.Fragment>
                                                    <Form.Control  
                                                    type="text" 
                                                    style={{
                                                            padding:'20px 10px',
                                                            margin:'0',
                                                            borderRadius:' 5px 0px 0px 5px'
                                                        }}
                                                    placeholder="Correo electrónico" 
                                                    value={this.state.email}
                                                    onChange={(e)=>{this.setState({email:e.target.value})}}
                                                    />
                                                    {
                                                        (this.state.loading)?
                                                            <button type='submit'
                                                                    style={{
                                                                            position:'relative',
                                                                            width: 60,
                                                                            padding:2,   
                                                                            backgroundColor:'#FF6600',
                                                                            color:'#FFF',
                                                                            borderRadius:' 0px 5px 5px 0px',
                                                                            border:'1px solid #FFF',
                                                                            }}
                                                                    onClick={()=>{}}
                                                                >
                                                                    <Spinner animation="border" variant="light" size="sm" />
                                                                
                                                            </button>
                                              
                                                        :
                                                            <button type='submit'
                                                                    style={{
                                                                            position:'relative',
                                                                            width: 60,
                                                                            padding:2,   
                                                                            backgroundColor:'#FF6600',
                                                                            color:'#FFF',
                                                                            borderRadius:' 0px 5px 5px 0px',
                                                                            border:'1px solid #FFF',
                                                                            }}
                                                                    onClick={()=>{this.send()}}
                                                                >
                                                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                        style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%,-50%)',
                                                                        }}
                                                                    >
                                                                        <path d="M8.53846 1L15 6.5M15 6.5L8.53846 12M15 6.5L1 6.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>

                                                            </button>
                                                    }
                                                 </React.Fragment>
                                            }
                                           
                                           
                                        </Form.Group>

                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row >
                                <Col md={5} className='mx-auto'>
                                <div className='social'>
                                        <a href='https://web.facebook.com/Postienda-2337245059928811'target='blank'>
                                            <img  
                                                alt="Facebook" 
                                                src={facebook}/>
                                        </a>
                                        <a href='https://twitter.com/postienda' target='blank'>
                                            <img  
                                                alt="Twitter" 
                                                src={twitter}/>
                                        </a>
                                        <a href='https://www.instagram.com/pos.tienda/'  target='blank'>
                                            <img  
                                                alt="Instagram" 
                                                src={ig}/>
                                        </a>
                                       
                                      </div>
                                  
                                </Col>
                                <Col md={12}>
                                    <div className="text-center" style={{marginTop:30,}}>
                                        <p className='copyright'>Todos los Derechos Reservados © 2020 | Positivo Plus C.A</p>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                    </Container>
                </footer>
                
            </React.Fragment>
        )
    }

}